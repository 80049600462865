import { apiUrl, client } from "../modules/store";

export function queryRagModelAi(dto) {
  return client.post(apiUrl + "/admin/tailwinds", {
    ...dto,
  });
}
export function queryRagModelAiDemo(dto) {
  return client.post(apiUrl + "/admin/tailwinds-demo", {
    ...dto,
  });
}
export function getUserToken(id) {
  return client.get(apiUrl + "/auth/superadminlogin/" + id);
}
