import queryString from "query-string";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";
const BASE_ROUTE = "/customerPortal";

const customerPortalEndpoints = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    // Get monitoring limits with pagination
    getUserPortalHistory: builder.query({
      query: (baseParams: any) =>
        `${BASE_ROUTE}/history?${queryString.stringify(baseParams)}`,
      providesTags: [RtkTagTypes.GetPortalUserHistory],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),
  }),
});
export const { useGetUserPortalHistoryQuery } = customerPortalEndpoints;
