import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Popover from "@mui/material/Popover";
import { saveAs } from "file-saver";
import InfoIcon from "@mui/icons-material/Info";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Card, Form, Grid } from "components/lynx-components";
import { dateUtil } from "../../../services/date-util";
import { localStorageService } from "../../../services/local-storage-service";
import {
  downloadImage,
  getMonitoringLocationTimeSeries,
} from "../../../services/time-series-service";
import useDebounce from "hooks/useDebounce";
import useAlert from "hooks/useAlert";
import { useGetMonitoringLocationsQuery } from "services/rtkApi/endpoints/monitoringLocations";

export function TimeseriesList(props) {
  const now = new Date();
  const defaultDateFilter = {
    timeStart: dateUtil.convertDateTimeToLocal(
      new Date().setDate(now.getDate() - 14)
    ),
    timeEnd: dateUtil.convertDateTimeToLocal(now),
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [columnsByParameter, setColumnsByParameter] = useState([]);
  const [dataByParameter, setDataByParameter] = useState([]);
  const [columnsByDate, setColumnsByDate] = useState([]);
  const [dataByDate, setDataByDate] = useState([]);
  const [monitoringLocations, setMonitoringLocations] = useState([]);
  const [selectedMonitoringLocationId, setSelectedMonitoringLocationId] =
    useState(0);
  const [dateFilter, setDateFilter] = useState(defaultDateFilter);
  const [viewBy, setViewBy] = useState("parameter");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sortParams, setSortParams] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [popoverText, setPopoverText] = useState("");
  const [size, setSize] = useState([0, 0]);
  const [isFilterNas, setIsFilterNas] = useState(false);
  const [selectedMonitoringLocation, setSelectedMonitoringLocation] =
    useState(null);
  const timeSeriesSelectedMonitoringLocation =
    "timeSeriesSelectedMonitoringLocation";
  const timeSeriesDateFilter = "timeSeriesDateFilter";
  const targetRef = useRef();
  const history = useHistory();
  const debouncedDateFilter = useDebounce(dateFilter, 600);
  const { showAlert } = useAlert();
  const isWildlifeCamera =
    selectedMonitoringLocation == null
      ? false
      : selectedMonitoringLocation.isWildlifeCamera;

  const { data: monitoringLocationsData } = useGetMonitoringLocationsQuery({
    isTimeSeries: true,
  });

  React.useLayoutEffect(() => {
    function updateSize() {
      var width = targetRef.current.offsetWidth;
      setSize([width, targetRef.current.offsetHeight - 292]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (
      selectedMonitoringLocation != null &&
      dateFilter.timeStart !== "" &&
      dateFilter.timeEnd !== ""
    ) {
      loadData();
    }
  }, [
    debouncedDateFilter,
    pageSize,
    pageNumber,
    sortParams,
    selectedMonitoringLocation,
    isFilterNas,
  ]);

  useEffect(() => {
    setValuesFromLocalStorage();
  }, []);

  useEffect(() => {
    if (monitoringLocationsData) {
      setMonitoringLocations(monitoringLocationsData.data);
    }
  }, [monitoringLocationsData]);

  useEffect(() => {
    if (selectedMonitoringLocationId > 0 && !_.isEmpty(monitoringLocations)) {
      var monLoc = monitoringLocations.find(
        (x) => x.id == selectedMonitoringLocationId
      );
      if (monLoc) {
        setSelectedMonitoringLocation(monLoc);
        setPageNumber(0);
      }
    }
  }, [selectedMonitoringLocationId, monitoringLocations]);
  const handleClick = (event, text) => {
    setPopoverText(text);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const setValuesFromLocalStorage = () => {
    const qs = queryString.parse(history.location.search);
    var lsSelectedMonitoringLocation =
      qs.monitoringLocationId ??
      localStorageService.getLocalStorage(timeSeriesSelectedMonitoringLocation);
    if (lsSelectedMonitoringLocation) {
      setSelectedMonitoringLocationId(lsSelectedMonitoringLocation);
      localStorageService.setLocalStorage(
        timeSeriesSelectedMonitoringLocation,
        lsSelectedMonitoringLocation
      );
    }
    var lsTimeSeriesStartDate =
      qs.minDate && qs.maxDate
        ? {
            timeStart: dateUtil.convertDateTimeToLocal(qs.minDate),
            timeEnd: dateUtil.convertDateTimeToLocal(qs.maxDate),
          }
        : localStorageService.getLocalStorage(timeSeriesDateFilter);
    if (lsTimeSeriesStartDate) {
      setDateFilter(lsTimeSeriesStartDate);
      localStorageService.setLocalStorage(
        timeSeriesDateFilter,
        lsTimeSeriesStartDate
      );
    }
  };

  const loadData = () => {
    setIsLoading(true);
    var params = {
      pageNumber: pageNumber + 1,
      pageSize: pageSize,
      monitoringLocationId: selectedMonitoringLocationId,
      timeStart: dateFilter.timeStart,
      timeEnd: dateFilter.timeEnd,
      filterOutNas: isWildlifeCamera ? isFilterNas : false,
    };
    const qs = queryString.parse(history.location.search);
    if (!_.isEmpty(sortParams)) {
      params = {
        sort: sortParams[0].field + " " + sortParams[0].sort,
        ...params,
      };
    }
    if (qs.importHistoryId) {
      params = {
        importHistoryId: qs.importHistoryId,
        ...params,
      };
    }

    getMonitoringLocationTimeSeries(params).then((res) => {
      if (res.data.length > 0) {
        const paging = JSON.parse(res.headers.pagination);
        setTotalItems(paging.totalItems);
        convertDataByParameter(res.data);
        convertDataByDate(res.data);
        setIsLoading(false);
      } else {
        setTotalItems(0);
        setColumnsByDate([]);
        setDataByDate([]);
        setColumnsByParameter([]);
        setDataByParameter([]);
        setIsLoading(false);
      }
    });
  };

  const convertDataByParameter = (data) => {
    let newColumns = [];
    newColumns.push({
      field: "time",
      headerName: "Time",
      width: 175,
      type: "dateTime",
      filterable: false,
      valueGetter: (value) => {
        return dateUtil.convertColumnDateTimeToLocal(value, true);
      },
    });
    if (isWildlifeCamera) {
      newColumns.push({
        field: "observation",
        headerName: "Observation",
        width: 175,
        type: "string",
        filterable: false,
        sortable: false,
      });
    }

    var measurements = data[0].measurements;
    var s3Column = {};
    measurements.forEach((m) => {
      if (m.columnName === "S3PhotoLink") {
        s3Column = {
          field: getColumnNameLowerCase(m.columnName),
          headerName: "",
          width: 175,
          type: "string",
          filterable: false,
          sortable: false,
          renderCell: (params) => (
            <>
              <Button
                variant="outlined"
                onClick={() => {
                  var links = params.row.s3PhotoLink.split("|");
                  links.forEach((l, idx) => {
                    handleDownloadImage(l, idx);
                  });
                }}
              >
                Download Image
                {params.row.s3PhotoLink.split("|").length > 1 ? `s` : ``}
              </Button>
            </>
          ),
        };
        newColumns.push(s3Column);
      } else if (!isWildlifeCamera || m.columnName == "Temp") {
        newColumns.push({
          field: getColumnNameLowerCase(m.columnName),
          headerName: m.columnName,
          width: 175,
          type: "string",
          filterable: false,
          sortable: false,
          renderHeader: (params) => (
            <strong>{getRenderedColumnHeader(m.columnName)}</strong>
          ),
        });
      }
    });

    var convertedData = [];
    data.forEach((timeSeries, i) => {
      var obj = {
        time: timeSeries.time,
        id: i,
        observation: timeSeries.observation,
      };
      timeSeries.measurements.forEach((m) => {
        obj[getColumnNameLowerCase(m.columnName)] = m.value;
      });
      convertedData.push(obj);
    });
    setColumnsByParameter(newColumns);
    setDataByParameter(convertedData);
  };

  const getRenderedColumnHeader = (columnName) => {
    if (columnName.includes("||")) {
      var splitString = columnName.split("||");

      return (
        <>
          {splitString[0]}
          <IconButton
            className="ml-1"
            size="small"
            color="neutral"
            aria-label="info"
            onClick={(e) => {
              handleClick(e, splitString[1]);
            }}
          >
            <InfoIcon />
          </IconButton>
        </>
      );
    } else {
      return <>{columnName}</>;
    }
  };

  const convertDataByDate = (data) => {
    let newColumns = [];
    newColumns.push({
      field: "parameterName",
      headerName: "Parameter Name",
      width: 175,
      type: "string",
      filterable: false,
      sortable: false,
    });
    var convertedData = [];

    var observationRow = { parameterName: "Observation", id: 1 };
    var tempRow = { parameterName: "Temp", id: 2 };
    if (!isWildlifeCamera) {
      data[0].measurements.forEach((m, i) => {
        if (m.columnName !== "S3PhotoLink") {
          convertedData.push({ parameterName: m.columnName, id: i });
        }
      });
    }
    data.forEach((timeSeries, i) => {
      newColumns.push({
        field: "time" + i,
        headerName: dateUtil.convertDateTimeToLocal(timeSeries.time, true),
        width: 175,
        type: "string",
        filterable: false,
        sortable: false,
      });

      if (isWildlifeCamera) {
        observationRow["time" + i] = timeSeries.observation;
        var tempColumn = timeSeries.measurements.filter(
          (m) => m.columnName === "Temp"
        )[0];
        if (tempColumn) {
          tempRow["time" + i] = tempColumn.value;
        }
      } else {
        timeSeries.measurements.forEach((m) => {
          if (m.columnName !== "S3PhotoLink") {
            var obj = convertedData.filter(
              (x) => x.parameterName === m.columnName
            )[0];
            obj["time" + i] = m.value;
          }
        });
      }
    });
    if (isWildlifeCamera) {
      convertedData.push(observationRow);
      convertedData.push(tempRow);
    }
    setColumnsByDate(newColumns);
    setDataByDate(convertedData);
  };

  const getColumnNameLowerCase = (columnName) => {
    if (columnName !== null && columnName.length >= 2) {
      return columnName.charAt(0).toLowerCase() + columnName.slice(1);
    }
    return columnName;
  };

  const handleRdoViewByChange = (value) => {
    setViewBy(value);
  };

  const handleMonitoringLocationChange = (value) => {
    localStorageService.setLocalStorage(
      timeSeriesSelectedMonitoringLocation,
      value
    );
    setSelectedMonitoringLocationId(value);
  };

  const handleDateFilterChange = (e) => {
    let newDateFilter = { ...dateFilter };
    const name = e.target.name;
    const value = e.target.value;
    _.set(newDateFilter, name, value);

    localStorageService.setLocalStorage(timeSeriesDateFilter, newDateFilter);
    setDateFilter(newDateFilter);
  };

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const handleSortModelChange = (sort) => {
    setSortParams(sort);
  };

  const handleDownloadImage = (filePath, idx) => {
    downloadImage(filePath)
      .then((res) => {
        saveAs(
          res.data,
          `Monitoring Location Time Series Image_${idx + 1}.jpg`
        );
      })
      .catch(() => {
        showAlert("error", "Error downloading image");
      });
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
      ref={targetRef}
    >
      <Popover
        id={"test" + "_popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>{popoverText}</Typography>
      </Popover>
      <Grid>
        <Paper elevation={1}>
          <Grid.Col width={12}>
            <div className="d-flex h-100 ">
              <props.header />
            </div>
          </Grid.Col>
        </Paper>
        <Grid.Col md={6} width={12} className="pt-3">
          <Form.Group label="Monitoring Location">
            <Form.Select
              name="monitoringLocation"
              value={selectedMonitoringLocationId}
              onChange={(e) => handleMonitoringLocationChange(e.target.value)}
            >
              <option value={""}></option>
              {monitoringLocations.map((ml) => (
                <option value={ml.id} key={ml.id}>
                  {ml.monitoringLocationNumber} - {ml.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Grid.Col>
        <Grid.Row>
          <Grid.Col md={3} width={12}>
            <Form.Group label="Start Date">
              <Form.Input
                type="datetime-local"
                value={dateFilter.timeStart}
                name="timeStart"
                onChange={handleDateFilterChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={3} width={12}>
            <Form.Group label="End Date">
              <Form.Input
                type="datetime-local"
                value={dateFilter.timeEnd}
                name="timeEnd"
                onChange={handleDateFilterChange}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        {selectedMonitoringLocationId > 0 &&
          dateFilter.timeStart !== "" &&
          dateFilter.timeEnd !== "" && (
            <>
              <Grid.Col md={12} width={12} className="d-flex">
                <FormControl className="pl-3">
                  <RadioGroup
                    row
                    name="rdoViewBy"
                    value={viewBy}
                    onChange={(e) => handleRdoViewByChange(e.target.value)}
                  >
                    <FormControlLabel
                      value="parameter"
                      control={<Radio />}
                      label="Columns as Parameter"
                    />
                    <FormControlLabel
                      value="date"
                      control={<Radio />}
                      label="Columns as Date"
                    />
                  </RadioGroup>
                </FormControl>
                {isWildlifeCamera && (
                  <span
                    className="mt-2 ml-2"
                    style={{ width: 200, display: "flex" }}
                  >
                    <Form.Checkbox
                      checked={isFilterNas}
                      label={"Filter out NAs"}
                      onChange={(e) => {
                        setIsFilterNas(e.target.checked);
                        setPageNumber(0);
                      }}
                    />
                  </span>
                )}
              </Grid.Col>
              <Grid.Row className="ml-0 mr-0">
                <Card className="mb-0">
                  <div
                    style={{
                      height: size[1],
                      width: "100%",
                    }}
                  >
                    <DataGridPro
                      rows={
                        viewBy == "parameter" ? dataByParameter : dataByDate
                      }
                      columns={
                        viewBy == "parameter"
                          ? columnsByParameter
                          : columnsByDate
                      }
                      disableMultipleRowSelection={true}
                      loading={isLoading}
                      pagination={true}
                      paginationMode="server"
                      paginationModel={{ page: pageNumber, pageSize: pageSize }}
                      pageSizeOptions={[5, 10, 20, 50, 100]}
                      onPaginationModelChange={(e) => {
                        setPageNumber(e.page);
                        setPageSize(e.pageSize);
                      }}
                      rowCount={totalItems}
                      sortingMode="server"
                      onSortModelChange={handleSortModelChange}
                      sortModel={sortParams ?? []}
                      disableColumnReorder={viewBy === "date"}
                    />
                  </div>
                </Card>
              </Grid.Row>
            </>
          )}
      </Grid>
    </div>
  );
}
