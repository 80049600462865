import "./LynxDataGrid.css";

import {
  GridFilterInputSingleSelect,
  GridFilterOperator,
} from "@mui/x-data-grid-pro";

export const multipleSelectContainsOperator: GridFilterOperator<any, any>[] = [
  {
    label: "contains",
    value: "contains",
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return (value) => {
        return true;
      };
    },
    InputComponent: GridFilterInputSingleSelect,
  },
];

export const multipleSelectDoesNotContainOperator: GridFilterOperator<
  any,
  string
>[] = [
  {
    label: "does not contain",
    value: "doesNotContain",
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return (value) => {
        return true;
      };
    },
    InputComponent: GridFilterInputSingleSelect,
  },
];
