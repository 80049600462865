import { UserDto } from "types";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";

const BASE_ROUTE = "/admin";

const adminEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    queryRagModelAi: builder.mutation<any, any>({
      query: (dto) => ({
        url: `${BASE_ROUTE}/tailwinds`,
        method: "POST",
        body: dto,
      }),
    }),

    queryRagModelAiDemo: builder.mutation<any, any>({
      query: (dto) => ({
        url: `${BASE_ROUTE}/tailwinds-demo`,
        method: "POST",
        body: dto,
      }),
    }),

    getUserToken: builder.query<any, string>({
      query: (id) => ({
        url: `/auth/superadminlogin/${id}`,
        method: "GET",
      }),
    }),
    deleteUser: builder.mutation<void, string>({
      query: (userId) => ({
        url: `${BASE_ROUTE}/users/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetUsers, RtkTagTypes.GetCustomers],
    }),

    updateUserAdmin: builder.mutation<UserDto, { userId: string; dto: any }>({
      query: ({ userId, dto }) => ({
        url: `${BASE_ROUTE}/users/${userId}`,
        method: "PATCH",
        body: dto,
      }),
      invalidatesTags: (result, error, { id }: any) => [
        RtkTagTypes.GetUsers,
        { type: RtkTagTypes.GetUsers, id },
      ],
    }),

    addUserAdmin: builder.mutation<UserDto, any>({
      query: (dto) => ({
        url: `${BASE_ROUTE}/users`,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetUsers, RtkTagTypes.GetCustomers],
    }),
  }),
});

export const {
  useAddUserAdminMutation,
  useUpdateUserAdminMutation,
  useDeleteUserMutation,
} = adminEndpoint;
