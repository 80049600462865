import { useState, useEffect } from "react";
import { Grid, Card } from "components/lynx-components";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { AddLookupHeader } from "./../add-lookup-header";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import { EditLookupModal } from "./../edit-lookup-modal";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import {
  IncidentNotificationStages,
  InspectionFormTypes,
  LookupTypes,
  UserRoles,
} from "../../../../types/enums";
import { roleMatch } from "../../../../actions/auth";
import { useGetIncidentWorkflowSettingsQuery } from "services/rtkApi/endpoints/incidentWorkflowSettings";
import { IncidentNotificationSettingModal } from "./incident-notification-setting-modal";
import { IncidentAssignmentSettingModal } from "./incident-assignment-setting-modal";
import { IncidentAssignmentStages } from "types/enums";
import { useGetLookupsQuery } from "services/rtkApi/endpoints/lookups";
import { useGetInspectionFormsQuery } from "services/rtkApi/endpoints/inspectionForms";

export function IncidentSettings() {
  const editFieldProps = {
    field: "Actions",
    headerName: "",
    width: 50,
    type: "actions",
    resizable: false,
    disableColumnMenu: true,
    disableReorder: true,
    disableExport: true,
    hideSortIcons: true,
  };
  const typeColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditLookup(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "code",
      headerName: "Type",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
  ];

  const severityColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditLookup(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "code",
      headerName: "Severity",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
  ];
  const getIncidentWorkflowSettingColumns = (isNotification) => {
    return [
      {
        ...editFieldProps,
        renderCell: (params) => {
          return (
            <>
              <Tooltip title="Edit">
                <IconButton
                  onClick={() => {
                    if (isNotification) {
                      setSelectedIncidentNotificationSetting(params.row);
                      setShowNotificationSettingsModal(true);
                    } else {
                      setSelectedIncidentAssignmentSetting(params.row);
                      setShowAssignmentSettingsModal(true);
                    }
                  }}
                  aria-label="Edit"
                  size="small"
                  className="grid-edit-button"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
        width: 200,
        type: "string",
      },
      {
        field: "stage",
        headerName: "Workflow Stage",
        width: 200,
        type: "string",
        valueGetter: (value) => {
          switch (value) {
            case IncidentNotificationStages.IncidentInitialReportSubmitted:
              return "Initial Report Submitted";
            case IncidentNotificationStages.IncidentInitialReviewSubmitted:
              return "Initial Review Submitted";
            case IncidentNotificationStages.IncidentInvestigationSubmitted:
              return "Investigation Submitted";
            case IncidentNotificationStages.IncidentFinalReviewSubmitted:
              return "Final Review Submitted";
            case IncidentNotificationStages.IncidentActionCreated:
              return "Action Created";
            case IncidentNotificationStages.IncidentActionApproved:
              return "Action Approved";
            case IncidentNotificationStages.IncidentActionCompleted:
              return "Action Completed";
            case IncidentNotificationStages.IncidentActionVerified:
              return "Action Verified";
            case IncidentNotificationStages.IncidentActionDue:
              return "Action Due";
            case IncidentNotificationStages.IncidentActionOverdue:
              return "Action Overdue";
            case IncidentAssignmentStages.IncidentInitialReviewAssigned:
              return "Incident Initial Review";
            case IncidentAssignmentStages.IncidentFinalReviewAssigned:
              return "Incident Final Review";
            case IncidentAssignmentStages.IncidentActionApprovalAssigned:
              return "Action Approval";
            case IncidentAssignmentStages.IncidentActionVerificationAssigned:
              return "Action Verification";
            default:
              return "";
          }
        },
      },
      {
        field: "userNames",
        headerName: "User Names",
        width: 200,
        type: "string",
      },
      {
        field: "userTypes",
        headerName: "User Types",
        width: 200,
        type: "string",
      },

      {
        field: "incidentTypes",
        headerName: "Incident Types",
        width: 200,
        type: "string",
      },
      {
        field: "incidentSeverities",
        headerName: "Incident Severities",
        width: 200,
        type: "string",
      },
      {
        field: "actionTypes",
        headerName: "Action Types",
        width: 200,
        type: "string",
      },
    ];
  };
  const participantColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditLookup(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "code",
      headerName: "Type",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
  ];

  const subTypeColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditLookup(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "parentLookupCode",
      headerName: "Type",
      width: 200,
      type: "string",
    },
    {
      field: "code",
      headerName: "Subtype",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
  ];
  const {
    data: incidentNotificationSettings,
    isFetching: incidentNotificationSettingsIsFetching,
  } = useGetIncidentWorkflowSettingsQuery(false);

  const {
    data: incidentAssignmentSettings,
    isFetching: incidentAssignmentSettingsIsFetching,
  } = useGetIncidentWorkflowSettingsQuery(true);

  const [showLookupModal, setShowLookupModal] = useState(false);
  const [selectedLookup, setSelectedLookup] = useState({});
  const [incidentForms, setIncidentForms] = useState([]);
  const [showAssignmentSettingsModal, setShowAssignmentSettingsModal] =
    useState(false);
  const [
    selectedIncidentAssignmentSetting,
    setSelectedIncidentAssignmentSetting,
  ] = useState(false);
  const [showNotificationSettingsModal, setShowNotificationSettingsModal] =
    useState(false);
  const [
    selectedIncidentNotificationSetting,
    setSelectedIncidentNotificationSetting,
  ] = useState(null);
  const history = useHistory();

  const { data: formData } = useGetInspectionFormsQuery({
    onlyIncidentForms: true,
  });

  const { data: incidentTypesData, isFetching: isFetchingIncidentTypes } =
    useGetLookupsQuery({
      lookupType: LookupTypes.IncidentType,
      bypassFilter: true,
    });

  const { data: incidentSubTypesData, isFetching: isFetchingIncidentSubTypes } =
    useGetLookupsQuery({
      lookupType: LookupTypes.IncidentSubType,
      bypassFilter: true,
    });

  const { data: incidentSeveritiesData, isFetching: isFetchingSeverities } =
    useGetLookupsQuery({
      lookupType: LookupTypes.IncidentSeverity,
      bypassFilter: true,
    });

  const {
    data: incidentParticipantTypesData,
    isFetching: isFetchingParticipantTypes,
  } = useGetLookupsQuery({
    lookupType: LookupTypes.IncidentParticipantType,
    bypassFilter: true,
  });

  useEffect(() => {
    if (formData) {
      setIncidentForms(formData);
    }
  }, [formData]);

  const handleEditLookup = (lookup) => {
    setShowLookupModal(true);
    setSelectedLookup(lookup);
  };

  const handleLookupModalClose = () => {
    setShowLookupModal(false);
    setSelectedLookup({});
  };

  const handleLookupSave = () => {
    handleLookupModalClose();
  };

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Incident Settings
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>

              <Typography color="text.primary">Incident Settings</Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Incident Types
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              An Incident type is a broad-based category for data entry on the
              Add Incident form (e.g., health and safety, community,
              environmental).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Type"
              descriptionLabel="Description"
              lookupType="IncidentType"
              lookupName="Incident Type"
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={incidentTypesData || []}
                columns={typeColumns}
                disableMultipleRowSelection={true}
                loading={isFetchingIncidentTypes}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Incident Subtypes
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              An Incident Subtype is more specific than an Incident Type and is
              attached to specific incident types. (e.g., a water tank leak
              subtype under the environmental type, or a community grievance
              subtype under the community type).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Subtype"
              descriptionLabel="Description"
              lookupType="IncidentSubType"
              lookupName="Incident Subtype"
              hasParentLookup
              parentLookupLabel="Type"
              parentLookups={incidentTypesData || []}
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={incidentSubTypesData || []}
                columns={subTypeColumns}
                disableMultipleRowSelection={true}
                loading={isFetchingIncidentSubTypes}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Incident Severities
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Incident Severities are used to track and rank the severity of an
              incident (e.g., high, medium, low or P1-P5). You can also adapt
              your companies risk severity tracking matrix to Incident
              Severities.
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Severity"
              descriptionLabel="Description"
              lookupType="IncidentSeverity"
              lookupName="Incident Severity"
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={incidentSeveritiesData || []}
                columns={severityColumns}
                disableMultipleRowSelection={true}
                loading={isFetchingSeverities}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Incident Participant Types
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Incident Participant types are the types of participants that are
              either the witness or affected party of the incident. (e.g.,
              Contractor, Employee, Third Party, etc).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Type"
              descriptionLabel="Description"
              lookupType="IncidentParticipantType"
              lookupName="Incident Participant Type"
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={incidentParticipantTypesData || []}
                columns={participantColumns}
                disableMultipleRowSelection={true}
                loading={isFetchingParticipantTypes}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Incident Assignment Workflows
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Workflow builder for incident assignments (initial review, final
              review, action approval, action verifier). Workflows can be
              assigned to specific incident types and severities.
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <Card.Header className="justify-content-end">
              <Button
                variant="contained"
                onClick={() => {
                  setShowAssignmentSettingsModal(true);
                }}
              >
                Add
              </Button>
            </Card.Header>
            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={incidentAssignmentSettings || []}
                columns={getIncidentWorkflowSettingColumns(false)}
                loading={incidentAssignmentSettingsIsFetching}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Incident Notification Workflows
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Workflow builder for incident notifications. Workflows can be
              assigned to specific incident types and severities to trigger
              notifications. Assignment workflow emails take priority over
              notifications.
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <Card.Header className="justify-content-end">
              <Button
                variant="contained"
                onClick={() => {
                  setShowNotificationSettingsModal(true);
                }}
              >
                Add
              </Button>
            </Card.Header>
            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={incidentNotificationSettings || []}
                columns={getIncidentWorkflowSettingColumns(true)}
                loading={incidentNotificationSettingsIsFetching}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>

      {roleMatch([UserRoles.InternalUser]) && (
        <Grid.Row>
          <Grid.Col md={2} width={12}>
            <div className="">
              <Typography variant="h5" component="div">
                Incident Forms
              </Typography>
            </div>
          </Grid.Col>

          <Grid.Col md={10} width={12}>
            <Card>
              <Card.Header className="justify-content-end">
                <Button
                  variant="contained"
                  onClick={() => {
                    handleNavigateTo(null, "/settings/incidents/form-builder");
                  }}
                >
                  Add
                </Button>
              </Card.Header>

              <div style={{ height: 630, width: "100%" }}>
                <DataGridPro
                  columns={[
                    {
                      field: "name",
                      headerName: "Name",
                      width: 300,
                      type: "string",
                      renderCell: (params) => {
                        return (
                          <Button
                            onClick={(e) => {
                              handleNavigateTo(
                                e,
                                "/settings/incidents/form-builder/" +
                                  params.row.id
                              );
                            }}
                          >
                            {params.value}
                          </Button>
                        );
                      },
                    },
                    {
                      field: "formType",
                      headerName: "Type",
                      width: 300,
                      type: "string",
                      renderCell: (params) => {
                        return (
                          <span>
                            {params.value ==
                            InspectionFormTypes.IncidentInvestigation
                              ? "Investigation"
                              : "Witness"}
                          </span>
                        );
                      },
                    },
                    {
                      field: "description",
                      headerName: "Description",
                      type: "string",
                      width: 500,
                    },
                  ]}
                  rows={incidentForms}
                  disableColumnReorder
                  disableColumnFilter
                  disableColumnMenu
                  disableRowSelectionOnClick
                  disableChildrenSorting
                />
              </div>
            </Card>
          </Grid.Col>
        </Grid.Row>
      )}
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showLookupModal}
        onClose={handleLookupModalClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <EditLookupModal
          lookup={selectedLookup}
          codeLabel="Name"
          descriptionLabel="Description"
          handleModalClose={handleLookupModalClose}
          handleLookupSave={handleLookupSave}
          lookupName={
            selectedLookup.lookupType
              ? selectedLookup.lookupType.toLowerCase() == "unit"
                ? "Unit"
                : selectedLookup.lookupType.toLowerCase() ==
                  "monitoringeventtype"
                ? "Monitoring Event Type"
                : ""
              : ""
          }
        />
      </Dialog>
      {showNotificationSettingsModal && (
        <IncidentNotificationSettingModal
          handleClose={(e, reason) => {
            if (reason && reason == "backdropClick") return;
            setShowNotificationSettingsModal(false);
            setSelectedIncidentNotificationSetting(false);
          }}
          incidentTypes={incidentTypesData || []}
          incidentSeverities={incidentSeveritiesData || []}
          open={showNotificationSettingsModal}
          selectedIncidentNotificationSetting={
            selectedIncidentNotificationSetting
          }
        />
      )}
      {showAssignmentSettingsModal && (
        <IncidentAssignmentSettingModal
          handleClose={(e, reason) => {
            if (reason && reason == "backdropClick") return;
            setShowAssignmentSettingsModal(false);
            setSelectedIncidentAssignmentSetting(false);
          }}
          incidentTypes={incidentTypesData || []}
          incidentSeverities={incidentSeveritiesData || []}
          open={showAssignmentSettingsModal}
          selectedIncidentAssignmentSetting={selectedIncidentAssignmentSetting}
        />
      )}
    </Grid>
  );
}
