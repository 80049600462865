import { Card, Form, Grid, Icon } from "components/lynx-components";
import { CustomerDto, LynxGridColDef, UserDto } from "types";
import { dateUtil } from "../../services/date-util";
import { AdminUserModal } from "../dashboards/settings/admin-user-modal";
import { LynxDataGrid } from "../data-grid/lynx-data-grid";
import { EntityTypes } from "types/enums-ts";
import { useGetUsersForCustomerQuery } from "services/rtkApi/endpoints/users";
interface Props {
  handleModalClose: () => void;
  customerUsers: UserDto[];
  selectedCustomer: CustomerDto;
}
export function CustomerUserModal(props: Props) {
  const columns = [
    {
      field: "email",
      headerName: "Email",
      width: 200,
      type: "string",
    },
    {
      field: "firstName",
      headerName: "First name",
      width: 100,
      type: "string",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 100,
      type: "string",
    },
    {
      field: "isActive",
      headerName: "Is Active?",
      width: 100,
      type: "boolean",
    },
    {
      field: "isAdmin",
      headerName: "Is Admin?",
      width: 100,
      type: "boolean",
    },
    {
      field: "isInternal",
      headerName: "Is Internal?",
      width: 100,
      type: "boolean",
    },
    {
      field: "registrationCompletedDateTimeUtc",
      headerName: "Registered Date",
      width: 150,
      type: "dateTime",
      valueGetter: (value: any) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
  ] as LynxGridColDef[];
  return (
    <Form className="card dsmodal-main w-50">
      <Card.Body>
        <Card.Title>
          <Icon
            name="x"
            onClick={() => {
              props.handleModalClose();
            }}
            className="float-right pointer"
          ></Icon>
        </Card.Title>
        <Grid.Row>
          <Grid.Col md={12} width={12}>
            <div style={{ height: "500px" }}>
              <LynxDataGrid
                useQuery={useGetUsersForCustomerQuery}
                useQueryParams={{ customerId: props.selectedCustomer.id }}
                columns={columns}
                entityType={EntityTypes.User}
                localStorageName="admin_users"
                hasEditButton
                entityModal={AdminUserModal}
                passEntityOnEditClick
                entityModalProps={{ selectedCustomer: props.selectedCustomer }}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Form>
  );
}
