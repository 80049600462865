import { dateUtil } from "services/date-util";
import { LynxGridColDef } from "types";

export const columns = [
  {
    field: "userFullName",
    headerName: "Name",
    width: 100,
    type: "string",
  },

  {
    field: "userEmail",
    headerName: "Email",
    width: 150,
    type: "string",
  },
  {
    field: "type",
    headerName: "Action",
    width: 100,
    type: "string",
  },
  {
    field: "tableName",
    headerName: "Entity",
    width: 100,
    type: "string",
  },
  {
    field: "dateTimeUtc",
    headerName: "DateTime(UTC)",
    width: 200,
    type: "dateTime",
    valueGetter: (value) => {
      return dateUtil.convertColumnDateTimeToLocal(value);
    },
  },
  {
    field: "affectedColumns",
    headerName: "Data Affected",
    width: 150,
    type: "string",
  },
  {
    field: "oldValues",
    headerName: "Old Values",
    width: 250,
    type: "string",
  },
  {
    field: "newValues",
    headerName: "New Values",
    width: 450,
    type: "string",
  },
] as LynxGridColDef[];
