import React from "react";
import { MainSidebar } from "./dashboards/MainSidebar";
import Navbar from "./Navbar";
import { urlService } from "../services/url-service";
import { useLocation } from "react-router-dom";
import { AdminSidebar } from "./admin/AdminSidebar";
import useWindowDimensions from "../hooks/useWindowDimensions";
import _ from "lodash";
import * as serviceWorker from "../serviceWorker";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { WylooPortalNavbar } from "./microsites/wyloo/wyloo-portal-navbar";
import { hasToken, isTokenExpired } from "./../actions/auth";
import { useSelector } from "react-redux";
import { refreshAccessToken } from "services/rtkApi/apiService";
import { useOnlineStatus } from "./../hooks/useOnlineStatus";
export default function Layout(props) {
  const location = useLocation();
  const dimensions = useWindowDimensions();
  const isOffline = !useOnlineStatus();
  const [waitingWorker, setWaitingWorker] = React.useState(null);
  const [showReload, setShowReload] = React.useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const hasNoOverflow = () => {
    if (_.toLower(location.pathname) == "/events" && dimensions.isMobile) {
      return true;
    }
    return false;
  };

  const isRuby = () => {
    if (_.toLower(location.pathname) == "/ruby-ai") {
      return true;
    }
    return false;
  };
  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload(true);
  };
  const hasNoSidebar = () => {
    if (_.toLower(location.pathname) == "/organizations") {
      return true;
    }
    return false;
  };

  const isMicrosite = () => {
    if (
      _.toLower(location.pathname) == "/investor" ||
      _.toLower(location.pathname) == "/wyloo" ||
      _.toLower(location.pathname).includes("/wyloo/")
    ) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    const checkAuth = async () => {
      if (hasToken() && isTokenExpired() && !isOffline) {
        try {
          await refreshAccessToken(); // Wait for the token to refresh
        } catch (error) {
          console.error("Failed to refresh token:", error);
          return;
        }
      }
    };
    if (urlService.isPublicScreen()) {
      checkAuth();
    }
  }, []);
  React.useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
    var appVersion = localStorage.getItem("APP_VERSION");
    if (
      (appVersion && appVersion != process.env.REACT_APP_VERSION) | !appVersion
    ) {
      localStorage.setItem("APP_VERSION", process.env.REACT_APP_VERSION);
    }
  }, []);
  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };
  return (
    <>
      {!isMicrosite() && !isRuby() && <Navbar />}
      {isMicrosite() && <WylooPortalNavbar />}
      {urlService.isPublicScreen() ? (
        <> {props.children} </>
      ) : (
        <>
          {isAuthenticated && (
            <>
              {!urlService.isAdminScreen() ? (
                document.location.pathname.toLowerCase() !=
                  "/organizations" && <MainSidebar className="d-print-none" />
              ) : (
                <AdminSidebar />
              )}
            </>
          )}

          <div
            className={`${
              hasNoOverflow()
                ? "content-wrapper-no-overflow"
                : "content-wrapper"
            } ${!hasNoSidebar() ? "padding-left-sidebar" : ""} d-print-block`}
          >
            {props.children}
          </div>
        </>
      )}

      <Dialog open={showReload}>
        <DialogTitle>New Version Available</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A new version of the application is available. Refresh the page to
            get the updated version.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowReload(false)}>Cancel</Button>
          <Button onClick={reloadPage}>Refresh</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
