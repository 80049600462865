import ArticleIcon from "@mui/icons-material/Article";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import FactoryIcon from "@mui/icons-material/Factory";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import GasMeterOutlinedIcon from "@mui/icons-material/GasMeterOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { Grid } from "components/lynx-components";
import { UserRoles } from "../../../types/enums";
import { roleMatch } from "./../../../actions/auth";
import LaptopIcon from "@mui/icons-material/Laptop";
import ChecklistIcon from "@mui/icons-material/Checklist";
import "./settings.css";
import { useHistory } from "react-router-dom";
export function Settings() {
  var organization = useSelector((state) => state.organization);
  const history = useHistory();
  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row">
          <Grid.Col md={2} width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Settings
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col md={10} width={12}></Grid.Col>
        </Grid.Row>
      </Paper>
      <Grid.Row className="ml-0 mr-0 ">
        {roleMatch([UserRoles.Admin]) && (
          <Grid.Col xs={6} xl={2}>
            <Paper
              className={`settings-paper pointer`}
              onClick={() => history.push("/settings/assets")}
            >
              <HomeOutlinedIcon
                className="mb-4"
                sx={{ color: "#495057", fontSize: 60 }}
              />

              <h5>Assets</h5>
            </Paper>
          </Grid.Col>
        )}

        {roleMatch([
          UserRoles.Admin,
          UserRoles.EventsAdministrator,
          UserRoles.EventsEditor,
        ]) &&
          organization &&
          organization.customer &&
          organization.customer.eventsLicenseCount > 0 && (
            <Grid.Col xs={6} xl={2}>
              <Paper
                className={`settings-paper pointer`}
                onClick={() => history.push("/settings/events")}
              >
                <EventOutlinedIcon
                  className="mb-4"
                  sx={{ color: "#495057", fontSize: 60 }}
                />

                <h5>Events</h5>
              </Paper>
            </Grid.Col>
          )}
        {roleMatch([UserRoles.InternalUser]) &&
          organization &&
          organization.customer &&
          organization.customer.inspectionsLicenseCount > 0 && (
            <Grid.Col xs={6} xl={2}>
              <Paper
                className={`settings-paper pointer`}
                onClick={() => history.push("/settings/inspections")}
              >
                <ContentPasteIcon
                  className="mb-4"
                  sx={{ color: "#495057", fontSize: 60 }}
                />

                <h5>Inspections</h5>
              </Paper>
            </Grid.Col>
          )}
        {roleMatch([UserRoles.IncidentsUser]) &&
          roleMatch([UserRoles.Admin]) &&
          organization &&
          organization.customer &&
          organization.customer.incidentsLicenseCount > 0 && (
            <Grid.Col xs={6} xl={2}>
              <Paper
                className={`settings-paper pointer`}
                onClick={() => history.push("/settings/incidents")}
              >
                <WarningAmberIcon
                  className="mb-4"
                  sx={{ color: "#495057", fontSize: 60 }}
                />

                <h5>Incidents</h5>
              </Paper>
            </Grid.Col>
          )}
        {roleMatch([UserRoles.Admin]) && (
          <Grid.Col xs={6} xl={2}>
            <Paper
              className={`settings-paper pointer`}
              onClick={() => history.push("/settings/actions")}
            >
              <PendingActionsIcon
                className="mb-4"
                sx={{ color: "#495057", fontSize: 60 }}
              />

              <h5>Actions</h5>
            </Paper>
          </Grid.Col>
        )}
        {roleMatch([
          UserRoles.Admin,
          UserRoles.EventsAdministrator,
          UserRoles.EventsEditor,
        ]) &&
          organization &&
          organization.customer &&
          organization.customer.eventsLicenseCount > 0 && (
            <Grid.Col xs={6} xl={2}>
              <Paper
                className={`settings-paper pointer`}
                onClick={() => history.push("/settings/contacts")}
              >
                <RecentActorsOutlinedIcon
                  className="mb-4"
                  sx={{ color: "#495057", fontSize: 60 }}
                />
                <h5>Contacts</h5>
              </Paper>
            </Grid.Col>
          )}
        {roleMatch([
          UserRoles.Admin,
          UserRoles.MonitoringAdministrator,
          UserRoles.MonitoringEditor,
        ]) &&
          organization &&
          organization.customer &&
          organization.customer.monitoringLicenseCount > 0 && (
            <Grid.Col xs={6} xl={2}>
              <Paper
                className={`settings-paper pointer`}
                onClick={() => history.push("/settings/monitoring")}
              >
                <GasMeterOutlinedIcon
                  className="mb-4"
                  sx={{ color: "#495057", fontSize: 60 }}
                />
                <h5>Monitoring</h5>
              </Paper>
            </Grid.Col>
          )}
        {roleMatch([
          UserRoles.Admin,
          UserRoles.EventsAdministrator,
          UserRoles.EventsEditor,
        ]) &&
          organization &&
          organization.customer &&
          organization.customer.eventsLicenseCount > 0 && (
            <Grid.Col xs={6} xl={2}>
              <Paper
                className={`settings-paper pointer`}
                onClick={() => history.push("/settings/tags")}
              >
                <LabelOutlinedIcon
                  className="mb-4"
                  sx={{ color: "#495057", fontSize: 60 }}
                />
                <h5>Tags</h5>
              </Paper>
            </Grid.Col>
          )}
        {roleMatch([UserRoles.EnergyUser]) &&
          roleMatch([UserRoles.Admin]) &&
          organization &&
          organization.customer &&
          organization.customer.energyLogsLicenseCount > 0 && (
            <Grid.Col xs={6} xl={2}>
              <Paper
                className={`settings-paper pointer`}
                onClick={() => history.push("/settings/energy-tracking")}
              >
                <FactoryIcon
                  className="mb-4"
                  sx={{ color: "#495057", fontSize: 60 }}
                />
                <h5>Energy Tracking</h5>
              </Paper>
            </Grid.Col>
          )}
        {roleMatch([
          UserRoles.Admin,
          UserRoles.EventsAdministrator,
          UserRoles.EventsEditor,
        ]) &&
          organization &&
          organization.hasPermitFeature > 0 && (
            <Grid.Col xs={6} xl={2}>
              <Paper
                className={`settings-paper pointer`}
                onClick={() => history.push("/settings/permits")}
              >
                <ArticleIcon
                  className="mb-4"
                  sx={{ color: "#495057", fontSize: 60 }}
                />
                <h5>Permits</h5>
              </Paper>
            </Grid.Col>
          )}
        {roleMatch([UserRoles.Admin]) && (
          <Grid.Col xs={6} xl={2}>
            <Paper
              className={`settings-paper pointer`}
              onClick={() => history.push("/settings/organization")}
            >
              <PeopleOutlinedIcon
                className="mb-4"
                sx={{ color: "#495057", fontSize: 60 }}
              />
              <h5>Organization</h5>
            </Paper>
          </Grid.Col>
        )}
        {roleMatch([
          UserRoles.Admin,
          UserRoles.EventsAdministrator,
          UserRoles.EventsEditor,
        ]) && (
          <Grid.Col xs={6} xl={2}>
            <Paper
              className={`settings-paper pointer`}
              onClick={() => history.push("/imports")}
            >
              <FileUploadOutlinedIcon
                className="mb-4"
                sx={{ color: "#495057", fontSize: 60 }}
              />
              <h5>Imports</h5>
            </Paper>
          </Grid.Col>
        )}

        <Grid.Col xs={6} xl={2}>
          <Paper
            className={`settings-paper pointer`}
            onClick={() => history.push("/settings/notifications")}
          >
            <NotificationsNoneIcon
              className="mb-4"
              sx={{ color: "#495057", fontSize: 60 }}
            />
            <h5>Notifications</h5>
          </Paper>
        </Grid.Col>

        {roleMatch([UserRoles.Admin]) && (
          <Grid.Col xs={6} xl={2}>
            <Paper
              className={`settings-paper pointer`}
              onClick={() => history.push("/audit-log")}
            >
              <LockOutlinedIcon
                className="mb-4"
                sx={{ color: "#495057", fontSize: 60 }}
              />
              <h5>Audit Log</h5>
            </Paper>
          </Grid.Col>
        )}
        {roleMatch([UserRoles.Admin]) && organization.hasCustomerPortal && (
          <Grid.Col xs={6} xl={2}>
            <Paper
              className={`settings-paper pointer`}
              onClick={() => history.push("/settings/portal")}
            >
              <Diversity2Icon
                className="mb-4"
                sx={{ color: "#495057", fontSize: 60 }}
              />
              <h5>Customer Portal</h5>
            </Paper>
          </Grid.Col>
        )}
        <Grid.Col xs={6} xl={2}>
          <Paper
            className={`settings-paper pointer`}
            onClick={() => history.push("/settings/device")}
          >
            <LaptopIcon
              className="mb-4"
              sx={{ color: "#495057", fontSize: 60 }}
            />
            <h5>Device</h5>
          </Paper>
        </Grid.Col>
        {roleMatch([UserRoles.Admin]) &&
          organization.featureFlags.showAiFeatures && (
            <Grid.Col xs={6} xl={2}>
              <Paper
                className={`settings-paper pointer`}
                onClick={() => history.push("/compliance")}
              >
                <ChecklistIcon
                  className="mb-4"
                  sx={{ color: "#495057", fontSize: 60 }}
                />
                <h5>Compliance and Regulations</h5>
              </Paper>
            </Grid.Col>
          )}
        <Grid.Col xs={6} xl={2}>
          <Paper
            className={`settings-paper pointer`}
            onClick={() => history.push("/about")}
          >
            <InfoOutlinedIcon
              className="mb-4"
              sx={{ color: "#495057", fontSize: 60 }}
            />
            <h5>About</h5>
          </Paper>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
}
