import React, { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import { MultiSelect } from "components/form-controls/multi-select";
import { useAppSelector } from "modules/hooks";
import { Form } from "components/lynx-components";

interface InspectionSchedulerDetailsProps {
  values: {
    assetId: number;
    inspectionFormId: number;
    assignedToUserId?: number;
    description: string;
    equipmentId?: number;
    assetSubLocationId?: number;
    permits?: number[];
  };
  errors: { [key: string]: any };
  touched: { [key: string]: boolean };
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLSelectElement>;
  assetsLookupData: any[];
  inspectionFormData: any[];
  equipmentData: { data: any[] };
  assetSubLocData: { data: any[] };
  permitsData: { data: any[] };
  isEquipmentRequired: boolean;
  isDrillHoleRequired: boolean;
}

const InspectionSchedulerDetails: FC<InspectionSchedulerDetailsProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  assetsLookupData,
  inspectionFormData,
  equipmentData,
  assetSubLocData,
  permitsData,
  isEquipmentRequired,
  isDrillHoleRequired,
}) => {
  const { users } = useAppSelector((state) => state.lookups);
  const { organization } = useAppSelector((state) => state);

  const inspectionUsers = useMemo(() => {
    if (users) {
      return users.filter((x) => x.isInspectionsUser);
    }
  }, [users]);
  
  return (
    <Box minHeight={320}>
      <Form.Group isRequired label="Asset">
        <Form.Select
          id="assetId"
          name="assetId"
          onChange={handleChange}
          value={values.assetId}
          error={!!errors.assetId && touched.assetId && errors.assetId}
          onBlur={handleBlur}
        >
          <option value=""></option>
          {assetsLookupData?.map((data) => (
            <option value={data.id} key={data.id}>
              {data.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      {organization.hasPermitFeature && (
        <Form.Group label="Associated Permits">
          <MultiSelect
            name="permits"
            onChange={handleChange}
            value={values.permits}
            dropdownValues={permitsData?.data}
            key="id"
            label="permitNumber"
            error={!!errors.permits && touched.permits && errors.permits}
            onBlur={handleBlur}
          />
        </Form.Group>
      )}
      <Form.Group isRequired label="Inspection Form">
        <Form.Select
          id="inspectionFormId"
          name="inspectionFormId"
          onChange={handleChange}
          value={values.inspectionFormId}
          error={
            !!errors.inspectionFormId &&
            touched.inspectionFormId &&
            errors.inspectionFormId
          }
          onBlur={handleBlur}
        >
          <option value=""></option>
          {inspectionFormData?.map((data) => (
            <option value={data.id} key={data.id}>
              {data.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      {isEquipmentRequired && (
        <Form.Group isRequired label={"Equipment"}>
          <Form.Select
            id="equipmentId"
            name="equipmentId"
            onChange={handleChange}
            value={values.equipmentId}
            error={
              !!errors.equipmentId && touched.equipmentId && errors.equipmentId
            }
            onBlur={handleBlur}
            disabled={!values.assetId}
          >
            <option value={""}></option>
            {equipmentData?.data
              .filter((x: any) => x.assetId == values.assetId)
              .map((e: any) => (
                <option value={e.id} key={e.id}>
                  {e.name}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      )}

      {isDrillHoleRequired && (
        <Form.Group isRequired label={"Drill Hole"}>
          <Form.Select
            name="assetSubLocationId"
            onChange={handleChange}
            value={values.assetSubLocationId}
            error={
              !!errors.assetSubLocationId &&
              touched.assetSubLocationId &&
              errors.assetSubLocationId
            }
            onBlur={handleBlur}
            disabled={!values.assetId}
          >
            <option value={""}></option>
            {assetSubLocData?.data?.map((e: any) => (
              <option value={e.id} key={e.id}>
                {e.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}
      <Form.Group label="Assigned To">
        <Form.Select
          name="assignedToUserId"
          onChange={handleChange}
          value={values.assignedToUserId}
          error={
            !!errors.assignedToUserId &&
            touched.assignedToUserId &&
            errors.assignedToUserId
          }
          onBlur={handleBlur}
        >
          <option value={""}></option>
          {inspectionUsers?.map((e: any) => (
            <option value={e.id} key={e.id}>
              {e.fullName}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group label="Description">
        <LynxTextArea
          name="description"
          id="description"
          onChange={handleChange}
          value={values.description}
          error={
            !!errors.description && touched.description && errors.description
          }
          onBlur={handleBlur}
        />
      </Form.Group>
    </Box>
  );
};

export default InspectionSchedulerDetails;
