import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React from "react";
import "./LynxDataGrid.css";
import { GridFilterOperator } from "@mui/x-data-grid-pro";

export const rangeOfDateOperator: GridFilterOperator<any, string>[] = [
  {
    label: "is within a range of",
    value: "isRange",
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 4) {
        return null;
      }
      if (
        filterItem.value[0] == null ||
        filterItem.value[1] == null ||
        filterItem.value[2] == null ||
        filterItem.value[3] == null
      ) {
        return null;
      }

      return (value) => {
        return true;
      };
    },
    InputComponent: RangeOfFilterInput,
  },
];
const SUBMIT_FILTER_STROKE_TIME = 500;
function RangeOfFilterInput(props: any) {
  const { item, applyValue, focusElementRef = null } = props;

  const filterTimeout = React.useRef<NodeJS.Timeout>();
  const [filterValueState, setFilterValueState] = React.useState(
    item.value ?? [undefined, "daysInThePast", undefined, "daysInTheFuture"]
  );

  const [applying, setIsApplying] = React.useState(false);

  React.useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  React.useEffect(() => {
    const itemValue = item.value ?? [
      undefined,
      "daysInThePast",
      undefined,
      "daysInTheFuture",
    ];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (
    fromDays: any,
    fromRange: any,
    toDays: any,
    toRange: any
  ) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([fromDays, fromRange, toDays, toRange]);

    if (
      fromDays != null &&
      fromRange != null &&
      toDays != null &&
      toRange != null
    ) {
      setIsApplying(true);
      filterTimeout.current = setTimeout(() => {
        setIsApplying(false);
        applyValue({ ...item, value: [fromDays, fromRange, toDays, toRange] });
      }, SUBMIT_FILTER_STROKE_TIME);
    }
  };

  const handleFromDaysFilterChange = (event: any) => {
    const newFromDays = event.target.value;
    updateFilterValue(
      newFromDays,
      filterValueState[1],
      filterValueState[2],
      filterValueState[3]
    );
  };
  const handleFromRangeFilterChange = (event: any) => {
    const newFromRange = event.target.value;
    updateFilterValue(
      filterValueState[0],
      newFromRange,
      filterValueState[2],
      filterValueState[3]
    );
  };

  const handleToDaysFilterChange = (event: any) => {
    const newToDays = event.target.value;
    updateFilterValue(
      filterValueState[0],
      filterValueState[1],
      newToDays,
      filterValueState[3]
    );
  };
  const handleToRangeFilterChange = (event: any) => {
    const newToRange = event.target.value;
    updateFilterValue(
      filterValueState[0],
      filterValueState[1],
      filterValueState[2],
      newToRange
    );
  };
  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "end",
        height: 48,
        width: 500,
      }}
    >
      <TextField
        sx={{ width: 70 }}
        name="lower-bound-input"
        placeholder="From"
        label="From"
        variant="standard"
        value={Number(filterValueState[0])}
        onChange={handleFromDaysFilterChange}
        type="number"
        inputRef={focusElementRef}
      />
      <FormControl sx={{ width: 200 }}>
        <Select
          name="lower-bound-input"
          labelId="lower-bound-input"
          id="lower-bound-input-select"
          value={filterValueState[1]}
          onChange={handleFromRangeFilterChange}
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
          }}
        >
          <MenuItem value={"daysInThePast"}>days in the past</MenuItem>
          <MenuItem value={"daysInTheFuture"}>days in the future</MenuItem>
        </Select>
      </FormControl>
      <TextField
        sx={{ width: 70, marginLeft: "0.5rem" }}
        name="lower-bound-input"
        placeholder="From"
        label="To"
        variant="standard"
        value={Number(filterValueState[2])}
        onChange={handleToDaysFilterChange}
        type="number"
        inputRef={focusElementRef}
      />
      <FormControl sx={{ width: 200 }}>
        <Select
          name="lower-bound-input"
          labelId="lower-bound-input"
          id="lower-bound-input-select"
          value={filterValueState[3]}
          onChange={handleToRangeFilterChange}
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
          }}
        >
          <MenuItem value={"daysInThePast"}>days in the past</MenuItem>
          <MenuItem value={"daysInTheFuture"}>days in the future</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
