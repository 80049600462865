import { LynxGridColDef } from "types";
import { LookupTypes } from "../../../types/enums";
import { useGetLookupsQuery } from "services/rtkApi/endpoints/lookups";

export const contactGroupColumns: LynxGridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    width: 150,
    type: "string",
  },
  {
    field: "contactGroupType",
    headerName: "Type",
    width: 150,
    type: "singleSelect",
    query: useGetLookupsQuery,
    queryParams: { lookupType: LookupTypes.ContactGroupType },
    getOptionValue: (value: any) => (!value ? "" : value.code),
    getOptionLabel: (value: any) => (!value ? "" : value.code),
  },

  {
    field: "description",
    headerName: "Description",
    width: 250,
    type: "string",
  },
  {
    field: "notes",
    headerName: "Notes",
    width: 250,
    type: "string",
  },
  {
    field: "numberOfAssociatedContacts",
    headerName: "# Associated Contacts",
    width: 250,
    type: "number",
  },
  {
    field: "numberOfAssociatedEvents",
    headerName: "# Associated Events",
    width: 250,
    type: "number",
    filterable: false,
    sortable: false,
  },
];
