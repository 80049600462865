import queryString from "query-string";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";

const BASE_ROUTE = "/correspondenceLogs";

const correspondenceLogsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    // Fetch correspondence logs with pagination
    getEntityCorrespondenceLogs: builder.query({
      query: (logParams: any) =>
        `${BASE_ROUTE}?${queryString.stringify(logParams)}`,
      providesTags: [RtkTagTypes.GetCorrespondenceLogs],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Get a single correspondence log by ID
    getCorrespondenceLog: builder.query({
      query: (id: number) => `${BASE_ROUTE}/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetCorrespondenceLogs, id },
      ],
    }),

    // Create a new correspondence log
    createCorrespondenceLog: builder.mutation({
      query: (dto: any) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [
        RtkTagTypes.GetCorrespondenceLogs,
        RtkTagTypes.GetAttachments,
        RtkTagTypes.GetContacts,
      ],
    }),

    // Update an existing correspondence log
    updateCorrespondenceLog: builder.mutation({
      query: ({ id, dto }: { id: number; dto: any }) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetCorrespondenceLogs, id },
        RtkTagTypes.GetCorrespondenceLogs,
        RtkTagTypes.GetContacts,
      ],
    }),

    // Delete a correspondence log
    deleteCorrespondenceLog: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetCorrespondenceLogs],
    }),
  }),
});

export const {
  useGetEntityCorrespondenceLogsQuery,
  useCreateCorrespondenceLogMutation,
  useDeleteCorrespondenceLogMutation,
  useUpdateCorrespondenceLogMutation,
} = correspondenceLogsEndpoint;
