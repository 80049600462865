import Button from "@mui/material/Button";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Card, Dimmer, Form, Grid, Icon } from "components/lynx-components";
import { roleMatch } from "../../../../actions/auth";
import { validationService } from "../../../../services/validation";
import { LynxDialog } from "../../../lynx-dialog";
import { MultiSelect } from "../../../form-controls/multi-select";

import {
  createEnergyParameter,
  updateEnergyParameter,
  deleteEnergyParameter,
  getParameterTypes,
} from "../../../../services/energy-service";
import { UserRoles, LookupTypes } from "../../../../types/enums";
import useAlert from "hooks/useAlert";
import { useLazyGetLookupsQuery } from "services/rtkApi/endpoints/lookups";

const initialForm = {
  id: 0,
  name: "",
  description: "",
  cO2Factor: null,
  cH4Factor: null,
  n2OFactor: null,
  biogeniceCO2Factor: null,
  aR4: null,
  aR5: null,
  isRequired: false,
  unitId: null,
  isInUse: false,
  energyLogTypes: [],
};

export function EnergyParameterModal(props) {
  const [formState, setFormState] = useState(initialForm);
  const [showDelete, setShowDelete] = useState(false);
  const [unitLookups, setUnitLookups] = useState([]);
  const [allowDelete, setAllowDelete] = useState(false);
  const [parameterTypes, setParameterTypes] = useState([]);
  const [energyLogTypes, setEnergyLogTypes] = useState([]);
  const [selectedParameterType, setSelectedParameterType] = useState(
    props.parameterType
  );
  const { showAlert } = useAlert();

  const [getLookupsTrigger] = useLazyGetLookupsQuery();

  useEffect(() => {
    if (props.entity.id > 0) {
      setFormStateFromProps(props.entity);
    }
  }, [energyLogTypes, props.entity.id]);

  useEffect(() => {
    getLookupsTrigger({lookupType: LookupTypes.Unit}).then((res) => {
      setUnitLookups(res.data);
    });

    getParameterTypes().then((res) => {
      setParameterTypes(res.data);
    });

    getLookupsTrigger({lookupType: LookupTypes.EnergyLogType})
      .then((res) => {
        var energyLogTypes = [];
        res.data.forEach((d) => {
          energyLogTypes.push({
            lookupId: d.id,
            code: d.code,
          });
        });

        setEnergyLogTypes(energyLogTypes);
      })
      .catch(() => {});
  }, []);

  const setFormStateFromProps = (energyParameter) => {
    var selectedEnergyLogTypes = [];
    energyParameter.energyLogTypes.forEach((elt) => {
      var selectedEnergyLogType = energyLogTypes.filter(
        (e) => e.lookupId === elt.lookupId
      )[0];
      if (selectedEnergyLogType) {
        selectedEnergyLogTypes.push(selectedEnergyLogType);
      }
    });

    let newState = {
      id: energyParameter.id,
      name: energyParameter.name,
      description: energyParameter.description,
      cO2Factor: energyParameter.cO2Factor,
      cH4Factor: energyParameter.cH4Factor,
      n2OFactor: energyParameter.n2OFactor,
      biogeniceCO2Factor: energyParameter.biogeniceCO2Factor,
      aR4: energyParameter.aR4,
      aR5: energyParameter.aR5,
      isRequired: energyParameter.isRequired,
      unitId: energyParameter.unitId,
      isInUse: energyParameter.isInUse,
      energyLogTypes: selectedEnergyLogTypes,
    };
    setFormState(newState);
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value, checked } = e.target;
    if (name == "isRequired") {
      _.set(newState, name, checked);
    } else {
      _.set(newState, name, value);
    }

    setFormState(newState);
  };

  const handleParameterTypeChange = (e) => {
    setSelectedParameterType(e.target.value);
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );

    validationService.validateRequiredField(
      newState,
      "energyLogTypes",
      "energyLogTypesError",
      "Energy Log Type"
    );

    validationService.validateRequiredField(
      newState,
      "unitId",
      "unitIdError",
      "Unit"
    );

    isFormValid = !validationService.hasError(
      newState,
      "nameError",
      "unitIdError"
    );

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    //unset errors
    let formToSave = validationService.unsetErrors(
      formState,
      "nameError",
      "unitIdError",
      "energyLogTypesError"
    );
    formToSave = validationService.unsetFields(formToSave);

    formToSave.parameterType = selectedParameterType;
    if (props.entity.id <= 0) {
      createEnergyParameter(formToSave)
        .then((res) => {
          showAlert("success", `Energy parameter created.`);
          props.handleModalClose(true);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
    if (props.entity.id > 0) {
      updateEnergyParameter(props.entity.id, formToSave)
        .then((res) => {
          showAlert("success", `Energy parameter saved.`);
          props.handleModalClose(true);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
  };

  const deleteItem = () => {
    deleteEnergyParameter(props.entity.id).then(() => {
      showAlert("success", `Energy parameter deleted.`);
      props.handleModalClose(true);
    });
  };

  const handleDelete = () => {
    setShowDelete(true);
  };

  return (
    <Form className="card dsmodal-main lynx-modal">
      <Card.Body>
        <Card.Title>
          {props.entity.id > 0
            ? `Edit Energy Parameter`
            : `Add Energy Parameter`}
          <Icon
            name="x"
            onClick={() => props.handleModalClose(false)}
            className="float-right pointer"
          ></Icon>
        </Card.Title>
        <Dimmer>
          <Grid.Row>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Parameter Type">
                <Form.Select
                  name="parameterType"
                  value={selectedParameterType}
                  onChange={handleParameterTypeChange}
                  disabled={props.entity.id > 0}
                >
                  {parameterTypes.map((type) => (
                    <option value={type.item1} key={type.item1}>
                      {type.item2}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group isRequired label="Energy Log Type">
                <MultiSelect
                  name="energyLogTypes"
                  onChange={handleInputChange}
                  value={formState.energyLogTypes}
                  dropdownValues={energyLogTypes}
                  key="lookupId"
                  label="code"
                  id="energyLogTypes"
                  error={formState.energyLogTypesError}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group isRequired label="Name">
                <Form.Input
                  name="name"
                  error={formState.nameError}
                  onChange={handleInputChange}
                  value={formState.name}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Description">
                <Form.Input
                  name="description"
                  onChange={handleInputChange}
                  value={formState.description}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Form.Group label="CO2 Factor">
                <Form.Input
                  type="number"
                  name="cO2Factor"
                  onChange={handleInputChange}
                  value={formState.cO2Factor}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Form.Group label="CH4 Factor">
                <Form.Input
                  type="number"
                  name="cH4Factor"
                  onChange={handleInputChange}
                  value={formState.cH4Factor}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Form.Group label="N2O Factor">
                <Form.Input
                  type="number"
                  name="n2OFactor"
                  onChange={handleInputChange}
                  value={formState.n2OFactor}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Form.Group label="Biogenice CO2 Factor">
                <Form.Input
                  type="number"
                  name="biogeniceCO2Factor"
                  onChange={handleInputChange}
                  value={formState.biogeniceCO2Factor}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Form.Group label="AR4">
                <Form.Input
                  type="number"
                  name="aR4"
                  onChange={handleInputChange}
                  value={formState.aR4}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Form.Group label="AR5">
                <Form.Input
                  type="number"
                  name="aR5"
                  onChange={handleInputChange}
                  value={formState.aR5}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group isRequired label="Unit">
                <Form.Select
                  name="unitId"
                  onChange={handleInputChange}
                  value={formState.unitId}
                  error={formState.unitIdError}
                >
                  <option value={""}></option>
                  {unitLookups.map((type) => (
                    <option value={type.id} key={type.id}>
                      {type.code}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            {/* <Grid.Col md={12} width={12}>
              <Form.Group>
                <Form.Checkbox
                  label="Required?"
                  name="isRequired"
                  checked={formState.isRequired}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Grid.Col> */}
          </Grid.Row>
        </Dimmer>
      </Card.Body>
      <Card.Footer>
        {props.entity.id <= 0 && (
          <Button
            variant="contained"
            className="float-right"
            onClick={saveForm}
          >
            Add Energy Parameter
          </Button>
        )}
        {props.entity.id > 0 && (
          <div>
            <div>
              {roleMatch([UserRoles.Admin]) && (
                <Button
                  disabled={formState.isInUse}
                  variant="contained"
                  color="error"
                  className="float-left"
                  onClick={handleDelete}
                >
                  Delete Energy Parameter
                </Button>
              )}
              {formState.isInUse && (
                <span className="ml-2"> Unable to delete when in use</span>
              )}
            </div>

            <Button
              variant="contained"
              className="float-right"
              onClick={saveForm}
            >
              Save Energy Parameter
            </Button>
          </div>
        )}
      </Card.Footer>
      <LynxDialog
        open={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteItem}
        title={`Delete Energy Parameter?`}
        description={
          "The energy parameter will be removed from all associated energy logs."
        }
      />
    </Form>
  );
}
