import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { FC, useState } from "react";
import { EntityTypes } from "../../../types/enums";
import Tags from "./tags";
import { EventDto } from "types";
import { Form, Grid } from "components/lynx-components";
import { SearchableSelect } from "components/form-controls/searchable-select";
import { Box, Button, Link, Paper, styled, Typography } from "@mui/material";
import { LynxDialog } from "components/lynx-dialog";
import _ from "lodash";
import CommentsInput from "./comments-input";

interface EventDetailsCommunityProps {
  event: EventDto;
}
interface FormProps {
  type: string;
  comment: string;
  primaryTopic: string;
  secondaryTopic: string;
  plainTextComment: string;
}
const initialForm: FormProps = {
  type: "",
  comment: "",
  primaryTopic: "",
  secondaryTopic: "",
  plainTextComment: "",
};
const EventDetailsTags: FC<EventDetailsCommunityProps> = ({ event }) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormProps>(initialForm);
  const typeOptions = ["Grievance", "Commitment", "Materiality"];

  const handleInputChange = (e: any) => {
    let newState = { ...formState };
    const { name, value } = e.target;

    _.set(newState, name, value);

    setFormState(newState);
  };
  const handleChangeCommentableFollowupDescription = (e: any, p: any) => {
    const newState = { ...formState };
    _.set(newState, "comment", e);
    _.set(newState, "plainTextComment", p);
    setFormState(newState);
  };

  function getContent() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Col md={12} width={12}>
            <Form.Group label="Type" isRequired>
              <Form.Select
                onChange={handleInputChange}
                name={`type`}
                value={formState.type}
              >
                <option value={""}></option>
                {typeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={12} width={12}>
            <Form.Group label="Comment" isRequired>
              <CommentsInput
                value={formState.comment}
                users={[]}
                canTagEvent
                onChange={(e: any, n: string, p: string) => {
                  handleChangeCommentableFollowupDescription(n, p);
                }}
              />
              <Typography variant="caption">
                Type "@" to tag users and events or "#" to tag contacts or
                contact groups
              </Typography>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    );
  }
  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  }));
  return (
    <Card className="lynx-card" variant="outlined" sx={{ width: "100%" }}>
      <CardHeader
        className="lynx-card-header"
        title="Commitments, Grievances, and Materiality"
      />
      <CardContent className="p-2">
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Item variant="outlined" className="mb-2">
                <div className="d-flex">
                  <Typography variant="h6" component="div">
                    Grievance
                  </Typography>
                </div>
                <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
                  Residents have reported excessive dust from construction
                  activities, impacting air quality and daily life. We request
                  mitigation measures such as regular watering of roads and dust
                  barriers.
                </Typography>
                <Box>
                  <Link href="#" underline="none">
                    Edit
                  </Link>{" "}
                  <Link href="#" underline="none" className="text-red ml-1">
                    Delete
                  </Link>
                </Box>
              </Item>
              <Item variant="outlined" className="mb-2">
                <div className="d-flex">
                  <Typography variant="h6" component="div">
                    Commitment
                  </Typography>
                </div>

                <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
                  We commit to hosting quarterly town hall meetings to provide
                  updates on project progress and address community concerns.
                </Typography>
                <Box>
                  <Link href="#" underline="none">
                    Edit
                  </Link>{" "}
                  <Link href="#" underline="none" className="text-red ml-1">
                    Delete
                  </Link>
                </Box>
              </Item>
              <Item variant="outlined" className="mb-2">
                <div className="d-flex">
                  <Typography variant="h6" component="div">
                    Materiality
                  </Typography>
                </div>

                <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
                  Access to clean drinking water is a top priority for the
                  community. Any project development should include assessments
                  of potential impacts on local water sources.
                </Typography>
                <Box>
                  <Link href="#" underline="none">
                    Edit
                  </Link>{" "}
                  <Link href="#" underline="none" className="text-red ml-1">
                    Delete
                  </Link>
                </Box>
              </Item>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="mt-2">
            <Grid.Col>
              <Button variant="outlined" onClick={() => setShowForm(true)}>
                Add
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </CardContent>
      {showForm && (
        <LynxDialog
          dividers
          maxWidth={"md"}
          fullWidth
          isCloseInHeader
          open={showForm}
          title="Commitments, Grievances, and Materiality"
          dialogContent={getContent()}
          handleClose={() => setShowForm(false)}
          handleSave={() => {}}
          saveButtonText={"Add"}
        ></LynxDialog>
      )}
    </Card>
  );
};

export default EventDetailsTags;
