import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import { loadAccount } from "../actions/account";
import { disableTwoFactor, loginUser } from "../actions/auth";
import LynxImage from "../images/Lynx-brandmark-long-hor-colour-rgb.svg";
import { getMaintenanceMessage } from "../services/settings";
import _ from "lodash";
import useOnlineStatus from "../hooks/useOnlineStatus";
import "./login.css";
import queryString from "query-string";
import { loadUserOrg } from "../actions/organization";
import useAlert from "../hooks/useAlert";
import { MainFooter } from "components/dashboards/main-footer";
import {
  authorizeTwoFactor,
  checkTwoFactorStatus,
  resendTwoFactorCode,
} from "services/auth-service";
import TwoFactorInput from "components/form-controls/two-factor-input";
import { navigateTo } from "services/navigation-service";
import { receiveLogin } from "../actions/auth";
import { LynxDialog } from "components/lynx-dialog";
import { refreshAccessToken } from "services/rtkApi/apiService";

const LoginContainer = (props) => {
  const { isAuthenticated, requiresOrganizationSelection, requiresTwoFactor } =
    props.auth;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const [loginDisabled, setLoginDisabled] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState("");
  const [showTwoFactor, setShowTwoFactor] = useState(false);

  const [twoFactorError, setTwoFactorError] = useState(null);
  const { errorMessage } = props.auth;
  const isOffline = !useOnlineStatus();
  const [dialogText, setDialogText] = useState(null);
  const { showAlert } = useAlert();
  const history = useHistory();

  useEffect(() => {
    if (errorMessage) {
      showAlert("error", errorMessage);
      setLoginDisabled(false);
    }
  }, [errorMessage]);

  useEffect(() => {
    getMaintenanceMessage().then((res) => {
      let dto = res.data;
      if (dto.isEnabled) {
        setMaintenanceMessage(dto.text);
      }
    });
  }, []);

  useEffect(() => {
    if (location.pathname === "/two-factor") {
      checkTwoFactorStatus()
        .then(() => setShowTwoFactor(true))
        .catch(() => {
          const qs = queryString.parse(props.location.search);
          if (qs.redirect) {
            navigateTo(history, "/authorize?redirect=" + qs.redirect, null);
          }
          navigateTo(history, "/authorize", null);
        });
    }
  }, [location.pathname]);

  const handleKeyDown = (e) => {
    if (e.code === "Enter") {
      doAuthorize();
    }
  };

  const doAuthorize = () => {
    setLoginDisabled(true);
    const creds = {
      email: email.trim(),
      password: password.trim(),
      url: location.pathname,
    };
    props.loginUser(creds);
  };

  const handleCodeEntered = (code) => {
    authorizeTwoFactor(code)
      .then((res) => {
        dispatch(receiveLogin(res.data));
      })
      .catch((err) => {
        if (err.response.status === 404) {
          showAlert("error", "Session has expired. Please sign in again.");
          setShowTwoFactor(false);
          setLoginDisabled(false);
          dispatch(disableTwoFactor());
          const qs = queryString.parse(props.location.search);
          if (qs.redirect) {
            navigateTo(history, "/authorize?redirect=" + qs.redirect, null);
          }
          navigateTo(history, "/authorize", null);
        } else {
          setTwoFactorError(err.response.data.message);
        }
      });
  };

  if (isAuthenticated) {
    const qs = queryString.parse(props.location.search);
    return <Redirect to={qs.redirect || "/dashboard"} />;
  }

  if (requiresOrganizationSelection) {
    const qs = queryString.parse(props.location.search);
    return (
      <Redirect
        to={`/organizations${qs.redirect ? `?redirect=${qs.redirect}` : ""}`}
      />
    );
  }

  if (requiresTwoFactor && location.pathname !== "/two-factor") {
    const qs = queryString.parse(props.location.search);
    return (
      <Redirect
        to={`/two-factor${qs.redirect ? `?redirect=${qs.redirect}` : ""}`}
      />
    );
  }
  const handleResendCode = () => {
    resendTwoFactorCode()
      .then(() => {
        setDialogText("A new code has been sent to your email address.");
      })
      .catch((err) => {
        setTwoFactorError(err.response.data.message);
      });
  };
  return (
    <div className="login-container">
      <Card className="login-card">
        <CardContent>
          <img src={LynxImage} alt="Lynx Logo" className="logo" />
          <Typography variant="h5" align="center" gutterBottom>
            {showTwoFactor ? "Two Factor Authentication" : "Sign In"}
          </Typography>
          {showTwoFactor && (
            <Typography variant="body1" align="center" gutterBottom>
              Enter the 6-digit code sent to your email address
            </Typography>
          )}
          <Box component="form" noValidate autoComplete="off">
            {!showTwoFactor ? (
              <>
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(event) => setEmail(event.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(event) => setPassword(event.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loginDisabled}
                  onClick={doAuthorize}
                  className="sign-in-button mt-2"
                >
                  Sign In
                </Button>
                {errorMessage && (
                  <Typography
                    color="error"
                    align="center"
                    className="error-text mt-2"
                  >
                    {errorMessage}
                  </Typography>
                )}
                <Button
                  fullWidth
                  onClick={() => props.history.push("/forgot-password")}
                  className="forgot-password-button mt-2"
                >
                  Forgot Password?
                </Button>
              </>
            ) : (
              <>
                <div className="two-factor-container">
                  <TwoFactorInput handleCodeEntered={handleCodeEntered} />
                </div>
                {twoFactorError && (
                  <Typography
                    color="error"
                    align="center"
                    className="error-text mt-2"
                  >
                    {twoFactorError}
                  </Typography>
                )}
                <Button
                  fullWidth
                  onClick={handleResendCode}
                  className="forgot-password-button mt-3"
                >
                  Resend Code
                </Button>
              </>
            )}
          </Box>

          {isOffline && (
            <Typography color="error" align="center" className="error-text">
              Offline login is not available. Users must login while connected
              to the internet every 30 days for offline functionality.
            </Typography>
          )}
          {!_.isEmpty(maintenanceMessage) && (
            <Typography color="error" align="center" className="error-text">
              {maintenanceMessage}
            </Typography>
          )}
          <Typography align="center" className="version-text mt-2">
            Version {process.env.REACT_APP_VERSION}
          </Typography>
        </CardContent>
      </Card>
      {dialogText && (
        <LynxDialog
          handleClose={() => setDialogText(null)}
          open={dialogText != null}
          title={dialogText}
        />
      )}
      <MainFooter />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loginUser: (creds) => dispatch(loginUser(creds)),
  loadAccount: () => dispatch(loadAccount()),
  loadUserOrg: () => dispatch(loadUserOrg()),
});

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
