import React from "react";
import { Card } from "components/lynx-components";
import { dateUtil } from "../../../services/date-util";
import { getPhotoImportHistories } from "../../../services/time-series-service";
import { PhotoImportHistoryStatuses } from "../../../types/enums";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { navigateTo } from "services/navigation-service";
import { LynxGridColDef } from "types";
import { EntityTypes } from "types/enums-ts";
import { useGetPhotoImportHistoriesQuery } from "services/rtkApi/endpoints/photoImportHistories";
export function PhotoImportHistoryModal() {
  const history = useHistory();
  const photoImportHistoryColumns: LynxGridColDef[] = [
    {
      field: "importDateTimeUtc",
      headerName: "Import Date",
      width: 200,
      type: "dateTime",
      valueGetter: (value: any) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
    {
      field: "importerUserFullName",
      headerName: "Imported By",
      width: 200,
      type: "string",
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      type: "string",
      valueGetter: (value: any) => {
        if (value == PhotoImportHistoryStatuses.AnalyzingPhotos) {
          return "Analyzing Photos";
        }
        if (value == PhotoImportHistoryStatuses.StartingModel) {
          return "Starting Model";
        }
        if (value == PhotoImportHistoryStatuses.UploadingPhotos) {
          return "Uploading Photos";
        }
        if (value == PhotoImportHistoryStatuses.AwaitingReview) {
          return "Awaiting Review";
        }
        if (value == PhotoImportHistoryStatuses.AwaitingModelTraining) {
          return "Awaiting Model Training";
        }
        return value;
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 280,
      type: "string",
      renderCell: (params: any) => (
        <>
          {params.row.status ===
            PhotoImportHistoryStatuses.AwaitingModelTraining && (
            <Button
              variant="outlined"
              onClick={(e) => {
                navigateTo(
                  history,
                  `/photo-import-history/${params.id}/training`
                );
              }}
            >
              Train Model
            </Button>
          )}
          {params.row.status === PhotoImportHistoryStatuses.AwaitingReview && (
            <Button
              variant="outlined"
              onClick={(e) => {
                navigateTo(history, `/photo-import-history/${params.id}`);
              }}
            >
              Review Analyzed Photos
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <Card>
      <div style={{ height: 550, minWidth: 500 }}>
        <LynxDataGrid
          columns={photoImportHistoryColumns}
          onlyShowTable
          entityType={EntityTypes.PhotoImportHistory}
          localStorageName={`photoImportHistory`}
          useQuery={useGetPhotoImportHistoriesQuery}
        />
      </div>
    </Card>
  );
}
