import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import * as jsonpatch from "fast-json-patch";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Card, Form, Grid } from "components/lynx-components";
import { roleMatch } from "../../../actions/auth";
import { validationService } from "../../../services/validation";
import "./incident-form.css";
import { LynxDialog } from "../../lynx-dialog";
import CircularProgress from "@mui/material/CircularProgress";
import { dateUtil } from "../../../services/date-util";
import Typography from "@mui/material/Typography";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import { IconButton, Stack } from "@mui/material";
import { Lock, LockOpenOutlined } from "@mui/icons-material";
import { IncidentStatus, IncidentUserTypes, UserRoles } from "types/enums";
import { useSelector } from "react-redux";
import { canSubmitStage } from "services/incident-assignment-service";
import useAlert from "hooks/useAlert";
import { IncidentDto } from "types";
import { RootState } from "types";
import { usePatchIncidentMutation } from "services/rtkApi/endpoints/incidents";

interface Props {
  incident: IncidentDto;
  updateIncident: (incident: IncidentDto) => void;
}

const IncidentFinalReview: FC<Props> = (props) => {
  const [formState, setFormState] = useState<{
    finalReviewComment?: string;
    finalReviewCommentError?: string;
  }>({});
  const [initialFormState, setInitialFormState] = useState<{
    finalReviewComment?: string;
  }>({});
  const [isSaving, setIsSaving] = useState(false);
  const incidentStatus = _.toLower(props.incident.status);
  const isInitiallyLocked = incidentStatus === "closed";
  const [isLocked, setIsLocked] = useState(isInitiallyLocked);
  const account = useSelector((state: RootState) => state.account);
  const { showAlert } = useAlert();
  const [patchIncidentTrigger] = usePatchIncidentMutation();

  useEffect(() => {
    if (!_.isEmpty(props.incident)) {
      setFormStateFromIncident(props.incident);
    }
  }, [props.incident]);

  const setFormStateFromIncident = (incident: IncidentDto) => {
    const newState = {
      ...formState,
      finalReviewComment: incident.finalReviewComment,
    };
    setFormState(newState);
    setInitialFormState(_.cloneDeep(newState));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newState = { ...formState, [e.target.name]: e.target.value };
    setFormState(newState);
  };

  const saveIncident = (saveAction: string) => {
    if (saveAction !== "finalreview" && !validateDataForSave(saveAction)) {
      return;
    }
    setIsSaving(true);
    const form = validationService.unsetErrors(
      formState,
      "finalReviewCommentError"
    );

    const diff = jsonpatch.compare(initialFormState, form);
    const dto = {
      incidentParticipants: [],
      userWitnesses: [],
      userParticipants: [],
      incidentPatch: diff,
    };
    if (props.incident.id) {
      patchIncidentTrigger({
        id: props.incident.id.toString(),
        jPatch: dto,
        saveAction: saveAction,
      }).then((res) => {
        showAlert(
          "success",
          saveAction === "finalreviewapproved"
            ? "Final review approved."
            : "Final review saved."
        );
        props.updateIncident(res.data);
        setIsSaving(false);
        setIsLocked(true);
      });
    }
  };

  const validateDataForSave = (saveAction: string): boolean => {
    const newState = { ...formState };
    validationService.validateRequiredField(
      newState,
      "finalReviewComment",
      "finalReviewCommentError",
      "Final Review Comment"
    );

    const isFormValid = !validationService.hasError(newState, [
      "finalReviewCommentError",
    ]);

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const canPerformFinalReview = () => {
    return (
      !_.isEmpty(props.incident) &&
      account.id &&
      canSubmitStage(
        props.incident.incidentUsers,
        account.id,
        IncidentUserTypes.FinalReviewer
      )
    );
  };

  const commentFieldLocked = () => {
    return (
      !canPerformFinalReview() || incidentStatus !== IncidentStatus.FinalReview
    );
  };

  return (
    <Container className="mt-2" maxWidth="xl">
      <Form className="card mb-0">
        <Card.Header>
          <Card.Title>
            <Stack direction="row" alignItems="center">
              <span>Final Review</span>
              {roleMatch([UserRoles.Admin]) && isInitiallyLocked && (
                <IconButton
                  aria-label="Lock"
                  onClick={() => setIsLocked(!isLocked)}
                  title={isLocked ? "Unlock" : "Lock"}
                >
                  {isLocked ? <Lock /> : <LockOpenOutlined color="success" />}
                </IconButton>
              )}
            </Stack>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={12} width={12}>
              <Form.Group isRequired label="Final Review Comment">
                <LynxTextArea
                  autoResize
                  name="finalReviewComment"
                  onChange={handleInputChange}
                  value={formState.finalReviewComment}
                  error={formState.finalReviewCommentError}
                  disabled={commentFieldLocked() && isLocked}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
        {canPerformFinalReview() && (
          <Card.Footer>
            <>
              {incidentStatus === IncidentStatus.FinalReview && (
                <Button
                  variant="contained"
                  color="success"
                  className="float-right ml-2"
                  onClick={() => saveIncident("finalreviewapproved")}
                >
                  Save and Submit - Approved
                </Button>
              )}
              <Button
                className="float-right review-button"
                variant="contained"
                onClick={() => saveIncident("finalreview")}
                disabled={isInitiallyLocked && isLocked}
              >
                Save
              </Button>
              {props.incident.finalReviewCompletedDateTimeUtc && (
                <div>
                  <Typography component="span">
                    Final Review submitted by:{" "}
                    {props.incident.finalReviewCompletedByUserFullName}{" "}
                    {dateUtil.convertDateTimeToLocal(
                      props.incident.finalReviewCompletedDateTimeUtc
                    )}
                  </Typography>
                </div>
              )}
            </>
          </Card.Footer>
        )}
      </Form>
      {isSaving && (
        <LynxDialog
          open={isSaving}
          title="Saving incident. Do not close the window."
          description={
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          }
        />
      )}
    </Container>
  );
};

export default IncidentFinalReview;
