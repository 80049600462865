import { CountListWidgetDto, GroupChartWidgetDto } from "types";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";

const BASE_ROUTE = "/dashboardWidgets";

const adminEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    updateWidgetGroupChart: builder.mutation({
      query: (args: GroupChartWidgetDto) => ({
        url: `${BASE_ROUTE}/groupChart`,
        method: "PUT",
        body: args,
      }),
    }),
    updateWidgetCountList: builder.mutation({
      query: (args: CountListWidgetDto) => ({
        url: `${BASE_ROUTE}/countlist`,
        method: "PUT",
        body: args,
      }),
    }),
    deleteUserDashboardWidgetById: builder.mutation({
      query: (id: any) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetUserDashboardWidget],
    }),
  }),
});

export const {
  useUpdateWidgetGroupChartMutation,
  useUpdateWidgetCountListMutation,
  useDeleteUserDashboardWidgetByIdMutation,
} = adminEndpoint;
