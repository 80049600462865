import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Grid } from "components/lynx-components";
import useAlert from "hooks/useAlert";
import { EntityTypes } from "types/enums-ts";
import _ from "lodash";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useGetImportHistoryQuery,
  useGetImportSpecificationsQuery,
} from "services/rtkApi/endpoints/imports";
import { LynxGridColDef } from "types";
import { dateUtil } from "../../../services/date-util";
import { deleteDataByHistory } from "../../../services/import";

import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import { LynxDialog } from "../../lynx-dialog";

export function ImportHistory() {
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleting, setShowDeleting] = useState(false);
  const [historyId, setHistoryId] = useState<number | null>(null);
  const { showAlert } = useAlert();
  const navigatePerEntity = (e: any, params: any) => {
    handleNavigateTo(
      e,
      _.toLower(params.row.entityType) == _.toLower("events")
        ? `events?importHistoryId=${params.row.id}`
        : _.toLower(params.row.entityType) == _.toLower("timeseries")
        ? `monitoring?view=timeseries&importHistoryId=${params.row.id}&monitoringLocationId=` +
          `${
            params.row.timeseriesMonitoringLocationId
          }&minDate=${params.row.timeseriesMinDate.replace(
            "+",
            "%2B"
          )}&maxDate=${params.row.timeseriesMaxDate.replace("+", "%2B")}`
        : `monitoring?view=results&importHistoryId=${params.row.id}`
    );
  };
  const importHistoryColumns: LynxGridColDef[] = [
    {
      field: "importDateTimeUtc",
      headerName: "Import Date",
      width: 200,
      type: "dateTime",
      valueGetter: (value: any) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
    {
      field: "entityType",
      headerName: "Type",
      width: 100,
      type: "singleSelect",
      valueOptions: ["Events", "Monitoring Results"],
    },
    {
      field: "importStatus",
      headerName: "Status",
      width: 200,
      type: "singleSelect",
      valueOptions: ["Completed", "Importing"],
    },
    {
      field: "numberOfSuccessRows",
      headerName: "# Success",
      width: 100,
      type: "number",
      renderCell: (params: any) => (
        <>
          {params.row.numberOfSuccessRows > 0 ? (
            <Link
              href="#"
              underline="none"
              onClick={(e) => {
                navigatePerEntity(e, params);
              }}
            >
              {params.value}
            </Link>
          ) : (
            params.value
          )}
        </>
      ),
    },

    {
      field: "numberOfErrorRows",
      headerName: "# Error",
      width: 100,
      type: "number",
    },
    {
      field: "numberOfVarianceExceedances",
      headerName: "# of Variance Exceedances",
      width: 100,
      type: "number",
      renderCell: (params: any) => (
        <>
          {params.row.numberOfVarianceExceedances > 0 ? (
            <Link
              href="#"
              underline="none"
              onClick={(e) => {
                handleNavigateTo(
                  e,
                  `monitoring?view=results&importHistoryId=${params.row.id}&varianceStatus=Exceedance`
                );
              }}
            >
              {params.value}
            </Link>
          ) : _.toLower(params.row.entityType) ==
            _.toLower(EntityTypes.MonitoringResult) ? (
            params.value
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      field: "importSpecificationName",
      headerName: "Configuration",
      width: 200,
      type: "singleSelect",
      query: useGetImportSpecificationsQuery,
      getOptionValue: (value: any) => (!value ? "" : value.name),
      getOptionLabel: (value: any) => (!value ? "" : value.name),
    },
    {
      field: "timeseriesMonitoringLocationName",
      headerName: "Timeseries Location",
      width: 200,
      type: "string",
    },
    {
      field: "fileName",
      headerName: "File Name",
      width: 200,
      type: "string",
    },
    {
      field: "uploaderUserFullName",
      headerName: "Imported By",
      width: 200,
      type: "string",
    },

    {
      field: "View Imported Data",
      headerName: "",
      width: 200,
      type: "string",
      resizable: false,
      disableColumnMenu: true,
      disableExport: true,
      hideSortIcons: true,
      renderCell: (params: any) => (
        <>
          {params.row.numberOfSuccessRows > 0 && (
            <Button
              variant="outlined"
              onClick={(e) => {
                navigatePerEntity(e, params);
              }}
            >
              View Imported Data
            </Button>
          )}
        </>
      ),
    },
    {
      field: "Delete Imported Data",
      headerName: "",
      width: 200,
      type: "string",
      resizable: false,
      disableColumnMenu: true,
      disableExport: true,
      hideSortIcons: true,
      renderCell: (params: any) => (
        <>
          {_.toLower(params.row.importStatus) == _.toLower("completed") && (
            <Button
              variant="outlined"
              color="error"
              onClick={(e) => {
                handleShowDelete(e, params.row.id);
              }}
            >
              Delete Imported Data
            </Button>
          )}
        </>
      ),
    },
  ];

  const handleShowDelete = (e: any, historyId: number) => {
    if (e && e != null) {
      e.preventDefault();
    }
    setHistoryId(historyId);
    setShowDelete(true);
  };
  const history = useHistory();
  const handleNavigateTo = (e: any, url: string) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };
  const handleDelete = () => {
    setShowDelete(false);
    setShowDeleting(true);
    deleteDataByHistory(historyId)
      .then((res) => {
        setHistoryId(null);
        showAlert("success", "Imported data deleted.");
        setShowDeleting(false);
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
        setShowDeleting(false);
      });
  };

  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-0">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Imports
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/imports")}
              >
                Imports
              </Link>
              <Typography color="text.primary">History</Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      <Box
        sx={{
          width: "100%",
          height: "calc(100% - 87px)",
          bgcolor: "background.paper",
        }}
      >
        <LynxDataGrid
          columns={importHistoryColumns}
          useQuery={useGetImportHistoryQuery}
          onlyShowTable
          entityType={EntityTypes.ImportHistory}
          localStorageName={`importhistory`}
        />
      </Box>
      <LynxDialog
        open={showDelete}
        title={"Are you sure you want to delete the imported data?"}
        description={"This action cannot be undone."}
        handleConfirm={handleDelete}
        handleClose={() => {
          setShowDelete(false);
        }}
      />
      <LynxDialog
        open={showDeleting}
        title={`Deleting data. Do not close the window.`}
        description={
          <>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          </>
        }
      />
    </Grid>
  );
}
