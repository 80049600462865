import { RtkTagTypes } from "types/enums";
import queryString from "query-string";

const { apiService } = require("../apiService");

const BASE_ROUTE = "/documents";

const assetsEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getDocuments: builder.query({
      query: (args) => {
        return {
          url: `${BASE_ROUTE}?${queryString.stringify(args)}`,
        };
      },
      providesTags: (result, error, { customerId }) => [
        RtkTagTypes.GetDocuments,
      ],
      transformResponse: (response, meta) => {
        const pagination = meta.response.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
    }),
    initializeUpload: builder.mutation({
      query: (fileName) => ({
        url: `/documents/initialize-chunk?fileName=${fileName}`,
        method: "POST",
      }),
    }),
    uploadChunk: builder.mutation({
      query: ({ file, index, totalChunks, fileName, filePath, data }) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("index", index);
        formData.append("totalChunks", totalChunks);
        formData.append("fileName", fileName);
        formData.append("filePath", filePath);
        formData.append("data", data);

        return {
          url: "/documents/upload-chunk",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: RtkTagTypes.GetDocuments, id },
      ],
    }),
    getDocumentsByEntityType: builder.query({
      query: (args) => {
        return {
          url: `${BASE_ROUTE}/entitytype?${queryString.stringify(args)}`,
        };
      },
      transformResponse: (response, meta) => {
        const pagination = meta.response.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
    }),
    getDocumentById: builder.query({
      query: (id) => {
        return {
          url: `${BASE_ROUTE}/${id}`,
        };
      },
      providesTags: (result, error, id) => [
        { type: RtkTagTypes.GetDocuments, id },
      ],
    }),
    addDocuments: builder.mutation({
      query: (args) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: args,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: RtkTagTypes.GetDocuments, id },
      ],
    }),
    updateDocument: builder.mutation({
      query: (args) => ({
        url: `${BASE_ROUTE}`,
        method: "PUT",
        body: args,
      }),
      invalidatesTags: (result, error, { id }) => [
        RtkTagTypes.GetDocuments,
        { type: RtkTagTypes.GetDocuments, id },
      ],
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useGetDocumentsByEntityTypeQuery,
  useGetDocumentByIdQuery,
  useAddDocumentsMutation,
  useUpdateDocumentMutation,
  useInitializeUploadMutation,
  useUploadChunkMutation,
} = assetsEndpoint;
