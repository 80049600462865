import { Button, Container, Typography } from "@mui/material";
import { loginUser, setOrganizationUsers } from "actions/auth";
import _ from "lodash";
import { useAppSelector } from "modules/hooks";
import { AppDispatch } from "modules/store";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { checkOrganizationSelectionStatus } from "services/auth-service";
import { navigateTo } from "services/navigation-service";
import { RootState } from "types";
import { Dimmer } from "./lynx-components";
export function OrganizationSelection() {
  const auth = useAppSelector((state: RootState) => state.auth);
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    checkOrganizationSelectionStatus()
      .then((res) => {
        dispatch(setOrganizationUsers(res.data));
        setIsLoading(false);
      })
      .catch((err) => {
        const qs = queryString.parse(location.search);
        let url = "/authorize";
        if (qs.redirect) {
          url = url + "?redirect=" + qs.redirect;
        }

        navigateTo(history, url);
      });
  }, []);
  //replace with check to organization-selection-status api cal
  if (auth.isAuthenticated) {
    const qs = queryString.parse(location.search);
    if (qs.redirect) {
      return <Redirect to={qs.redirect as string} />;
    }
    return <Redirect to="/dashboard" />;
  }
  const handleLoginUser = (id?: number) => {
    const creds = {
      email: auth.user ? auth.user.email?.trim() : "",
      password: auth.user ? auth.user.password?.trim() : "",
      url: location.pathname,
      organizationUserId: id,
    };
    dispatch(loginUser(creds));
  };
  return (
    <Container className="mt-5">
      <Dimmer active={isLoading}>
        <Typography variant="h4" className="mb-2">
          Select an Organization
        </Typography>
        {auth.organizationUsers.map((a, i) => {
          return (
            <Button
              key={i}
              onClick={() => {
                handleLoginUser(a.id);
              }}
              className="mr-5 mb-2"
              sx={{
                height: "100px",
                width: "400px",
                backgroundColor: "white",
              }}
              variant="outlined"
            >
              {a.organizationName}
            </Button>
          );
        })}
      </Dimmer>
    </Container>
  );
}
