import { apiUrl, client } from "../modules/store";

//used in offline
export function getEvents(params) {
  return client.get(apiUrl + "/events", {
    params: params,
  });
}

//used in offline
export function createEventWithAttachments(form) {
  return client.post(apiUrl + "/events/attachments/", form);
}

export function createGuid() {
  function _p8(s) {
    var p = (Math.random().toString(16) + "000000000").substr(2, 8);
    return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
  }
  return _p8() + _p8(true) + _p8(true) + _p8();
}

