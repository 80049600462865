import { RtkTagTypes } from "types/enums";
import { apiService } from "../apiService";

const BASE_ROUTE = "/dashboard";

const adminEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getUserDashboardWidgets: builder.query({
      query: () => `${BASE_ROUTE}`,
      provideTags: [RtkTagTypes.GetUserDashboardWidget],
    })
  }),
});

export const {
  useLazyGetUserDashboardWidgetsQuery
} = adminEndpoint;
