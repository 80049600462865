import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { saveAs } from "file-saver";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { EventStatuses, UserRoles } from "../../../types/enums";
import { EntityTypes } from "types/enums-ts";
import lynxColors from "../../../modules/lynxColors";
import { getCustomFields } from "../../../services/custom-fields-service";
import PageNotFound from "../../PageNotFound";
import { roleMatch } from "actions/auth";
import "./event-details.css";
import { Dimmer, Grid } from "components/lynx-components";
import { ReportsModal } from "components/data-grid/reports-modal";
import { LynxDialog } from "components/lynx-dialog";
import EventAttachmentDetails from "./event-attachment-details";
import EventDetails from "./event-details";
import EventDetailsActions from "./event-details-actions";
import EventDetailsComments from "./event-details-comments";
import EventDetailsContacts from "./event-details-contacts";
import EventDetailsCorrespondenceLog from "./event-details-correspondence-log";
import EventDetailsLinkedEvents from "./event-details-linked-events";
import EventDetailsMap from "./event-details-map";
import EventDetailsReview from "./event-details-review";
import EventDetailsTags from "./event-details-tags";
import EventEmailModal from "./event-email-modal";
import EventForm from "./event-form";
import {
  EntityAttachmentDto,
  EventCustomFieldDto,
  EventDto,
  ReportDto,
  RootState,
  UserDto,
} from "types";
import { DescriptionOutlined, Email } from "@mui/icons-material";
import EventAdditionalFieldsDetails from "./event-additional-fields-details";
import {
  useLazyGetEventQuery,
  useGetEventQuery,
  useSubmitEventMutation,
} from "services/rtkApi/endpoints/events";
import {
  useLazyGetReportsQuery,
  useLazyRunEventReportQuery,
} from "services/rtkApi/endpoints/reports";
import EventDetailsTopics from "./event-details-topics";
import EventDetailsCommunity from "./event-details-community";
import useAlert from "hooks/useAlert";

interface ParamsProps {
  eventId: string;
}

export const EventDetailsContainer: React.FC = () => {
  const params = useParams<ParamsProps>();
  const {
    data: event,
    isLoading,
    isFetching,
  } = useGetEventQuery(params.eventId, {
    skip: !params.eventId,
  });
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [openSection, setOpenSection] = useState<string>("");
  const [eventLocked, setEventLocked] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showReportsModal, setShowReportsModal] = useState(false);
  const [reports, setReports] = useState<ReportDto[]>([]);
  const [isScheduledEvent, setIsScheduleEvent] = useState(false);
  const [isLinkedEvent, setIsLinkedEvent] = useState(false);
  const [customFields, setCustomFields] = useState<EventCustomFieldDto[]>([]);
  const [showNotFound, setShowNotFound] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showFollowUpDialog, setShowFollowUpDialog] = useState(false);
  const [isFollowUp, setIsFollowUp] = useState(false);
  const [showDownloadingReport, setShowDownloadingReport] = useState(false);
  const history = useHistory();

  const location = useLocation();
  const isPendingEmailReview =
    event && event.status === EventStatuses.PendingEmailReview;
  const organization = useSelector((state: RootState) => state.organization);
  const { users } = useSelector((state: RootState) => state.lookups);
  const [getEventTrigger] = useLazyGetEventQuery();
  const [getReportsTrigger] = useLazyGetReportsQuery();
  const [runEventReportTrigger] = useLazyRunEventReportQuery();
  const [submitEventTrigger] = useSubmitEventMutation();

  const { showAlert } = useAlert();

  useEffect(() => {
    loadReports();
    const values = queryString.parse(location.search);
    if (values.hasFollowUp) {
      setShowFollowUpDialog(true);
      setIsFollowUp(true);
    }
  }, [params.eventId]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (params.edit) {
      if (
        roleMatch([
          UserRoles.EventsAdministrator,
          UserRoles.EventsEditor,
          UserRoles.EventsContributor,
        ])
      ) {
        setShowEdit(true);
      }
    } else {
      setShowEdit(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (!isLoading) {
      loadCustomFields();
    }
  }, [isLoading]);

  const handleNavigateTo = (
    e: React.MouseEvent<HTMLAnchorElement>,
    url: string
  ) => {
    e.preventDefault();
    history.push(url);
  };

  const loadCustomFields = () => {
    getCustomFields(EntityTypes.Event).then((res) => {
      let customFieldsToSet = res.data.filter(
        (x: EventCustomFieldDto) =>
          x.parentLookupId == null && x.childLookupId == null
      );
      customFieldsToSet = [
        ...customFieldsToSet,
        ...res.data.filter(
          (x: EventCustomFieldDto) =>
            x.parentLookupId === event?.eventCategoryId &&
            x.childLookupId == null
        ),
      ];
      if (event?.eventSubCategoryId && event?.eventSubCategoryId > 0) {
        customFieldsToSet = [
          ...customFieldsToSet,
          ...res.data.filter(
            (x: EventCustomFieldDto) =>
              x.parentLookupId === event.eventCategoryId &&
              x.childLookupId === event.eventSubCategoryId
          ),
        ];
      }
      customFieldsToSet = customFieldsToSet.filter(
        (x: EventCustomFieldDto) => !x.isDeleted
      );
      setCustomFields(customFieldsToSet);
    });
  };

  const loadReports = () => {
    getReportsTrigger(EntityTypes.Event, true).then((res) => {
      let reports = res.data;

      reports = reports.filter(
        (x: ReportDto) => x.name !== "Records of Engagement"
      );

      setReports(reports);
    });
  };

  const handleModalClose = (isDelete?: boolean, promptFollowUp?: boolean) => {
    removeEditQueryParam();
    setIsFollowUp(false);

    if (isDelete) {
      history.push("/events");
    }

    if (promptFollowUp) {
      setShowFollowUpDialog(true);
      setIsFollowUp(true);
    }
    setShowEdit(false);
  };

  const handleEdit = (openSection: string) => {
    history.push(`/events/${event?.id}?edit=true`);
    setIsLinkedEvent(false);
    setIsScheduleEvent(false);
    setOpenSection(openSection);
  };

  const handleRunReport = (reportId: number) => {
    setShowDownloadingReport(true);
    if (event && event.id) {
      runEventReportTrigger({ reportId: reportId, eventId: event.id })
        .then((res) => {
          if (res.data) {
            const reportToRun = reports.find((x) => x.id == reportId);
            saveAs(
              res.data,
              `${event?.eventNumber}_${reportToRun?.name?.replace(
                / /g,
                ""
              )}.pdf`
            );
          }
        })
        .finally(() => setShowDownloadingReport(false));
    }
  };

  const handleAddLinkedEvent = (isScheduledEvent: boolean) => {
    setOpenSection("");
    setIsScheduleEvent(isScheduledEvent);
    setIsLinkedEvent(true);
    setShowEdit(true);
  };

  const removeQueryParam = () => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("hasFollowUp")) {
      queryParams.delete("hasFollowUp");
      history.replace({ search: queryParams.toString() });
    }
  };

  const removeEditQueryParam = () => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("edit")) {
      queryParams.delete("edit");
      history.replace({ search: queryParams.toString() });
    }
  };

  const handleSetLockFromNewReview = (e: boolean, shouldRefresh: boolean) => {
    setEventLocked(e);
  };

  const generateImportAttachments = (event: EventDto) => {
    if (event.importHistory != null) {
      return [
        {
          importHistoryId: event.importHistory.id,
          fileName: event.importHistory.fileName,
          isImport: true,
          filePath: event.importHistory.filePath,
          importDateTimeUtc: event.importHistory.importDateTimeUtc,
          uploaderUserFullName: event.importHistory.uploaderUserFullName,
        } as EntityAttachmentDto,
      ];
    }
    return [];
  };

  const handleSubmitEvent = () => {
    if (event) {
      submitEventTrigger(event.id).then((res) => {
        if (res.data) {
          showAlert("success", "Event submitted.");
        }
      });
    }
  };

  if (showNotFound) {
    return <PageNotFound message="Event not found" />;
  } else
    return (
      <Dimmer active={isLoading || organization.isLoading} loader>
        <Paper>
          <Grid.Row className="ml-0 mr-0">
            <Grid.Col lg={4} width={12} className="">
              <div className="d-flex">
                <Typography
                  variant="h3"
                  className="event-number event-number-title"
                  component="div"
                >
                  {event?.eventNumber}
                </Typography>
                <div className="align-self-center ml-5 mt-1">
                  {getStatusChip(event ?? {})}
                  {event?.status == EventStatuses.Draft && !showEdit && (
                    <Button
                      className="ml-2"
                      variant="outlined"
                      onClick={() => {
                        handleSubmitEvent();
                      }}
                    >
                      Submit Event
                    </Button>
                  )}
                </div>
              </div>
            </Grid.Col>
            <Grid.Col lg={8} width={12} className="">
              {!isPendingEmailReview && (
                <div className="d-flex">
                  <div className="align-self-center mt-3 mb-2">
                    <Button
                      variant="outlined"
                      startIcon={<Email />}
                      onClick={() => setShowEmailModal(true)}
                    >
                      Email
                    </Button>
                  </div>

                  <div className="align-self-center ml-2 mt-3 mb-2">
                    <Button
                      variant="outlined"
                      onClick={() => setShowReportsModal(true)}
                      startIcon={<DescriptionOutlined />}
                    >
                      Reports
                    </Button>
                  </div>
                </div>
              )}
            </Grid.Col>
            <Grid.Col lg={12} width={12} className="">
              <Breadcrumbs aria-label="breadcrumb" className="mb-2">
                <Link
                  color="inherit"
                  className="events-link"
                  onClick={(e) => handleNavigateTo(e, "/events")}
                  to={"#"}
                >
                  Events
                </Link>
                {showEdit ? (
                  [
                    <Link
                      color="inherit"
                      to="#"
                      className="event-number-link"
                      onClick={(e) => {
                        if (isPendingEmailReview) {
                          return;
                        }
                        window.history.replaceState(
                          null,
                          "",
                          window.location.href.split("?")[0]
                        );
                        setIsFollowUp(false);
                        setShowEdit(false);
                      }}
                    >
                      {event?.eventNumber}
                    </Link>,
                    <Typography color="text.primary">
                      {isLinkedEvent && !isScheduledEvent
                        ? "Add Linked Event"
                        : isLinkedEvent && isScheduledEvent
                        ? "Schedule Linked Event"
                        : "Edit"}
                    </Typography>,
                  ]
                ) : (
                  <Typography color="text.primary">
                    {event?.eventNumber}
                  </Typography>
                )}
              </Breadcrumbs>
            </Grid.Col>
          </Grid.Row>
        </Paper>
        {showEdit && !isLoading ? (
          <EventForm
            event={event}
            isForEventDetails
            entityId={isLinkedEvent ? 0 : (event?.id as number)}
            openSection={openSection}
            handleModalClose={(isDelete, promptFollowUp) =>
              handleModalClose(isDelete, promptFollowUp)
            }
            isScheduledEvent={isScheduledEvent}
            linkedEntityId={event?.id as number}
            defaultValues={
              isFollowUp
                ? {
                    entityAssets: event?.entityAssets,
                    eventDueDateTime: event?.followUpDate,
                    description: event?.followUpDescription,
                  }
                : isLinkedEvent
                ? { entityAssets: event?.entityAssets }
                : {}
            }
          />
        ) : (
          <>
            <div className="dashboard-grid-container">
              <div className="dashboard-item">
                <EventDetails
                  handleEdit={handleEdit}
                  event={event ?? {}}
                  eventLocked={eventLocked}
                />
              </div>
              {!_.isEmpty(customFields) && (
                <div className="dashboard-item">
                  <EventAdditionalFieldsDetails
                    handleEdit={handleEdit}
                    event={event ?? {}}
                    eventLocked={eventLocked}
                    customFields={customFields}
                  />
                </div>
              )}
              <div style={{ marginBottom: "10px" }}>
                <EventAttachmentDetails
                  event={event ?? {}}
                  eventLocked={eventLocked}
                  importAttachments={generateImportAttachments(event ?? {})}
                />
              </div>

              <div className="dashboard-item">
                <EventDetailsTags
                  event={event ?? {}}
                  commentsLoading={commentsLoading}
                />
              </div>
              <div className="dashboard-item">
                <EventDetailsContacts
                  event={event ?? {}}
                  commentsLoading={commentsLoading}
                  isForInternalTeamMember
                />
              </div>
              <div className="dashboard-item">
                <EventDetailsContacts
                  event={event ?? {}}
                  commentsLoading={commentsLoading}
                />
              </div>
              {!isLoading && (
                <div className="dashboard-item">
                  <EventDetailsLinkedEvents
                    event={event ?? {}}
                    isLoading={isLoading}
                    handleAddLinkedEvent={handleAddLinkedEvent}
                  />
                </div>
              )}
              <div className="dashboard-item">
                <EventDetailsMap
                  event={event ?? {}}
                  eventLocked={eventLocked}
                  handleEdit={handleEdit}
                />
              </div>

              {organization.featureFlags?.showInDevelopmentFeatures && (
                <>
                  <div className="dashboard-item">
                    <EventDetailsCommunity event={event ?? {}} />
                  </div>
                  <div className="dashboard-item">
                    <EventDetailsTopics
                      event={event ?? {}}
                      commentsLoading={commentsLoading}
                    />
                  </div>
                </>
              )}

              {!isLoading && (
                <div className="dashboard-item">
                  <EventDetailsActions
                    event={event ?? {}}
                    eventLocked={eventLocked}
                  />
                </div>
              )}
              {!_.isEmpty(event) && (
                <div className="dashboard-item">
                  <EventDetailsCorrespondenceLog
                    eventLocked={eventLocked}
                    event={event}
                  />
                </div>
              )}
              <div className="dashboard-item">
                <EventDetailsComments
                  event={event ?? {}}
                  users={users as UserDto[]}
                  handleAddComment={(e) => {
                    setCommentsLoading(e);
                  }}
                />
              </div>
              {event?.isReviewable && (
                <div className="dashboard-item">
                  <EventDetailsReview
                    event={event ?? {}}
                    setEventLocked={(e, shouldRefresh) =>
                      handleSetLockFromNewReview(e, shouldRefresh)
                    }
                  />
                </div>
              )}
            </div>
            {showEmailModal && (
              <EventEmailModal
                open={showEmailModal}
                event={event ?? {}}
                handleClose={() => setShowEmailModal(false)}
              />
            )}
            {showReportsModal && (
              <ReportsModal
                reports={reports}
                open={showReportsModal}
                handleClose={() => setShowReportsModal(false)}
                handleRunReport={handleRunReport}
              />
            )}
          </>
        )}
        {showFollowUpDialog && (
          <LynxDialog
            open={showFollowUpDialog}
            title={`Schedule follow up?`}
            description={"Would you like to schedule a follow up event?"}
            handleClose={(e: any) => {
              setShowFollowUpDialog(false);
              removeQueryParam();
              setIsFollowUp(false);
            }}
            handleConfirm={() => {
              setShowFollowUpDialog(false);
              setShowEdit(true);
              setIsLinkedEvent(true);
              setIsScheduleEvent(true);
            }}
          />
        )}
        {showDownloadingReport && (
          <LynxDialog
            open={showDownloadingReport}
            title={`Running Report. Do not close the window.`}
            description={
              <>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <CircularProgress />
                </div>
              </>
            }
          />
        )}
      </Dimmer>
    );
};

export const getStatusChip = (event: EventDto) => {
  const status = event.status;
  if (status) {
    if (status === EventStatuses.Incomplete) {
      return <Chip label={status} color="success" />;
    }
    if (
      [
        EventStatuses.PendingReview,
        EventStatuses.PendingActions,
        EventStatuses.PendingEmailReview,
      ].includes(status)
    ) {
      return (
        <Chip
          label={status}
          sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
        />
      );
    }
    if (status === EventStatuses.IncompleteOverdue) {
      return (
        <Chip
          label={status}
          sx={{ backgroundColor: lynxColors.error, color: "white" }}
        />
      );
    }
    if (status === EventStatuses.Draft) {
      return (
        <Chip label={status} sx={{ backgroundColor: lynxColors.warning }} />
      );
    }
    return <Chip label={status} color="primary" />;
  }
};
