import _ from "lodash";
import { getName } from "./../actions/auth";
export class LocalStorageService {
  getLocalStorage(itemId) {
    const itemKey = this.getLocalStorageKey(itemId);
    const localValue = localStorage.getItem(itemKey);
    if (!localValue) {
      return null;
    }
    return JSON.parse(localValue);
  }

  getLocalStorageWithDefaultValue(itemId, defaultValue) {
    let lsValue = this.getLocalStorage(itemId);
    if (lsValue) {
      return lsValue;
    }
    return defaultValue;
  }

  getLocalStorageWithDefaultNumber(itemId, defaultValue) {
    let lsValue = this.getLocalStorage(itemId);
    if (lsValue) {
      return _.toNumber(lsValue);
    }
    return defaultValue;
  }

  setLocalStorage(itemId, itemValue) {
    const itemKey = this.getLocalStorageKey(itemId);
    if (itemValue) {
      localStorage.setItem(itemKey, JSON.stringify(itemValue));
    } else {
      localStorage.setItem(itemKey, "");
    }
  }

  removeLocalStorage(itemId) {
    const key = this.getLocalStorageKey(itemId);
    localStorage.removeItem(key);
  }

  getLocalStorageKey(itemId) {
    let name = getName();
    let key = "";
    if (name) {
      key = `${_.toLower(name)}`;
    }
    return `${key}:${itemId}`;
  }
}

export const localStorageService = new LocalStorageService();
