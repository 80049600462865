import queryString from "query-string";
import { apiService } from "../apiService";
import { AssetSubLocationDto } from "types";
import { RtkTagTypes } from "types/enums";

const BASE_ROUTE = "/assetsublocations";

const assetsSubLocationEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getAssetSubLocations: builder.query<any, any>({
      query: (params) => `${BASE_ROUTE}?${queryString.stringify(params)}`,
      providesTags: [RtkTagTypes.GetAssetSubLocations],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    getAssetSubLocationById: builder.query<AssetSubLocationDto, number>({
      query: (id) => `${BASE_ROUTE}/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetAssetSubLocations, id },
      ],
    }),

    createAssetSubLocation: builder.mutation<void, AssetSubLocationDto>({
      query: (dto) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        RtkTagTypes.GetAssetSubLocations,
      ],
    }),

    updateAssetSubLocation: builder.mutation<void, AssetSubLocationDto>({
      query: (dto) => ({
        url: `${BASE_ROUTE}`,
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetAssetSubLocations, id },
        RtkTagTypes.GetAssetSubLocations,
      ],
    }),

    deleteAssetSubLocation: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetAssetSubLocations],
    }),
  }),
});

export const {
  useGetAssetSubLocationsQuery,
  useGetAssetSubLocationByIdQuery,
  useCreateAssetSubLocationMutation,
  useUpdateAssetSubLocationMutation,
  useDeleteAssetSubLocationMutation,
} = assetsSubLocationEndpoint;
