import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import _ from "lodash";
import { useEffect, useState } from "react";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import EventQuickView from "../events/event-quick-view";
import { getActionColumns } from "./action-columns";
import { ActionMobileCard } from "./action-mobile-card";
import { useSelector } from "react-redux";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Dimmer } from "components/lynx-components";
import IncidentQuickView from "../incidents/incident-quick-view";
import {
  useGetActionsExportAsXLSXMutation,
  useGetActionsQuery,
} from "services/rtkApi/endpoints/actions";
import { EntityTypes } from "types/enums-ts";
import { useHistory } from "react-router-dom";
import {
  ActionDto,
  EventDto,
  IncidentDto,
  InspectionEventDto,
  LynxGridColDef,
  RootState,
} from "types";
import { useLazyGetIncidentQuery } from "services/rtkApi/endpoints/incidents";
import { useLazyGetEventQuery } from "services/rtkApi/endpoints/events";
import { useLazyGetInspectionQuery } from "services/rtkApi/endpoints/inspections";
import InspectionQuickView from "../inspections/inspection-quick-view";
interface ActionListProps {}
export function ActionList(props: ActionListProps) {
  const [columns, setColumns] = useState<LynxGridColDef[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [linkedEntity, setLinkedEntity] = useState<
    IncidentDto | EventDto | InspectionEventDto
  >({});
  const history = useHistory();
  const [getActionsExportAsXLSXTrigger] = useGetActionsExportAsXLSXMutation();
  const [getIncidentTrigger] = useLazyGetIncidentQuery();
  const [getEventTrigger] = useLazyGetEventQuery();
  const [getInspectionEventTrigger] = useLazyGetInspectionQuery();

  const handleActionNumberClick = (row: ActionDto) => {
    history.push(`/actions/${row.id}`);
  };

  const handleSourceNumberClick = (e: any, data: ActionDto) => {
    if (data.linkedEventId) {
      getEventTrigger(data.linkedEventId, true).then((res) => {
        if (res.data) {
          setLinkedEntity(res.data);
        }
      });
    }
    if (data.linkedIncidentId) {
      getIncidentTrigger(data.linkedIncidentId, true).then((res) => {
        if (res.data) {
          setLinkedEntity(res.data);
        }
      });
    }
    if (data.linkedInspectionEventId) {
      getInspectionEventTrigger(data.linkedInspectionEventId, true).then(
        (res) => {
          if (res.data) {
            setLinkedEntity(res.data);
          }
        }
      );
    }

    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLinkedEntity({});
  };

  var organization = useSelector((state: RootState) => state.organization);
  const { users } = useSelector((state: RootState) => state.lookups);
  const predefinedLookups = { users: users };
  const open = Boolean(anchorEl);
  const id = open ? "linked-entity-popover" : undefined;
  useEffect(() => {
    if (!organization.isLoading) {
      let newColumns = [...getActionColumns(predefinedLookups)];
      let colIndex = newColumns.findIndex((x) => x.field == "actionNumber");
      newColumns[colIndex].renderCell = (
        params: GridRenderCellParams<any, string>
      ) => {
        return (
          <Button
            variant="text"
            onClick={() => handleActionNumberClick(params.row)}
          >
            {params.value}
          </Button>
        );
      };

      let colIndex2 = newColumns.findIndex((x) => x.field == "sourceNumber");
      newColumns[colIndex2].renderCell = (
        params: GridRenderCellParams<any, string>
      ) => {
        const data = params.row;
        return (
          <Button
            variant="text"
            aria-describedby={id}
            onClick={(e) => handleSourceNumberClick(e, data)}
          >
            {params.value}
          </Button>
        );
      };
      setColumns(newColumns);
    }
  }, [organization.isLoading]);

  return (
    <>
      <LynxDataGrid
        disableRefetchOnMountOrArgChange={true}
        enableSavedFilters
        columns={columns}
        useQuery={useGetActionsQuery}
        localStorageName="actions"
        entityType={EntityTypes.Action}
        mobileCard={ActionMobileCard}
        handleExportXLSXTrigger={getActionsExportAsXLSXTrigger}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Dimmer active={_.isEmpty(linkedEntity)} loader>
          {_.isEmpty(linkedEntity) && (
            <div style={{ height: 50, width: 50 }}></div>
          )}
          {!_.isEmpty(linkedEntity) &&
            "eventNumber" in linkedEntity &&
            linkedEntity.eventNumber && (
              <EventQuickView
                eventId={0}
                event={linkedEntity}
                handleClose={handleClose}
              />
            )}
          {!_.isEmpty(linkedEntity) &&
            "incidentNumber" in linkedEntity &&
            linkedEntity.incidentNumber && (
              <IncidentQuickView
                incident={linkedEntity}
                handleClose={handleClose}
              />
            )}
          {!_.isEmpty(linkedEntity) &&
            "inspectionNumber" in linkedEntity &&
            linkedEntity.inspectionNumber && (
              <InspectionQuickView
                inspectionEventId={linkedEntity.id || 0}
                inspection={linkedEntity}
                handleClose={handleClose}
              />
            )}
        </Dimmer>
      </Popover>
    </>
  );
}
