import { apiUrl, client } from "../modules/store";

//called in offlinedataservice.js
export function getInspections(params) {
  return client.get(apiUrl + "/inspections", {
    params: params,
  });
}

//called in offlinedataservice.js
export function createInspection(formData, isSubmit) {
  return client.post(
    `${apiUrl}/inspections${isSubmit ? "?isCompleted=true" : ""}`,
    formData
  );
}
