import { apiUrl, client } from "../modules/store";
import _ from "lodash";

export function getEnergyParameters(parameterType) {
  return client.get(
    apiUrl + "/energyParameters?parameterType=" + parameterType
  );
}

export function getEnergyParametersForLog(energyLogTypeId) {
  return client.get(
    apiUrl + "/energyParameters/logs?energyLogTypeId=" + energyLogTypeId
  );
}

export function getParameterTypes() {
  return client.get(apiUrl + "/energyParameters/parameterTypes");
}

export function createEnergyParameter(dto) {
  return client.post(apiUrl + "/energyParameters", {
    ...dto,
  });
}

export function updateEnergyParameter(id, dto) {
  return client.put(`${apiUrl}/energyParameters/${id}`, dto);
}

export function updateEnergyParameterSort(dto) {
  let url = `${apiUrl}/energyParameters/sort`;

  return client.post(url, { ...dto });
}

export function deleteEnergyParameter(id) {
  return client.delete(apiUrl + "/energyParameters/" + id);
}

export function getEnergyLogs(params) {
  return client.get(apiUrl + "/energyLogs", {
    params: params,
  });
}

export function getEnergyLogsCountByFilterId(filterId) {
  let url = apiUrl + "/energyLogs/count/" + filterId;

  return client.get(url);
}

export function getEnergyLog(id) {
  return client.get(apiUrl + "/energyLogs/" + id);
}

export function createEnergyLog(dto, files, saveAction) {
  let form = new FormData();
  var attachmentsToUpload = [];
  if (!_.isEmpty(files)) {
    for (var index = 0; index < files.length; index++) {
      let file = files[index];
      attachmentsToUpload.push({
        name: file.name,
        blob: file.slice(0, file.size, file.type),
      });
      var element = files[index];
      form.append("file", element);
    }
  }
  let dataString = JSON.stringify(dto);

  form.append("data", dataString);

  var url = `${apiUrl}/energyLogs/`;
  if (saveAction) {
    url = `${url}?saveAction=${saveAction}`;
  }

  return client.post(url, form);
}

export function deleteEnergyLog(id) {
  return client.delete(apiUrl + "/energyLogs/" + id);
}

export function patchEnergyLog(id, jPatch, saveAction) {
  var url = `${apiUrl}/energyLogs/${id}`;
  if (saveAction) {
    url = `${url}?saveAction=${saveAction}`;
  }
  return client.patch(url, jPatch);
}

export function getEnergyLogExportAsXLSX({ params, columnDefinitionDto }) {
  var url = `${apiUrl}/energyLogs/export`;

  return client
    .post(url, columnDefinitionDto, {
      responseType: "blob",
      params: params,
    })
    .then((res) => res);
}
