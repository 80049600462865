import Button from "@mui/material/Button";

import { Card, Dimmer, Form, Grid } from "components/lynx-components";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import { useHistory, useParams } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import { useGetAssetByIdQuery } from "services/rtkApi/endpoints/assets";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AssetSubLocationDto } from "types";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import { useMemo, useState } from "react";
import {
  useCreateAssetSubLocationMutation,
  useDeleteAssetSubLocationMutation,
  useGetAssetSubLocationByIdQuery,
  useUpdateAssetSubLocationMutation,
} from "services/rtkApi/endpoints/assetsSubLocation";
import useAlert from "hooks/useAlert";
import { LynxDialog } from "components/lynx-dialog";
import {
  Breadcrumbs,
  CircularProgress,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { LocationSelectionControl } from "components/location-selection-control";
import { roleMatch } from "actions/auth";
import { UserRoles } from "types/enums";

export function AssetSubLocationForm() {
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const params = useParams<{ assetId: string; assetSubLocationId: string }>();
  const { showAlert } = useAlert();

  const isForUpdate = useMemo(() => {
    return Boolean(params.assetSubLocationId);
  }, [params.assetSubLocationId]);

  const { data: assetSubLocData, isFetching: isFetchingAssetSubLoc } =
    useGetAssetSubLocationByIdQuery(+params.assetSubLocationId, {
      skip: !params.assetSubLocationId,
    });

  const assetId = isForUpdate ? assetSubLocData?.assetId : +params.assetId;

  const { data: assetData, isFetching } = useGetAssetByIdQuery(assetId || 0, {
    skip: !assetId,
  });

  const [createAssetSubLocTrigger] = useCreateAssetSubLocationMutation();
  const [updateAssetSubLocTrigger] = useUpdateAssetSubLocationMutation();
  const [deleteAssetSubLocationTrigger] = useDeleteAssetSubLocationMutation();

  const schema = Yup.object().shape({
    assetId: Yup.number().required().label("Asset"),
    name: Yup.string().required().label("Name"),
    description: Yup.string().notRequired().label("Description"),
    latitude: Yup.number().required().label("Latitude"),
    longitude: Yup.number().required().label("Longitude"),
  });

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setFieldValue,
    handleBlur,
  } = useFormik<AssetSubLocationDto>({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues: {
      id: isForUpdate && assetSubLocData ? assetSubLocData.id : 0,
      name: isForUpdate && assetSubLocData ? assetSubLocData.name : "",
      description:
        isForUpdate && assetSubLocData ? assetSubLocData.description : "",
      assetId: assetId || 0,
      latitude:
        isForUpdate && assetSubLocData
          ? assetSubLocData.latitude
          : assetData?.centroidLatitude,
      longitude:
        isForUpdate && assetSubLocData
          ? assetSubLocData.longitude
          : assetData?.centroidLongitude,
    },
    onSubmit: () => {
      onSave();
    },
  });

  const onSave = () => {
    setIsSaving(true);
    if (isForUpdate) {
      updateAssetSubLocTrigger(values)
        .then((res) => {
          if (res.data) {
            showAlert("success", "Drill Hole updated.");
          }
        })
        .finally(() => setIsSaving(false));
    } else {
      createAssetSubLocTrigger(values)
        .then((res) => {
          if (res.data) {
            showAlert("success", "Drill Hole created.");
            redirectToList();
          }
        })
        .finally(() => setIsSaving(false));
    }
  };

  const handleDelete = () => {
    if (params.assetSubLocationId) {
      deleteAssetSubLocationTrigger(+params.assetSubLocationId)
        .then((res) => {
          showAlert("success", "Drill Hole deleted.");
          redirectToList();
        })
        .finally(() => {
          setShowDelete(false);
        });
    }
  };

  const redirectToList = () => {
    history.push(`/assets/${assetId}/asset-sub-locations`);
  };

  return (
    <Grid>
      <form onSubmit={handleSubmit}>
        <Dimmer active={isFetching || isFetchingAssetSubLoc} loader>
          <Paper>
            <Grid.Row className="ml-0 mr-0">
              <Grid.Col lg={12} width={12} className="">
                <div className="d-flex">
                  <Typography
                    variant="h3"
                    className="form-header-text"
                    component="div"
                  >
                    {isForUpdate
                      ? "Edit Drill Hole"
                      : `${assetData?.name} - Add Drill Holes`}
                  </Typography>
                </div>
              </Grid.Col>
              <Grid.Col lg={12} width={12} className="">
                <Grid.Col lg={12} width={12} className="p-0">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      color="primary"
                      href="#"
                      onClick={(e) => history.push("/assets")}
                    >
                      Assets
                    </Link>
                    <Link
                      underline="hover"
                      color="primary"
                      href="#"
                      onClick={(e) => redirectToList()}
                    >
                      {assetData?.assetNumber}
                    </Link>
                    <Typography color="text.primary">
                      {`${isForUpdate ? "Edit" : "Add"} Drill Hole`}
                    </Typography>
                  </Breadcrumbs>
                </Grid.Col>
              </Grid.Col>
            </Grid.Row>
          </Paper>
          <Grid.Row>
            <Container className="mt-2" maxWidth="xl">
              <Card>
                <Card.Body className="p-0">
                  <Grid.Row>
                    <Accordion className="w-100" defaultExpanded>
                      <AccordionSummary
                        aria-controls="details-content"
                        id="details-header"
                      >
                        <Typography>Details</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid.Row>
                          <Grid.Col md={12} width={12}>
                            <Form.Group isRequired label="Asset">
                              <Form.Select
                                id="assetId"
                                name="assetId"
                                onChange={handleChange}
                                value={values.assetId}
                                disabled
                              >
                                <option>{assetData?.name}</option>
                              </Form.Select>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col md={12} width={12}>
                            <Form.Group label="Name" isRequired>
                              <Form.Input
                                type="text"
                                name="name"
                                id="name"
                                value={values.name}
                                onChange={handleChange}
                                error={
                                  !!errors.name && touched.name
                                    ? errors.name
                                    : undefined
                                }
                                onBlur={handleBlur}
                              ></Form.Input>
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col md={12} width={12}>
                            <Form.Group label="Description">
                              <LynxTextArea
                                autoResize
                                id="description"
                                name="description"
                                onChange={(
                                  e: React.ChangeEvent<HTMLTextAreaElement>
                                ) =>
                                  setFieldValue("description", e.target.value)
                                }
                                value={values.description}
                              ></LynxTextArea>
                            </Form.Group>
                          </Grid.Col>
                        </Grid.Row>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion className="w-100" defaultExpanded>
                      <AccordionSummary
                        aria-controls="location-content"
                        id="location-header"
                      >
                        <Typography>Location</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid.Row>
                          <Grid.Col md={12} width={12}>
                            <Grid.Row>
                              <LocationSelectionControl
                                isRequired
                                defaultLatitude={values.latitude}
                                defaultLongitude={values.longitude}
                                handleLatLongChange={(
                                  lat: number,
                                  long: number
                                ) => {
                                  setFieldValue("latitude", lat);
                                  setFieldValue("longitude", long);
                                }}
                                latitudeError={errors.latitude}
                                longitudeError={errors.longitude}
                              />
                            </Grid.Row>
                          </Grid.Col>
                        </Grid.Row>
                      </AccordionDetails>
                    </Accordion>
                  </Grid.Row>
                </Card.Body>

                <Card.Footer className="justify-content-end">
                  <Stack
                    direction="row"
                    justifyContent="end"
                    spacing={1}
                    alignItems="center"
                  >
                    {assetSubLocData?.isInUse && (
                      <Typography fontSize={14} className="text-red">
                        Unable to delete when in use
                      </Typography>
                    )}
                    {roleMatch([UserRoles.Admin]) && isForUpdate && (
                      <Button
                        color="error"
                        onClick={() => setShowDelete(true)}
                        variant="contained"
                        disabled={assetSubLocData?.isInUse}
                      >
                        Delete
                      </Button>
                    )}
                    <Button type="submit" variant="contained">
                      {isForUpdate ? "Save" : "Add"} Drill Hole
                    </Button>
                  </Stack>
                </Card.Footer>
              </Card>
            </Container>
          </Grid.Row>
        </Dimmer>
      </form>

      <LynxDialog
        open={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={assetSubLocData?.isInUse ? undefined : handleDelete}
        title={"Delete Drill Hole?"}
        description={
          "Are you sure you want to delete this Drill Hole? This action cannot be undone."
        }
      />

      <LynxDialog
        open={isSaving}
        title={`Saving event. Do not close the window.`}
        dialogContent={
          <div className="d-flex align-items-center justify-content-center mt-4">
            <CircularProgress />
          </div>
        }
      />
    </Grid>
  );
}
