import queryString from "query-string";
import { RtkTagTypes } from "types/enums";
import { apiService } from "../apiService";
import { EventDto } from "types";

const BASE_ROUTE = "/events";

const eventsEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getNumberOfEvents: builder.mutation({
      query: (args) => ({
        url: `${BASE_ROUTE}/schedule/count`,
        method: "POST",
        body: args,
      }),
    }),
    addEventSchedule: builder.mutation({
      query: (args) => ({
        url: `${BASE_ROUTE}/schedule`,
        method: "POST",
        body: args,
      }),
      invalidatesTags: [RtkTagTypes.GetEvents],
    }),
    getEvents: builder.query({
      query: (params) => `${BASE_ROUTE}?${queryString.stringify(params)}`,
      transformResponse: (response, meta: any) => {
        const pagination = meta.response.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
      providesTags: [RtkTagTypes.GetEvents],
    }),
    getEventsForMap: builder.query({
      query: (params: any) =>
        `${BASE_ROUTE}/map?${queryString.stringify(params)}`,
      providesTags: [RtkTagTypes.GetEvents],
    }),
    getEvent: builder.query<EventDto, any>({
      query: (id) => `${BASE_ROUTE}/${id}`,
      providesTags: (result, error, id) => [
        { type: RtkTagTypes.GetEvent, id: id },
      ],
    }),
    getLinkedEvents: builder.query<EventDto[], any>({
      query: (id) => `${BASE_ROUTE}/${id}/linked`,
      providesTags: (result, error, id) => [
        { type: RtkTagTypes.GetEvent, id: id },
      ],
    }),
    searchEvents: builder.query({
      query: (params: { search: string; eventNumberOnly?: boolean }) =>
        `${BASE_ROUTE}/search?${queryString.stringify(params)}`,
      providesTags: [RtkTagTypes.SearchEvents],
    }),
    getEventCountByFilterId: builder.query({
      query: (filterId) => `${BASE_ROUTE}/count/${filterId}`,
    }),
    getEventsAttachments: builder.query({
      query: (params) => ({
        url: `${BASE_ROUTE}/attachments`,
        params,
        responseHandler: (response: any) => response.blob(),
      }),
    }),
    getEventsExportAsXLSX: builder.mutation({
      query: ({ params, columnDefinitionDto }) => ({
        url: `${BASE_ROUTE}/export`,
        method: "POST",
        body: columnDefinitionDto,
        params,
        responseHandler: (response: any) => response.blob(),
      }),
    }),
    createEvent: builder.mutation({
      query: (dto) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetEvents],
    }),
    mergeEventCorrespondence: builder.mutation({
      query: ({ fromId, toId }) => ({
        url: `${BASE_ROUTE}/mergeCorrespondence?fromId=${fromId}&toId=${toId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { fromId, toId }) => [
        RtkTagTypes.GetEvents,
        { type: RtkTagTypes.GetEvents, id: fromId },
        { type: RtkTagTypes.GetEvents, id: toId },
      ],
    }),
    createEventWithAttachments: builder.mutation({
      query: (form) => ({
        url: `${BASE_ROUTE}/attachments/`,
        method: "POST",
        body: form,
      }),
      invalidatesTags: (result, error, args) => [
        RtkTagTypes.GetEvents,
        ...(result.parentEventId
          ? [{ type: RtkTagTypes.GetEvent, id: result.parentEventId }]
          : []),
      ],
    }),
    deleteEvent: builder.mutation({
      query: (id) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetEvents],
    }),
    patchEvent: builder.mutation({
      query: ({ id, jPatch }) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "PATCH",
        body: jPatch,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: RtkTagTypes.GetEvent, id },
        RtkTagTypes.GetEvents,
      ],
    }),
    bulkUpdateEvents: builder.mutation({
      query: ({ params, data }) => ({
        url: `${BASE_ROUTE}/bulkUpdate?${queryString.stringify(params)}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [RtkTagTypes.GetEvents, RtkTagTypes.GetEvent],
    }),
    bulkUpdateEventAttachments: builder.mutation({
      query: ({ params, files }) => {
        const formData = new FormData();
        files.forEach((file: any) => formData.append("file", file));
        return {
          url: `${BASE_ROUTE}/bulkUpdateAttachments`,
          method: "POST",
          body: formData,
          params,
        };
      },
      invalidatesTags: [RtkTagTypes.GetEvents, RtkTagTypes.GetEvent],
    }),
    unlinkEvent: builder.mutation({
      query: (params) => ({
        url: `${BASE_ROUTE}/unlink`,
        method: "POST",
        params: params,
      }),
      invalidatesTags: (result, error, args) => [
        {
          type: RtkTagTypes.GetEvent,
          id: args.pageEventId,
        },
      ],
    }),

    submitEvent: builder.mutation({
      query: (id) => ({
        url: `${BASE_ROUTE}/submit/${id}`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, args) => [
        RtkTagTypes.GetEvents,
        { type: RtkTagTypes.GetEvent, id: args.id },
      ],
    }),
  }),
});

export const {
  useGetEventQuery,
  useLazyGetEventQuery,
  useBulkUpdateEventsMutation,
  useGetNumberOfEventsMutation,
  useAddEventScheduleMutation,
  useGetEventsQuery,
  useBulkUpdateEventAttachmentsMutation,
  useUnlinkEventMutation,
  useGetEventsForMapQuery,
  useLazyGetEventsForMapQuery,
  useSearchEventsQuery,
  useLazySearchEventsQuery,
  useGetEventCountByFilterIdQuery,
  useLazyGetEventCountByFilterIdQuery,
  useGetEventsAttachmentsQuery,
  useLazyGetEventsAttachmentsQuery,
  useGetEventsExportAsXLSXMutation,
  useMergeEventCorrespondenceMutation,
  useCreateEventWithAttachmentsMutation,
  useDeleteEventMutation,
  usePatchEventMutation,
  useGetLinkedEventsQuery,
  useSubmitEventMutation,
} = eventsEndpoint;
