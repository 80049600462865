import queryString from "query-string";
import { BaseParams, OrganizationDto } from "types";
import { RtkTagTypes } from "types/enums";

const { apiService } = require("../apiService");

const BASE_ROUTE = "/organizations";

const organizationsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getOrganizations: builder.query({
      query: (params: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(params)}`,
      providesTags: [RtkTagTypes.GetOrganizations],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),
    getOrganizationById: builder.query({
      query: (id: any) => `${BASE_ROUTE}/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetOrganizations, id },
      ],
    }),
    addOrganization: builder.mutation({
      query: (orgDto: OrganizationDto) => ({
        url: `${BASE_ROUTE}`,
        method: "POST",
        body: orgDto,
        headers: { "Content-Type": "application/json" },
      }),
      invalidatesTags: [RtkTagTypes.GetOrganizations],
    }),
    updateOrganization: builder.mutation({
      query: ({ id, orgDto }: { id: number; orgDto: any }) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "PUT",
        body: orgDto,
        headers: { "Content-Type": "application/json" },
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetOrganizations, id },
        RtkTagTypes.GetOrganizations,
      ],
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useGetOrganizationByIdQuery,
  useAddOrganizationMutation,
  useUpdateOrganizationMutation,
} = organizationsEndpoint;
