import { BaseParams } from "types";
import queryString from "query-string";
import { RtkTagTypes } from "types/enums";
import { apiService } from "../apiService";

const BASE_ROUTE = "/monitoringlocations";

const monitoringlocationsEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getMonitoringLocations: builder.query({
      query: (params: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(params)}`,
      providesTags: [RtkTagTypes.GetMonitoringLocations],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
    }),
    getMonitoringLocation: builder.query({
      query: (id: number) => `${BASE_ROUTE}/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetMonitoringLocation, id },
      ],
    }),
    addMonitoringLocation: builder.mutation({
      query: (args: any) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: args,
      }),
      invalidatesTags: [RtkTagTypes.GetMonitoringLocations],
    }),
    updateMonitoringLocation: builder.mutation({
      query: (args: any) => ({
        url: `${BASE_ROUTE}/${args.id}`,
        method: "PATCH",
        body: args.body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: RtkTagTypes.GetMonitoringLocation, id },
        RtkTagTypes.GetMonitoringLocations,
      ],
    }),
    deleteMonitoringLocation: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetMonitoringLocations],
    }),
    getMonitoringLocationExportAsXLSX: builder.mutation<
      any,
      { params: any; columnDefinitionDto: any }
    >({
      query: ({ params, columnDefinitionDto }) => ({
        url: `${BASE_ROUTE}/export`,
        method: "POST",
        params: params,
        body: columnDefinitionDto,
        responseHandler: (response: any) => response.blob(),
      }),
    }),
  }),
});

export const {
  useGetMonitoringLocationQuery,
  useLazyGetMonitoringLocationQuery,
  useGetMonitoringLocationsQuery,
  useLazyGetMonitoringLocationsQuery,
  useAddMonitoringLocationMutation,
  useUpdateMonitoringLocationMutation,
  useDeleteMonitoringLocationMutation,
  useGetMonitoringLocationExportAsXLSXMutation,
} = monitoringlocationsEndpoint;
