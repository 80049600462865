import * as React from "react";
import Stepper from "@mui/material/Stepper";
import InspectionSchedulerDetails from "./inspection-scheduler-details";
import InspectionSchedulerDates from "./inspection-scheduler-dates";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useGetAssetsLookupsQuery } from "services/rtkApi/endpoints/assets";
import { LynxDialog } from "components/lynx-dialog";
import { useHistory } from "react-router";
import { Grid } from "components/lynx-components";
import { blueGrey } from "@mui/material/colors";
import useAlert from "hooks/useAlert";
import { useGetAssetSubLocationsQuery } from "services/rtkApi/endpoints/assetsSubLocation";
import { useGetInspectionFormsQuery } from "services/rtkApi/endpoints/inspectionForms";
import { RootState } from "types";
import { useSelector } from "react-redux";
import { useGetEquipmentQuery } from "services/rtkApi/endpoints/equipment";
import { useGetPermitsQuery } from "services/rtkApi/endpoints/permits";
import {
  useAddInspectionScheduleMutation,
  useGetNumberOfInspectionsMutation,
} from "services/rtkApi/endpoints/inspections";

interface FormState {
  assetId: number;
  inspectionFormId: number;
  assignedToUserId?: number;
  description: string;
  equipmentId?: number;
  permits?: number[];
  assetSubLocationId?: number;
  repeatEveryCount: number;
  repeatEveryGranularity: string;
  startDate: string;
  endDate: string;
  daysOfWeek: any[];
  customDates: any[];
}

interface Step {
  component: (props: any) => JSX.Element;
}

const steps: Step[] = [
  {
    component: (props) => <InspectionSchedulerDetails {...props} />,
  },
  {
    component: (props) => <InspectionSchedulerDates {...props} />,
  },
];

const InspectionScheduler: React.FC = () => {
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [inspectionCount, setinspectionCount] = useState(0);
  const activeStepIndex = steps.indexOf(activeStep);
  const isLastStep = activeStepIndex === steps.length - 1;
  const history = useHistory();
  const { users } = useSelector((state: RootState) => state.lookups);

  const { data: assetsLookupData } = useGetAssetsLookupsQuery(true);
  const { data: equipmentData } = useGetEquipmentQuery();
  const { data: permitsData } = useGetPermitsQuery();

  const [addInspectionScheduleTrigger] = useAddInspectionScheduleMutation();
  const [getNumberOfInspectionsTrigger] = useGetNumberOfInspectionsMutation();
  const { showAlert } = useAlert();

  const formSchema = Yup.object().shape({
    assetId: Yup.number().label("Assets"),
    inspectionFormId: Yup.number().label("Inspection Form"),
    equipmentId: Yup.number().label("Equipment"),
    assetSubLocationId: Yup.number().label("Drill Hole"),
    startDate: Yup.string().label("Start Date").required(),
    repeatEveryCount: Yup.number().label("Count").required(),
    repeatEveryGranularity: Yup.string().label("Granularity").required(),
    endDate: Yup.string().label("End Date").required(),
    daysOfWeek: Yup.array().of(Yup.string()),
    customDates: Yup.array().of(Yup.object()),
    description: Yup.string().label("Description"),
    assignedToUserId: Yup.number().label("Assigned To"),
    permits: Yup.array().of(Yup.object()).label("Permits"),
  });

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setFieldValue,
    handleBlur,
    isValid,
  } = useFormik<FormState>({
    enableReinitialize: true,
    validationSchema: formSchema,
    initialValues: {
      assetId: 0,
      inspectionFormId: 0,
      startDate: "",
      repeatEveryCount: 1,
      repeatEveryGranularity: "week",
      endDate: "",
      daysOfWeek: [],
      customDates: [],
      description: "",
      permits: [],
    },
    onSubmit: () => {},
  });

  const { data: assetSubLocData } = useGetAssetSubLocationsQuery(
    { assetId: values.assetId },
    { skip: !values.assetId, refetchOnMountOrArgChange: true }
  );

  const { data: inspectionFormData } = useGetInspectionFormsQuery();

  const isEquipmentRequired = React.useMemo(() => {
    if (inspectionFormData && assetsLookupData && values.inspectionFormId) {
      const inspectionForm = inspectionFormData.find(
        (x: any) => x.id === +values.inspectionFormId
      );
      return inspectionForm ? inspectionForm.isEquipmentInspection : false;
    }
    return false;
  }, [inspectionFormData, assetsLookupData, values.inspectionFormId]);

  const isDrillHoleRequired = React.useMemo(() => {
    if (inspectionFormData && assetsLookupData && values.inspectionFormId) {
      const inspectionForm = inspectionFormData.find(
        (x: any) => x.id === +values.inspectionFormId
      );
      return inspectionForm ? inspectionForm.isSubLocationInspection : false;
    }
    return false;
  }, [assetSubLocData, assetsLookupData, values.inspectionFormId]);

  const handleNext = () => {
    if (isLastStep) {
      getNumberOfInspectionsTrigger(values).then((result) => {
        setinspectionCount(result.data || 0);
        setIsWarningOpen(true);
      });
    } else {
      const nextStep = steps[activeStepIndex + 1];
      setActiveStep(nextStep);
    }
  };

  const handleBack = () => {
    const nextStep = steps[activeStepIndex - 1];
    setActiveStep(nextStep);
  };

  const canGoNext = () => {
    return (
      values.assetId &&
      values.inspectionFormId &&
      (isEquipmentRequired ? values.equipmentId : true) &&
      (isDrillHoleRequired ? values.assetSubLocationId : true)
    );
  };

  const onSubmit = () => {
    if (isValid) {
      const finalValue = {
        ...values,
      };
      addInspectionScheduleTrigger(finalValue).then(() => {
        showAlert("success", "Inspection created.");
        history.push("/inspections");
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <form onSubmit={handleSubmit}>
        <Stepper activeStep={activeStepIndex}></Stepper>
        <Box>
          {steps[activeStepIndex].component({
            activeStepIndex,
            handleSubmit,
            errors,
            touched,
            handleChange,
            values,
            setFieldValue,
            handleBlur,
            assetsLookupData,
            permitsData,
            inspectionFormData,
            equipmentData,
            assetSubLocData,
            isValid,
            isEquipmentRequired,
            isDrillHoleRequired,
          })}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStepIndex === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            onClick={handleNext}
            disabled={isLastStep ? !isValid : !canGoNext()}
          >
            {isLastStep ? "Schedule Inspections" : "Next"}
          </Button>
        </Box>
      </form>

      <LynxDialog
        title={`Schedule ${inspectionCount} Inspection${
          inspectionCount > 1 ? "s" : ""
        }?`}
        dialogContent={
          <Box minWidth={500}>
            {inspectionCount === 0 ? (
              "No inspection will be created. Please change the scheduling criteria and try again."
            ) : (
              <Box sx={{ paddingY: "10px", background: blueGrey[50] }}>
                <Grid>
                  <Grid.Row className="mb-3">
                    <Grid.Col md={3}>Asset:</Grid.Col>
                    <Grid.Col md={9}>
                      {
                        assetsLookupData.find(
                          (f: any) => f.id == values.assetId
                        ).name
                      }
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-3">
                    <Grid.Col md={3}>Inspection Form:</Grid.Col>
                    <Grid.Col md={9}>
                      {inspectionFormData.find(
                        (f: any) => f.id == values.inspectionFormId
                      )?.name || "-"}
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-3">
                    <Grid.Col md={3}>Permits:</Grid.Col>
                    <Grid.Col md={9}>
                      {values.permits
                        ?.map((permit: any) => permit.permitNumber)
                        ?.join(", ") || "-"}
                    </Grid.Col>
                  </Grid.Row>
                  {isEquipmentRequired && (
                    <Grid.Row className="mb-3">
                      <Grid.Col md={3}>Equipment:</Grid.Col>
                      <Grid.Col md={9}>
                        {equipmentData?.data?.find(
                          (f: any) => f.id == values.equipmentId
                        )?.name || "-"}
                      </Grid.Col>
                    </Grid.Row>
                  )}
                  {isDrillHoleRequired && (
                    <Grid.Row className="mb-3">
                      <Grid.Col md={3}>Drill Hole:</Grid.Col>
                      <Grid.Col md={9}>
                        {assetSubLocData?.data?.find(
                          (f: any) => f.id == values.assetSubLocationId
                        )?.name || "-"}
                      </Grid.Col>
                    </Grid.Row>
                  )}
                  <Grid.Row className="mb-3">
                    <Grid.Col md={3}>Assigned To:</Grid.Col>
                    <Grid.Col md={9}>
                      {users?.find((user) => user.id == values.assignedToUserId)
                        ?.fullName || "-"}
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-3">
                    <Grid.Col md={3}>Description:</Grid.Col>
                    <Grid.Col md={9}>
                      <Typography sx={{ wordBreak: "break-word" }}>
                        {values.description || "-"}
                      </Typography>
                    </Grid.Col>
                  </Grid.Row>
                </Grid>
              </Box>
            )}
          </Box>
        }
        open={isWarningOpen}
        handleCancel={() => setIsWarningOpen(false)}
        buttons={() =>
          inspectionCount > 0 ? (
            <Button variant="contained" color="success" onClick={onSubmit}>
              Yes
            </Button>
          ) : undefined
        }
      />
    </Box>
  );
};

export default InspectionScheduler;
