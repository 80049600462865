import Button from "@mui/material/Button";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { dateUtil } from "services/date-util";
import { useGetKpisQuery } from "services/rtkApi/endpoints/kpis";
import { LynxGridColDef } from "types";
import { EntityTypes } from "types/enums-ts";
import { getKpis } from "../../../services/kpi-service";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import { LynxDialog } from "../../lynx-dialog";

export function KpiList() {
  const [showDialog, setShowDialog] = useState(false);
  const history = useHistory();
  useEffect(() => {
    getKpis().then((res) => {
      if (_.isEmpty(res.data)) {
        setShowDialog(true);
      }
    });
  }, []);
  const handleKpiClick = (row: any) => {
    history.push(`/kpis/${row.id}`);
  };
  const statusColorSwitch = (params: any) => {
    const kpi = params.row;
    if (!kpi.threshold) {
      return "text-warning";
    }
    if (kpi.isUpperThreshold) {
      if (kpi.lastCalculatedValue > kpi.threshold) {
        return "text-danger";
      } else {
        return "text-success";
      }
    } else {
      if (kpi.lastCalculatedValue > kpi.threshold) {
        return "text-success";
      } else {
        return "text-danger";
      }
    }
  };

  const kpiColumns: LynxGridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 300,
      type: "string",
      renderCell: (params: any) => (
        <Button variant="text" onClick={() => handleKpiClick(params.row)}>
          {params.value}
        </Button>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      type: "string",
    },
    {
      field: "kpiCalculation",
      headerName: "Calculation",
      width: 500,
      type: "string",
    },
    {
      field: "filterName",
      headerName: "Filter",
      width: 150,
      type: "string",
    },
    {
      field: "lastCalculatedDateTimeUtc",
      headerName: "Last Calculated Date",
      width: 150,
      type: "dateTime",
      valueGetter: (value) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
    {
      field: "lastCalculatedValue",
      headerName: "Last Calculated Value",
      width: 150,
      type: "number",
      valueGetter: (value: any) => {
        if (!value) {
          return value;
        }

        return parseFloat(value).toFixed(8);
      },
    },
    {
      field: "threshold",
      headerName: "Threshold",
      width: 150,
      type: "number",
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      type: "number",
      renderCell: (params: any) => (
        <div>
          <>
            <i className={"fe fe-circle " + statusColorSwitch(params)} />
          </>
        </div>
      ),
    },
  ];

  return (
    <>
      <LynxDialog
        title="Contact Lynx to add KPIs!"
        description="Stay on top of your team’s performance with the Lynx Key Performance Indicator (KPI) module. KPI’s are like a compass to keep you on track and headed in the right direction with performance, benchmarking and evaluation. Contact the Lynx team to get started today."
        handleConfirm={() => {
          setShowDialog(false);
        }}
        open={showDialog}
      />
      <LynxDataGrid
        columns={kpiColumns}
        useQuery={useGetKpisQuery}
        localStorageName="kpis"
        entityType={EntityTypes.Kpi}
      />
    </>
  );
}
