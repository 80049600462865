import { useEffect, useState } from "react";
import { Form, Grid } from "components/lynx-components";

import Button from "@mui/material/Button";
import _ from "lodash";
import { validationService } from "../../../services";
import { useSelector } from "react-redux";
import {
  createGroupChartWidget,
  getGroupByData,
  handleDeleteWidgetFilterLocalStorage,
} from "../../../services/dashboard-widget-service";
import { getCustomFields } from "../../../services/custom-fields-service";
import { transformCustomFieldDefsToColumnDefs } from "./../../../services/custom-fields-service";
import "./add-widget-modal.css";
import { BarChartWidget } from "./widgets/bar-chart-widget";
import { DonutChartWidget } from "./widgets/donut-chart-widget";
import { monEventColumns } from "../monitoring/mon-event-columns";
import { getMonitoringResultColumns } from "../monitoring/monitroing-result-columns";
import { getActionColumns } from "../actions/action-columns";
import { incidentColumns } from "../incidents/incident-columns";
import {
  hasAnyEventRole,
  hasAnyMonitoringRole,
  roleMatch,
} from "../../../actions/auth";
import { EntityTypes, UserRoles } from "./../../../types/enums";
import { getInspectionColumns } from "../inspections/inspection-columns";
import { energyLogColumns } from "../energy/energy-log-columns";

import { getEventColumns } from "../events/event-columns";
import useAlert from "hooks/useAlert";
import {
  useDeleteUserDashboardWidgetByIdMutation,
  useUpdateWidgetGroupChartMutation,
} from "services/rtkApi/endpoints/dashboardWidget";
import { LynxDialog } from "components/lynx-dialog";
import { Stack } from "@mui/material";
import { WidgetTypes } from "types/enums-ts";
const initialForm = {
  title: "",
  titleError: "",
  filterId: "",
  groupBy: "",
  dataset: "",
  datasetError: "",
};
export function CreateGroupChart(props) {
  var organization = useSelector((state) => state.organization);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(initialForm);
  const [eventCustomFields, setEventCustomFields] = useState(null);
  const [previewData, setPreviewData] = useState([]);

  const [filters, setFilters] = useState([]);
  const [groupByColumns, setGroupByColumns] = useState([]);
  const { users } = useSelector((state) => state.lookups);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const predefinedLookups = { users: users };
  const [updateWidgetTrigger] = useUpdateWidgetGroupChartMutation();
  const [deleteWidgetTrigger] = useDeleteUserDashboardWidgetByIdMutation();

  const { showAlert } = useAlert();

  const isForUpdate = Boolean(props.existingWidget);

  useEffect(() => {
    getCustomFields(EntityTypes.Event).then((res) => {
      let eventCustomFields = res.data;
      setEventCustomFields(eventCustomFields);
    });
    if (isForUpdate) {
      prepareFormsateFromExisting();
    }
  }, []);

  useEffect(() => {
    if (formState.filterId && formState.groupBy && formState.dataset) {
      getGroupByData(
        formState.dataset,
        formState.filterId,
        formState.groupBy
      ).then((res) => {
        setPreviewData(res.data);
      });
    }
  }, [formState.filterId, formState.groupBy, formState.dataset]);

  useEffect(() => {
    if (!_.isEmpty(props.filters) && formState.dataset != "") {
      const filtersToSet = props.filters.filter(
        (x) => _.toLower(x.entityType) === _.toLower(formState.dataset)
      );
      setFilters(filtersToSet);
    }
  }, [props.filters, formState.dataset]);

  useEffect(() => {
    if (formState.dataset != "" && !organization.isLoading) {
      const predefinedLookups = {
        users: users,
      };

      let newColumns =
        _.toLower(formState.dataset) == "event"
          ? getEventColumns(
              organization.hasPermitFeature,
              false,
              predefinedLookups
            ).filter((x) => !x.disableColumnMenu)
          : _.toLower(formState.dataset) == "monitoringevent"
          ? monEventColumns(predefinedLookups)
          : _.toLower(formState.dataset) == "action"
          ? getActionColumns(predefinedLookups)
          : _.toLower(formState.dataset) == "incident"
          ? incidentColumns(predefinedLookups)
          : _.toLower(formState.dataset) == "inspectionevent"
          ? getInspectionColumns(predefinedLookups)
          : _.toLower(formState.dataset) == _.toLower(EntityTypes.EnergyLog)
          ? energyLogColumns(predefinedLookups)
          : _.toLower(formState.dataset) ==
            _.toLower(EntityTypes.InspectionEvent)
          ? getInspectionColumns(predefinedLookups)
          : getMonitoringResultColumns(predefinedLookups);
      if (
        !_.isEmpty(eventCustomFields) &&
        _.toLower(formState.dataset) == "event"
      ) {
        newColumns = [
          ...newColumns,
          ...transformCustomFieldDefsToColumnDefs(eventCustomFields),
        ];
      }

      newColumns = newColumns.filter(
        (x) => x.filterable != false && x.useInDashboard != false
      );
      setGroupByColumns(newColumns);
    }
  }, [formState.dataset, eventCustomFields, organization.isLoading]);

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    //unset errors
    let formToSave = validationService.unsetErrors(
      formState,
      "titleError",
      "filterIdError",
      "groupByError"
    );
    formToSave.widgetType = props.widgetType;

    if (isForUpdate) {
      formToSave.id = props.existingWidget.id;
      updateWidgetTrigger(formToSave).then(() => {
        props.handleModalClose(true);
        showAlert("success", "Widget updated.");
        customEventRefreshWidgetChart();
      });
    } else {
      createGroupChartWidget(formToSave)
        .then(() => {
          props.handleModalClose(true);
          showAlert("success", "Widget created.");
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
  };

  const customEventRefreshWidgetChart = () => {
    const event = new CustomEvent("ce-refresh-widget-chart", {
      detail: { refreshData: true },
    });
    window.dispatchEvent(event);
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "title",
      "titleError",
      "Title"
    );

    validationService.validateRequiredField(
      newState,
      "groupBy",
      "groupByError",
      "Group By"
    );

    validationService.validateRequiredField(
      newState,
      "dataset",
      "datasetError",
      "Data set"
    );

    validationService.validateRequiredField(
      newState,
      "filterId",
      "filterIdError",
      "Filter"
    );

    isFormValid = !validationService.hasError(
      newState,
      "titleError",
      "filterIdError",
      "groupByError"
    );

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const prepareFormsateFromExisting = () => {
    const jsonValue = JSON.parse(props.existingWidget.propertiesJson);
    let newState = { ...formState };
    _.set(newState, "title", props.existingWidget.title);
    _.set(newState, "dataset", jsonValue.entityType);
    _.set(newState, "groupBy", jsonValue.groupBy);
    _.set(
      newState,
      "filterId",
      jsonValue.filterId
    );
    setFormState(newState);
  };

  const handleDeleteWidget = () => {
    deleteWidgetTrigger(props.existingWidget.id)
      .then((res) => {
        showAlert("success", "Widget deleted.");
      })
      .finally(() => {
        setIsDeleteOpen(false);
        props.handleModalClose(true);
      });
    handleDeleteWidgetFilterLocalStorage(props.existingWidget.id);
  };

  return (
    <>
      <Grid.Col md={12} width={12}>
        <Form.Group isRequired label="Title" id="title">
          <Form.Input
            name="title"
            error={formState.titleError}
            onChange={handleInputChange}
            value={formState.title}
          ></Form.Input>
        </Form.Group>
      </Grid.Col>
      <Grid.Col md={6} width={6}>
        <Form.Group label="Select Dataset" isRequired id="dataset">
          <Form.Select
            name="dataset"
            onChange={handleInputChange}
            value={formState.dataset}
            error={formState.datasetError}
          >
            <option value={""}></option>
            {(hasAnyEventRole() || roleMatch([UserRoles.IncidentsUser])) && (
              <option value={"Action"}>Actions</option>
            )}

            {hasAnyEventRole() && <option value={"Event"}>Events</option>}
            {roleMatch([UserRoles.IncidentsUser]) && (
              <option value={"Incident"}>Incidents</option>
            )}
            {roleMatch([UserRoles.InspectionsUser]) && (
              <option value={EntityTypes.InspectionEvent}>Inspections</option>
            )}

            {hasAnyMonitoringRole() && (
              <>
                <option value={"MonitoringEvent"}>Monitoring Events</option>
                <option value={"MonitoringResult"}>Monitoring Results</option>
              </>
            )}
            {roleMatch([UserRoles.EnergyUser]) && (
              <option value={EntityTypes.EnergyLog}>Energy Logs</option>
            )}
          </Form.Select>
        </Form.Group>
      </Grid.Col>
      <Grid.Col md={6} width={6}>
        <Form.Group label="Select Filter" isRequired id="filterId">
          <Form.Select
            name="filterId"
            onChange={handleInputChange}
            value={formState.filterId}
            error={formState.filterIdError}
            disabled={formState.dataset == ""}
          >
            <option value={""}></option>
            {_.orderBy(filters, "name").map((filter) => (
              <option value={filter.id} key={filter.id}>
                {filter.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Grid.Col>
      <Grid.Col md={6} width={6}>
        <Form.Group label="Group By" isRequired id="groupBy">
          <Form.Select
            name="groupBy"
            onChange={handleInputChange}
            value={formState.groupBy}
            error={formState.groupByError}
            disabled={formState.dataset == ""}
          >
            <option value={""}></option>
            {_.orderBy(groupByColumns, "headerName").map((col) => (
              <option value={col.field} key={col.field}>
                {col.headerName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Grid.Col>
      <Grid.Col md={12} width={12}>
        {props.widgetType == WidgetTypes.DonutChart && (
          <DonutChartWidget
            title={formState.title}
            data={previewData}
            loading={loading}
          />
        )}
        {props.widgetType == WidgetTypes.BarChart && (
          <BarChartWidget
            title={formState.title}
            data={previewData}
            loading={loading}
          />
        )}
      </Grid.Col>
      <Grid.Col md={12} width={12}>
        <Stack direction="row-reverse" spacing={1}>
          <Button
            variant="contained"
            className="float-right"
            onClick={saveForm}
          >
            {isForUpdate ? "Save" : "Create Widget"}
          </Button>
          {isForUpdate && (
            <Button
              variant="contained"
              color="error"
              onClick={() => setIsDeleteOpen(true)}
            >
              Delete
            </Button>
          )}
        </Stack>
      </Grid.Col>
      <LynxDialog
        open={isDeleteOpen}
        title="Are you sure you want to delete the dashboard widget?"
        description={"This cannot be undone."}
        handleClose={() => setIsDeleteOpen(false)}
        handleConfirm={handleDeleteWidget}
      />
    </>
  );
}
