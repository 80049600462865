import queryString from "query-string";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";

const BASE_ROUTE = "/portalSubmissions";

const portalSubmissionsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    // Fetch submissions with pagination
    getSubmissions: builder.query({
      query: (baseParams: any) =>
        `${BASE_ROUTE}?${queryString.stringify(baseParams)}`,
      providesTags: [RtkTagTypes.GetPortalSubmissions],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Fetch a single submission by ID
    getSubmission: builder.query({
      query: (id: number) => `${BASE_ROUTE}/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetPortalSubmissions, id },
      ],
    }),

    // Add a new submission
    addSubmission: builder.mutation({
      query: (dto: any) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetPortalSubmissions],
    }),

    // Patch an existing submission
    patchSubmission: builder.mutation({
      query: ({
        id,
        submissionPatch,
      }: {
        id: number;
        submissionPatch: any;
      }) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "PATCH",
        body: submissionPatch,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetPortalSubmissions, id },
      ],
    }),
  }),
});

export const { useGetSubmissionsQuery } = portalSubmissionsEndpoint;
