import { Chip, Popover } from "@mui/material";
import { useState } from "react";
import { MentionTagModel } from "types";
import { ContactQuickCard } from "../contacts/contact-quick-card";
import UserQuickCard from "./user-quick-card";
import { EventContactTypes } from "types/enums";
import EventQuickView from "./event-quick-view";
type Props = {
  id: string;
  type: string;
  name: string;
};
const TagChip = ({ id, type, name }: Props) => {
  const [selectedTag, setSelectedTag] = useState<MentionTagModel | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  return (
    <>
      <Chip
        size="small"
        sx={{ paddingBottom: "0.1rem" }}
        variant="outlined"
        label={name}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setSelectedTag({
            id: id.replace("c_", "").replace("g_", ""),
            type: type,
          } as MentionTagModel);
        }}
      />
      {selectedTag && (
        <Popover
          open={Boolean(selectedTag)}
          anchorEl={anchorEl}
          onClose={() => {
            setSelectedTag(null);
            setAnchorEl(null);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          {selectedTag &&
            (selectedTag.type == EventContactTypes.User ? (
              <UserQuickCard id={selectedTag.id} />
            ) : selectedTag.type == EventContactTypes.Event ? (
              <EventQuickView eventId={+selectedTag.id} />
            ) : (
              <ContactQuickCard id={selectedTag.id} type={selectedTag.type} />
            ))}
        </Popover>
      )}
    </>
  );
};

export default TagChip;
