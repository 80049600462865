import queryString from "query-string";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";

const BASE_ROUTE = "/monitoringLimits";

const monitoringLimitsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    // Get monitoring limits with pagination
    getMonitoringLimits: builder.query({
      query: (baseParams: any) =>
        `${BASE_ROUTE}?${queryString.stringify(baseParams)}`,
      providesTags: [RtkTagTypes.GetMonitoringLimits],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Create a new monitoring limit
    createMonitoringLimit: builder.mutation({
      query: (dto: any) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [RtkTagTypes.GetMonitoringLimits],
    }),

    // Update an existing monitoring limit
    updateMonitoringLimit: builder.mutation({
      query: ({ id, dto }: { id: number; dto: any }) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetMonitoringLimits, id },
        RtkTagTypes.GetMonitoringLimits,
      ],
    }),

    // Delete a monitoring limit
    deleteMonitoringLimit: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetMonitoringLimits],
    }),
  }),
});

export const {
  useGetMonitoringLimitsQuery,
  useCreateMonitoringLimitMutation,
  useUpdateMonitoringLimitMutation,
  useDeleteMonitoringLimitMutation,
} = monitoringLimitsEndpoint;
