import { getGridSingleSelectOperators } from "@mui/x-data-grid-pro";
import { useGetAssetsLookupsQuery } from "services/rtkApi/endpoints/assets";
import { useGetLookupsQuery } from "services/rtkApi/endpoints/lookups";
import { useGetMonitoringLocationsQuery } from "services/rtkApi/endpoints/monitoringLocations";
import { useGetParametersQuery } from "services/rtkApi/endpoints/parameters";
import {
  AssetDto,
  LookupDto,
  LynxGridColDef,
  MonitoringLocationDto,
  ParameterDto,
} from "types";
import { dateUtil } from "../../../services/date-util";
import { LookupTypes, VarianceStatuses } from "../../../types/enums";
export const getMonitoringResultColumns = (predefinedLookups: any) => {
  return [
    {
      field: "monitoringEventNumber",
      headerName: "Event #",
      width: 100,
      type: "string",
    },
    {
      field: "monitoringEventDueDateTimeUtc",
      headerName: "Due Date",
      width: 150,
      type: "date",
      importable: true,
      isEventField: true,
      valueGetter: (value: any) => {
        return dateUtil.convertColumnDateOnlyToLocal(value);
      },
      disabled: true,
    },

    {
      field: "monitoringEventStartDateTimeUtc",
      headerName: "Start Date",
      width: 150,
      type: "dateTime",
      importable: true,
      isEventField: true,
      valueGetter: (value: any) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
    {
      field: "monitoringEventEndDateTimeUtc",
      headerName: "End Date",
      width: 150,
      type: "dateTime",
      importable: true,
      isEventField: true,
      valueGetter: (value: any) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },

    {
      field: "monitoringEventName",
      headerName: "Sample ID",
      width: 150,
      type: "string",
      importable: true,
      isEventField: true,
    },
    {
      field: "parameterName",
      headerName: "Parameter",
      width: 150,
      type: "singleSelect",
      query: useGetParametersQuery,
      getOptionValue: (value: ParameterDto) => (!value ? "" : value.name),
      getOptionLabel: (value: ParameterDto) => (!value ? "" : value.name),
      importable: true,
    },
    {
      field: "enteredValue",
      headerName: "Value",
      width: 50,
      type: "string",
      importable: true,
    },
    {
      field: "value",
      headerName: "Numeric Value",
      width: 50,
      type: "number",
      disabled: true,
    },

    {
      field: "unitName",
      headerName: "Unit",
      width: 150,
      type: "singleSelect",
      query: useGetLookupsQuery,
      queryParams: { lookupType: LookupTypes.Unit },
      getOptionValue: (value: LookupDto) => (!value ? "" : value.code),
      getOptionLabel: (value: LookupDto) => (!value ? "" : value.code),
      importable: true,
    },
    {
      field: "enteredMethodDetectionLimit",
      headerName: "MDL",
      width: 50,
      type: "string",
      importable: true,
    },
    {
      field: "enteredReportingDetectionLimit",
      headerName: "RDL",
      width: 50,
      type: "string",
      importable: true,
    },
    {
      field: "sequence",
      headerName: "Sequence",
      width: 50,
      type: "string",
      importable: true,
    },
    {
      field: "populationVariance",
      headerName: "Population Variance",
      width: 50,
      type: "number",
    },
    {
      field: "sampleVariance",
      headerName: "Sample Variance",
      width: 50,
      type: "number",
      useInDashboard: false,
    },
    {
      field: "varianceLimit",
      headerName: "Variance Allowance",
      width: 50,
      type: "number",
      useInDashboard: false,
    },
    {
      field: "varianceStatus",
      headerName: "Variance Status",
      width: 50,
      type: "singleSelect",
      valueOptions: [
        VarianceStatuses.Exceedance,
        VarianceStatuses.NonExceedance,
      ],
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value !== "isAnyOf"
      ),
      renderCell: (params: any) => (
        <div>
          <>
            <i
              className={`fe fe-circle ${
                params.value === VarianceStatuses.Exceedance
                  ? "text-danger"
                  : "text-success"
              }`}
            />
            &nbsp;{params.value}
          </>
        </div>
      ),
    },
    {
      field: "rdp",
      headerName: "RPD",
      width: 50,
      type: "number",
    },
    {
      field: "analysisMethod",
      headerName: "Analysis Method",
      width: 150,
      type: "string",
      importable: true,
    },

    {
      field: "lowestLimitString",
      headerName: "Lowest Limit",
      width: 50,
      type: "string",
      filterable: false,
      sortable: false,
    },

    {
      field: "limitsList",
      headerName: "All Limits",
      width: 150,
      type: "string",
      filterable: false,
      sortable: false,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 100,
      type: "string",
      importable: true,
    },
    {
      field: "resultType",
      headerName: "Result Type",
      width: 100,
      type: "singleSelect",
      query: useGetLookupsQuery,
      queryParams: { lookupType: LookupTypes.MonitoringResultType },
      getOptionValue: (value: LookupDto) => (!value ? "" : value.code),
      getOptionLabel: (value: LookupDto) => (!value ? "" : value.code),
      importable: true,
    },
    {
      field: "monitoringLocationNumber",
      headerName: "Location Number",
      width: 150,
      type: "singleSelect",
      query: useGetMonitoringLocationsQuery,
      getOptionValue: (value: MonitoringLocationDto) =>
        value.monitoringLocationNumber,
      getOptionLabel: (value: MonitoringLocationDto) =>
        value.monitoringLocationNumber,
    },
    {
      field: "monitoringLocationName",
      headerName: "Monitoring Location",
      width: 150,
      type: "singleSelect",
      query: useGetMonitoringLocationsQuery,
      getOptionValue: (value: MonitoringLocationDto) =>
        !value ? "" : value.name,
      getOptionLabel: (value: MonitoringLocationDto) =>
        !value ? "" : value.name,
      importable: true,
      isEventField: true,
    },
    {
      field: "assetName",
      headerName: "Asset Name",
      width: 150,
      type: "singleSelect",
      query: useGetAssetsLookupsQuery,
      getOptionValue: (value: AssetDto) => (!value ? "" : value.name),
      getOptionLabel: (value: AssetDto) => (!value ? "" : value.name),
    },

    {
      field: "monitoringEventType",
      headerName: "Event Type",
      width: 200,
      type: "singleSelect",
      query: useGetLookupsQuery,
      queryParams: { lookupType: LookupTypes.MonitoringEventType },
      getOptionValue: (value: LookupDto) => (!value ? "" : value.code),
      getOptionLabel: (value: LookupDto) => (!value ? "" : value.code),
      importable: true,
      isEventField: true,
    },
    {
      field: "monitoringEventDescription",
      headerName: "Description",
      width: 150,
      type: "string",
      importable: true,
      disabled: true,
      isEventField: true,
    },
    {
      field: "assignedToUserFullName",
      headerName: "Assigned To",
      width: 150,
      type: "singleSelect",
      valueOptions: predefinedLookups.users,
      getOptionValue: (value: any) => (!value ? "" : value.fullName),
      getOptionLabel: (value: any) => (!value ? "" : value.fullName),
      importable: true,
      isEventField: true,
      disabled: true,
    },
    {
      field: "isNonCompliant",
      headerName: "Compliance Status",
      width: 150,
      type: "singleSelect",
      valueOptions: [
        { value: "true", label: "Noncompliant" },
        { value: "false", label: "Compliant" },
      ],
      getOptionValue: (value) => (!value ? "" : value.value),
      getOptionLabel: (value) => (!value ? "" : value.label),
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value !== "isAnyOf"
      ),
      renderCell: (params: any) => (
        <div>
          <>
            <i
              className={`fe fe-circle ${
                params.value === true
                  ? "text-danger"
                  : params.value === false
                  ? "text-success"
                  : "text-warning"
              }`}
            />
            &nbsp;{params.value}
          </>
        </div>
      ),
    },
  ] as LynxGridColDef[];
};
