import React, { FC, useCallback } from "react";
import GroupIcon from "@mui/icons-material/Group";
import { Mention, MentionsInput, OnChangeHandlerFunc } from "react-mentions";
import { EventContactTypes } from "../../../types/enums";
import { useLazySearchContactsQuery } from "services/rtkApi/endpoints/contacts";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { useLazySearchEventsQuery } from "services/rtkApi/endpoints/events";
import _, { debounce } from "lodash";

export interface TaggableEntity {
  id: number | string;
  display: string;
}

interface CommentInputProps {
  value: string;
  onChange: OnChangeHandlerFunc;
  style?: React.CSSProperties;
  users: TaggableEntity[];
  isDisabled?: boolean;
  canTagEvent?: boolean;
}

const CommentsInput: FC<CommentInputProps> = ({
  value,
  onChange,
  style,
  users,
  isDisabled,
  canTagEvent,
}) => {
  const [triggerSearchContacts] = useLazySearchContactsQuery();
  const [searchEventTrigger] = useLazySearchEventsQuery();

  const renderSuggestion = (
    entry: any,
    search: string,
    highlightedDisplay: React.ReactNode,
    index: number,
    focused: boolean
  ) => {
    return (
      <div className="d-flex">
        {entry.type === "ContactGroup" && (
          <GroupIcon
            className="mr-2"
            style={{ color: "rgba(0, 0, 0, 0.26)" }}
          />
        )}
        {highlightedDisplay}
      </div>
    );
  };

  const renderUsersAndEvents = (
    entry: any,
    search: string,
    highlightedDisplay: React.ReactNode
  ) => {
    return (
      <div className="d-flex">
        {entry.type === "Event" && (
          <EventOutlinedIcon
            className="mr-2"
            style={{ color: "rgba(0, 0, 0, 0.26)" }}
          />
        )}
        {highlightedDisplay}
      </div>
    );
  };

  const fetchContacts = useCallback(
    debounce((query: string, callback: (data: TaggableEntity[]) => void) => {
      if (!query) return;
      triggerSearchContacts({ query: query, includeUsers: false })
        .then((res: any) =>
          res.data.map((contact: any) => ({
            id:
              contact.type === EventContactTypes.Contact
                ? "c_" + contact.id
                : "g_" + contact.id,
            display: contact.name,
            type: contact.type,
          }))
        )
        .then(callback);
    }, 300),
    []
  );

  const getUsersAndEvents = useCallback(
    debounce((query: string, callback: (data: TaggableEntity[]) => void) => {
      if (!query.trim()) return;
      searchEventTrigger({ search: query, eventNumberOnly: true })
        .then((res: any) => {
          const filteredUsers = users.filter((user) =>
            user.display.toLowerCase().includes(query.trim().toLowerCase())
          );

          const events = res.data?.map((event: any) => ({
            id: event.id,
            display: event.eventNumber,
            type: "Event",
          }));

          const usersAndEvents = filteredUsers.concat(events || []);
          return _.orderBy(usersAndEvents, ["display"], ["asc"]);
        })
        .then(callback);
    }, 300),
    []
  );

  return (
    <MentionsInput
      disabled={isDisabled}
      allowSpaceInQuery
      className="lynx-mentions-input"
      rows={4}
      value={value}
      onChange={onChange}
      style={style}
    >
      <Mention
        trigger="@"
        style={{
          backgroundColor: "#f3a738",
          opacity: 0.3,
          borderRadius: 3,
        }}
        renderSuggestion={renderUsersAndEvents}
        displayTransform={(id, display) => `@${display}`}
        markup="@[__display__](__id__)"
        data={canTagEvent ? getUsersAndEvents : users}
      />
      <Mention
        trigger="#"
        style={{
          backgroundColor: "#00A9E0",
          opacity: 0.3,
          borderRadius: 3,
        }}
        renderSuggestion={renderSuggestion}
        displayTransform={(id, display) => `#${display}`}
        markup="#[__display__](__id__)"
        data={fetchContacts}
      />
    </MentionsInput>
  );
};

export default CommentsInput;
