import queryString from "query-string";
import { AssetDto } from "types";
import { apiService } from "../apiService";

const BASE_ROUTE = "/assets";

const assetsEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getAssetsLookups: builder.query({
      query: (showAll: boolean | undefined = false) =>
        `${BASE_ROUTE}/lookup?showAll=${showAll}`,
    }),
    getAssets: builder.query({
      query: (params: any) => `${BASE_ROUTE}?${queryString.stringify(params)}`,
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
    }),
    getAssetById: builder.query<AssetDto, number>({
      query: (id) => `${BASE_ROUTE}/${id}`,
    }),
  }),
});

export const {
  useGetAssetsLookupsQuery,
  useGetAssetsQuery,
  useGetAssetByIdQuery,
} = assetsEndpoint;
