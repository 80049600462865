import { Card, Form, Grid } from "components/lynx-components";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortIcon from "@mui/icons-material/Sort";
import SyncIcon from "@mui/icons-material/Sync";
import useOnlineStatus from "hooks/useOnlineStatus";
import Button from "@mui/material/Button";
import {
  DataGridPro,
  getGridDateOperators,
  getGridNumericOperators,
  GridCallbackDetails,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import React, {
  FC,
  useLayoutEffect,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { localStorageService } from "services/local-storage-service";
import { CustomToolbar } from "./custom-toolbar";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { EntityTypes } from "types/enums-ts";
import Chip from "@mui/material/Chip";
import { saveAs } from "file-saver";
import useAlert from "hooks/useAlert";
import {
  LynxGridColDef,
  FilterDto,
  ReportDto,
  DocumentSource,
  RootState,
} from "types";
import _, { AnyKindOfDictionary } from "lodash";
import {
  convertFilterDefToQueryString,
  deleteFilter,
  getFilters,
  saveFilter,
} from "services/filter-service";
import { rangeOfDateOperator } from "./range-of-date-filter-operator";
import { betweenDateOperator } from "./between-date-filter-operator";
import { betweenDateTimeOperator } from "./between-datetime-filter-operator";
import { LynxDialog } from "components/lynx-dialog";
import { FilterModal } from "./filter-modal";
import { roleMatch } from "actions/auth";
import { UserRoles } from "types/enums";
import { ReportsModal } from "./reports-modal";
import {
  Badge,
  CircularProgress,
  Dialog,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import useWindowDimensions from "hooks/useWindowDimensions";
import { DataGridSearch } from "./datagrid-search";
import localforage from "localforage";
import { BulkUpdate } from "./bulk-update";

import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import { queryRagModelAi } from "services/admin";
import Box from "@mui/system/Box";
import lynxColors from "modules/lynxColors";
import DocumentSourceChip from "components/dashboards/ai/document-source-chip";
import { useLazyGetMonitoringLocationQuery } from "services/rtkApi/endpoints/monitoringLocations";
import {
  useLazyGetContactByIdQuery,
  useLazyGetContactGroupByIdQuery,
} from "services/rtkApi/endpoints/contacts";
import {
  useLazyGetReportsQuery,
  useLazyRunEventsReportQuery,
  useLazyRunIncidentsReportQuery,
} from "services/rtkApi/endpoints/reports";
import { useSelector } from "react-redux";

export interface LynxDataGridProps {
  //used to set height to height of parent container
  autoHeight?: boolean;
  disableCardBorder?: boolean;
  localStorageName?: string;
  enableReports?: boolean;
  //disables the toolbar for the data grid
  disableToolbar?: boolean;
  //only shows the datagrid and no headers
  onlyShowTable?: boolean;
  enableSavedFilters?: boolean;
  entityType: EntityTypes;
  indexDbObjectStoreName?: string;
  indexDbSortColumn?: string;
  useQuery?: any;
  //additional params to add to baseParams
  useQueryParams?: any;
  //additional params to be passed into useQuery first, followed by baseParams
  useQueryRouteParams?: any;
  parentContainerProps?: any;
  //set to true once add/update/delete api has been replaced with RTK
  disableRefetchOnMountOrArgChange?: boolean;
  bulkUpdateAttachmentsTrigger?: (args: { params: any; files: any }) => any;
  handleExportAttachmentsTrigger?: (e: any) => any;
  bulkUpdateTrigger?: (args: any) => any;
  handleExportXLSXTrigger?: (args: any) => any;
  handleEditClick?: (e: any) => any;
  handleRefresh?: (e: any) => any;
  //column definitions for the data grid
  columns: LynxGridColDef[];
  hasEditButton?: boolean;
  passEntityOnEditClick?: boolean;
  entityModal?: React.ComponentType<any>;
  mobileCard?: React.ComponentType<any>;
  header?: React.ComponentType<any>;
  tour?: React.ComponentType<any>;
  entityModalProps?: any;
  isOfflineReady?: boolean;
  addButtonAction?: (e: any) => any;
  title?: string;
  searchEnabled?: boolean;
  searchLabel?: string;
  hideAddButton?: boolean;
  disableAddButton?: boolean;
  addButtonText?: string;
  gridWidth?: number;
  getRowId?: string;
  hasAiQuery?: boolean;
}

interface GridQueryParamsModel {
  filterId?: number;
  view?: string;
  varianceStatus?: string;
  groupBy?: string;
  importHistoryId?: number;
  monitoringLocationId?: number;
  contactId?: number;
  contactGroupId?: number;
}

export const LynxDataGrid = (props: LynxDataGridProps) => {
  const filterStorageName = props.localStorageName + "_filter_v2";
  const columnsStorageName = props.localStorageName + "_columns_v2";
  const filterIdStorageName = props.localStorageName + "_filterId_v2";
  const sortStorageName = props.localStorageName + "_sort_v2";
  const paginationStorageName = props.localStorageName + "_pagination_v2";
  const getInitialSortModel = (): GridSortModel => {
    let sortObject = null;
    if (props.localStorageName) {
      sortObject = localStorageService.getLocalStorage(sortStorageName);
    }

    return sortObject ? sortObject : [];
  };
  const getInitialPageModel = (): GridPaginationModel => {
    let pageObject = null;
    if (props.localStorageName) {
      pageObject = localStorageService.getLocalStorage(paginationStorageName);
    }
    return pageObject ? pageObject : { page: 0, pageSize: 20 };
  };

  const getInitialFilterModel = (): GridFilterModel => {
    let filterObject = null;
    if (props.localStorageName && !props.disableToolbar) {
      filterObject = localStorageService.getLocalStorage(filterStorageName);
    }
    return filterObject ? filterObject : defaultFilterModel;
  };
  const [showReportError, setShowReportError] = useState(false);
  const [aiSources, setAiSources] = useState<DocumentSource[]>([]);
  const [reportDownloading, setReportDownloading] = useState(false);
  const defaultFilterModel = { items: [] };
  const [selectedEntity, setSelectedEntity] = useState({});
  const [originalColumnDef, setOriginalColumnDef] = useState<LynxGridColDef[]>(
    []
  );
  const [gridData, setGridData] = useState<any[]>([]);
  const [prompt, setPrompt] = useState("");
  const [aiIds, setAiIds] = useState<number[]>([]);
  const [showAiQuery, setShowAiQuery] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [defaultEntityModalValues, setDefaultEntityModalValues] = useState({});
  const [linkedEntityId, setLinkedEntityId] = useState(0);
  const [reports, setReports] = useState<ReportDto[]>([]);
  const [selectedDataId, setSelectedDataId] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [size, setSize] = useState([0, 0]);
  const [exportError, setExportError] = useState("");
  const [savedFilters, setSavedFilters] = useState<FilterDto[]>([]);
  const { search } = useLocation();
  const [answer, setAnswer] = useState<string | null>(null);
  const [answerLoading, setAnswerLoading] = useState(false);
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [sortModel, setSortModel] = useState<GridSortModel>(
    getInitialSortModel()
  );
  const [bulkUpdating, setBulkUpdating] = useState(false);
  const [qsValues, setQsValues] = useState<GridQueryParamsModel>();
  const [anchorEl, setAnchorEl] = useState(null);
  const eventMenuOpen = Boolean(anchorEl);
  const [offlineMessageOpen, setOfflineMessageOpen] = useState(false);
  const [filtersLoading, setFilterLoading] = useState(true);
  //initial configuration, indiciating that its ready to load lookups
  //when set to true, will start loading lookups
  const [columnsConfigured, setColumnsConfigured] = useState(false);
  const [columns, setColumns] = useState<LynxGridColDef[]>(props.columns);
  const [showBulkUpdate, setShowBulkUpdate] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterDto>({});
  const [isExportingAttachments, setIsExportingAttachments] = useState(false);
  const [isExportingXlsx, setIsExportingXlsx] = useState(false);
  const [columnVisibilityModel, setColumnVisabilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    getInitialFilterModel()
  );
  const [isScheduledEvent, setIsScheduledEvent] = useState(false);
  const [isLoadFilterMode, setIsLoadFilterMode] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showDeleteFilter, setShowDeleteFilter] = useState(false);

  const [addingBulkAttachments, setAddingBulkAttachments] = useState(false);
  const [showSortDialog, setShowSortDialog] = useState(false);
  const [exportParams, setExportParams] = useState<any>({});
  const [filterChipLabel, setFilterChipLabel] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [showReportsModal, setShowReportsModal] = useState(false);
  const [showResetColumnsDialog, setShowResetColumnsDialog] = useState(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    getInitialPageModel()
  );
  const dimensions = useWindowDimensions();
  const isOffline = !useOnlineStatus();
  const { showAlert } = useAlert();
  const history = useHistory();
  const location = useLocation();

  const [getMonLocTrigger] = useLazyGetMonitoringLocationQuery();
  const [getContactTrigger] = useLazyGetContactByIdQuery();
  const [getContactGroupTrigger] = useLazyGetContactGroupByIdQuery();
  const isMobileCardView =
    dimensions && dimensions.isMobile && props.mobileCard && true;
  const memoizedColumns = useMemo(() => props.columns, [props.columns]);
  const [getReportsTrigger] = useLazyGetReportsQuery();
  const [runIncidentsReportTrigger] = useLazyRunIncidentsReportQuery();
  const [runEventsReportTrigger] = useLazyRunEventsReportQuery();
  const organization = useSelector((state: RootState) => state.organization);
  const handleUpdateColumn = useCallback((updatedColumn: LynxGridColDef) => {
    setColumns((prevColumns) => {
      var cols = [...prevColumns];
      var index = prevColumns.findIndex((x) => x.field == updatedColumn.field);
      cols[index] = updatedColumn;
      return cols;
    });
  }, []);
  useEffect(() => {
    handleSetFilterChipLabel();
  }, []);
  useEffect(() => {
    if (props.enableSavedFilters && !isOffline) {
      loadFilters();
    } else {
      setFilterLoading(false);
    }
  }, [isOffline, props.enableSavedFilters]);

  useEffect(() => {
    if (props.enableReports && !isOffline && !organization.isLoading) {
      loadReports();
    }
  }, [props.enableReports, isOffline, organization.isLoading]);

  useEffect(() => {
    const qsToSet = queryString.parse(location.search);

    if (qsToSet && (qsToSet.groupBy || qsToSet.importHistoryId)) {
      resetAllFilterValues();
      if (qsToSet.filterId) {
        delete qsToSet.filterId;
      }
      history.replace({
        search: queryString.stringify(qsToSet),
      });
    }

    setQsValues(qsToSet);
  }, []);
  const handleSetFilterChipLabel = () => {
    const qs: GridQueryParamsModel = queryString.parse(history.location.search);
    if (qs.monitoringLocationId && qs.monitoringLocationId > 0) {
      getMonLocTrigger(qs.monitoringLocationId).then((res: any) => {
        if (res.data) {
          setFilterChipLabel(res.data.name);
        }
      });
    }
    if (qs.contactId && qs.contactId > 0) {
      getContactTrigger(qs.contactId).then((res: any) => {
        if (res.data) {
          setFilterChipLabel(res.data.fullName);
        }
      });
    }
    if (qs.contactGroupId && qs.contactGroupId > 0) {
      getContactGroupTrigger(qs.contactGroupId).then((res: any) => {
        if (res.data) {
          setFilterChipLabel(res.data.name);
        }
      });
    }
  };
  const resetAllFilterValues = () => {
    if (props.localStorageName) {
      localStorageService.setLocalStorage(filterIdStorageName, 0);

      localStorageService.setLocalStorage(filterStorageName, "");
    }

    setSelectedFilter({});

    setFilterModel(defaultFilterModel);
  };

  useEffect(() => {
    if (!props.disableToolbar) {
      const search: GridQueryParamsModel = queryString.parse(location.search);
      if (
        search &&
        search.filterId &&
        search.filterId > 0 &&
        !filtersLoading &&
        props.enableSavedFilters
      ) {
        let filter = savedFilters.find((x) => x.id == search.filterId);
        if (filter) {
          let filterDef = JSON.parse(filter.filterDef as string);
          setFilterModel(filterDef);
          if (props.localStorageName) {
            localStorageService.setLocalStorage(filterIdStorageName, filter.id);
            localStorageService.setLocalStorage(filterStorageName, filterDef);
          }

          setSelectedFilter(filter);
          //TO DO warn if filter does not exist
        }
        return;
      }

      const lsFilterId = props.localStorageName
        ? localStorageService.getLocalStorage(filterIdStorageName)
        : 0;
      if (
        lsFilterId &&
        lsFilterId > 0 &&
        !filtersLoading &&
        props.enableSavedFilters
      ) {
        let filter = savedFilters.find((x) => x.id == lsFilterId);
        if (filter) {
          let filterDef = JSON.parse(filter.filterDef as string);

          setFilterModel(filterDef);
          if (props.localStorageName) {
            localStorageService.setLocalStorage(filterStorageName, filterDef);
          }

          setSelectedFilter(filter);
          var qsObjet: any = { filterId: lsFilterId };
          if (search.view) {
            qsObjet = { ...qsObjet, view: search.view };
          }
          history.replace({
            search: queryString.stringify(qsObjet),
          });
          //TO DO warn if filter does not exist
        }
        return;
      }

      if (search.varianceStatus && !filtersLoading) {
        let filterToSet = {
          items: [
            {
              field: "varianceStatus",
              operator: "is",
              id: 82373,
              value: "Exceedance",
            },
          ],
        };
        setFilterModel(filterToSet);
        if (props.localStorageName) {
          localStorageService.setLocalStorage(filterStorageName, filterToSet);
        }

        return;
      }

      if (!search.filterId && (!filtersLoading || !props.enableSavedFilters)) {
        var filterModelString: GridFilterModel =
          localStorageService.getLocalStorage(filterStorageName);
        if (filterModelString && _.isObject(filterModelString)) {
          setFilterModel(filterModelString);
        } else {
          setFilterModel(defaultFilterModel);
          localStorageService.setLocalStorage(filterStorageName, "");
        }
      }
    }
  }, [filtersLoading]);
  useEffect(() => {
    if (!_.isEmpty(props.columns)) {
      configureColumns();
    }
  }, [props.columns]);
  useEffect(() => {
    if (sortModel && paginationModel && filterModel) {
      setIsReady(true);
      return;
    }
  }, [columns, sortModel, paginationModel, filterModel, searchValue, qsValues]);
  const triggerParams = useMemo(() => {
    let params: any = {
      pageSize: paginationModel.pageSize,
      pageNumber: paginationModel.page + 1,
      search: searchValue,
      sort:
        sortModel && !_.isEmpty(sortModel)
          ? `${sortModel[0].field} ${sortModel[0].sort}`
          : "",
      filter: convertFilterDefToQueryString(filterModel),
    };
    if (qsValues && qsValues.groupBy) {
      params = { groupBy: qsValues.groupBy, ...params };
    }
    if (qsValues && qsValues.contactId) {
      params = { contactId: qsValues.contactId, ...params };
    }
    if (qsValues && qsValues.contactGroupId) {
      params = { contactGroupId: qsValues.contactGroupId, ...params };
    }
    if (qsValues && qsValues.importHistoryId) {
      params = { importHistoryId: qsValues.importHistoryId, ...params };
    }
    if (
      qsValues &&
      qsValues.monitoringLocationId &&
      qsValues.monitoringLocationId > 0
    ) {
      params = {
        monitoringLocationId: qsValues.monitoringLocationId,
        ...params,
      };
    }
    return props.useQueryParams
      ? {
          ...props.useQueryParams,
          ...params,
        }
      : { ...params, ...(!_.isEmpty(aiIds) ? { eventIds: aiIds } : {}) };
  }, [
    props.useQueryParams,
    paginationModel,
    qsValues,
    searchValue,
    sortModel,
    filterModel,
    aiIds,
  ]);

  const { data, isLoading, isFetching, isError, error } = props.useQuery(
    triggerParams,
    {
      skip: !isReady, // Skip the query until `isReady` is true
      refetchOnMountOrArgChange: !props.disableRefetchOnMountOrArgChange,
    }
  );

  const pagination = data ? JSON.parse(data.pagination) : {};

  useEffect(() => {
    setExportParams(triggerParams);
  }, [triggerParams]);
  useEffect(() => {
    if (isError && props.indexDbObjectStoreName) {
      localforage.getItem(props.indexDbObjectStoreName).then((data: any) => {
        setGridData(data);
      });
    } else {
      if (data) {
        setGridData(data ? data.data : []);
        if (data.data && props.indexDbObjectStoreName) {
          localforage.setItem(props.indexDbObjectStoreName, data.data);
        }
      }
    }
  }, [isError, error, props.indexDbObjectStoreName, data]);

  const handleEditClick = (e: AnyKindOfDictionary) => {
    if (props.passEntityOnEditClick) {
      setSelectedEntity(e);
    } else {
      setSelectedDataId(e);
    }
    setModalOpen(true);
  };
  const handleAdd = (isScheduled: boolean) => {
    setSelectedDataId(0);
    if (
      props.isOfflineReady == null ||
      !isOffline ||
      (props.isOfflineReady != null &&
        props.isOfflineReady == true &&
        isOffline)
    ) {
      props.entityModal
        ? setModalOpen(true)
        : props.addButtonAction && props.addButtonAction(isScheduled);
    } else {
      setOfflineMessageOpen(true);
    }
  };
  const loadReports = () => {
    getReportsTrigger(props.entityType, true).then((res) => {
      var reportsToSet = res.data;
      if (
        props.entityType == EntityTypes.Event &&
        !organization.featureFlags?.showInDevelopmentFeatures
      ) {
        reportsToSet = reportsToSet.filter(
          (x: ReportDto) => x.name !== "Records of Engagement"
        );
      }
      if (props.entityType == EntityTypes.Incident) {
        if (!roleMatch([UserRoles.Admin])) {
          reportsToSet = reportsToSet.filter(
            (x: ReportDto) =>
              x.name != "Full Incident Report" &&
              x.name != "Incident Investigation Report"
          );
        }
      }
      setReports(reportsToSet);
    });
  };

  const configureColumns = () => {
    let newColumns = [...props.columns];
    if (props.hasEditButton) {
      let selectionColumn: LynxGridColDef = {
        field: "Actions",
        headerName: "",
        filterable: false,
        sortable: false,
        width: 50,
        type: "actions",
        resizable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        hideSortIcons: true,
        renderCell: (params) => {
          return (
            <>
              {props.hasEditButton && (
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => {
                      props.handleEditClick
                        ? props.handleEditClick(params.id)
                        : handleEditClick(
                            props.passEntityOnEditClick ? params.row : params.id
                          );
                    }}
                    aria-label="Edit"
                    size="small"
                    disabled={isOffline}
                    className="grid-edit-button"
                  >
                    {params.row.notSynced ? (
                      <SyncIcon color="error" />
                    ) : (
                      <EditIcon />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        },
      };

      if (!_.isEmpty(selectionColumn)) {
        newColumns = [selectionColumn, ...newColumns];
      }
    }
    newColumns.forEach((col, index) => {
      //remove is any of from numbers
      if (col.type == "number") {
        newColumns[index] = {
          ...col,
          filterOperators: getGridNumericOperators().filter(
            (operator) => operator.value !== "isAnyOf"
          ),
        };
      }

      //add floating date ranges to date
      if (col.type == "date") {
        newColumns[index] = {
          ...col,
          filterOperators: [
            ...getGridDateOperators(),
            ...rangeOfDateOperator,
            ...betweenDateOperator,
          ],
        };
      }

      if (col.type == "dateTime") {
        newColumns[index] = {
          ...col,
          filterOperators: [
            ...getGridDateOperators(true),
            ...rangeOfDateOperator,
            ...betweenDateTimeOperator,
          ],
        };
      }
      if (isOffline) {
        newColumns[index].sortable = false;
      } else {
        newColumns[index].sortable =
          newColumns[index].sortable === false ? false : true;
      }
    });
    var modelToSet: LynxGridColDef[] = [];
    newColumns.forEach((col) => {
      modelToSet.push({
        field: col.field,
        width: col.width,
        enabled: !col.disabled,
      });
    });
    setOriginalColumnDef(modelToSet);
    if (props.localStorageName) {
      let colSettings = localStorageService.getLocalStorage(columnsStorageName);

      if (
        colSettings &&
        colSettings.length == newColumns.length &&
        colSettings.every((obj1: LynxGridColDef) =>
          newColumns.some((obj2) => obj1.field == obj2.field)
        )
      ) {
        let vModel: GridColumnVisibilityModel = {};
        let columnsOrdered: LynxGridColDef[] = [];
        colSettings.forEach((col: LynxGridColDef) => {
          let newColumn = newColumns.find((x) => x.field == col.field);
          if (newColumn) {
            newColumn.width = col.width;
            newColumn.disabled = !col.enabled;
            vModel[col.field] = col.enabled as boolean;
            columnsOrdered.push(newColumn);
          }
        });
        newColumns = columnsOrdered;
        setColumnVisabilityModel(vModel);
      } else {
        if (props.localStorageName) {
          localStorageService.setLocalStorage(columnsStorageName, modelToSet);
        }

        setColumnVisabilityModel({});
      }
    }

    setColumns(newColumns);
    setColumnsConfigured(true);
  };

  const targetRef = React.useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    function updateSize() {
      var width = targetRef.current ? targetRef.current.offsetWidth : 0;

      setSize([
        width,
        targetRef.current
          ? targetRef.current.offsetHeight - (props.onlyShowTable ? 0 : 70)
          : 0,
      ]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
    if (props.localStorageName) {
      localStorageService.setLocalStorage(sortStorageName, model);
    }
  };
  const handleColumnOrderChange = (
    params: GridColumnOrderChangeParams,
    event: { defaultMuiPrevented?: boolean | undefined },
    details: GridCallbackDetails<any>
  ) => {
    //Save column order in local storage
    if (props.localStorageName) {
      let columnDefString = localStorageService.getLocalStorage(
        //getColumnLocalStorageName()
        columnsStorageName
      );

      if (columnDefString) {
        let colDef = columnDefString;
        let newValues = [...colDef];
        let oldValue = newValues.splice(params.oldIndex, 1);
        newValues.splice(params.targetIndex, 0, ...oldValue);
        localStorageService.setLocalStorage(
          //getColumnLocalStorageName(),
          columnsStorageName,
          newValues
        );
      }
    }
  };
  const handleColumnWidthChange = (params: GridColumnResizeParams) => {
    if (props.localStorageName) {
      let columnDefString = localStorageService.getLocalStorage(
        //getColumnLocalStorageName(),
        columnsStorageName
      );
      if (columnDefString) {
        let colDef = columnDefString;
        let colIndex = colDef.findIndex(
          (x: any) => x.field == params.colDef.field
        );
        colDef[colIndex].width = params.colDef.width;
        localStorageService.setLocalStorage(
          //getColumnLocalStorageName(),
          columnsStorageName,
          colDef
        );
      }
    }
  };

  const handleColumnVisibilityModelChange = (e: GridColumnVisibilityModel) => {
    setColumnVisabilityModel(e);
    if (!isLoading && props.localStorageName) {
      let columnDefString = localStorageService.getLocalStorage(
        //getColumnLocalStorageName(),
        columnsStorageName
      );
      if (columnDefString) {
        let colDef = columnDefString;
        colDef.forEach((col: any) => {
          if (e[col.field] != undefined) {
            col.enabled = e[col.field];
          } else {
            col.enabled = true;
          }
        });

        localStorageService.setLocalStorage(
          //getColumnLocalStorageName(),
          columnsStorageName,
          colDef
        );
      }
    }
  };

  const handleFilterModelChange = (model: GridFilterModel, e: any) => {
    const search = queryString.parse(location.search);
    if (search.filterId) {
      history.replace({ search: "" });
    }
    if (props.localStorageName) {
      localStorageService.setLocalStorage(filterIdStorageName, 0);
    }

    setSelectedFilter({});
    handleFilterChange(model);
  };

  const handleFilterChange = (filterDef: GridFilterModel) => {
    setFilterModel(filterDef);
    if (props.localStorageName) {
      localStorageService.setLocalStorage(filterStorageName, filterDef);
    }
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model);
    if (props.localStorageName) {
      localStorageService.setLocalStorage(paginationStorageName, model);
    }
  };

  const handleSaveFilter = () => {
    setIsLoadFilterMode(false);
    setShowFilterModal(true);
  };
  const handleLoadFilter = () => {
    setIsLoadFilterMode(true);
    setShowFilterModal(true);
  };

  const handleLoadFilterClose = () => {
    setShowFilterModal(false);
    setIsLoadFilterMode(false);
  };
  const handleShowDeleteConfirmation = () => {
    setShowDeleteFilter(true);
  };
  const handleShowResetColumnDialog = () => {
    setShowResetColumnsDialog(true);
  };
  const handleClearFilter = () => {
    setShowFilterModal(false);
    resetGridParamsAndReload();
  };

  const resetGridParamsAndReload = () => {
    resetAllFilterValues();
    let qsObject: GridQueryParamsModel = queryString.parse(location.search);
    if (qsObject.view) {
      history.replace({
        search: queryString.stringify({ view: qsObject.view }),
      });
      setQsValues({ view: qsObject.view });
    } else {
      history.replace({ search: "" });
      setQsValues({});
    }
  };

  const handleReportsClick = () => {
    setShowReportsModal(true);
  };
  const handleExportAttachments = () => {
    setIsExportingAttachments(true);
    if (props.handleExportAttachmentsTrigger) {
      props
        .handleExportAttachmentsTrigger(exportParams)
        .then((res: any) => {
          if (res.data.size > 0) {
            saveAs(res.data, "Lynx Attachments.zip");
          } else {
            showAlert("error", "No attachments available to export.");
          }
          setIsExportingAttachments(false);
        })
        .catch((err: any) => {
          setIsExportingAttachments(false);
        });
    }
  };
  const postBulkUpdate = (data: any) => {
    //MC TODO fix bulk update stuff
    setBulkUpdating(true);
    if (props.bulkUpdateTrigger) {
      props.bulkUpdateTrigger({ params: exportParams, data }).then(() => {
        showAlert("success", "Bulk update complete.");
        setShowBulkUpdate(false);
        searchParams.delete("bulkUpdate", "true");
        history.push({ search: searchParams.toString() });
        setBulkUpdating(false);
      });
    }
  };
  const handleBulkUpdate = () => {
    setShowBulkUpdate(true);
    searchParams.set("bulkUpdate", "true");
    history.push({ search: searchParams.toString() });
  };
  const handleExportAsXLSX = () => {
    setIsExportingXlsx(true);

    let savedColDef = localStorageService.getLocalStorage(columnsStorageName);

    let colDef = [];
    if (savedColDef) {
      colDef = savedColDef;
      colDef.forEach(
        (item: any) =>
          (item.headerName = props.columns.find(
            (column) => column.field == item.field
          )?.headerName)
      );
    }
    if (props.handleExportXLSXTrigger) {
      props
        .handleExportXLSXTrigger({
          params: exportParams,
          columnDefinitionDto: colDef,
        })
        .then((res: any) => {
          if (res.data.size > 0) {
            saveAs(
              res.data,
              "Lynx ".concat(props.entityType).concat(" Export")
            );
            setIsExportingXlsx(false);
          } else {
            showAlert("error", "No records to export.");
          }
        })
        .catch(async (err: any) => {
          var errorString = JSON.parse(await err.response.data.text());
          setIsExportingXlsx(false);
          setExportError(errorString.message);
        });
    }
  };
  const handleEventAddClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEventMenuClose = () => {
    setAnchorEl(null);
  };
  const getReadableEntityName = () => {
    return size[0] < 480
      ? ""
      : props.entityType == EntityTypes.Event
      ? "Event"
      : "Monitoring Event";
  };
  const getHeaders = () => {
    return (
      <>
        <Paper elevation={1}>
          <Grid.Row
            className={`ml-0 mr-0 ${
              isMobileCardView
                ? "subpage-header-row-fixed"
                : "subpage-header-row"
            }`}
          >
            {(props.title || props.entityType) && (
              <Grid.Col width={12}>
                <div className="d-flex h-100 ">
                  {!props.header ? (
                    <>
                      {(!isMobileCardView || props.entityType != "Event") && (
                        <>
                          <Typography
                            variant={isMobileCardView ? "h6" : "h3"}
                            component="div"
                            className="align-self-center entity-number mr-5"
                          >
                            {props.title ?? props.entityType + "s"}{" "}
                            {showBulkUpdate && "Bulk Update"}
                          </Typography>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <props.header />
                    </>
                  )}
                  {props.searchEnabled &&
                    !showBulkUpdate &&
                    dimensions &&
                    dimensions.width &&
                    dimensions.width > 900 && (
                      <Stack
                        width="50%"
                        marginX={1}
                        justifyContent="end"
                        marginBottom={1}
                      >
                        <DataGridSearch
                          placeholder={props.searchLabel}
                          localStorageName={props.localStorageName}
                          handleSearch={(search: string) =>
                            setSearchValue(search)
                          }
                        />
                      </Stack>
                    )}
                  {!props.hideAddButton &&
                    (props.entityModal || props.addButtonAction) &&
                    props.entityType != "Event" &&
                    props.entityType != "MonitoringEvent" &&
                    props.entityType != "MonitoringResult" && (
                      <div className="d-flex h-100 ml-auto header-button">
                        <Button
                          size={isMobileCardView ? "small" : "medium"}
                          variant="contained"
                          onClick={(e) => handleAdd(false)}
                          disabled={
                            props.disableAddButton ||
                            (isOffline &&
                              props.entityType != EntityTypes.InspectionEvent)
                          }
                          className="ml-1  mr-1 ml-auto  align-self-center grid-add-button"
                        >
                          {props.addButtonText ?? "Add " + props.entityType}
                        </Button>
                      </div>
                    )}
                  {!props.hideAddButton &&
                    (props.entityType == "Event" ||
                      props.entityType == "MonitoringEvent" ||
                      props.entityType == "MonitoringResult") &&
                    !showBulkUpdate && (
                      <div className="d-flex h-100 ml-auto header-button">
                        <Button
                          id="basic-button"
                          size={isMobileCardView ? "small" : "medium"}
                          aria-controls={
                            eventMenuOpen ? "basic-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={eventMenuOpen ? "true" : undefined}
                          onClick={handleEventAddClick}
                          variant="contained"
                          className="ml-1 mr-1  align-self-center grid-add-button ml-auto"
                        >
                          {props.addButtonText ??
                            "Add " + getReadableEntityName()}
                        </Button>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={eventMenuOpen}
                          onClose={handleEventMenuClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              setIsScheduledEvent(false);
                              handleAdd(false);
                              handleEventMenuClose();
                            }}
                          >
                            Add {getReadableEntityName()}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setIsScheduledEvent(true);
                              handleAdd(true);
                              handleEventMenuClose();
                            }}
                          >
                            Schedule {getReadableEntityName()}
                          </MenuItem>
                        </Menu>
                      </div>
                    )}
                </div>
              </Grid.Col>
            )}
          </Grid.Row>
        </Paper>
      </>
    );
  };
  const getGridWithHeaders = () => {
    return (
      <Grid>
        {(!props.onlyShowTable || props.header) && getHeaders()}
        <Grid.Row className="ml-0 mr-0">
          <Grid.Col width={props.gridWidth ?? 12} className="pl-0 pr-0">
            {getDataGridCard()}
          </Grid.Col>
        </Grid.Row>
      </Grid>
    );
  };
  const handleAiQueryClick = () => {
    setShowAiQuery(true);
  };
  const getDataGridCard = () => {
    if (isMobileCardView) {
      return getMobileCards();
    } else {
      return (
        <Card className={`mb-0 ${props.disableCardBorder ? "no-border" : ""}`}>
          <div
            style={
              !props.autoHeight
                ? {
                    height: size[1],
                    width: "100%",
                  }
                : {
                    display: "flex",
                    flexDirection: "column",
                    ...props.parentContainerProps,
                  }
            }
          >
            {!showBulkUpdate ? (
              <>
                {paginationModel && sortModel && columnsConfigured && (
                  <DataGridPro
                    getRowId={(row) =>
                      props.getRowId ? row[props.getRowId] : row.id
                    }
                    columns={columnsConfigured ? columns : []}
                    className="lynx-data-grid"
                    columnVisibilityModel={columnVisibilityModel}
                    disableColumnFilter={isOffline || props.disableToolbar}
                    disableColumnPinning
                    disableMultipleColumnsSorting
                    disableMultipleRowSelection
                    disableRowSelectionOnClick
                    filterMode="server"
                    filterModel={filterModel}
                    loading={isLoading || isFetching || !columnsConfigured}
                    pagination={!isOffline}
                    paginationMode={props.useQuery ? "server" : "client"}
                    paginationModel={paginationModel}
                    rows={gridData ?? []}
                    rowCount={(pagination && pagination.totalItems) ?? 0}
                    sortModel={sortModel}
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    sortingMode={props.useQuery ? "server" : "client"}
                    onSortModelChange={handleSortModelChange}
                    onColumnOrderChange={handleColumnOrderChange}
                    onColumnWidthChange={handleColumnWidthChange}
                    onColumnVisibilityModelChange={
                      handleColumnVisibilityModelChange
                    }
                    initialState={{
                      sorting: {
                        sortModel: sortModel,
                      },
                    }}
                    onFilterModelChange={handleFilterModelChange}
                    onPaginationModelChange={handlePaginationModelChange}
                    slots={{
                      toolbar: props.disableToolbar ? null : CustomToolbar,
                    }}
                    slotProps={
                      props.disableToolbar
                        ? {
                            pagination: {
                              showFirstButton: true,
                              showLastButton: true,
                            },
                          }
                        : {
                            pagination: {
                              showFirstButton: true,
                              showLastButton: true,
                            },
                            toolbar: {
                              filterModel,
                              handleLoadFilter,
                              isOffline,
                              handleShowResetColumnDialog,
                              handleClearFilter,
                              enableSaveFilters: props.enableSavedFilters,
                              handleReportsClick,
                              handleSaveFilter,
                              entityType: props.entityType,
                              getQueryParamDisplay,
                              selectedFilter: selectedFilter,
                              handleExportAttachments:
                                props.handleExportAttachmentsTrigger
                                  ? handleExportAttachments
                                  : undefined,
                              handleBulkUpdate,
                              bulkUpdateTrigger: props.bulkUpdateTrigger,
                              handleExportAsXLSX: props.handleExportXLSXTrigger
                                ? handleExportAsXLSX
                                : null,
                              enableReports: props.enableReports,
                              hasAiQuery: props.hasAiQuery,
                              handleAiQueryClick: handleAiQueryClick,
                            },
                          }
                    }
                  ></DataGridPro>
                )}
              </>
            ) : (
              <BulkUpdate
                totalItems={pagination.totalItems}
                columns={columns}
                postBulkUpdate={postBulkUpdate}
                handleUploadAttachments={handleUploadAttachments}
              />
            )}
          </div>
        </Card>
      );
    }
  };
  const getGroupByLabel = () => {
    const groupByValues = qsValues?.groupBy?.split("//");
    if (groupByValues) {
      const groupColumn = columns.find((x: any) => x.field == groupByValues[0]);
      if (groupColumn) {
        return `Group By: ${groupColumn.headerName} == ${groupByValues[1]}`;
      }
    }
  };
  const getImportHistoryIdLabel = () => {
    const importHistoryId = qsValues?.importHistoryId;
    if (importHistoryId) {
      return `Import History ID == ${importHistoryId}`;
    }
  };
  const getMonLocationIdLabel = () => {
    const monitoringLocationId = qsValues?.monitoringLocationId;
    if (monitoringLocationId) {
      return `Monitoring Location == ${filterChipLabel}`;
    }
  };

  const getContactIdLabel = () => {
    const contactId = qsValues?.contactId;
    if (contactId) {
      return `Associated Contact == ${filterChipLabel}`;
    }
  };

  const getContactGroupIdLabel = () => {
    const contactGroupId = qsValues?.contactGroupId;
    if (contactGroupId) {
      return `Associated Contact Group == ${filterChipLabel}`;
    }
  };

  const getQueryParamDisplay = () => {
    return (
      <>
        {qsValues && qsValues.groupBy && (
          <div className="d-flex h-100 ml-2">
            <Chip
              color="primary"
              variant="outlined"
              label={getGroupByLabel()}
              className="align-self-center"
              onDelete={() => {
                resetGridParamsAndReload();
              }}
            />
          </div>
        )}
        {qsValues &&
          qsValues.importHistoryId &&
          props.entityType != EntityTypes.PhotoImportHistory && (
            <div className="d-flex h-100 ml-2">
              <Chip
                color="primary"
                variant="outlined"
                label={getImportHistoryIdLabel()}
                className="align-self-center"
                onDelete={() => {
                  resetGridParamsAndReload();
                }}
              />
            </div>
          )}
        {qsValues && qsValues.monitoringLocationId && (
          <div className="d-flex h-100 ml-2">
            <Chip
              color="primary"
              variant="outlined"
              label={getMonLocationIdLabel()}
              className="align-self-center"
              onDelete={() => {
                resetGridParamsAndReload();
              }}
            />
          </div>
        )}
        {qsValues && qsValues.contactId && (
          <div className="d-flex h-100 ml-2">
            <Chip
              color="primary"
              variant="outlined"
              label={getContactIdLabel()}
              className="align-self-center"
              onDelete={() => {
                resetGridParamsAndReload();
              }}
            />
          </div>
        )}
        {qsValues && qsValues.contactGroupId && (
          <div className="d-flex h-100 ml-2">
            <Chip
              color="primary"
              variant="outlined"
              label={getContactGroupIdLabel()}
              className="align-self-center"
              onDelete={() => {
                resetGridParamsAndReload();
              }}
            />
          </div>
        )}
      </>
    );
  };
  const handleResetColumns = () => {
    if (props.localStorageName) {
      localStorageService.setLocalStorage(
        columnsStorageName,
        originalColumnDef
      );
    }

    configureColumns();
    setShowResetColumnsDialog(false);
    showAlert("success", "Columns reset.");
  };
  const loadFilters = () => {
    getFilters(props.entityType)
      .then((res) => {
        setSavedFilters(res.data);
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      })
      .finally(() => {
        setFilterLoading(false);
      });
  };

  const onFilterSave = (filterName: string, existingFilterId: number) => {
    let model = _.cloneDeep(filterModel);
    let items = model.items;
    items = items.filter(
      (x) => x.value || x.operator == "isEmpty" || x.operator == "isNotEmpty"
    );
    model.items = items;
    let filterModelToSave = JSON.stringify(model);

    let dto = {
      entityType: props.entityType,
      name: filterName,
      filterDef: filterModelToSave,
      id: existingFilterId,
    };
    saveFilter(dto)
      .then((res: any) => {
        setShowFilterModal(false);
        const qsToSet = { filterId: res.data.id };
        history.replace({ search: queryString.stringify(qsToSet) });
        if (props.localStorageName) {
          localStorageService.setLocalStorage(filterIdStorageName, res.data.id);
        }

        setSelectedFilter(res.data);
        showAlert("success", "Filter saved.");
        loadFilters();
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
  };
  const handleDeleteFilter = () => {
    setShowDeleteFilter(false);
    setShowFilterModal(false);
    deleteFilter(selectedFilter.id)
      .then(() => {
        showAlert("success", "Filter deleted.");
      })
      .catch((err: any) => {
        showAlert("error", err.response.data.message);
      })
      .finally(() => {
        resetGridParamsAndReload();
        loadFilters();
      });
  };

  const handleLoadFilterSelected = (filterId: number) => {
    setShowFilterModal(false);
    setIsLoadFilterMode(false);

    const qsToSet = { filterId: filterId };
    history.replace({ search: queryString.stringify(qsToSet) });
    if (props.localStorageName) {
      localStorageService.setLocalStorage(
        filterIdStorageName,
        _.toString(filterId)
      );
    }

    var filter = savedFilters.find((x: FilterDto) => x.id == filterId);
    if (filter && filter.filterDef) {
      setSelectedFilter(filter);
      handleFilterChange(JSON.parse(filter.filterDef));
      const search: GridQueryParamsModel = queryString.parse(location.search);
      var qsObjet: any = { filterId: filter.id };
      if (search.view) {
        qsObjet = { ...qsObjet, view: search.view };
      }
      history.replace({
        search: queryString.stringify(qsObjet),
      });

      setPaginationModel({ page: 1, pageSize: 20 });
    }
  };

  const handleRunReport = (reportId: number) => {
    if (pagination.totalItems > 1000) {
      setShowReportError(true);
      return;
    }
    setReportDownloading(true);
    if (props.entityType == EntityTypes.Incident) {
      const reportParams = { ...exportParams, pageSize: pagination.totalItems };
      runIncidentsReportTrigger({
        reportId: reportId,
        params: reportParams,
      })
        .then((res) => {
          if (res.data) {
            const reportToRun = reports.find((x) => x.id == reportId);
            if (reportToRun) {
              saveAs(
                res.data,
                `Incidents-${reportToRun.name?.replace(/ /g, "")}`
              );
            }
          }
        })
        .catch(async (err) => {
          var errorString = JSON.parse(await err.response.data.text());

          showAlert("error", errorString.message);
        })
        .finally(() => setReportDownloading(false));
    }
    if (props.entityType == EntityTypes.Event) {
      const reportParams = { ...exportParams, pageSize: pagination.totalItems };
      runEventsReportTrigger({ reportId: reportId, params: reportParams })
        .then((res) => {
          if (res.data) {
            const reportToRun = reports.find((x) => x.id == reportId);
            if (reportToRun) {
              saveAs(res.data, `Events-${reportToRun.name?.replace(/ /g, "")}`);
            }
          }
        })
        .catch(async (err) => {
          var errorString = JSON.parse(await err.response.data.text());
          showAlert("error", errorString.message);
        })
        .finally(() => setReportDownloading(false));
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    setLinkedEntityId(0);
    setDefaultEntityModalValues({});
    setSelectedDataId(0);
    setSelectedEntity({});
  };

  const handleDialogClose = (event: any, reason: string) => {
    if (reason && reason == "backdropClick" && selectedDataId <= 0) return;
    handleClose();
  };
  const handleSortOrderChange = (e: any) => {
    let existingSort = [...sortModel];
    setSortModel([{ field: existingSort[0].field, sort: e.target.value }]);
  };
  const handleSortFieldChange = (e: any) => {
    let existingSort = [...sortModel];
    if (!e.target.value) {
      setSortModel([]);
      return;
    }
    if (_.isEmpty(existingSort)) {
      setSortModel([{ field: e.target.value, sort: "asc" }]);
    } else {
      setSortModel([{ field: e.target.value, sort: existingSort[0].sort }]);
    }
  };
  const getPaginationForMobile = () => {
    return (
      <Pagination
        size="small"
        className="mt-1"
        page={paginationModel.page + 1}
        count={Math.ceil(pagination.totalItems / paginationModel.pageSize)}
        onChange={(e, value) => {
          setPaginationModel({ ...paginationModel, page: value - 1 });
        }}
      />
    );
  };

  const handleUploadAttachments = (files: any) => {
    if (props.bulkUpdateAttachmentsTrigger) {
      setAddingBulkAttachments(true);
      props
        .bulkUpdateAttachmentsTrigger({ params: exportParams, files: files })
        .then(() => {
          showAlert("success", "Attachments added to events.");
        })
        .finally(() => {
          setAddingBulkAttachments(false);
          setShowBulkUpdate(false);
        });
    }
  };

  const getMobileCards = () => {
    return (
      <>
        {isLoading || isFetching ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: size[1],
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            <CircularProgress sx={{ alignSelf: "center" }} />
          </div>
        ) : (
          <>
            <div className="mobile-pagination">
              {!isOffline ? (
                <div className="d-flex justify-content-between ">
                  <Tooltip title="Sort">
                    <IconButton onClick={() => setShowSortDialog(true)}>
                      <Badge
                        color="primary"
                        invisible={_.isEmpty(sortModel)}
                        variant="dot"
                      >
                        <SortIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  {getPaginationForMobile()}
                  <Tooltip title="Filter">
                    <IconButton
                      onClick={() => {
                        setIsLoadFilterMode(true);
                        setShowFilterModal(true);
                      }}
                    >
                      <Badge
                        color="primary"
                        invisible={_.isEmpty(selectedFilter)}
                        variant="dot"
                      >
                        <FilterListIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </div>
              ) : (
                <div className="h-100 d-flex justify-content-center mt-2 text-red">
                  <Typography variant="body2">
                    Offline Mode - Showing Last Loaded Events
                  </Typography>
                </div>
              )}
            </div>
            <div className="mobile-card-container pb-5">
              {!isLoading &&
                !isFetching &&
                gridData &&
                gridData.map(
                  (item: any, i: number) =>
                    props.mobileCard && (
                      <props.mobileCard
                        entity={item}
                        key={i}
                      ></props.mobileCard>
                    )
                )}
              {_.isEmpty(gridData) && (
                <div className="d-flex justify-content-center mt-2 ml-2">
                  <Typography className="">No rows</Typography>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  };
  const getIdsFromResponse = (documentSources: DocumentSource[]) => {
    if (props.entityType == EntityTypes.Event) {
      let eventIds = _.uniq(documentSources.map((x) => _.toNumber(x.eventId)));
      setAiIds(eventIds);
    }
  };
  const queryTailwinds = () => {
    setAnswerLoading(true);
    var requestParams = {
      prompt: prompt,
      includeEvents: props.entityType == EntityTypes.Event,
      includeDocuments: props.entityType == EntityTypes.Document,
      supplementalDataRecordLimit: 50,
    };
    queryRagModelAi(requestParams)
      .then((res) => {
        setAiSources(res.data.documentSources);

        setAnswer(res.data.answer);
      })
      .finally(() => {
        setAnswerLoading(false);
      });
  };

  const showAiEvents = () => {
    getIdsFromResponse(aiSources);
    setShowAiQuery(false);
  };
  return (
    <>
      <div
        ref={targetRef}
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        {!_.isEmpty(memoizedColumns) &&
          columnsConfigured &&
          !props.disableToolbar &&
          !isOffline &&
          memoizedColumns
            .filter((column) => column.type === "singleSelect" && column.query)
            .map((column) => (
              <ColumnFetcher
                key={column.field}
                column={column}
                onUpdate={handleUpdateColumn}
              />
            ))}
        {getGridWithHeaders()}
        {showResetColumnsDialog && (
          <LynxDialog
            open={showResetColumnsDialog}
            handleClose={() => setShowResetColumnsDialog(false)}
            title={`Reset columns?`}
            handleConfirm={handleResetColumns}
          />
        )}
        {showFilterModal && (
          <FilterModal
            open={showFilterModal}
            handleClose={() => setShowFilterModal(false)}
            handleSaveFilter={onFilterSave}
            savedFilters={savedFilters}
            isLoadFilterMode={isLoadFilterMode}
            handleLoadFilterSelected={handleLoadFilterSelected}
            handleLoadFilterClose={handleLoadFilterClose}
            selectedFilter={selectedFilter}
            handleDeleteFilter={handleShowDeleteConfirmation}
            handleClearFilter={handleClearFilter}
          />
        )}
        {selectedFilter && (
          <LynxDialog
            open={showDeleteFilter}
            handleClose={() => setShowDeleteFilter(false)}
            handleDelete={handleDeleteFilter}
            title={`Delete Filter - ${selectedFilter.name}?`}
          />
        )}
        {showReportError && (
          <LynxDialog
            open={showReportError}
            handleConfirm={() => setShowReportError(false)}
            title={`Unable to run report.`}
            description="Please filter the data so that it is less than 1000 records."
          />
        )}
        {showReportsModal && (
          <ReportsModal
            open={showReportsModal}
            handleClose={() => setShowReportsModal(false)}
            reports={reports}
            handleRunReport={handleRunReport}
          />
        )}
        {offlineMessageOpen && isOffline && (
          <LynxDialog
            open={offlineMessageOpen && isOffline}
            title={`Unable to create event offline`}
            description={
              "Return online and press the sync button in the top right corner."
            }
            handleClose={(e: any) => {
              setOfflineMessageOpen(false);
            }}
          />
        )}
        {addingBulkAttachments && (
          <LynxDialog
            open={addingBulkAttachments}
            title={`Adding attachments to events. Do not close the window.`}
            description={
              <>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <CircularProgress />
                </div>
              </>
            }
          />
        )}
        {bulkUpdating && (
          <LynxDialog
            open={bulkUpdating}
            title={`Bulk updating events. Do not close the window.`}
            description={
              <>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <CircularProgress />
                </div>
              </>
            }
          />
        )}
        {isExportingAttachments && (
          <LynxDialog
            open={isExportingAttachments}
            title={`Exporting attachments. Do not close the window.`}
            description={
              <>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <CircularProgress />
                </div>
              </>
            }
          />
        )}
        {isExportingXlsx && (
          <LynxDialog
            open={isExportingXlsx}
            title={`Exporting data as xlsx. Do not close the window.`}
            description={
              <>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <CircularProgress />
                </div>
              </>
            }
          />
        )}
        {reportDownloading && (
          <LynxDialog
            open={reportDownloading}
            title={`Running report. Do not close the window.`}
            description={
              <>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <CircularProgress />
                </div>
              </>
            }
          />
        )}
        {showAiQuery && (
          <LynxDialog
            fullWidth
            maxWidth="lg"
            title={"Lynx AI Assistant"}
            open={showAiQuery}
            handleClose={() => {
              setShowAiQuery(false);
            }}
            description={
              <Grid>
                <Grid.Row>
                  <Grid.Col width={12} className="pl-0 pr-0">
                    <div style={{ minWidth: 400 }}>
                      <LynxTextArea
                        placeholder="How can I help you today?"
                        autoHeight
                        name="prompt"
                        onChange={(e: any) => {
                          setPrompt(e.target.value);
                        }}
                        value={prompt}
                      ></LynxTextArea>
                    </div>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col className="pl-0 pr-0">
                    <Typography variant="body2" className="mt-2">
                      <i>
                        This AI-powered tool provides responses based on indexed
                        documents and data within the system. While we strive
                        for accuracy, the information generated may not always
                        be complete, current, or error-free. Please verify
                        critical information before making decisions.
                      </i>
                    </Typography>
                  </Grid.Col>
                  <Grid.Col width={12} className="pl-0 pr-0">
                    <Button
                      disabled={_.isEmpty(prompt)}
                      variant="contained"
                      onClick={queryTailwinds}
                      color="success"
                      className="mb-2 mt-2"
                    >
                      Send
                    </Button>
                  </Grid.Col>
                </Grid.Row>
                {(answerLoading || answer != null) && (
                  <Grid.Row>
                    <Grid.Col width={12} className="pl-0 pr-0">
                      <div style={{ minWidth: 400 }}>
                        {answerLoading ? (
                          <div className="d-flex align-items-center  mt-4">
                            <CircularProgress />{" "}
                            <Typography className="ml-2">
                              Searching events...
                            </Typography>
                          </div>
                        ) : (
                          <div>
                            <Typography variant="h6" className="mt-2">
                              Response
                            </Typography>
                            <Box
                              sx={{
                                border: `1px solid ${lynxColors.gray}`,
                                padding: "0.5rem",
                                marginTop: "0.5rem",
                              }}
                            >
                              <Typography sx={{ whiteSpace: "pre-line" }}>
                                {answer}
                              </Typography>
                            </Box>
                          </div>
                        )}
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                )}
                {aiSources && aiSources.length > 0 && (
                  <Grid.Row>
                    <Grid.Col width={12} className="pl-0 pr-0">
                      <div style={{ minWidth: 400 }}>
                        <Typography variant="h6" className="mt-2">
                          Sources
                        </Typography>
                        <Box
                          sx={{
                            border: `1px solid ${lynxColors.gray}`,
                            padding: "0.5rem",
                            marginTop: "0.5rem",
                          }}
                        >
                          {_.orderBy(_.uniqBy(aiSources, "entityNumber"), [
                            "type",
                            "entityNumber",
                          ]).map((source) => (
                            <DocumentSourceChip
                              className="mr-2 mb-2"
                              source={source}
                            />
                          ))}
                        </Box>
                      </div>
                      <Button
                        variant="outlined"
                        className="mt-2"
                        onClick={showAiEvents}
                      >
                        Show events in grid
                      </Button>
                    </Grid.Col>
                  </Grid.Row>
                )}
              </Grid>
            }
          ></LynxDialog>
        )}

        {props.entityModal && modalOpen && (
          <Dialog
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={modalOpen}
            onClose={handleDialogClose}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <props.entityModal
              handleModalClose={handleClose}
              entityId={selectedDataId}
              entity={selectedEntity}
              isScheduledEvent={isScheduledEvent}
              defaultValues={defaultEntityModalValues}
              linkedEntityId={linkedEntityId}
              {...props.entityModalProps}
            />
          </Dialog>
        )}
        {showSortDialog && (
          <LynxDialog
            title={"Select Sort"}
            open={showSortDialog && isMobileCardView}
            handleConfirm={() => {
              setShowSortDialog(false);
            }}
            description={
              <>
                <Form.Group label="Sort By">
                  <Form.Select
                    onChange={handleSortFieldChange}
                    value={!_.isEmpty(sortModel) ? sortModel[0].field : ""}
                  >
                    <option value={""}></option>
                    {columns
                      .filter((x) => x.sortable !== false)
                      .map((column) => (
                        <option value={column.field} key={column.field}>
                          {column.headerName}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Select
                    onChange={handleSortOrderChange}
                    value={
                      !_.isEmpty(sortModel)
                        ? _.toString(sortModel[0].sort)
                        : "asc"
                    }
                    disabled={_.isEmpty(sortModel)}
                  >
                    <option value={"asc"}>Ascending</option>
                    <option value={"desc"}>Descending</option>
                  </Form.Select>
                </Form.Group>
              </>
            }
          ></LynxDialog>
        )}
        {props.tour &&
          dimensions &&
          dimensions.width &&
          dimensions.width > 900 &&
          !isLoading &&
          !isFetching &&
          !isOffline && <props.tour />}
      </div>
    </>
  );
};

declare module "@mui/x-data-grid-pro" {
  interface FilterPanelPropsOverrides {
    filterModel: GridFilterModel;

    handleLoadFilter: () => void;
    handleShowDeleteConfirmation: () => void;
    selectedFilter: any;
    enableSaveFilters: boolean;
  }
  interface ToolbarPropsOverrides {
    filterModel: GridFilterModel;
    handleLoadFilter: () => void;
    isOffline: boolean;
    handleSaveFilter: () => void;
    handleShowResetColumnDialog: () => void;
    handleClearFilter: () => void;
    enableSaveFilters: boolean;
    handleReportsClick: () => void;
    entityType: EntityTypes;
    getQueryParamDisplay: () => JSX.Element;
    handleExportAttachments?: () => void;
    handleBulkUpdate: () => void;
    bulkUpdateTrigger: (e: any, f: any) => any;
    handleExportAsXLSX: (() => void) | null;
    enableReports: boolean;
    selectedFilter?: FilterDto;
    hasAiQuery?: boolean;
    handleAiQueryClick: () => void;
  }
}
const ColumnFetcher: FC<{
  column: LynxGridColDef;
  onUpdate: (col: any) => void;
}> = React.memo(({ column, onUpdate }) => {
  const { data, error, isLoading, isFetching } = column.query(
    column.queryParams ?? {}
  );
  useEffect(() => {
    if (!isLoading && !isFetching && data) {
      onUpdate({
        ...column,
        valueOptions: data && data.data ? data.data : data || [],
      });
    }
  }, [data, isLoading, column, onUpdate]);

  if (error) {
    console.error(`Error fetching data for column ${column.field}`, error);
  }

  return null;
});
