import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ClearIcon from "@mui/icons-material/Clear";
import DrawIcon from "@mui/icons-material/Draw";
import EventIcon from "@mui/icons-material/Event";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import NumbersIcon from "@mui/icons-material/Numbers";
import SegmentIcon from "@mui/icons-material/Segment";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { DataGridPro, GRID_REORDER_COL_DEF } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Form, Grid, Card, Dimmer } from "components/lynx-components";
import {
  CustomFieldTypes,
  InspectionFormTypes,
  LookupTypes,
} from "../../../../types/enums";

import { MultiSelect } from "../../../form-controls/multi-select";
import { LynxDialog } from "../../../lynx-dialog";
import { InspectionFormField } from "../../inspections/inspection-form-field";
import "./form-builder.css";
import { validationService } from "../../../../services";
import { Alert } from "@mui/material";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import useAlert from "hooks/useAlert";
import { useLazyGetLookupsQuery } from "services/rtkApi/endpoints/lookups";
import {
  useCreateInspectionFormMutation,
  useDeleteInspectionFormMutation,
  useLazyGetInspectionFormQuery,
  useUpdateInspectionFormMutation,
  useCreateInspectionFormInLibraryMutation,
  useLazyGetInspectionFormsFromLibraryQuery,
} from "services/rtkApi/endpoints/inspectionForms";
import { SearchableSelect } from "components/form-controls/searchable-select";
import { Box } from "@mui/system";
import lynxColors from "modules/lynxColors";
const initialFormState = {
  name: "",
  nameError: "",
  description: "",
  formType: InspectionFormTypes.IncidentInvestigation,
  formTypeError: "",
  retiredDate: "",
  isInUse: false,
};
const initialFieldFormState = {
  id: "",
  label: "",
  labelError: "",
  type: "",
  typeError: "",
  isRequired: false,
  width: 50,
  isEquipmentInspection: false,
  isEquipmentInspectionError: false,
  isSubLocationInspection: false,
};

const initialLibraryFormState = {
  displayName: "",
  displayNameError: "",
  description: "",
  descriptionError: "",
  type: "",
  typeError: "",
};
//create a functional component called FormBuilder
export const FormBuilder = (props) => {
  const history = useHistory();
  const [formState, setFormState] = useState(initialFormState);
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [fieldFormState, setFieldFormState] = useState(initialFieldFormState);
  const [formFields, setFormFields] = useState([]);
  const [incidentTypes, setIncidentTypes] = useState([]);
  const [severities, setSeverities] = useState([]);
  const [libraryFormState, setLibraryFormState] = useState(
    initialLibraryFormState
  );
  const [libraryLoading, setLibraryLoading] = useState(false);
  const [showAddField, setShowAddField] = useState(false);
  const [lookupList, setLookupList] = useState([]);
  const [inspectionFormLookups, setInspectionFormLookups] = useState([]);
  const [existingInspectionForm, setExistingInspectionForm] = useState({});
  const [isDeleteFormOpen, setIsDeleteFormOpen] = useState(false);
  const { showAlert } = useAlert();
  const [getLookupsTrigger] = useLazyGetLookupsQuery();
  const [createInspectionFormInLibraryTrigger] =
    useCreateInspectionFormInLibraryMutation();
  const [createInspectionFormTrigger] = useCreateInspectionFormMutation();
  const [updateInspectionFormTrigger] = useUpdateInspectionFormMutation();
  const [getInspectionFormTrigger] = useLazyGetInspectionFormQuery();
  const [getInspectionFormsFromLibraryTrigger] =
    useLazyGetInspectionFormsFromLibraryQuery();
  const [deleteInspectionFormTrigger] = useDeleteInspectionFormMutation();
  const [showSaveToLibraryForm, setShowSaveToLibraryForm] = useState(false);
  const [selectedLibraryForm, setSelectedLibraryForm] = useState(null);
  const [formsFromLibrary, setFormsFromLibrary] = useState([]);
  const isForIncidents =
    document.location.pathname
      .toLowerCase()
      .indexOf("/incidents".toLowerCase()) >= 0;
  const params = useParams();
  const existingId = params.id;
  useEffect(() => {
    loadLookups();
  }, []);

  useEffect(() => {
    if (existingId) {
      getInspectionFormTrigger(existingId, true)
        .then((res) => {
          let inspection = res.data;
          setExistingInspectionForm(inspection);
          setFormFields(
            _.orderBy(inspection.inspectionFormFields, ["position"])
          );
          setFormState({
            name: inspection.name,
            description: inspection.description,
            formType: inspection.formType,
            isInUse: inspection.isInUse,
            retiredDate: inspection.retiredDate,
            isEquipmentInspection: inspection.isEquipmentInspection,
            isSubLocationInspection: inspection.isSubLocationInspection,
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [existingId]);
  useEffect(() => {
    if (!_.isEmpty(incidentTypes) && !_.isEmpty(severities)) {
      var newLookupList = [];
      severities.forEach((severity) => {
        incidentTypes.forEach((incidentType) => {
          newLookupList.push({
            lookupId: incidentType.id,
            secondaryLookupId: severity.id,
            id: `${incidentType.id}-${severity.id}`,
            label: `${incidentType.code} - ${severity.code}`,
          });
        });
      });
      setLookupList(newLookupList);
    }
  }, [incidentTypes, severities]);

  useEffect(() => {
    if (!_.isEmpty(lookupList) && !_.isEmpty(existingInspectionForm)) {
      setInspectionFormLookups(
        lookupList.filter((a) =>
          existingInspectionForm.inspectionFormLookups
            .map((x) => `${x.lookupId}-${x.secondaryLookupId}`)
            .includes(a.id)
        )
      );
    }
  }, [lookupList, existingInspectionForm]);
  const loadLookups = () => {
    if (isForIncidents) {
      getLookupsTrigger({
        lookupType: LookupTypes.IncidentType,
        bypassFilter: true,
      }).then((response) => {
        setIncidentTypes(response.data);
      });
      getLookupsTrigger({
        lookupType: LookupTypes.IncidentSeverity,
        bypassFilter: true,
      }).then((response) => {
        setSeverities(response.data);
      });
    }
  };
  const handleRowOrderChange = (e) => {
    let newValues = [...dropdownValues];
    let oldValue = newValues.splice(e.oldIndex, 1);
    newValues.splice(e.targetIndex, 0, ...oldValue);
    setDropdownValues(newValues);
  };
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    let name = e.target.name;
    let value = e.target.type == "checkbox" ? e.target.checked : e.target.value;

    _.set(newState, name, value);
    setFormState(newState);
  };
  const handleDropdownValueAdd = () => {
    if (fieldFormState.dropdownValue == "" || !fieldFormState.dropdownValue)
      return;
    let newValues = [...dropdownValues];
    newValues.push(fieldFormState.dropdownValue);
    setDropdownValues(newValues);
    let newState = { ...fieldFormState };
    newState.dropdownValue = "";
    setFieldFormState(newState);
  };
  const updateField = () => {
    let objIndex = formFields.findIndex((x) => x.id == fieldFormState.id);
    let objectToUpdate = {
      label:
        fieldFormState.type == CustomFieldTypes.Divider
          ? null
          : fieldFormState.label,
      type: fieldFormState.type,
      isRequired: fieldFormState.isRequired,
      width:
        fieldFormState.type == CustomFieldTypes.Divider
          ? 100
          : fieldFormState.width,
      id: fieldFormState.id,
      position: fieldFormState.position,
      dropdownValues:
        !_.isEmpty(dropdownValues) &&
        (fieldFormState.type == "Dropdown" ||
          fieldFormState.type == "MultiSelect")
          ? dropdownValues.join("|")
          : null,
    };
    let customFieldState = [...formFields];
    customFieldState[objIndex] = objectToUpdate;
    setFormFields(_.orderBy(customFieldState, ["position"]));
  };

  const addField = () => {
    let maxPosition = _.maxBy(formFields, "position");
    let objectToCreate = {
      label:
        fieldFormState.type == CustomFieldTypes.Divider
          ? null
          : fieldFormState.label,
      type: fieldFormState.type,
      isRequired: fieldFormState.isRequired,
      width:
        fieldFormState.type == CustomFieldTypes.Divider
          ? 100
          : fieldFormState.width,
      id: _.uniqueId(`field_`),
      name: _.uniqueId(`field_`),
      position: maxPosition ? maxPosition.position + 1 : 1,
      dropdownValues:
        !_.isEmpty(dropdownValues) &&
        (fieldFormState.type == "Dropdown" ||
          fieldFormState.type == "MultiSelect")
          ? dropdownValues.join("|")
          : null,
    };
    setFormFields(_.orderBy([...formFields, objectToCreate], ["position"]));
    showAlert("success", "Field added.");
  };

  const handleAddField = () => {
    // TODO validate stuff here
    if (fieldFormState.id) {
      updateField();
    } else {
      addField();
    }

    setFieldFormState(initialFieldFormState);
    setDropdownValues([]);
    setShowAddField(false);
  };

  const handleFieldInputChange = (e) => {
    let newState = { ...fieldFormState };
    const { name } = e.target;
    const value =
      name === "isRequired" || name == "isDeleted"
        ? e.target.checked
        : e.target.value;

    _.set(newState, name, value);
    setFieldFormState(newState);
  };
  const handleLibraryInputChange = (e) => {
    let newState = { ...libraryFormState };
    const { name } = e.target;
    const value = e.target.value;

    _.set(newState, name, value);
    setLibraryFormState(newState);
  };

  const handleEditClick = (field) => {
    var selectedField = formFields.find((f) => f.id == field.id);
    setFieldFormState({ ...initialFieldFormState, ...selectedField });
    if (
      selectedField.type == CustomFieldTypes.Dropdown ||
      selectedField.type == CustomFieldTypes.MultiSelect
    ) {
      setDropdownValues(selectedField.dropdownValues.split("|"));
    }

    setShowAddField(true);
  };

  const handleSortOrderChange = (e, x, y) => {
    let newValues = [...formFields];
    let oldValue = newValues.splice(e.oldIndex, 1);
    newValues.splice(e.targetIndex, 0, ...oldValue);
    newValues = newValues.map((value, index) => ({
      ...value,
      position: index + 1,
    }));
    setFormFields(newValues);
  };

  const handleRemoveField = () => {
    let fieldToRemove = formFields.findIndex((x) => x.id == fieldFormState.id);
    let newFields = [...formFields];
    newFields.splice(fieldToRemove, 1);
    newFields.forEach((value) => {
      value.position = newFields.indexOf(value) + 1;
    });
    setFormFields(newFields);
    setDropdownValues([]);
    setFieldFormState(initialFieldFormState);
    setShowAddField(false);
  };

  const handleFormDelete = () => {
    deleteInspectionFormTrigger(existingInspectionForm.id)
      .then((response) => {
        showAlert("success", "Form deleted.");
        handleNavigateTo(null, "/settings/inspections/");
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
  };
  const handleSaveToLibrary = () => {
    if (!validateLibraryFormForSave()) {
      return;
    }
    let dto = {
      name: libraryFormState.displayName,
      description: libraryFormState.description,
      formType: libraryFormState.type,
    };
    dto.inspectionFormFields = [
      ...formFields.map((field) => ({ ...field, id: 0 })),
    ];
    createInspectionFormInLibraryTrigger(dto).then((res) => {
      if (res.data) {
        showAlert("success", "Form saved to library.");
        setLibraryFormState(initialLibraryFormState);
        setShowSaveToLibraryForm(false);
      }
    });
  };
  const handleFormSave = () => {
    if (!validateDataForSave()) {
      return;
    }
    let objectToSave = { ...formState };
    objectToSave.inspectionFormFields = [
      ...formFields.map((field) => ({ ...field, id: 0 })),
    ];
    objectToSave.inspectionFormLookups = inspectionFormLookups;
    if (!isForIncidents) {
      objectToSave.formType = InspectionFormTypes.Inspection;
    }
    if (existingId) {
      updateInspectionFormTrigger({ id: existingId, dto: objectToSave })
        .then((response) => {
          showAlert("success", "Form updated.");
          if (!isForIncidents) {
            handleNavigateTo(null, "/settings/inspections/");
          }
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    } else {
      createInspectionFormTrigger(objectToSave)
        .then((response) => {
          showAlert("success", "Form created.");
          if (isForIncidents) {
            handleNavigateTo(null, "/settings/incidents/");
          } else {
            handleNavigateTo(null, "/settings/inspections/");
          }
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
  };
  const validateLibraryFormForSave = () => {
    let newState = { ...libraryFormState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "displayName",
      "displayNameError",
      "Display name"
    );
    validationService.validateRequiredField(
      newState,
      "description",
      "descriptionError",
      "Description"
    );
    validationService.validateRequiredField(
      newState,
      "type",
      "typeError",
      "Type"
    );
    isFormValid = !validationService.hasError(
      newState,
      "displayNameError",
      "typeError",
      "descriptionError"
    );
    if (!isFormValid) {
      setLibraryFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };
  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );
    validationService.validateRequiredField(
      newState,
      "formType",
      "formTypeError",
      "Form Type"
    );

    isFormValid = !validationService.hasError(
      newState,
      "nameError",
      "formTypeError"
    );
    if (_.isEmpty(formFields)) {
      isFormValid = false;
    }
    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };
  const saveToLibraryForm = (
    <>
      {existingId ? (
        <div>
          <Grid>
            <Grid.Row>
              <Grid.Col lg={6} width={12}>
                <Form.Group label="Display name" isRequired>
                  <Form.Input
                    type="text"
                    name="displayName"
                    onChange={handleLibraryInputChange}
                    value={libraryFormState.displayName}
                    error={libraryFormState.displayNameError}
                  ></Form.Input>
                </Form.Group>
              </Grid.Col>
              <Grid.Col lg={6} width={12}>
                <Form.Group label="Description" isRequired>
                  <Form.Input
                    type="text"
                    name="description"
                    onChange={handleLibraryInputChange}
                    value={libraryFormState.description}
                    error={libraryFormState.descriptionError}
                  ></Form.Input>
                </Form.Group>
              </Grid.Col>
              <Grid.Col lg={6} width={12}>
                <Form.Group label="Type" isRequired>
                  <Form.Select
                    name="type"
                    onChange={handleLibraryInputChange}
                    value={libraryFormState.type}
                    error={libraryFormState.typeError}
                  >
                    <option value={""}></option>
                    <option value={"Construction"}>Construction</option>
                    <option value={"Mining"}>Mining</option>
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </div>
      ) : (
        <div>
          <Dimmer loader active={libraryLoading}>
            <Grid>
              <Grid.Row>
                <Grid.Col className="pl-0 pr-0">
                  <Alert severity="error" className="mb-2">
                    Loading a form will overwrite your existing inspeciton form.
                  </Alert>
                  <Form.Group label="Select template to see preview">
                    <SearchableSelect
                      name="form"
                      options={formsFromLibrary}
                      value={selectedLibraryForm}
                      labelFunction={(option) =>
                        option ? `${option.name} (${option.formType})` : ""
                      }
                      onChange={(e) => {
                        setSelectedLibraryForm(e);
                      }}
                      placeholder="Search or choose from list"
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Form.Group label="Preview">
                <Box
                  className="pt-2 pb-2"
                  sx={{
                    width: "100%",
                    minHeight: "50px",
                    border: `1px solid ${lynxColors.gray}`,
                  }}
                >
                  {selectedLibraryForm && (
                    <Grid.Row>
                      {_.orderBy(selectedLibraryForm.inspectionFormFields, [
                        "position",
                      ]).map((field) => (
                        <InspectionFormField
                          field={field}
                          signatureValue={""}
                          value={""}
                          handleInputChange={() => {}}
                          error={""}
                          disabled={false}
                          key={field.name}
                        />
                      ))}
                    </Grid.Row>
                  )}
                </Box>
              </Form.Group>
            </Grid>
          </Dimmer>
        </div>
      )}
    </>
  );
  const fieldForm = (
    <>
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Col lg={6} width={12}>
              <Form.Group label="Field type" isRequired>
                <Form.Select
                  name="type"
                  onChange={handleFieldInputChange}
                  value={fieldFormState.type}
                  error={fieldFormState.typeError}
                >
                  <option value={""}></option>
                  <option value={CustomFieldTypes.Text}>Text</option>
                  <option value={CustomFieldTypes.TextArea}>Text Area</option>
                  <option value={CustomFieldTypes.Number}>Number</option>
                  <option value={CustomFieldTypes.Date}>Date</option>
                  <option value={CustomFieldTypes.DateTime}>
                    Date and Time
                  </option>
                  <option value={CustomFieldTypes.Dropdown}>
                    Single Select (Dropdown)
                  </option>
                  <option value={CustomFieldTypes.MultiSelect}>
                    Multiple Select (Dropdown)
                  </option>
                  <option value={CustomFieldTypes.Checkbox}>Checkbox</option>
                  <option value={CustomFieldTypes.Divider}>Divider</option>
                  <option value={CustomFieldTypes.Paragraph}>Paragraph</option>
                  <option value={CustomFieldTypes.Signature}>Signature</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            {fieldFormState.type !== CustomFieldTypes.Divider && (
              <Grid.Col lg={6} width={12}>
                {" "}
                <Form.Group label="Field label" isRequired>
                  <Form.Input
                    type="text"
                    name="label"
                    onChange={handleFieldInputChange}
                    value={fieldFormState.label}
                    error={fieldFormState.labelError}
                  ></Form.Input>
                </Form.Group>
              </Grid.Col>
            )}

            {(fieldFormState.type == "Dropdown" ||
              fieldFormState.type == "MultiSelect") && (
              <>
                <Grid.Col lg={6} width={12}>
                  <Form.Group label="Add dropdown value:" isRequired>
                    <Form.Input
                      type="text"
                      name="dropdownValue"
                      error={formState.dropdownValuesError}
                      onChange={handleFieldInputChange}
                      value={fieldFormState.dropdownValue}
                    ></Form.Input>
                    <Button
                      variant="contained"
                      className="float-right mt-2"
                      onClick={handleDropdownValueAdd}
                    >
                      Add dropdown value
                    </Button>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col lg={6} width={12}>
                  <Form.Group label="Dropdown values">
                    <div
                      style={{
                        height: "300px",
                        width: "100%",
                      }}
                    >
                      <DataGridPro
                        columnHeaderHeight={0}
                        columns={[
                          { field: "value", headerName: "Value", width: 200 },
                          {
                            field: "remove",
                            headerName: "Remove",
                            width: 45,
                            type: "actions",
                            sortable: false,
                            renderCell: (params) => (
                              <Tooltip title="Remove">
                                <IconButton
                                  onClick={() => {
                                    let newValues = [...dropdownValues];
                                    newValues.splice(params.id, 1);
                                    setDropdownValues(newValues);
                                    let newState = { ...formState };
                                    newState.dropdownValues =
                                      newValues.join("|");
                                    setFormState(newState);
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Tooltip>
                            ),
                          },
                        ]}
                        rows={[
                          ...dropdownValues.map((value, index) => ({
                            id: index,
                            value: value,
                          })),
                        ]}
                        rowReordering
                        hideFooter={true}
                        onRowOrderChange={handleRowOrderChange}
                      />
                    </div>
                  </Form.Group>
                </Grid.Col>
              </>
            )}
            {fieldFormState.type != CustomFieldTypes.Divider && (
              <Grid.Col md={6} width={12}>
                <Form.Group label="Large Screen Width" isRequired>
                  <Form.Select
                    name="width"
                    onChange={handleFieldInputChange}
                    value={fieldFormState.width}
                    error={fieldFormState.widthError}
                  >
                    <option value={50}>50%</option>
                    <option value={100}>100%</option>
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
            )}

            {fieldFormState.type != CustomFieldTypes.Checkbox &&
              fieldFormState.type != CustomFieldTypes.Divider &&
              fieldFormState.type != CustomFieldTypes.Paragraph && (
                <Grid.Col md={6} width={12}>
                  <Form.Group label="">
                    <Form.Checkbox
                      label="Required field?"
                      name="isRequired"
                      className="mt-5"
                      onChange={handleFieldInputChange}
                      checked={fieldFormState.isRequired}
                    />
                  </Form.Group>
                </Grid.Col>
              )}
            {/* <Grid.Col md={6} width={12}>
              <Form.Checkbox
                label="Disabled?"
                name="isDeleted"
                onChange={handleFieldInputChange}
                checked={fieldFormState.isDeleted}
              />
            </Grid.Col> */}
          </Grid.Row>
        </Grid>
      </div>
    </>
  );

  const handleAddFieldsToForm = () => {
    var fields = [...selectedLibraryForm.inspectionFormFields];
    fields = fields.map((field, index) => ({
      ...field,
      id: index,
    }));
    setFormFields(fields);
    setShowSaveToLibraryForm(false);
    setSelectedLibraryForm(null);
  };

  return (
    <Grid>
      <Paper className="form-builder-header ">
        <Grid.Row className="  subpage-header-row  mb-2">
          <Grid.Col width={12}>
            <Typography variant="h3" component="div">
              {isForIncidents ? "Incident" : "Inspection"} Form Builder
            </Typography>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      <div className="form-preview">
        <Card className="mb-2">
          <Card.Header>Form Details</Card.Header>
          <Card.Body>
            {" "}
            <Grid.Row>
              {isForIncidents && (
                <Grid.Col md={6} width={12}>
                  <Form.Group label="Type" isRequired>
                    <Form.Select
                      name={"formType"}
                      onChange={handleInputChange}
                      value={formState.formType}
                      error={formState.formTypeError}
                    >
                      <option value={InspectionFormTypes.IncidentInvestigation}>
                        Investigation
                      </option>
                      <option value={InspectionFormTypes.IncidentParticipant}>
                        Witness
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Grid.Col>
              )}

              <Grid.Col md={6} width={12}>
                <Form.Group label="Form Name" isRequired>
                  <Form.Input
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                    value={formState.name}
                    error={formState.nameError}
                  ></Form.Input>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} width={12}>
                <Form.Group label="Description">
                  <LynxTextArea
                    onChange={handleInputChange}
                    name={"description"}
                    value={formState.description}
                  ></LynxTextArea>
                </Form.Group>
              </Grid.Col>
              {isForIncidents && (
                <Grid.Col md={6} width={12}>
                  {!_.isEmpty(lookupList) &&
                    formState.formType ==
                      InspectionFormTypes.IncidentInvestigation && (
                      <Form.Group
                        label={"Select incident types and severities"}
                      >
                        <MultiSelect
                          name="inspectionFormLookups"
                          id="inspectionFormLookups"
                          onChange={(e) => {
                            setInspectionFormLookups(e.target.value);
                          }}
                          value={inspectionFormLookups}
                          dropdownValues={lookupList}
                          key="id"
                          label="label"
                        />
                      </Form.Group>
                    )}
                </Grid.Col>
              )}
            </Grid.Row>
            {!isForIncidents && (
              <Grid.Row>
                <Grid.Col md={6} width={12}>
                  <Form.Group label={"Select Retired Date"}>
                    <Form.Input
                      type="date"
                      value={formState.retiredDate}
                      name={"retiredDate"}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            )}
            <Grid.Col md={12} width={12} className="mt-3">
              <Form.Group label="">
                <Form.Checkbox
                  label="Equipment Inspection?"
                  name="isEquipmentInspection"
                  checked={formState.isEquipmentInspection}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12} className="mt-3">
              <Form.Group label="">
                <Form.Checkbox
                  label="Sub Location Inspection?"
                  name="isSubLocationInspection"
                  checked={formState.isSubLocationInspection}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Grid.Col>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <Typography>Form Fields</Typography>
          </Card.Header>
          <Card.Body>
            {" "}
            <Grid.Row>
              <Grid.Col width={12}>
                <Button
                  className="mb-4"
                  variant="outlined"
                  onClick={() => {
                    setShowAddField(true);
                  }}
                >
                  Add Field
                </Button>
                <Button
                  className="mb-4 float-right"
                  variant="outlined"
                  onClick={() => {
                    setShowSaveToLibraryForm(true);
                    if (!existingId) {
                      setLibraryLoading(true);
                      getInspectionFormsFromLibraryTrigger().then((res) => {
                        if (res.data) {
                          setFormsFromLibrary(res.data);
                          setLibraryLoading(false);
                        }
                      });
                    }
                  }}
                >
                  {existingId ? "Save to form library" : "Load from library"}
                </Button>
              </Grid.Col>
              {existingId && formState.isInUse && (
                <Grid.Col width={12}>
                  <Alert className="mb-4" severity="warning">
                    This Inspection Form is in use. Data may be lost when
                    editing form fields.
                  </Alert>
                </Grid.Col>
              )}
              <Grid.Col width={12} className="mb-4">
                <Divider />
              </Grid.Col>
              {_.orderBy(formFields, ["position"]).map((field) => (
                <InspectionFormField
                  field={field}
                  signatureValue={""}
                  value={""}
                  handleInputChange={() => {}}
                  error={""}
                  disabled={false}
                  key={field.name}
                />
              ))}
            </Grid.Row>
            <Grid.Row>
              <Grid.Col width={12}>
                <Button
                  className="ml-2 mt-3 mb-3"
                  variant="outlined"
                  color="success"
                  onClick={handleFormSave}
                >
                  Save
                </Button>
                {existingId && !isLoading && (
                  <Button
                    disabled={formState.isInUse}
                    className="ml-2 mt-3 mb-3"
                    variant="contained"
                    color="error"
                    onClick={() => setIsDeleteFormOpen(true)}
                  >
                    Delete
                  </Button>
                )}

                {existingId && !isLoading && formState.isInUse && (
                  <Typography
                    as="span"
                    fontSize={14}
                    className="text-red"
                    ml={1}
                  >
                    Unable to delete when in use
                  </Typography>
                )}
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        </Card>

        <LynxDialog
          title={`Delete Inspection Form`}
          description={"Are you sure you want to delete this item?"}
          open={isDeleteFormOpen}
          handleClose={() => setIsDeleteFormOpen(false)}
          buttons={() => {
            return (
              <Button
                variant="contained"
                color="error"
                onClick={handleFormDelete}
              >
                Yes
              </Button>
            );
          }}
        />
      </div>
      <div className="field-sidebar mr-2">
        <div style={{ height: "100%", width: "100%" }}>
          <DataGridPro
            hideFooter={true}
            getRowHeight={() => "auto"}
            localeText={{
              noRowsLabel: "No Fields Added",
            }}
            columns={[
              {
                ...GRID_REORDER_COL_DEF,
              },
              {
                field: "type",
                headerName: "Edit",
                width: 60,
                type: "string",
                sortable: false,
                renderCell: (params) => {
                  return (
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        handleEditClick(params.row);
                      }}
                    >
                      {getFieldTypeIcon(params.value)}
                    </IconButton>
                  );
                },
              },
              {
                field: "label",
                headerName: "Label",
                flex: 1,
                type: "string",
                sortable: false,
              },
            ]}
            rows={formFields}
            onRowOrderChange={handleSortOrderChange}
            //onCellClick={handleCustomFieldEdit}
            rowReordering
            disableColumnReorder
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            disableChildrenSorting
          />
        </div>
      </div>
      {setShowSaveToLibraryForm && (
        <LynxDialog
          dividers
          maxWidth={"lg"}
          open={showSaveToLibraryForm}
          title={existingId ? "Save to form library" : "Load from library"}
          fullWidth
          saveDisabled={!existingId && !selectedLibraryForm}
          saveButtonText={existingId ? "Save" : "Load"}
          dialogContent={saveToLibraryForm}
          handleClose={() => {
            setShowSaveToLibraryForm(false);
            setLibraryFormState(initialLibraryFormState);
            setSelectedLibraryForm(null);
          }}
          handleSave={existingId ? handleSaveToLibrary : handleAddFieldsToForm}
        />
      )}
      {showAddField && (
        <LynxDialog
          dividers
          maxWidth={"lg"}
          open={showAddField}
          title={fieldFormState.id ? "Edit Form Field" : `Add Form Field`}
          fullWidth
          dialogContent={fieldForm}
          handleClose={() => {
            setShowAddField(false);
            setFieldFormState(initialFieldFormState);
            setDropdownValues([]);
          }}
          handleDelete={fieldFormState.id ? handleRemoveField : null}
          handleSave={handleAddField}
        />
      )}
    </Grid>
  );
};

const getFieldTypeIcon = (type) => {
  switch (_.toLower(type)) {
    case _.toLower(CustomFieldTypes.Text):
    case _.toLower(CustomFieldTypes.TextArea):
      return <TextFieldsIcon />;
    case _.toLower(CustomFieldTypes.Date):
    case _.toLower(CustomFieldTypes.DateTime):
      return <EventIcon />;
    case _.toLower(CustomFieldTypes.Paragraph):
      return <SegmentIcon />;
    case _.toLower(CustomFieldTypes.Divider):
      return <HorizontalRuleIcon />;
    case _.toLower(CustomFieldTypes.Checkbox):
      return <CheckBoxIcon />;
    case _.toLower(CustomFieldTypes.Number):
      return <NumbersIcon />;
    case _.toLower(CustomFieldTypes.Dropdown):
      return <ArrowDropDownCircleIcon />;
    case _.toLower(CustomFieldTypes.MultiSelect):
      return <ChecklistIcon />;
    case _.toLower(CustomFieldTypes.Signature):
      return <DrawIcon />;
    default:
      return <TextFieldsIcon />;
  }
};
