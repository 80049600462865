import { BaseParams } from "types";
import queryString from "query-string";

const { apiService } = require("../apiService");

const BASE_ROUTE = "/permits";

const permitsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getPermits: builder.query({
      query: (params: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(params)}`,
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
    }),
  }),
});

export const { useGetPermitsQuery, useLazyGetPermitsQuery } = permitsEndpoint;
