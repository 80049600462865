import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { Card, Dimmer, Form, Grid } from "components/lynx-components";
import { MultiSelect } from "./../../form-controls/multi-select";
import { styled } from "@mui/material/styles";
import { IconButton, Stack } from "@mui/material";
import { Lock, LockOpenOutlined } from "@mui/icons-material";
import { roleMatch } from "actions/auth";
import { UserRoles } from "types/enums";
import ParticipantModal from "./participant-modal";
import { IncidentParticipantDto, LookupDto, UserDto } from "types";
import { IncidentParticipantsActionTypes } from "types/enums-ts";

interface IncidentParticipantsProps {
  affectedParties: IncidentParticipantDto[];
  setAffectedParties: (
    parties: IncidentParticipantDto[],
    action?: IncidentParticipantsActionTypes
  ) => void;
  witnesses: IncidentParticipantDto[];
  setWitnesses: (
    witnesses: IncidentParticipantDto[],
    action?: IncidentParticipantsActionTypes
  ) => void;
  users: UserDto[];
  formState: {
    affectedPartiesError?: string;
    userParticipants: string[];
    userWitnesses: string[];
  };
  handleInputChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleSave?: () => void;
  isDisabled: () => boolean;
  hasLock: boolean;
  isLocked?: { parties: boolean; witness: boolean };
  isInitiallyLocked?: boolean;
  isLoading: boolean;
  showAttachments?: boolean;
  hasNoFooter?: boolean;
  toggleLock?: (section: string) => void;
  incidentParticipantTypes?: LookupDto[];
}

const IncidentParticipants: React.FC<IncidentParticipantsProps> = (props) => {
  const [participantToEdit, setParticipantToEdit] =
    useState<IncidentParticipantDto>({} as IncidentParticipantDto);
  const [showParticipantModal, setShowParticipantModal] =
    useState<boolean>(false);
  const [showWitnessModal, setShowWitnessModal] = useState<boolean>(false);
  const { hasLock, isLocked, isInitiallyLocked, toggleLock } = props;

  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  }));

  const handleEditParty = (party: IncidentParticipantDto) => {
    setParticipantToEdit({ ...party });
    setShowParticipantModal(true);
  };

  const handleEditAffectedParty = (party: IncidentParticipantDto) => {
    const existingParties = [...props.affectedParties];
    const index = party.newId
      ? existingParties.findIndex((x) => x.newId === party.newId)
      : existingParties.findIndex((x) => x.id === party.id);
    existingParties.splice(index, 1);
    existingParties.push(party);
    props.setAffectedParties(
      existingParties,
      IncidentParticipantsActionTypes.Update
    );
    setParticipantToEdit({} as IncidentParticipantDto);
    setShowParticipantModal(false);
  };

  const handleEditAffectedWitness = (party: IncidentParticipantDto) => {
    const existingParties = [...props.witnesses];
    const index = party.newId
      ? existingParties.findIndex((x) => x.newId === party.newId)
      : existingParties.findIndex((x) => x.id === party.id);
    existingParties.splice(index, 1);
    existingParties.push(party);
    props.setWitnesses(existingParties, IncidentParticipantsActionTypes.Update);
    setParticipantToEdit({} as IncidentParticipantDto);
    setShowWitnessModal(false);
  };

  const handleAddAffectedParty = (e: IncidentParticipantDto) => {
    const existingParties = [...props.affectedParties];
    props.setAffectedParties(
      [{ newId: _.uniqueId("new"), ...e }, ...existingParties],
      IncidentParticipantsActionTypes.Add
    );
    setShowParticipantModal(false);
  };

  const handleAddWitness = (e: IncidentParticipantDto) => {
    const existingWitnesses = [...props.witnesses];
    props.setWitnesses(
      [
        { newId: _.uniqueId("new"), ...e, isWitness: true },
        ...existingWitnesses,
      ],
      IncidentParticipantsActionTypes.Add
    );
    setShowWitnessModal(false);
  };

  const handleDeleteParty = (party: IncidentParticipantDto) => {
    const existingParties = [...props.affectedParties];
    const index = existingParties.findIndex(
      (x) => x.id === party.id || x.newId === party.newId
    );
    existingParties.splice(index, 1);
    props.setAffectedParties(
      existingParties,
      IncidentParticipantsActionTypes.Delete
    );
  };

  const handleDeleteWitness = (witness: IncidentParticipantDto) => {
    const existingWitnesses = [...props.witnesses];
    const index =
      witness.newId !== undefined
        ? existingWitnesses.findIndex((x) => x.newId === witness.newId)
        : existingWitnesses.findIndex((x) => x.id === witness.id);

    existingWitnesses.splice(index, 1);
    props.setWitnesses(
      existingWitnesses,
      IncidentParticipantsActionTypes.Delete
    );
  };

  const handleEditWitness = (party: IncidentParticipantDto) => {
    setParticipantToEdit({ ...party });
    setShowWitnessModal(true);
  };

  return (
    <>
      <Container className="form-container" maxWidth="xl">
        <Dimmer active={props.isLoading} loader>
          <Form className="card mb-0">
            <Card.Header>
              <Card.Title>
                <Stack direction="row" alignItems="center">
                  <span>Affected Parties</span>
                  {roleMatch([UserRoles.Admin]) &&
                    isInitiallyLocked &&
                    hasLock && (
                      <IconButton
                        aria-label="Lock"
                        onClick={() => toggleLock && toggleLock("parties")}
                        title={isLocked?.parties ? "Unlock" : "Lock"}
                      >
                        {isLocked?.parties ? (
                          <Lock />
                        ) : (
                          <LockOpenOutlined color="success" />
                        )}
                      </IconButton>
                    )}
                </Stack>
              </Card.Title>
            </Card.Header>
            <Card.Body className="pt-2">
              <Grid.Row>
                <Grid.Col md={12} width={12} className="mb-2">
                  <div>
                    Choose from users or add an affected party
                    <span className="form-required">*</span>
                  </div>
                  {props.formState.affectedPartiesError && (
                    <span
                      className="invalid-feedback"
                      style={{ display: "inline" }}
                    >
                      {props.formState.affectedPartiesError}
                    </span>
                  )}
                </Grid.Col>
                <Grid.Col md={6} width={12}>
                  <Form.Group label="Select Users">
                    <MultiSelect
                      disabled={
                        props.isDisabled() || (hasLock && isLocked?.parties)
                      }
                      name="userParticipants"
                      id="selectedUserParticipants"
                      onChange={props.handleInputChange}
                      value={props.formState.userParticipants}
                      dropdownValues={props.users}
                      key="id"
                      label="fullName"
                    />
                    <Button
                      onClick={() => setShowParticipantModal(true)}
                      startIcon={<AddIcon />}
                      sx={{ maxHeight: "50px", alignSelf: "center" }}
                      disabled={
                        props.isDisabled() || (hasLock && isLocked?.parties)
                      }
                    >
                      Or Add New Affected Party
                    </Button>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={12} width={12} className="d-flex">
                  <div className="compliance-grid-container">
                    {_.orderBy(props.affectedParties, ["name"]).map(
                      (party, index) => (
                        <div className="dashboard-item" key={index}>
                          <Item
                            variant="outlined"
                            sx={{
                              opacity: hasLock && isLocked?.parties ? 0.7 : 1,
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="div"
                              sx={{
                                opacity:
                                  hasLock && isLocked?.witness ? 0.55 : 1,
                              }}
                            >
                              {party.name}
                            </Typography>
                            <Typography
                              sx={{ mb: 1.5 }}
                              color="text.secondary"
                              className="mb-0"
                            >
                              {
                                props.incidentParticipantTypes?.find(
                                  (x) => x.id === party.participantTypeId
                                )?.code
                              }
                            </Typography>
                            <Typography variant="body2">
                              {party.details}
                            </Typography>
                            <div className="d-flex h-100 align-items-end justify-content-end">
                              <Button
                                className="mt-2"
                                disabled={
                                  props.isDisabled() ||
                                  (hasLock && isLocked?.parties)
                                }
                                onClick={() => handleEditParty(party)}
                              >
                                Edit
                              </Button>
                              <Button
                                color="error"
                                disabled={
                                  props.isDisabled() ||
                                  (hasLock && isLocked?.parties)
                                }
                                onClick={() => handleDeleteParty(party)}
                                className="mt-2"
                              >
                                Delete
                              </Button>
                            </div>
                          </Item>
                        </div>
                      )
                    )}
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
          </Form>
        </Dimmer>
      </Container>

      <Container className="form-container" maxWidth="xl">
        <Dimmer active={props.isLoading} loader>
          <Form
            className={`card ${
              !props.showAttachments && props.hasNoFooter ? "mb-2" : "mb-0"
            }`}
          >
            <Card.Header>
              <Card.Title>
                <Stack direction="row" alignItems="center">
                  <span>Witnesses</span>
                  {roleMatch([UserRoles.Admin]) &&
                    isInitiallyLocked &&
                    hasLock && (
                      <IconButton
                        aria-label="Lock"
                        onClick={() => toggleLock && toggleLock("witness")}
                        title={isLocked?.witness ? "Unlock" : "Lock"}
                      >
                        {isLocked?.witness ? (
                          <Lock />
                        ) : (
                          <LockOpenOutlined color="success" />
                        )}
                      </IconButton>
                    )}
                </Stack>
              </Card.Title>
            </Card.Header>
            <Card.Body className="pt-2">
              <Grid.Row>
                <Grid.Col md={12} width={12} className="mb-2">
                  <div>Choose from users or add a witness</div>
                </Grid.Col>
                <Grid.Col md={6} width={12}>
                  <Form.Group label="Select Users">
                    <MultiSelect
                      disabled={
                        props.isDisabled() || (hasLock && isLocked?.witness)
                      }
                      name="userWitnesses"
                      id="selectedWitnesses"
                      onChange={props.handleInputChange}
                      value={props.formState.userWitnesses}
                      dropdownValues={props.users}
                      key="id"
                      label="fullName"
                    />
                    <Button
                      disabled={
                        props.isDisabled() || (hasLock && isLocked?.witness)
                      }
                      onClick={() => setShowWitnessModal(true)}
                      startIcon={<AddIcon />}
                      sx={{ maxHeight: "50px", alignSelf: "center" }}
                    >
                      Or Add New Witness
                    </Button>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={12} width={12} className="d-flex">
                  <div className="compliance-grid-container">
                    {_.orderBy(props.witnesses, ["name"]).map((party) => (
                      <div
                        className="dashboard-item"
                        key={party.newId || party.id}
                      >
                        <Item
                          variant="outlined"
                          sx={{
                            opacity: hasLock && isLocked?.witness ? 0.7 : 1,
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{
                              opacity: hasLock && isLocked?.witness ? 0.55 : 1,
                            }}
                          >
                            {party.name}
                          </Typography>
                          <Typography
                            sx={{ mb: 1.5 }}
                            color="text.secondary"
                            className="mb-0"
                          >
                            {
                              props.incidentParticipantTypes?.find(
                                (x) => x.id === party.participantTypeId
                              )?.code
                            }
                          </Typography>
                          <Typography
                            variant="body2"
                            component="div"
                            sx={{ whiteSpace: "pre-line" }}
                          >
                            {party.details}
                          </Typography>
                          <div className="d-flex h-100 align-items-end justify-content-end">
                            <Button
                              disabled={
                                props.isDisabled() ||
                                (hasLock && isLocked?.witness)
                              }
                              className="mt-2"
                              onClick={() => handleEditWitness(party)}
                            >
                              Edit
                            </Button>
                            <Button
                              disabled={
                                props.isDisabled() ||
                                (hasLock && isLocked?.witness)
                              }
                              color="error"
                              onClick={() => handleDeleteWitness(party)}
                              className="mt-2"
                            >
                              Delete
                            </Button>
                          </div>
                        </Item>
                      </div>
                    ))}
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
          </Form>
        </Dimmer>
      </Container>

      {showParticipantModal && (
        <ParticipantModal
          open={showParticipantModal}
          handleClose={() => setShowParticipantModal(false)}
          handleAdd={(e: any) => handleAddAffectedParty(e)}
          participantTypes={props?.incidentParticipantTypes || []}
          participant={participantToEdit}
          handleUpdate={(e: any) => handleEditAffectedParty(e)}
        />
      )}

      {showWitnessModal && (
        <ParticipantModal
          open={showWitnessModal}
          handleClose={() => setShowWitnessModal(false)}
          handleAdd={(e: any) => handleAddWitness(e)}
          participantTypes={props.incidentParticipantTypes || []}
          participant={participantToEdit}
          handleUpdate={(e: any) => handleEditAffectedWitness(e)}
          isWitness
        />
      )}
    </>
  );
};

export default IncidentParticipants;
