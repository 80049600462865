import Button from "@mui/material/Button";
import { roleMatch } from "actions/auth";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { tagParseToChip } from "services/helpers/tagParser";
import {
  useBulkUpdateEventAttachmentsMutation,
  useBulkUpdateEventsMutation,
  useGetEventsExportAsXLSXMutation,
  useGetEventsQuery,
  useLazyGetEventsAttachmentsQuery,
} from "services/rtkApi/endpoints/events";
import { EventCustomFieldDto, EventDto, RootState } from "types";
import { EntityTypes } from "types/enums-ts";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import {
  getCustomFields,
  transformCustomFieldDefsToColumnDefs,
} from "../../../services/custom-fields-service";
import {
  customFieldStore,
  eventStore,
  offlineDataService,
} from "../../../services/offlineDataService";
import { eventGridTourSteps } from "../../../tours/event-grid-tour";
import { EventStatuses, TourTypes, UserRoles } from "../../../types/enums";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import { LynxTour } from "../../lynx-tour";
import { getEventColumns, latLongColumns, utmColumns } from "./event-columns";
import EventMobileCard from "./event-mobile-card";
import localforage from "localforage";

const EventList = () => {
  const [eventCustomFields, setEventCustomFields] = useState<
    EventCustomFieldDto[] | null
  >(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [isOfflineReady, setIsOfflineReady] = useState(true);
  const history = useHistory();
  const isOffline = !useOnlineStatus();

  const account = useSelector((state: RootState) => state.account);
  const organization = useSelector((state: RootState) => state.organization);
  const useUtm = account.coordinateType !== "LatLong";

  const { users } = useSelector((state: RootState) => state.lookups);
  const predefinedLookups = { users };
  const [bulkUpdateTrigger] = useBulkUpdateEventsMutation();
  const [bulkUpdateEventAttachmentsTrigger] =
    useBulkUpdateEventAttachmentsMutation();
  const [getEventsAttachmentsTrigger] = useLazyGetEventsAttachmentsQuery();
  const [getEventsExportAsXLSXTrigger] = useGetEventsExportAsXLSXMutation();

  useEffect(() => {
    getCustomFields(EntityTypes.Event)
      .then((res) => {
        const eventCustomFields = res.data;
        setEventCustomFields(eventCustomFields);
      })
      .catch(() => {
        localforage.getItem(customFieldStore).then((data: any) => {
          setEventCustomFields(data);
        });
      });
  }, []);

  useEffect(() => {
    if (isOffline) {
      offlineDataService.checkIfOfflineIsReady().then((res) => {
        setIsOfflineReady(res);
      });
    }
  }, [isOffline]);

  useEffect(() => {
    if (
      !_.isEmpty(eventCustomFields) &&
      !account.isLoading &&
      !organization.isLoading
    ) {
      let newColumns: any = getEventColumns(
        organization.hasPermitFeature,
        false,
        predefinedLookups,
        organization.hasDateOnlyEventDate,
        organization.featureFlags && organization.featureFlags.showAiFeatures
      );
      if (eventCustomFields) {
        newColumns = [
          ...newColumns,
          ...transformCustomFieldDefsToColumnDefs(eventCustomFields),
        ];
      }

      newColumns = useUtm
        ? [...newColumns, ...utmColumns]
        : [...newColumns, ...latLongColumns];

      setDefaultColumns(newColumns);
    }
  }, [eventCustomFields, account.isLoading, organization.isLoading, isOffline]);

  const handleEventNumberClick = (row: EventDto) => {
    history.push(
      `/events/${row.id}${
        row.status?.toString() === EventStatuses.PendingEmailReview
          ? "?edit=true"
          : ""
      }`
    );
  };

  const setDefaultColumns = (columns: any[]) => {
    const newColumns = [...columns];
    const eventNumberIndex = newColumns.findIndex(
      (obj) => obj.field === "eventNumber"
    );

    if (eventNumberIndex > -1) {
      newColumns[eventNumberIndex].renderCell = (params: any) => (
        <Button
          variant="text"
          className="event-number-button"
          disabled={isOffline}
          onClick={() => handleEventNumberClick(params.row)}
        >
          {params.value}
        </Button>
      );
    }

    const descriptionIndex = newColumns.findIndex(
      (obj) => obj.field === "description"
    );

    if (descriptionIndex > -1) {
      newColumns[descriptionIndex].renderCell = (params: any) => (
        <>{params.value ? <div>{tagParseToChip(params.value)}</div> : "--"}</>
      );
    }

    const ffDescriptionIndex = newColumns.findIndex(
      (obj) => obj.field === "followUpDescription"
    );

    if (ffDescriptionIndex > -1) {
      newColumns[ffDescriptionIndex].renderCell = (params: any) => (
        <>{params.value ? <div>{tagParseToChip(params.value)}</div> : "--"}</>
      );
    }

    setColumns(newColumns);
  };

  const navigateToAddEvent = (isScheduledEvent: boolean) => {
    history.push(`/add-event${isScheduledEvent ? "?isScheduled=true" : ""}`);
  };

  const getTour = () => (
    <LynxTour
      title="Welcome to the Events Grid!"
      description="Events are primarily used to track community and stakeholder engagement data (E.g., town halls, trainings, workshops, records of consultation, etc.). The event grid shows your Events and provides a number of options to sort, filter, export, and report."
      steps={eventGridTourSteps}
      flagField="eventsGridTourCompletedDateTimeUtc"
      tourType={TourTypes.EventsGrid}
    />
  );

  const userRoleCanSave = roleMatch([
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
  ]);

  return (
    <>
      <LynxDataGrid
        tour={getTour}
        enableSavedFilters
        hasAiQuery
        hideAddButton={!userRoleCanSave}
        columns={columns}
        useQuery={useGetEventsQuery}
        indexDbObjectStoreName={eventStore}
        localStorageName="events"
        addButtonAction={navigateToAddEvent}
        entityType={EntityTypes.Event}
        indexDbSortColumn="eventNumber"
        enableReports
        isOfflineReady={isOfflineReady}
        mobileCard={EventMobileCard}
        handleExportAttachmentsTrigger={getEventsAttachmentsTrigger}
        handleExportXLSXTrigger={getEventsExportAsXLSXTrigger}
        bulkUpdateTrigger={bulkUpdateTrigger}
        bulkUpdateAttachmentsTrigger={bulkUpdateEventAttachmentsTrigger}
        searchEnabled
        searchLabel="Search By: Event #, Category, Contact, Contact Group, Description or Tags"
        disableRefetchOnMountOrArgChange
      />
    </>
  );
};

export default EventList;
