import queryString from "query-string";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";
import {
  LynxGridColDef,
  MonitoringResultDto,
  MonitoringResultParams,
  MonitoringResultWideParams,
} from "types";

const BASE_ROUTE = "/monitoringResults";

const monitoringResultsEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    // Fetch monitoring results with pagination
    getMonitoringResults: builder.query({
      query: (resultParams: MonitoringResultParams) =>
        `${BASE_ROUTE}?${queryString.stringify(resultParams)}`,
      providesTags: [RtkTagTypes.GetMonitoringResults],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Fetch monitoring results in wide format with pagination
    getMonitoringResultsInWideFormat: builder.query({
      query: (resultParams: MonitoringResultWideParams) =>
        `${BASE_ROUTE}/wide?${queryString.stringify(resultParams)}`,
      providesTags: [RtkTagTypes.GetMonitoringResults],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    // Get count of results by filter ID
    getMonitoringResultCountByFilterId: builder.query({
      query: (filterId: number) => `${BASE_ROUTE}/count/${filterId}`,
    }),

    // Update a monitoring result
    updateMonitoringResult: builder.mutation({
      query: ({ id, dto }: { id: number; dto: MonitoringResultDto }) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetMonitoringResults, id },
        RtkTagTypes.GetMonitoringResults,
      ],
    }),

    // Delete a monitoring result
    deleteMonitoringResult: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetMonitoringResults],
    }),

    // Export monitoring results as a file
    exportMonitoringResults: builder.mutation({
      query: ({
        param,
        gridColumnDefinitions,
      }: {
        param: MonitoringResultParams;
        gridColumnDefinitions: LynxGridColDef[];
      }) => ({
        url: `${BASE_ROUTE}/export?${queryString.stringify(param)}`,
        method: "POST",
        body: gridColumnDefinitions,
        responseType: "blob", // Ensures the response is treated as a file
      }),
    }),
    getMonitoringResultExportAsXLSX: builder.mutation<
      any,
      { params: any; columnDefinitionDto: any }
    >({
      query: ({ params, columnDefinitionDto }) => ({
        url: `${BASE_ROUTE}/export`,
        method: "POST",
        params: params,
        body: columnDefinitionDto,
        responseHandler: (response: any) => response.blob(),
      }),
    }),
  }),
});
export const {
  useGetMonitoringResultsQuery,
  useLazyGetMonitoringResultsQuery,
  useLazyGetMonitoringResultCountByFilterIdQuery,
  useGetMonitoringResultsInWideFormatQuery,
  useUpdateMonitoringResultMutation,
  useDeleteMonitoringResultMutation,
  useGetMonitoringResultExportAsXLSXMutation,
} = monitoringResultsEndpoint;
