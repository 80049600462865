import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useGetImportSpecificationsQuery } from "services/rtkApi/endpoints/imports";
import { Grid } from "components/lynx-components";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { deleteSpecification } from "../../../services/import";
import { LynxDialog } from "../../lynx-dialog";

import useAlert from "hooks/useAlert";
import { LynxGridColDef } from "types";
import { dateUtil } from "../../../services/date-util";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import { EntityTypes } from "types/enums-ts";

export function ImportSpecifications() {
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleting, setShowDeleting] = useState(false);
  const [specId, setSpecId] = useState<number | null>(null);
  const { showAlert } = useAlert();
  const importSpecColumns: LynxGridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      type: "string",
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      type: "string",
    },
    {
      field: "entityType",
      headerName: "Type",
      width: 100,
      type: "singleSelect",
      valueOptions: ["Events", "Monitoring Results"],
    },

    {
      field: "numberOfSuccessfulImports",
      headerName: "# of Successful Imports",
      width: 100,
      type: "number",
    },
    {
      field: "lastSuccessfulImportDateTime",
      headerName: "Last Successful Import",
      width: 200,
      type: "dateTime",
      valueGetter: (value) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
    {
      field: "lastSuccessfulImporter",
      headerName: "Last Imported By",
      width: 200,
      type: "string",
    },
    {
      field: "Delete Import Configuration",
      headerName: "",
      width: 250,
      type: "string",
      resizable: false,
      disableColumnMenu: true,
      disableExport: true,
      hideSortIcons: true,
      renderCell: (params: any) => (
        <>
          <Button
            variant="outlined"
            color="error"
            onClick={(e) => {
              handleShowDelete(e, params.row.id);
            }}
          >
            Delete Import Configuration
          </Button>
        </>
      ),
    },
  ];

  const handleShowDelete = (e: any, specId: number) => {
    if (e && e != null) {
      e.preventDefault();
    }
    setSpecId(specId);
    setShowDelete(true);
  };
  const history = useHistory();
  const handleNavigateTo = (e: any, url: string) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };
  const handleDelete = () => {
    setShowDelete(false);
    setShowDeleting(true);
    deleteSpecification(specId)
      .then((res) => {
        setSpecId(null);
        showAlert("success", "Imported configuration deleted.");
        setShowDeleting(false);
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
        setShowDeleting(false);
      });
  };

  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-0">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Imports
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/imports")}
              >
                Imports
              </Link>
              <Typography color="text.primary">Configurations</Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      <Box
        sx={{
          width: "100%",
          height: "calc(100% - 87px)",
          bgcolor: "background.paper",
        }}
      >
        <LynxDataGrid
          columns={importSpecColumns}
          useQuery={useGetImportSpecificationsQuery}
          onlyShowTable
          entityType={EntityTypes.ImportSpecification}
          localStorageName={"importspecification"}
        />
      </Box>
      <LynxDialog
        open={showDelete}
        title={
          "Are you sure you want to delete the import configuration? Any imported data and history will remain, but will lose their association to the configuration."
        }
        description={"This action cannot be undone."}
        handleConfirm={handleDelete}
        handleClose={() => {
          setShowDelete(false);
        }}
      />
      <LynxDialog
        open={showDeleting}
        title={`Deleting configuration. Do not close the window.`}
        description={
          <>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          </>
        }
      />
    </Grid>
  );
}
