import queryString from "query-string";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";
import { IncidentParticipantDto } from "types";

const BASE_ROUTE = "/incidents";

const incidentsEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getIncidents: builder.query({
      query: (params) => `${BASE_ROUTE}?${queryString.stringify(params)}`,
      transformResponse: (response, meta) => {
        const pagination = meta?.response?.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
      providesTags: [RtkTagTypes.GetIncidents],
    }),
    getIncident: builder.query<any, number>({
      query: (id) => `${BASE_ROUTE}/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetIncident, id },
      ],
    }),
    getIncidentCountByFilterId: builder.query<number, string>({
      query: (filterId) => `${BASE_ROUTE}/count/${filterId}`,
    }),
    createIncident: builder.mutation<any, { dto: any; files: File[] }>({
      query: ({ dto, files }) => {
        const form = new FormData();
        if (files && files.length > 0) {
          files.forEach((file) => {
            form.append("file", file);
          });
        }
        form.append("data", JSON.stringify(dto));

        return {
          url: `${BASE_ROUTE}`,
          method: "POST",
          body: form,
        };
      },
      invalidatesTags: [RtkTagTypes.GetIncidents],
    }),
    updateIncidentInspection: builder.mutation<
      any,
      { id: number; formFields: any; saveAction: string }
    >({
      query: ({ id, formFields, saveAction }) => ({
        url: `${BASE_ROUTE}/${id}/investigation`,
        method: "POST",
        params: { saveAction },
        body: formFields,
      }),
      invalidatesTags: (result, error, { id }) => [
        RtkTagTypes.GetIncidents,
        { type: RtkTagTypes.GetIncident, id },
      ],
    }),
    createWitnessStatement: builder.mutation<any, { id: number; dto: any }>({
      query: ({ id, dto }) => ({
        url: `${BASE_ROUTE}/${id}/witnessStatement`,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: (result, error, { id }) => [RtkTagTypes.GetIncidents],
    }),
    updateIncidentParticipants: builder.mutation<any, { id: number; dto: any }>(
      {
        query: ({ id, dto }) => ({
          url: `${BASE_ROUTE}/${id}/participants`,
          method: "POST",
          body: dto,
        }),
        invalidatesTags: (result, error, { id }) => [
          RtkTagTypes.GetIncidents,
          { type: RtkTagTypes.GetIncident, id },
          { type: RtkTagTypes.GetIncidentParticipants, id },
        ],
      }
    ),
    deleteIncident: builder.mutation<any, number>({
      query: (id) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetIncidents],
    }),
    patchIncident: builder.mutation<
      any,
      { id: string; jPatch: any; saveAction?: string }
    >({
      query: ({ id, jPatch, saveAction }) => {
        const url = saveAction
          ? `${BASE_ROUTE}/${id}?saveAction=${saveAction}`
          : `${BASE_ROUTE}/${id}`;
        return {
          url,
          method: "PATCH",
          body: jPatch,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        RtkTagTypes.GetIncidents,
        { type: RtkTagTypes.GetIncident, id },
        { type: RtkTagTypes.GetIncidentParticipants, id },
      ],
    }),
    updateIncidentUser: builder.mutation<any, { id: number; dto: any }>({
      query: ({ id, dto }) => ({
        url: `${BASE_ROUTE}/investigationteam/${id}`,
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: (result, error, { id }) => [
        RtkTagTypes.GetIncidents,
        { type: RtkTagTypes.GetIncident, id },
      ],
    }),
    getIncidentExportAsXLSX: builder.mutation<
      any,
      { params: any; columnDefinitionDto: any }
    >({
      query: ({ params, columnDefinitionDto }) => ({
        url: `${BASE_ROUTE}/export`,
        method: "POST",
        body: columnDefinitionDto,
        params: params,
        responseType: "blob",
        responseHandler: (response: any) => response.blob(),
      }),
    }),
    getIncidentParticipantsByIncidentId: builder.query<IncidentParticipantDto[], number>({
      query: (incidentId) => `${BASE_ROUTE}/participants/${incidentId}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetIncidentParticipants, id },
      ],
    }),
  }),
});

export const {
  useGetIncidentsQuery,
  useLazyGetIncidentQuery,
  useLazyGetIncidentCountByFilterIdQuery,
  useCreateIncidentMutation,
  useUpdateIncidentInspectionMutation,
  useCreateWitnessStatementMutation,
  useUpdateIncidentParticipantsMutation,
  useDeleteIncidentMutation,
  usePatchIncidentMutation,
  useUpdateIncidentUserMutation,
  useGetIncidentExportAsXLSXMutation,
  useGetIncidentParticipantsByIncidentIdQuery,
} = incidentsEndpoint;
