import _ from "lodash";
import { getMonitoringEvents } from "./monitoring-events";
import { getEvents } from "./events";
import { createEventWithAttachments } from "./events";
import { createMonitoringEvent } from "./monitoring-events";
import localforage from "localforage";
import moment from "moment";
import { UserRoles } from "../types/enums";
import { createInspection, getInspections } from "./inspection-event-service";
import {
  hasAnyEventRole,
  hasAnyMonitoringRole,
  roleMatch,
} from "../actions/auth";
import { getLastSyncCacheInvalidated } from "./organization-service";
import { client } from "modules/store";
import { apiUrl } from "modules/store";
import { store } from "./../index";

export const assetStore = "assetStore_v2";
export const inspectionFormStore = "inspectionFormStore_v2";
export const equipmentStore = "equipmentStore_v2";
export const categoryStore = "categoryStore_v2";
export const subCategoryStore = "subCategoryStore_v2";
export const customFieldStore = "customFieldStore_v2";
export const monLocStoreName = "monitoringLocationStore_v2";
export const unitStoreName = "unitStore_v2";
export const resultTypeStoreName = "resultTypeStore_v2";
export const eventTypeStoreName = "eventTypeStore_v2";
export const parameterStoreName = "parameterStore_v2";
export const monLimitStore = "monLimitStore_v2";
export const eventStore = "eventStore_v2";
export const actionStore = "actionStore_v2";
export const userStore = "userStore_v2";
export const inspectionEventStore = "inspectionEventStore_v2";
export const monitoringEventsStore = "monitoringEventsStore_v2";
export const eventSyncStore = "eventSyncStore_v2";
export const lastSyncName = "lastSyncDateTime";
export const actionCategoryStoreName = "actionCategoryStore_v2";
export const actionEvidenceRequiredStoreName = "actionEvidenceRequired_v2";
export const monitoringCustomFieldStoreName = "monitoringCustomFieldStore_v2";
function getOfflineData() {
  return client.get(apiUrl + "/offlineData");
}
class OfflineDataService {
  async handleSyncEvents() {
    var deleteFromSyncDb = function (id) {
      localforage.getItem(eventSyncStore).then((res) => {
        let data = [...res];
        const index = data.find((x) => x.id == id);
        data.splice(index, 1);
        localforage.setItem(eventSyncStore, data);
      });
    };
    //send events to server
    return new Promise(function (resolve) {
      let packages = [];
      localforage.getItem(eventSyncStore).then(async (res) => {
        if (res == null || res == undefined) {
          resolve(true);
          return;
        }
        const totalEventsToSync = res.length;
        if (totalEventsToSync == 0) {
          resolve(true);
          return;
        }
        res.forEach((result) => {
          let form = new FormData();
          if (!_.isEmpty(result.attachments)) {
            for (var index = 0; index < result.attachments.length; index++) {
              var element = result.attachments[index];
              form.append("file", new File([element.blob], element.name));
            }
          }
          form.append("data", JSON.stringify(result.form));
          let item = {
            form: form,
            result: result,
          };
          packages.push(item);
        });
        if (packages.length > 0) {
          for (let i = 0; i < packages.length; i++) {
            if (packages[i].result.type == "event") {
              try {
                const response = await createEventWithAttachments(
                  packages[i].form
                );
                deleteFromSyncDb(packages[i].result.id);
              } catch (err) {
                console.log(err);
              }
            } else if (packages[i].result.type == "monitoring") {
              try {
                const result = await createMonitoringEvent(packages[i].form);
                deleteFromSyncDb(packages[i].result.id);
              } catch (err) {
                console.log(err);
              }
            } else if (packages[i].result.type == "inspection-event") {
              try {
                const result = await createInspection(packages[i].form);
                deleteFromSyncDb(packages[i].result.id);
              } catch (err) {
                console.log(err);
              }
            }
          }
        }
        resolve(true);
        return;
      });
    });
  }

  async checkIfOfflineIsReady() {
    return new Promise(function (resolve) {
      getLastSyncCacheInvalidated().then((res) => {
        const lastSyncInvalidDate = moment
          .utc(res.data)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
        localforage.getItem(lastSyncName).then((res) => {
          if (res != null && res != undefined) {
            const lastSyncDate = moment
              .utc(res)
              .local()
              .format("YYYY-MM-DD HH:mm:ss");
            if (new Date(lastSyncInvalidDate) > new Date(lastSyncDate)) {
              resolve(false);
            }
          } else {
            resolve(false);
          }
          const offlineStoreNames = [
            assetStore,
            customFieldStore,
            monitoringCustomFieldStoreName,
            categoryStore,
            subCategoryStore,
            unitStoreName,
            resultTypeStoreName,
            parameterStoreName,
            eventTypeStoreName,
            monLocStoreName,
            monLimitStore,
            monitoringEventsStore,
            eventStore,
            userStore,
            inspectionFormStore,
            equipmentStore,
          ];
          doAllStoresExist(offlineStoreNames).then((allExists) => {
            if (allExists) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
        });
      });
    });
  }

  prepareForOffline() {
    getOfflineData().then((res) => {
      localforage.setItem(assetStore, res.data.assets);
      localforage.setItem(customFieldStore, res.data.eventCustomFields);
      localforage.setItem(
        monitoringCustomFieldStoreName,
        res.data.monitoringEventCustomFields
      );
      localforage.setItem(categoryStore, res.data.eventCategories);
      localforage.setItem(subCategoryStore, res.data.eventSubCategories);
      localforage.setItem(unitStoreName, res.data.units);
      localforage.setItem(resultTypeStoreName, res.data.monitoringResultTypes);
      localforage.setItem(eventTypeStoreName, res.data.monitoringEventTypes);
      localforage.setItem(monLocStoreName, res.data.monitoringLocations);
      localforage.setItem(monLimitStore, res.data.monitoringLimits);
      localforage.setItem(monitoringEventsStore, res.data.monitoringEvents);
      localforage.setItem(eventStore, res.data.events);
      localforage.setItem(inspectionEventStore, res.data.inspectionEvents);
      localforage.setItem(inspectionFormStore, res.data.inspectionForms);
      localforage.setItem(equipmentStore, res.data.equipment);
      localforage.setItem(parameterStoreName, res.data.parameters);
    });
    const currentState = store.getState();
    localforage.setItem(userStore, currentState.lookups.users);

    let utcDateTime = moment.utc().format();
    localforage.setItem(lastSyncName, utcDateTime);
  }

  reloadEvents() {
    if (hasAnyMonitoringRole()) {
      getMonitoringEvents({ pageSize: 20 }).then((res) => {
        localforage.setItem(monitoringEventsStore, res.data);
      });
    }
    if (hasAnyEventRole()) {
      getEvents({ pageSize: 20 }).then((res) => {
        localforage.setItem(eventStore, res.data);
      });
    }
    if (roleMatch([UserRoles.InspectionsUser])) {
      getInspections({ pageSize: 20, onlyShowInspectionForms: true }).then(
        (res) => {
          localforage.setItem(inspectionEventStore, res.data);
        }
      );
    }
  }

  saveToIndexDb(storeName, data) {
    localforage.setItem(storeName, data);
  }
}
async function doAllStoresExist(storeNames) {
  try {
    const results = await Promise.all(
      storeNames.map(async (storeName) => {
        const value = await localforage.getItem(storeName);
        return value !== null && value !== undefined;
      })
    );
    return results.every((exists) => exists); // Check if all results are true
  } catch (error) {
    console.error("Error checking stores:", error);
    return false;
  }
}
export const offlineDataService = new OfflineDataService();
