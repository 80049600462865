import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import { addAlert } from "reducers/alerts";
import { isErrorWithMessage } from "services/rtkApi/helpers";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (store: MiddlewareAPI) => (next) => (action: any) => {
    if (
      action &&
      action.meta &&
      action.meta.arg &&
      action.meta.arg.endpointName &&
      (action.meta.arg.endpointName == "getEvents" ||
        action.meta.arg.endpointName == "getMonitoringEvents" ||
        action.meta.arg.endpointName == "getInspections")
    ) {
      return next(action);
    }
    if (isRejectedWithValue(action)) {
      const errorMsg = isErrorWithMessage(action.payload)
        ? action.payload.data.message
        : "An error has occured. Please contact Lynx support.";
      store.dispatch(
        addAlert({
          message: errorMsg || "Error has occured.",
          variant: "error",
        })
      );
    }

    return next(action);
  };
