import _ from "lodash";
import { useEffect, useState } from "react";
import { Card, Dimmer } from "components/lynx-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import { getWidgetData } from "../../../../services/dashboard-widget-service";
import { DashboardWidgetHeader } from "./dashboard-widget-header";
import { EntityTypes } from "../../../../types/enums";
import { useHistory } from "react-router-dom";

export function CountListWidget(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [lastCalculatedDate, setLastCalculatedDate] = useState("");
  const history = useHistory();
  useEffect(() => {
    if (!_.isEmpty(props.widget)) {
      loadWidgetData(false);
    }
  }, [props.widget]);

  useEffect(() => {
    window.addEventListener("ce-refresh-widget-chart", loadWidgetData);

    return () => {
      window.removeEventListener("ce-refresh-widget-chart", loadWidgetData);
    };
  }, []);

  const loadWidgetData = (refreshData = false) => {
    if (props.widget) {
      setIsLoading(true);
      getWidgetData(props.widget.id, refreshData).then((res) => {
        setLastCalculatedDate(res.data.lastCalculatedDateTimeUtc);
        setRows(JSON.parse(res.data.dataJson));
        setIsLoading(false);
      });
    }
  };

  const handleRefresh = () => {
    loadWidgetData(true);
  };

  const handleTableClick = (e, i) => {
    const index = i + 1;
    if (props.widget) {
      let filter = props.widget.dashboardWidgetFilters.find(
        (x) => x.sortOrder == index
      );
      if (
        _.toLower(filter.entityFilter.entityType) ==
        _.toLower(EntityTypes.Event)
      ) {
        history.push(`/events?filterId=${filter.entityFilterId}`);
      }
      if (
        _.toLower(filter.entityFilter.entityType) ==
        _.toLower(EntityTypes.MonitoringEvent)
      ) {
        history.push(
          `/monitoring?view=events&filterId=${filter.entityFilterId}`
        );
      }
      if (
        _.toLower(filter.entityFilter.entityType) ==
        _.toLower(EntityTypes.MonitoringResult)
      ) {
        history.push(
          `/monitoring?view=results&filterId=${filter.entityFilterId}`
        );
      }
      if (
        _.toLower(filter.entityFilter.entityType) ==
        _.toLower(EntityTypes.Incident)
      ) {
        history.push(`/incidents?filterId=${filter.entityFilterId}`);
      }
      if (
        _.toLower(filter.entityFilter.entityType) ==
        _.toLower(EntityTypes.Action)
      ) {
        history.push(`/actions?filterId=${filter.entityFilterId}`);
      }
      if (
        _.toLower(filter.entityFilter.entityType) ==
        _.toLower(EntityTypes.InspectionEvent)
      ) {
        history.push(`/inspections?filterId=${filter.entityFilterId}`);
      }
      if (
        _.toLower(filter.entityFilter.entityType) ==
        _.toLower(EntityTypes.EnergyLog)
      ) {
        history.push(`/energy-logs?filterId=${filter.entityFilterId}`);
      }
    }
  };

  return (
    <Card>
      <DashboardWidgetHeader
        handleSortChange={props.handleSortChange}
        handleDelete={props.handleDelete}
        upDisabled={props.upDisabled}
        downDisabled={props.downDisabled}
        widget={props.widget}
        handleRefresh={handleRefresh}
        lastCalculatedDate={lastCalculatedDate}
        title={props.title}
        handleUpdate={props.handleUpdate}
      />
      <Dimmer active={props.loading ?? isLoading} loader>
        <div
          style={{
            height: isLoading && props.widget ? 400 : "100%",
            padding: 0,
            width: "100%",
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth: "100%" }} aria-label="count-list-table">
              <TableHead>
                <TableRow>
                  <TableCell>Filter</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.orderBy(props.rows ?? rows, "sortOrder").map((row, i) => (
                  <TableRow key={row.filterName}>
                    <TableCell>{row.filterName}</TableCell>
                    <TableCell>
                      {!row.inactive ? (
                        <>
                          {props.widget ? (
                            <Link
                              underline="none"
                              component="button"
                              onClick={() => handleTableClick(row, i)}
                            >
                              {row.entityCount}
                            </Link>
                          ) : (
                            <>{row.entityCount}</>
                          )}
                        </>
                      ) : (
                        <div>Module Inactive</div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Dimmer>
    </Card>
  );
}
