import { Button, Divider } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { SearchableSelect } from "components/form-controls/searchable-select";
import { Form, Grid } from "components/lynx-components";
import { FC, useState } from "react";
import { EventDto } from "types";
import MuiGrid from "@mui/material/Grid";
import Chip, { chipClasses } from "@mui/material/Chip";
interface EventDetailsTopicsProps {
  commentsLoading: boolean;
  event: EventDto;
}

const EventDetailsTags: FC<EventDetailsTopicsProps> = ({
  commentsLoading,
  event,
}) => {
  const [primaryTopic, setPrimaryTopic] = useState<string>();
  const [secondaryTopic, setSecondaryTopic] = useState<string>();
  return (
    <Card className="lynx-card" variant="outlined" sx={{ width: "100%" }}>
      <CardHeader className="lynx-card-header" title="Topics" />
      <CardContent className="p-2">
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <MuiGrid container>
                <MuiGrid item mr={1} mb={1}>
                  <Chip
                    onClick={(e) => {}}
                    label={"Donation (Administration)"}
                    variant="outlined"
                    sx={{
                      [`& .${chipClasses.icon}`]: {
                        color: "rgba(0, 0, 0, 0.26)",
                      },
                    }}
                    onDelete={() => {}}
                  />
                </MuiGrid>
                <MuiGrid item mr={1} mb={1}>
                  <Chip
                    onClick={(e) => {}}
                    label={"Donation (Infrastructure)"}
                    variant="outlined"
                    sx={{
                      [`& .${chipClasses.icon}`]: {
                        color: "rgba(0, 0, 0, 0.26)",
                      },
                    }}
                    onDelete={() => {}}
                  />
                </MuiGrid>
                <MuiGrid item mr={1} mb={1}>
                  <Chip
                    onClick={(e) => {}}
                    label={"Water (Biological Environment)"}
                    variant="outlined"
                    sx={{
                      [`& .${chipClasses.icon}`]: {
                        color: "rgba(0, 0, 0, 0.26)",
                      },
                    }}
                    onDelete={() => {}}
                  />
                </MuiGrid>
              </MuiGrid>
            </Grid.Col>
          </Grid.Row>
          <Divider className="mb-2" />
          <Grid.Row>
            <Grid.Col>
              <Form.Group label="Select Primary Topic">
                <SearchableSelect
                  name="investigationLeadUserId"
                  options={[
                    "Administration",
                    "Biological Environment",
                    "Document Review",
                    "Human Environment",
                  ]}
                  value={primaryTopic}
                  labelFunction={(option: any) => option}
                  onChange={(e: string) => setPrimaryTopic(e)}
                  placeholder="Search or choose from list"
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Form.Group label="Select Secondary Topic">
                <SearchableSelect
                  disabled={!primaryTopic}
                  name="investigationLeadUserId"
                  options={["Donation", "Operations", "Post-Closure"]}
                  value={secondaryTopic}
                  labelFunction={(option: any) => option}
                  onChange={(e: string) => setSecondaryTopic(e)}
                  placeholder="Search or choose from list"
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Button variant="outlined">Add Topic</Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EventDetailsTags;
