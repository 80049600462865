export const incidentGridTourSteps = [
  {
    disableBeacon: true,
    target: ".filter-grid-button",
    placementBeacon: "bottom",
    placement: "bottom",
    title: "Create and Save Filters",
    content:
      "Filters are used to quickly view the data you need, when you need it. Filters are also used to create widgets for the Dashboard. Use the Filters button on any grid to create custom filters. Filters can be saved and applied quickly using the Saved Filters button.",
  },
  {
    target: ".export-grid-button",
    placementBeacon: "bottom",
    placement: "bottom",
    title: "Export Your Data",
    content:
      "Use the export button to export the current grid view in .csv format.",
  },
  {
    target: ".incident-status-header",
    placement: "bottom",
    title: "The Event Workflow Status",
    content:
      "Stay on top of your Incidents workflow with the Incident Status. The incident status is automatically set by the platform and identifies the workflow status of the incident. The status can be Pending Review, Investigation, Pending Actions, Final Review, or Closed.",
  },
  {
    target: ".incident-number-header",
    placement: "bottom",
    title: "The Incident Number",
    content:
      "Each Incident is assigned a unique Incident Number. Clicking on the Incident Number will open the Incident Details page.",
  },
  {
    target: ".grid-add-button",
    placementBeacon: "left",
    placement: "left",
    title: "Add an Incident!",
    content:
      "While we hope you haven’t had an incident today, let’s go ahead and practice adding an Incident. Click on the Add Incident Button on the top right. This brings up the Add Incident form, where you can add details, associated contacts, and much more.",
  },
];
