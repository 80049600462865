import Button from "@mui/material/Button";
import * as jsonpatch from "fast-json-patch";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Card, Dimmer, Form, Grid, Icon } from "components/lynx-components";
import { roleMatch } from "../../../actions/auth";
import { validationService } from "../../../services";
import { LookupTypes, UserRoles } from "../../../types/enums";
import Typography from "@mui/material/Typography";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import useAlert from "hooks/useAlert";
import { UsersPermissions } from "./user-permissions";
import { useLazyGetLookupQuery, usePatchLookupMutation } from "services/rtkApi/endpoints/lookups";

const initialForm = {
  id: "",
  lookupType: "",
  code: "",
  description: "",
  parentLookupId: "",
  // descriptionError: "",
  codeError: "",
  isRestricted: false,
  isReviewable: false,
  requireChildSelection: false,
};

export function SettingsLookUpEditModal(props) {
  const [formState, setFormState] = useState(initialForm);
  const [initialFormState, setInitialFormState] = useState({});
  const [formTitle, SetFormTitle] = useState("");
  const [lookupType, SetLookupType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [lookup, setLookup] = useState({});
  const [organizationUserLookups, setOrganizationUserLookups] = useState([]);
  const { showAlert } = useAlert();
  const [getLookupTrigger] = useLazyGetLookupQuery();
  const [patchLookup] = usePatchLookupMutation();

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name } = e.target;

    const value =
      name == "isRestricted" ||
      name == "requireChildSelection" ||
      name == "isReviewable"
        ? e.target.checked
        : e.target.value;

    _.set(newState, name, value);

    setFormState(newState);
  };

  useEffect(() => {
    if (props.lookupId > 0) {
      // setFormStateFromProps(props.lookupItem  );
      //   setIsLoading(false);
      getLookupTrigger(props.lookupId).then((res) => {
        setFormStateFromProps(res.data);
        setLookup(res.data);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [props.lookupId]);

  const setFormStateFromProps = (lookup) => {
    let newState = {
      id: lookup.id,
      lookupType: lookup.lookupType,
      code: lookup.code,
      description: lookup.description,
      parentLookupId: lookup.parentLookupId,
      childLookupId: lookup.childLookupId,
      isRestricted: lookup.isRestricted,
      requireChildSelection: lookup.requireChildSelection,
      isReviewable: lookup.isReviewable,
    };
    setFormState(newState);
    setInitialFormState(newState);
    switch (_.toLower(lookup.lookupType)) {
      case "incidentcategory":
        SetFormTitle("Incident Category");
        SetLookupType("Category");
        break;
      case "incidenttype":
        SetFormTitle("Incident Type");
        SetLookupType("Type");
        break;
      case "incidentlocationtype":
        SetFormTitle("Incident Location Type");
        SetLookupType("Location Type");
        break;
      case "complaincecategory":
        SetFormTitle("Complaince Category");
        SetLookupType("Category");
        break;
      case "eventcategory":
        SetFormTitle("Event Category");
        SetLookupType("Category");
        break;
      case "eventsubcategory":
        SetFormTitle("Event Subcategory");
        SetLookupType("Subcategory");
        break;
      case "correspondencelogtype":
        SetFormTitle("Correspondence Log Type");
        SetLookupType("Correspondence Log Type");
        break;
      default:
        SetFormTitle("");
        SetLookupType("");
        break;
    }
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    //unset errors
    let formToSave = validationService.unsetErrors(formState, "codeError");

    if (props.lookupId > 0) {
      var diff = jsonpatch.compare(initialFormState, formToSave);
      var dto = {
        lookupPatch: diff,
        organizationUserLookups: organizationUserLookups,
      };

      patchLookup({ id: props.lookupId, dto: dto })
        .then((res) => {
          showAlert("success", lookupType + " Saved.");
          props.handleModalClose(true);
        })
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "code",
      "codeError",
      lookupType
    );

    if (lookupType === "Type") {
      validationService.validateRequiredField(
        newState,
        "parentLookupId",
        "parentLookupIdError",
        "Category"
      );
    }

    isFormValid = !validationService.hasError(
      newState,
      "codeError",
      lookupType === "Type" ? "parentLookupIdError" : ""
    );

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  return (
    <Form className="card dsmodal-main lynx-modal">
      <Card.Body>
        <Dimmer active={isLoading} loader>
          <Card.Title>
            {"Edit " + formTitle}

            <Icon
              name="x"
              onClick={props.handleModalClose}
              className="float-right pointer"
            ></Icon>
          </Card.Title>
          {lookupType === "Type" && (
            <Grid.Row alignItems="center">
              <Grid.Col sm={12}>
                <Form.Group label={"Framework"}>
                  <Form.Select
                    name="parentLookupId"
                    onChange={handleInputChange}
                    value={formState.parentLookupId}
                    error={formState.parentLookupIdError}
                  >
                    <option value={""}></option>
                    {props.parents.map((type) => (
                      <option value={type.id} key={type.id}>
                        {type.code}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          )}

          <Grid.Row alignItems="center">
            <Grid.Col sm={12}>
              <Form.Group label={lookupType}>
                <Form.Input
                  type="text"
                  value={formState.code}
                  name="code"
                  onChange={handleInputChange}
                  error={formState.codeError}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row alignItems="center">
            <Grid.Col sm={12}>
              <Form.Group label="Description">
                <Form.Input
                  type="text"
                  value={formState.description}
                  name="description"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          {_.toLower(lookup.lookupType) === "eventcategory" && (
            <>
              {roleMatch([UserRoles.Admin]) && (
                <>
                  <Grid.Row className="mt-2">
                    <Grid.Col>
                      <Form.Checkbox
                        label={"Require subcategory?"}
                        name={`requireChildSelection`}
                        onChange={handleInputChange}
                        checked={formState.requireChildSelection}
                        value={formState.requireChildSelection}
                      />
                    </Grid.Col>
                  </Grid.Row>
                </>
              )}
            </>
          )}
          {(_.toLower(lookup.lookupType) === "eventcategory" ||
            _.toLower(lookup.lookupType) === "eventsubcategory") &&
            roleMatch([UserRoles.Admin]) && (
              <>
                <Grid.Row className="mt-2">
                  <Grid.Col>
                    <Form.Checkbox
                      label={"Require final review?"}
                      name={`isReviewable`}
                      onChange={handleInputChange}
                      checked={formState.isReviewable}
                      value={formState.isReviewable}
                    />
                  </Grid.Col>
                </Grid.Row>
              </>
            )}
          {_.toLower(lookup.lookupType) ===
            _.toLower(LookupTypes.EventCategory) && (
            <Grid.Row className="mt-2">
              <Grid.Col width={12}>
                <Accordion className="w-100" defaultExpanded={false}>
                  <AccordionSummary
                    aria-controls="location-content"
                    id="permissions-header"
                  >
                    <Typography>Permissions</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <UsersPermissions
                      lookupType={lookup.lookupType}
                      lookupId={props.lookupId}
                      setOrganizationUserLookups={(e) => {
                        setOrganizationUserLookups(e);
                      }}
                    ></UsersPermissions>
                  </AccordionDetails>
                </Accordion>
              </Grid.Col>
            </Grid.Row>
          )}
        </Dimmer>
      </Card.Body>
      <Card.Footer>
        <Button variant="contained" className="float-right" onClick={saveForm}>
          Save
        </Button>
      </Card.Footer>
    </Form>
  );
}
