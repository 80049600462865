import { LynxDataGrid } from "../../data-grid/lynx-data-grid";

import {
  useGetEquipmentQuery,
  useLazyGetEquipmentQuery,
} from "services/rtkApi/endpoints/equipment";
import { LynxGridColDef } from "types";
import { EntityTypes } from "types/enums-ts";
import { EquipmentModal } from "./equipment-modal";
export const equipmentColumns: LynxGridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    width: 300,
    type: "string",
  },
  {
    field: "description",
    headerName: "Description",
    width: 300,
    type: "string",
  },
  {
    field: "assetName",
    headerName: "Asset",
    width: 150,
    type: "string",
  },
];
export function EquipmentList() {
  return (
    <>
      <LynxDataGrid
        columns={equipmentColumns}
        useQuery={useGetEquipmentQuery}
        title="Equipment"
        localStorageName="equipment"
        entityType={EntityTypes.Equipment}
        entityModal={EquipmentModal}
        hasEditButton
        passEntityOnEditClick
        addButtonText="Add Equipment"
      />
    </>
  );
}
