import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { Card, Grid } from "components/lynx-components";

import { roleMatch } from "../../../../actions/auth";
import { SettingsLookUpEditModal } from "../SettingsLookUpEditModal";
import { DeleteConfirmModal } from "./../DeleteConfirmModal";
import { assetTypeColumns } from "./AssetTypeColumns";
import { AssetTypeModel } from "./AssetTypeModel";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { LookupTypes, UserRoles } from "../../../../types/enums";
import { useGetLookupsQuery } from "services/rtkApi/endpoints/lookups";
const initialLookupData1 = {
  id: "",
  lookupType: "",
  code: "",
  description: "",
};
export function AssetSettings() {
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [selectedDataId, setSelectedDataId] = useState(0);

  const [selectedLookupData, setSelectedLookupData] =
    useState(initialLookupData1);
  const [initialLookupData, setInitialLookupData] = useState({});
  const history = useHistory();

  const { data: assetTypeData, isFetching: isFetchingAssetTypeData } =
    useGetLookupsQuery({
      lookupType: LookupTypes.AssetType,
    });

  const setLookupDataFromProps = (LookupItem) => {
    let newState = {
      id: LookupItem.id,
      lookupType: LookupItem.lookupType,
      code: LookupItem.code,
    };
    setSelectedLookupData(newState);
  };

  const handleRowSelected = (e) => {
    setSelectedDataId(e.id);
  };

  const handleEditDelete = (e) => {
    if (
      (e.field == "delete" && roleMatch([UserRoles.Admin]) && !e.row.isInUse) ||
      roleMatch([UserRoles.InternalUser])
    ) {
      setSelectedDataId(e.row.id);
      setLookupDataFromProps(e.row);
      setOpen(true);
      setDeleteOpen(true);
    }

    if (e.field == "edit") {
      setSelectedDataId(e.row.id);
      setLookupDataFromProps(e.row);
      setOpen(true);
      setEditOpen(true);
    }
  };

  const handleClose = (refreshData) => {
    setOpen(false);
    setDeleteOpen(false);
    setEditOpen(false);
    setSelectedDataId(0);
    setSelectedLookupData(initialLookupData);
  };

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Asset Settings
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>

              <Typography color="text.primary">Asset Settings</Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>

      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Asset Types
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Asset types are used to categorize your assets (e.g., exploration
              areas, concessions, mines). An asset type is required for every
              asset. Asset types can be used to filter assets.
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AssetTypeModel />
            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={assetTypeData || []}
                columns={assetTypeColumns}
                disableMultipleRowSelection={true}
                onCellClick={handleEditDelete}
                onRowClick={handleRowSelected}
                loading={isFetchingAssetTypeData}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={deleteOpen}
        onClose={handleClose}
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DeleteConfirmModal
          handleModalClose={handleClose}
          lookupId={selectedDataId}
          lookupItem={selectedLookupData}
        ></DeleteConfirmModal>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={editOpen}
        onClose={handleClose}
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <SettingsLookUpEditModal
          handleModalClose={handleClose}
          lookupId={selectedDataId}
          lookupItem={selectedLookupData}
        ></SettingsLookUpEditModal>
      </Modal>
    </Grid>
  );
}
