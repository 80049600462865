import FactoryIcon from "@mui/icons-material/Factory";
import LinkIcon from "@mui/icons-material/Link";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Grid } from "components/lynx-components";
import useAlert from "hooks/useAlert";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MonitoringEventDto } from "types";
import { dateUtil } from "../../../services/date-util";
import { getStatusChip } from "../events/event-details-container";
import { useLazyGetMonitoringEventQuery } from "services/rtkApi/endpoints/monitoringEvents";

interface EventQuickViewProps {
  monitoringEvent: MonitoringEventDto;
  monitoringEventId: number;
  handleClose: () => void;
}

const MonitoringEventQuickView: FC<EventQuickViewProps> = (props) => {
  const [event, setEvent] = useState<MonitoringEventDto>(props.monitoringEvent);
  const history = useHistory();
  const { showAlert } = useAlert();
  const [getMonitoringEvent] = useLazyGetMonitoringEventQuery();
  useEffect(() => {
    if (props.monitoringEventId > 0) {
      getMonitoringEvent(props.monitoringEventId).then((res: any) => {
        if (res.data) {
          setEvent(res.data);
        }
      });
    }
  }, [props.monitoringEvent, props.monitoringEventId]);

  const handleEventNumberClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (event) {
      history.push(`/events/${event.id}`);
    }
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(`${window.origin}/events/${event?.id}`);
    showAlert("success", "Link copied!");
  };
  return (
    <>
      <DialogContent
        className="event-quick-view-dialog"
        sx={{ padding: "10px 12px" }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Col width={10}>
              <div className="d-flex flex-wrap align-items-center mb-1">
                <Link
                  href="#"
                  onClick={handleEventNumberClick}
                  underline="hover"
                  className="mr-2"
                >
                  <Typography variant="h6" component="span">
                    {event?.monitoringEventNumber} - {event?.eventTypeName}
                  </Typography>
                </Link>
                <div>{getStatusChip(event)}</div>
              </div>
            </Grid.Col>
            <Grid.Col width={2}>
              <div className="d-flex flex-wrap align-items-center mb-1">
                <div className="ml-auto">
                  <Tooltip title="Copy link to event">
                    <IconButton onClick={handleLinkClick}>
                      <LinkIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              {event?.dueDateTimeUtc && (
                <Typography variant="subtitle2">
                  Due:&nbsp;
                  {dateUtil.convertDateOnlyToLocal(event.dueDateTimeUtc)}
                </Typography>
              )}
              {event?.endDateTimeUtc && (
                <Typography variant="subtitle2">
                  Completed:&nbsp;
                  {dateUtil.convertDateOnlyToLocal(event.endDate)}
                </Typography>
              )}
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Typography variant="subtitle2">{event?.assetName}</Typography>
            </Grid.Col>
          </Grid.Row>

          {event?.numberOfResults != null && event?.numberOfResults > 0 && (
            <Grid.Row>
              <Grid.Col md={6} width={12} className="mt-1">
                <FactoryIcon className="pt-1 mr-1" />
                <Typography variant="body1">
                  <b># of Results:</b>&nbsp;
                  {event.numberOfResults}
                </Typography>
              </Grid.Col>
            </Grid.Row>
          )}
          <Divider className="mt-2 mb-2" />
          <Grid.Row className="mt-2">
            <Grid.Col md={6} width={12}>
              <Typography variant="body1">
                <b>Location:</b>&nbsp;{event?.monitoringLocationName}
              </Typography>
            </Grid.Col>
            {event?.assignedToUserFullName && (
              <Grid.Col md={6} width={12}>
                <Typography variant="body1">
                  <b>Assigned To: </b>
                  {event.assignedToUserFullName}
                </Typography>
              </Grid.Col>
            )}
          </Grid.Row>
          {event?.description && (
            <Grid.Row className="mt-2">
              <Grid.Col md={12} width={12}>
                <Typography variant="body1">
                  <b>Description: </b>
                  {event.description}
                </Typography>
              </Grid.Col>
            </Grid.Row>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>
    </>
  );
};

export default MonitoringEventQuickView;
