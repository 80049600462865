import { RtkTagTypes } from "types/enums";
import { apiService } from "../apiService";

const BASE_ROUTE = "/parameters";

const parametersEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getParameters: builder.query({
      query: () =>
        `${BASE_ROUTE}`,
      providesTags: [RtkTagTypes.GetParameters]
    }),
    getUnusedParameters: builder.query<
      any,
      { entityType: string; entityId: number }
    >({
      query: ({ entityType, entityId }) =>
        `${BASE_ROUTE}/${entityType}/${entityId}`,
      providesTags: [RtkTagTypes.GetUnusedParameters],
    }),
    addParameter: builder.mutation({
      query: (args: any) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: args,
      }),
      invalidatesTags: [
        RtkTagTypes.GetParameters,
        RtkTagTypes.GetUnusedParameters,
      ],
    }),
    updateParameter: builder.mutation({
      query: (args: any) => ({
        url: `${BASE_ROUTE}/${args.id}`,
        method: "PATCH",
        body: args.body,
      }),
      invalidatesTags: [
        RtkTagTypes.GetParameters,
        RtkTagTypes.GetUnusedParameters,
      ],
    }),
    deleteParameter: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        RtkTagTypes.GetParameters,
        RtkTagTypes.GetUnusedParameters,
      ],
    }),
  }),
});

export const {
  useGetParametersQuery,
  useLazyGetParametersQuery,
  useGetUnusedParametersQuery,
  useAddParameterMutation,
  useUpdateParameterMutation,
  useDeleteParameterMutation,
} = parametersEndpoint;
