import { useEffect, useState } from "react";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { LynxGridColDef } from "types";
import { EntityTypes } from "types/enums-ts";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import {
  useDeleteAssetSubLocationMutation,
  useGetAssetSubLocationsQuery,
} from "services/rtkApi/endpoints/assetsSubLocation";
import { useGetAssetByIdQuery } from "services/rtkApi/endpoints/assets";
import {
  Breadcrumbs,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Grid } from "components/lynx-components";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { LynxDialog } from "components/lynx-dialog";
import useAlert from "hooks/useAlert";
import { roleMatch } from "actions/auth";
import { UserRoles } from "types/enums";

export function AssetSubLocationList() {
  const [columns, setColumns] = useState<LynxGridColDef[]>([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedAssetSubLocationId, setSelectedAssetSubLocationId] =
    useState<number>(0);
  const history = useHistory();
  const params = useParams<{ assetId: string }>();
  const { showAlert } = useAlert();

  const { data: assetData, isFetching: isFetchingAssetData } =
    useGetAssetByIdQuery(+params.assetId, {
      skip: !params.assetId,
    });
  const [deleteAssetSubLocationTrigger] = useDeleteAssetSubLocationMutation();

  useEffect(() => {
    let defaultColumns: LynxGridColDef[] = [
      {
        field: "Actions",
        headerName: ``,
        width: 100,
        type: "string",
        sortable: false,
        filterable: false,
        renderCell: (params: any) => {
          return (
            <Stack direction="row" mt={0.5}>
              <Tooltip title="Edit">
                <IconButton
                  onClick={() =>
                    history.push(
                      `/asset-sub-location/${params.row.id}`
                    )
                  }
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              {roleMatch([UserRoles.Admin]) && (
                <Tooltip
                  color={params.row.isInUse ? "default" : "error"}
                  title={
                    params.row.isInUse
                      ? "Unable to delete when in use"
                      : "Delete"
                  }
                >
                  <IconButton
                    onClick={
                      params.row.isInUse
                        ? undefined
                        : () => {
                            setSelectedAssetSubLocationId(params.row.id);
                            setShowDelete(true);
                          }
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
        width: 250,
        type: "string",
      },
      {
        field: "description",
        headerName: "Description",
        // width: 250,
        type: "string",
      },
      {
        field: "latitude",
        headerName: "Latitude",
        // width: 250,
        type: "string",
      },
      {
        field: "longitude",
        headerName: "Longitude",
        // width: 250,
        type: "string",
      },
    ];

    setColumns([...defaultColumns]);
  }, [assetData]);

  const getHeader = () => (
    <>
      <Grid.Row className="ml-0 mr-0">
        <Typography variant="h3" className="form-header-text" component="div">
          {`${assetData?.name} - Drill Holes`}
        </Typography>
        <Grid.Col lg={12} width={12} className="p-0">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="primary"
              href="#"
              onClick={(e) => history.push("/assets")}
            >
              Assets
            </Link>
            <Typography color="text.primary">
              {assetData?.assetNumber}
            </Typography>
          </Breadcrumbs>
        </Grid.Col>
      </Grid.Row>
    </>
  );

  const handleDelete = () => {
    if (selectedAssetSubLocationId > 0) {
      deleteAssetSubLocationTrigger(selectedAssetSubLocationId)
        .then((res) => {
          showAlert("success", "Drill Hole deleted.");
        })
        .finally(() => {
          setSelectedAssetSubLocationId(0);
          setShowDelete(false);
        });
    }
  };

  return (
    <>
      {!_.isEmpty(columns) && !isFetchingAssetData && (
        <LynxDataGrid
          columns={columns}
          useQuery={useGetAssetSubLocationsQuery}
          useQueryParams={{ assetId: params.assetId }}
          localStorageName="assetSubLocation"
          entityType={EntityTypes.AssetSubLocation}
          title={`${assetData?.name} - Drill Holes`}
          addButtonText="Add Drill Hole"
          addButtonAction={() => {
            history.push(`/assets/${params.assetId}/add-asset-sub-location`);
          }}
          header={getHeader}
          disableRefetchOnMountOrArgChange
        />
      )}

      <LynxDialog
        open={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={handleDelete}
        title={"Delete Drill Hole?"}
        description={
          "Are you sure you want to delete this Drill Hole? This action cannot be undone."
        }
      />
    </>
  );
}
