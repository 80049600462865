import { Chip, Container, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import { Dimmer, Form, Grid } from "components/lynx-components";
import { LynxDialog } from "components/lynx-dialog";
import useWindowDimensions from "hooks/useWindowDimensions";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { queryRagModelAiDemo } from "services/admin";
interface FormState {
  prompt?: string;
  referenceEntityAttachmentIds: number[];
  temperature?: number;
  referenceDataRecordLimit: number;
  supplementalDataRecordLimit: number;
}
const prompts = [
  {
    id: "who",
    prompt: "Who / what are you?",
  },

  {
    id: "kc",
    prompt: "Summarize the key community concerns in the Raven’s Nest project.",
  },
  {
    id: "irma",
    prompt:
      "Reference IRMA and generate a regulatory compliance update based on the latest Fox Hole events.",
  },
  {
    id: "risk",
    prompt:
      "Provide a risk assessment based on community reactions to recent project activities.",
  },
  {
    id: "env",
    prompt:
      "Summarize the environmental risks linked to the acid leachate incident.",
  },
  {
    id: "press",
    prompt:
      "Draft a press release addressing community concerns at Raven’s Nest.",
  },
  {
    id: "envsum",
    prompt: "Give me a summary of our environmental data capture practices.",
  },
  {
    id: "comsum",
    prompt:
      "Give me a summary of the outreach we've done to government and community.",
  },
  {
    id: "gri",
    prompt: "Reference GRI 14.10 and comment on our progress with communities.",
  },
];
const initialForm: FormState = {
  prompt: "",
  temperature: 0,
  referenceEntityAttachmentIds: [],
  referenceDataRecordLimit: 10,
  supplementalDataRecordLimit: 10,
};
const RubyPlayground: React.FC = () => {
  const [selectedPrompt, setSelectedPrompt] = useState<any>({
    id: "",
    prompt: "",
  });
  const [formState, setFormState] = useState(initialForm);
  const [isLoading, setIsLoading] = useState(false);
  const [promptIndex, setPromptIndex] = useState(0);
  const [typedPrompt, setTypedPrompt] = useState("");
  const [responseLoading, setResponseLoading] = useState(false);
  const [response, setResponse] = useState<string>("");
  const [typedResponse, setTypedResponse] = useState<string>("");
  const [responseIndex, setResponseIndex] = useState(0);
  const [password, setPassword] = useState("");
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [showUnlockDialog, setShowUnlockDiaglog] = useState(false);
  const [scrollTouched, setScrollTouched] = useState(false);
  const textRef = React.useRef<any>(null);
  const dimensions = useWindowDimensions();
  useEffect(() => {
    if (promptIndex < selectedPrompt.prompt.length) {
      const timeout = setTimeout(() => {
        setTypedPrompt(
          (prevText) => prevText + selectedPrompt.prompt[promptIndex]
        );
        setPromptIndex((prevIndex) => prevIndex + 1);
      }, 50);

      return () => clearTimeout(timeout);
    }
  }, [promptIndex, selectedPrompt]);

  useEffect(() => {
    if (textRef.current && !scrollTouched) {
      textRef.current.scrollTop = textRef.current.scrollHeight;
    }
  }, [typedResponse]);

  useEffect(() => {
    if (responseIndex < response.length) {
      const timeout = setTimeout(() => {
        setTypedResponse((prevText) => prevText + response[responseIndex]);
        setResponseIndex((prevIndex) => prevIndex + 1);
      }, 25);

      return () => clearTimeout(timeout);
    }
  }, [responseIndex, response]);
  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    let newState = { ...formState };
    let { name } = e.target;
    const value =
      e.target.type == "checkbox" ? e.target.checked : e.target.value;
    _.set(newState, name, value);
    setFormState(newState);
  };
  const handlePromptSelected = (prompt: any) => {
    setResponse("");
    setTypedResponse("");
    setResponseIndex(0);
    setResponseLoading(true);
    var requestBody = { prompt: prompt.prompt, promptId: prompt.id };
    queryRagModelAiDemo(requestBody).then((res) => {
      setResponse(res.data.answer);
      setScrollTouched(false);
      setResponseLoading(false);
    });
    setTypedPrompt("");
    setPromptIndex(0);
    setSelectedPrompt(prompt);
  };

  const handleSubmitPrompt = () => {
    setResponse("");
    setTypedResponse("");
    setResponseIndex(0);
    setResponseLoading(true);
    var requestBody = { prompt: typedPrompt };
    queryRagModelAiDemo(requestBody).then((res) => {
      setResponse(res.data.answer);

      setResponseLoading(false);
    });
  };

  const handleUnlock = () => {
    if (!isUnlocked) {
      setShowUnlockDiaglog(true);
    } else {
      setIsUnlocked(false);
    }
  };

  const handlePasswordSubmit = () => {
    if (password == "rubyai") {
      setIsUnlocked(true);
      setShowUnlockDiaglog(false);
    }
  };
  return (
    <div className="ruby-container">
      <Container>
        <Card className="ruby-card  mt-2">
          <CardContent className="pb-1 pt-1" sx={{ overflowY: "auto" }}>
            <Grid>
              <Grid.Row>
                <div
                //onDoubleClick={handleUnlock}
                >
                  <Typography
                    variant={
                      dimensions.width && dimensions.width < 390
                        ? "body1"
                        : "h6"
                    }
                  >
                    <b>Ruby - Your Lynx AI Assistant</b>
                  </Typography>
                </div>
              </Grid.Row>
            </Grid>
          </CardContent>
        </Card>
        <Card className="ruby-card mb-5 mt-2">
          <CardContent sx={{ overflowY: "auto", maxHeight: "40vh" }}>
            <Grid>
              <Grid.Row className="mb-2">
                <Typography>
                  <b>Fox Hole and Raven’s Nest: AI-Powered Event Analysis</b>
                  <br></br>
                  Fox Hole and Raven’s Nest are fictional mining operations
                  designed to demonstrate Lynx’s AI-driven event tracking and
                  stakeholder engagement tools. Fox Hole represents a mid-tier
                  production mine dealing with a significant acid leachate
                  release, triggering community concerns, regulatory inquiries,
                  and operational adjustments. Raven’s Nest, an exploration
                  project near the Wahnapitae First Nation, highlights
                  early-stage challenges such as water access, road development,
                  and environmental monitoring. For these scenarios, Events
                  track community meetings and government interactions. Lynx AI
                  enables users to query event data, analyze stakeholder
                  concerns, and generate detailed reports tailored to
                  regulators, investors, and internal teams. The AI can
                  summarize community engagement trends, flag compliance risks,
                  and draft sections for sustainability reports in seconds.{" "}
                  <br></br>
                  <b>Try it now</b> -{" "}
                  <i>
                    select a prompt below to see how AI transforms raw data into
                    actionable insights.
                  </i>
                </Typography>
              </Grid.Row>
              <Grid.Row>
                <Dimmer active={isLoading} loader>
                  {prompts.map((prompt) => (
                    <Chip
                      sx={{
                        height: "auto",
                        "& .MuiChip-label": {
                          display: "block",
                          whiteSpace: "normal",
                          marginTop: "0.25rem",
                          marginBottom: "0.25rem",
                        },
                      }}
                      className="mr-2 mb-2"
                      label={prompt.prompt}
                      variant={
                        selectedPrompt.id == prompt.id ? "filled" : "outlined"
                      }
                      onClick={() => {
                        handlePromptSelected(prompt);
                      }}
                    />
                  ))}
                </Dimmer>
              </Grid.Row>
            </Grid>
          </CardContent>
        </Card>
        <Card className="ruby-card">
          <CardContent
            sx={{ overflowY: "auto", maxHeight: "50vh" }}
            ref={textRef}
          >
            <Grid>
              <Grid.Row>
                <Grid.Col className="p-0">
                  <Typography>
                    <b>Prompt</b>
                  </Typography>
                  <TextField
                    multiline
                    className="w-100"
                    name="prompt"
                    value={typedPrompt}
                  ></TextField>
                </Grid.Col>
              </Grid.Row>

              <Grid.Row className="mt-2 ">
                <Grid.Col className="p-0">
                  <Typography>
                    <b>Response</b>
                  </Typography>
                  <Dimmer active={responseLoading} loader>
                    <div className="w-100">
                      <TextField
                        className="w-100"
                        name="response"
                        multiline
                        value={typedResponse}
                      ></TextField>
                    </div>
                  </Dimmer>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      {showUnlockDialog && (
        <LynxDialog
          open={showUnlockDialog}
          title="Unlock"
          handleConfirm={handlePasswordSubmit}
          description={
            <Form.Group>
              <Form.Input
                name="password"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Enter password"
              ></Form.Input>
            </Form.Group>
          }
          handleClose={() => {
            setShowUnlockDiaglog(false);
          }}
        ></LynxDialog>
      )}
    </div>
  );
};

export default RubyPlayground;
