import { LynxDataGrid } from "components/data-grid/lynx-data-grid";
import { LynxGridColDef, OrganizationComplianceRegulationDto } from "types";
import useAlert from "hooks/useAlert";
import { ChangeEvent, useState } from "react";
import { EntityTypes } from "types/enums-ts";
import {
  useGetOrgComplianceAndRegulationsQuery,
  useUpdateOrgComplianceAndRegulationsMutation,
} from "services/rtkApi/endpoints/organizationComplianceRegulation";
import SingleSelect from "components/form-controls/single-select";
import { ComplianceApplicability } from "types/enums";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

const ComplianceList = () => {
  const { showAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);

  const applicabilityValues = Object.values(ComplianceApplicability);
  const [updateComplianceTrigger] =
    useUpdateOrgComplianceAndRegulationsMutation();

  const onChangeApplicability = (
    e: ChangeEvent<HTMLSelectElement>,
    row: OrganizationComplianceRegulationDto
  ) => {
    const payload = {
      ...row,
      applicability: e.target.value,
    };
    setIsLoading(true);
    updateComplianceTrigger(payload)
      .then(() => {
        showAlert("success", "Applicability updated.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns: LynxGridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 500,
      type: "string",
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      type: "string",
    },
    {
      field: "category",
      headerName: "Category",
      width: 300,
      type: "string",
    },
    {
      field: "subcategory",
      headerName: "Sub Category",
      width: 300,
      type: "string",
    },
    {
      field: "industry",
      headerName: "Industry",
      width: 150,
      type: "string",
    },
    {
      field: "jurisdiction",
      headerName: "Jurisdiction",
      width: 150,
      type: "string",
    },
    {
      field: "applicability",
      headerName: "Applicability",
      width: 230,
      sortable: false,
      filterable: false,
      renderCell: (
        params: GridRenderCellParams<OrganizationComplianceRegulationDto>
      ) => {
        return (
          <div>
            <SingleSelect
              disabled={isLoading}
              dropdownValues={applicabilityValues}
              defaultValue={params.value}
              name="applicability"
              onChange={(e: any) => onChangeApplicability(e, params.row)}
              id="id"
              labelFunction={(value: any) => {
                return `${value.id} - ${value.name}`;
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <LynxDataGrid
        columns={columns}
        useQuery={useGetOrgComplianceAndRegulationsQuery}
        entityType={EntityTypes.ComplianceAndRegulation}
        title="Compliance and Regulations"
      />
    </>
  );
};

export default ComplianceList;
