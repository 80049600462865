import {
  useInitializeUploadMutation,
  useUploadChunkMutation,
} from "services/rtkApi/endpoints/documents";

export const useUploadFileInChunks = () => {
  const [initializeUpload] = useInitializeUploadMutation();
  const [uploadChunk] = useUploadChunkMutation();

  const uploadFileInChunks = async (file, data, onProgress) => {
    const chunkSize = 5 * 1024 * 1024; // 5MB per chunk
    const totalChunks = Math.ceil(file.size / chunkSize);
    const dto = await initializeUpload(file.name).unwrap();

    for (let i = 0; i < totalChunks; i++) {
      const chunkBlob = file.slice(i * chunkSize, (i + 1) * chunkSize);
      const chunkFile = new File([chunkBlob], file.name, { type: file.type });

      await uploadChunk({
        file: chunkFile,
        index: i,
        totalChunks,
        fileName: file.name,
        filePath: dto.fileName,
        data: data,
      }).unwrap();

      // Calculate progress percentage and call the callback function
      if (onProgress) {
        onProgress(chunkSize);
      }
    }
  };

  return uploadFileInChunks;
};
