import React, { useState, useRef, useEffect } from "react";
import { Box, TextField } from "@mui/material";

interface TwoFactorInputProps {
  handleCodeEntered: (code: string) => void;
}

const TwoFactorInput: React.FC<TwoFactorInputProps> = (props) => {
  const [values, setValues] = useState<string[]>(["", "", "", "", "", ""]);
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
  useEffect(() => {
    if (values.every((val) => val !== "")) {
      props.handleCodeEntered(values.join(""));
    }
  }, [values]);
  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;

    if (!/^\d*$/.test(newValue)) return; // Allow only digits

    const newValues = [...values];
    newValues[index] = newValue.slice(-1); // Only take the last digit
    setValues(newValues);

    // Auto-focus to the next input
    if (newValue && index < 5) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.key === "Backspace") {
      if (!values[index] && index > 0) {
        // Move to the previous input on Backspace
        inputsRef.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const pasteData = event.clipboardData.getData("Text").slice(0, 6);
    if (/^\d{1,6}$/.test(pasteData)) {
      const newValues = pasteData.split("");
      setValues(newValues.concat(Array(6 - newValues.length).fill("")));
      if (newValues.length === 6) {
        inputsRef.current[5]?.focus();
      }
    }
    event.preventDefault();
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={1}
      onPaste={handlePaste}
    >
      {values.map((value, index) => (
        <TextField
          key={index}
          value={value}
          inputRef={(el) => (inputsRef.current[index] = el)}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          inputProps={{
            maxLength: 1,
            style: {
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "bold",
              padding: "10px",
              width: "50px",
            },
          }}
          variant="outlined"
        />
      ))}
    </Box>
  );
};

export default TwoFactorInput;
