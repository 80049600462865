import lynxColors from "../../../modules/lynxColors";
import _ from "lodash";
import { useGetAssetsLookupsQuery } from "services/rtkApi/endpoints/assets";
import { useGetLookupsQuery } from "services/rtkApi/endpoints/lookups";

import { LynxGridColDef } from "types";
import { LookupTypes } from "types/enums";
export const energyLogColumns = (
  predefinedLookups: any = null
): LynxGridColDef[] => [
  {
    field: "energyLogNumber",
    headerName: "Energy Log #",
    width: 100,
    type: "string",
  },
  {
    field: "assetName",
    headerName: "Asset Name",
    width: 200,
    type: "singleSelect",
    query: useGetAssetsLookupsQuery,
    getOptionValue: (value: any) => (!value ? "" : value.name),
    getOptionLabel: (value: any) => (!value ? "" : value.name),
  },
  {
    field: "energyLogType",
    headerName: "Energy Log Type",
    width: 200,
    type: "singleSelect",
    query: useGetLookupsQuery,
    queryParams: { lookupType: LookupTypes.EnergyLogType },
    getOptionValue: (value: any) => (!value ? "" : value.code),
    getOptionLabel: (value: any) => (!value ? "" : value.code),
  },
  {
    field: "energyLogDate",
    headerName: "Log Date",
    width: 200,
    type: "dateTime",
    filterable: false,
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    type: "singleSelect",
    valueOptions: ["Incomplete", "Complete"],
    renderCell: (params: any) => (
      <div className="energy-log-status">
        <i
          className={"fe fe-circle "}
          style={{ color: statusColorSwitch(params.row) }}
        />
        &nbsp;
        {params.value}
      </div>
    ),
  },
  {
    field: "scope1Total",
    headerName: "Scope 1 Total",
    width: 100,
    type: "number",
    filterable: false,
    sortable: false,
  },
  {
    field: "scope2Total",
    headerName: "Scope 2 Total",
    width: 100,
    type: "number",
    filterable: false,
    sortable: false,
  },
  {
    field: "scope3Total",
    headerName: "Scope 3 Total",
    width: 100,
    type: "number",
    filterable: false,
    sortable: false,
  },
  {
    field: "createdByFullName",
    headerName: "Created By",
    width: 150,
    type: "singleSelect",
    valueOptions: predefinedLookups.users,
    getOptionValue: (value: any) => (!value ? "" : value.fullName),
    getOptionLabel: (value: any) => (!value ? "" : value.fullName),
  },
  {
    field: "submittedByFullName",
    headerName: "Submitted By",
    width: 150,
    type: "singleSelect",
    valueOptions: predefinedLookups.users,
    getOptionValue: (value: any) => (!value ? "" : value.fullName),
    getOptionLabel: (value: any) => (!value ? "" : value.fullName),
  },
  {
    field: "reviewedByFullName",
    headerName: "Reviewed By",
    width: 150,
    type: "singleSelect",
    valueOptions: predefinedLookups.users,
    getOptionValue: (value: any) => (!value ? "" : value.fullName),
    getOptionLabel: (value: any) => (!value ? "" : value.fullName),
  },
];

export const statusColorSwitch = (row: any) => {
  switch (_.toLower(row.status)) {
    case "complete":
      return lynxColors.primary;
    case "incomplete":
      return lynxColors.error;
    case "pending review":
      return lynxColors.harvestOrange;
    default:
      return lynxColors.primary;
  }
};
