import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  Button,
  Chip,
} from "@mui/material";
import { chipClasses } from "@mui/material/Chip";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { LynxDialog } from "../../lynx-dialog";
import useAlert from "hooks/useAlert";
import { EventDto } from "types";
import {
  useLazySearchEventsQuery,
  useMergeEventCorrespondenceMutation,
} from "services/rtkApi/endpoints/events";

interface EventCorrespondenceMergeProps {
  event: EventDto;
  eventLocked?: boolean;
}

const EventCorrespondenceMerge: React.FC<EventCorrespondenceMergeProps> = ({
  event,
  eventLocked,
}) => {
  const [autoCompleteEventValue, setAutoCompleteEventValue] =
    useState<EventDto | null>(null);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<EventDto | null>(null);
  const [mergeWarning, setMergeWarning] = useState(false);
  const [autoCompleteEventInputValue, setAutoCompleteEventInputValue] =
    useState("");
  const [options, setOptions] = useState<EventDto[]>([]);
  const history = useHistory();
  const { showAlert } = useAlert();
  const [searchEventsTrigger] = useLazySearchEventsQuery();
  const [mergeEventCorrespondenceTrigger] =
    useMergeEventCorrespondenceMutation();
  useEffect(() => {
    if (autoCompleteEventInputValue === "") {
      setOptions(autoCompleteEventValue ? [autoCompleteEventValue] : []);
      return;
    }
    setEventsLoading(true);
    searchEventsTrigger({ search: autoCompleteEventInputValue })
      .then((res) => {
        if (res.data) {
          setOptions(res.data);
        }
      })
      .finally(() => setEventsLoading(false));
  }, [autoCompleteEventInputValue, autoCompleteEventValue]);

  const handleNavigateTo = (url: string) => {
    history.push(url);
  };

  const handleEventSelected = (
    _: React.ChangeEvent<{}>,
    value: EventDto | null
  ) => {
    setAutoCompleteEventValue(null);
    setSelectedEvent(value || null);
  };

  const autoCompleteEventInputChange = (
    _: React.ChangeEvent<{}>,
    newValue: string,
    reason: string
  ) => {
    if (reason === "reset") {
      setAutoCompleteEventValue(null);
      return;
    }
    setAutoCompleteEventInputValue(newValue);
  };

  const handleMergeCorrespondence = () => {
    if (selectedEvent) {
      mergeEventCorrespondenceTrigger({
        fromId: event.id,
        toId: selectedEvent.id,
      }).then(() => {
        showAlert("success", "Correspondence merged successfully.");
        handleNavigateTo(`/events/${selectedEvent.id}`);
      });
    }
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <Autocomplete
        filterOptions={(x) => x}
        loading={eventsLoading}
        id="event-search"
        options={options}
        noOptionsText={
          !autoCompleteEventInputValue ? "Enter text to search" : "No options"
        }
        getOptionLabel={(option) => `${option.assetNames} ${option.tagGroup}`}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Typography>{option.eventNumber}&nbsp;</Typography>(
            <Typography>{option.eventCategory}</Typography>
            {option.eventSubCategory && (
              <Typography>- {option.eventSubCategory}</Typography>
            )}
            )<Typography variant="body2">&nbsp;{option.description}</Typography>
          </Box>
        )}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Search events by event number, category, subcategory, or description"
          />
        )}
        onChange={handleEventSelected}
        value={autoCompleteEventValue}
        inputValue={autoCompleteEventInputValue}
        onInputChange={autoCompleteEventInputChange}
      />
      {!_.isEmpty(selectedEvent) && (
        <>
          <Chip
            label={`Merge correspondence from ${event.eventNumber} to ${selectedEvent?.eventNumber}`}
            variant="outlined"
            className="mt-2"
            sx={{
              [`& .${chipClasses.icon}`]: {
                color: "rgba(0, 0, 0, 0.26)",
              },
            }}
            {...(!eventLocked
              ? { onDelete: () => setSelectedEvent(null) }
              : {})}
          />
          <Button
            variant="outlined"
            onClick={() => setMergeWarning(true)}
            className="mt-2"
          >
            Merge Correspondence
          </Button>
        </>
      )}
      {mergeWarning && (
        <LynxDialog
          handleClose={() => setMergeWarning(false)}
          title="Are you sure?"
          open={mergeWarning}
          handleConfirm={handleMergeCorrespondence}
          description={`The correspondence from event ${event.eventNumber} will be merged into event ${selectedEvent?.eventNumber}, and ${selectedEvent?.eventNumber} will be deleted.`}
        />
      )}
    </div>
  );
};

export default EventCorrespondenceMerge;
