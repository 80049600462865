import { apiService } from "../apiService";

const BASE_ROUTE = "/reports";

const reportsEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getReports: builder.query<any, string>({
      query: (entityType) => `${BASE_ROUTE}?entityType=${entityType}`,
    }),
    runIncidentReport: builder.query<
      Blob,
      { reportId: number; incidentId: number }
    >({
      query: ({ reportId, incidentId }) => ({
        url: `${BASE_ROUTE}/${reportId}/incidents/${incidentId}`,
        responseType: "blob",
        responseHandler: (response: any) => response.blob(),
      }),
    }),
    runEventReport: builder.query<Blob, { reportId: number; eventId: number }>({
      query: ({ reportId, eventId }) => ({
        url: `${BASE_ROUTE}/${reportId}/events/${eventId}`,
        responseType: "blob",
        responseHandler: (response: any) => response.blob(),
      }),
    }),
    runIncidentsReport: builder.query<Blob, { reportId: number; params: any }>({
      query: ({ reportId, params }) => ({
        url: `${BASE_ROUTE}/${reportId}/incidents/`,
        params: params,
        responseHandler: (response: any) => response.blob(),
      }),
    }),
    runEventsReport: builder.query<Blob, { reportId: number; params: any }>({
      query: ({ reportId, params }) => ({
        url: `${BASE_ROUTE}/${reportId}/events/`,
        params: params,
        responseHandler: (response: any) => response.blob(),
      }),
    }),
  }),
});
export const {
  useGetReportsQuery,
  useLazyGetReportsQuery,
  useLazyRunIncidentReportQuery,
  useLazyRunEventReportQuery,
  useLazyRunIncidentsReportQuery,
  useLazyRunEventsReportQuery,
} = reportsEndpoint;
