import React, { useEffect, useState } from "react";
import "./dashboard-widgets.css";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { LookupTypes } from "../../../types/enums";
import { useLazyGetLookupsQuery } from "services/rtkApi/endpoints/lookups";

export function TimeFilterButton(props) {
  const [anchorElTime, setAnchorElTime] = React.useState(null);
  const openTime = Boolean(anchorElTime);

  const handleTimeClick = (event) => {
    setAnchorElTime(event.currentTarget);
  };
  const handleTimeSelected = (option) => {
    props.handleTimeSelected(option);
    setAnchorElTime(null);
  };

  const handleClose = () => {
    setAnchorElTime(null);
  };

  const options = [
    { value: "AllTime", label: "All time" },
    { value: "Last12Months", label: "Last 12 months" },
    { value: "Last6Months", label: "Last 6 months" },
    { value: "Last90Days", label: "Last 90 days" },
    { value: "Last60Days", label: "Last 60 days" },
    { value: "Last30Days", label: "Last 30 days" },
    { value: "Last14Days", label: "Last 14 days" },
    { value: "Last7Days", label: "Last 7 days" },
    { value: "ThisYear", label: "This year" },
    { value: "LastYear", label: "Last year" },
    { value: "ThisMonth", label: "This month" },
    { value: "LastMonth", label: "Last month" },
  ];

  return (
    <>
      <Button
        variant="outlined"
        id="basic-button"
        aria-controls={openTime ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openTime ? "true" : undefined}
        onClick={handleTimeClick}
        className="ml-2"
      >
        {options.find((x) => x.value === props.filterValue).label}
      </Button>
      <Menu
        id="event-time-menu"
        anchorEl={anchorElTime}
        open={openTime}
        onClose={handleClose}
        disableScrollLock={true}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => handleTimeSelected(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export function EventStatusButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleValueSelected = (option) => {
    props.handleValueSelected(option);
    setAnchorEl(null);
  };

  const options = [
    { value: "All", label: "All events" },
    { value: "Open", label: "Open events" },
    { value: "Closed", label: "Closed events" },
  ];

  return (
    <>
      <Button
        variant="outlined"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="ml-2"
      >
        {options.find((x) => x.value === props.filterValue).label}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => handleValueSelected(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export function ComplianceTypeButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [complianceTypes, setComplianceTypes] = useState([]);
  const [getLookupsTrigger] = useLazyGetLookupsQuery();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTypeSelected = (code) => {
    props.handleComplianceTypeSelected(code);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.handleComplianceTypeSelected) {
      getLookupsTrigger({ lookupType: LookupTypes.TagGroup }).then((res) => {
        if (res.data) {
          setComplianceTypes(res.data);
        }
      });
    }
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="ml-2"
      >
        {props.filterValue === "All" ? "All Groups" : props.filterValue}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleTypeSelected("All")}>
          All Groups
        </MenuItem>
        {complianceTypes.map((type) => (
          <MenuItem
            key={type.code}
            onClick={() => handleTypeSelected(type.code)}
          >
            {type.code}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export function ComplianceCategoryButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [complianceCategories, setComplianceCategories] = useState([]);
  const [getLookupsTrigger] = useLazyGetLookupsQuery();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTypeSelected = (code) => {
    props.handleComplianceCategorySelected(code);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.handleComplianceCategorySelected) {
      getLookupsTrigger({ lookupType: LookupTypes.TagCategory }).then((res) => {
        if (res.data) {
          setComplianceCategories(res.data);
        }
      });
    }
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="ml-2"
      >
        {props.filterValue === "All" ? "All Categories" : props.filterValue}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleTypeSelected("All")}>
          All Categories
        </MenuItem>
        {complianceCategories.map((type) => (
          <MenuItem
            key={type.code}
            onClick={() => handleTypeSelected(type.code)}
          >
            {type.code}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export function EmissionsScopeBoutton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [complianceTypes, setComplianceTypes] = useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTypeSelected = (code) => {
    props.handleEmissionsScopeSelected(code);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="outlined"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="ml-2"
      >
        {props.filterValue}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleTypeSelected("All")}>All</MenuItem>
        <MenuItem onClick={() => handleTypeSelected("Scope 1")}>
          Scope 1
        </MenuItem>
        <MenuItem onClick={() => handleTypeSelected("Scope 2")}>
          Scope 2
        </MenuItem>
        <MenuItem onClick={() => handleTypeSelected("Scope 3")}>
          Scope 3
        </MenuItem>
      </Menu>
    </>
  );
}
