import React, { useState, FC } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import { LookupTypes, UserRoles } from "../../../types/enums";
import { EntityTypes } from "types/enums-ts";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import { CorrespondenceLogModal } from "../correspondence-log/correspondence-log-modal";
import { dateUtil } from "../../../services/date-util";
import EditIcon from "@mui/icons-material/Edit";
import { LynxDialog } from "../../lynx-dialog";
import { Grid } from "components/lynx-components";
import { roleMatch } from "actions/auth";
import { IconButton } from "@mui/material";
import { CorrespondenceLogDto, EventDto, LynxGridColDef } from "types";
import { CorrespondenceContactChip } from "../correspondence-log/correspondence-contact-chip";
import _ from "lodash";
import { useGetEntityCorrespondenceLogsQuery } from "services/rtkApi/endpoints/correspondenceLogs";
import { useGetLookupsQuery } from "services/rtkApi/endpoints/lookups";

interface EventDetailsCorrespondenceLogProps {
  event: EventDto;
  isEventForm?: boolean;
  noHeader?: boolean;
  eventLocked?: boolean;
}

const EventDetailsCorrespondenceLog: FC<EventDetailsCorrespondenceLogProps> = (
  props
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showCorrespondenceModal, setShowCorrespondenceModal] = useState(false);
  const [selectedCorrespondenceLog, setSelectedCorrespondenceLog] =
    useState<CorrespondenceLogDto | null>(null);

  const [corLogForEdit, setCorLogForEdit] =
    useState<CorrespondenceLogDto | null>(null);

  const handleCorView = (row: CorrespondenceLogDto) => {
    setSelectedCorrespondenceLog(row);
  };

  const handleAddCorrespondenceLog = () => {
    setIsLoading(true);
    setShowCorrespondenceModal(true);
  };

  const handleEditCorrespondenceLog = (log: CorrespondenceLogDto) => {
    setIsLoading(true);
    setCorLogForEdit(log);
    setShowCorrespondenceModal(true);
  };

  const userRoleCanSave = roleMatch([
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
    UserRoles.EventsContributor,
  ]);

  const correspondenceLogColumns: LynxGridColDef[] = [
    {
      field: "actions",
      headerName: `View ${userRoleCanSave ? "/ Edit" : ""}`,
      width: 95,
      type: "string",
      sortable: false,
      filterable: false,
      renderCell: (params: { row: CorrespondenceLogDto }) => (
        <>
          <IconButton onClick={() => handleCorView(params.row)}>
            <VisibilityIcon />
          </IconButton>
          {userRoleCanSave && (
            <IconButton onClick={() => handleEditCorrespondenceLog(params.row)}>
              <EditIcon />
            </IconButton>
          )}
        </>
      ),
    },
    {
      field: "correspondenceDateTimeUtc",
      headerName: "Date Time",
      width: props.isEventForm ? 140 : 110,
      type: "dateTime",
      valueGetter: (value: any) => dateUtil.convertColumnDateTimeToLocal(value),
    },
    {
      field: "correspondenceTypeCode",
      headerName: "Type",
      width: 75,
      type: "singleSelect",
      query: useGetLookupsQuery,
      queryParams: { lookupType: LookupTypes.CorrespondenceLogType },
      getOptionValue: (value: any) => (!value ? "" : value.code),
      getOptionLabel: (value: any) => (!value ? "" : value.code),
    },
    {
      field: "subject",
      headerName: "Subject",
      width: props.isEventForm ? 220 : 90,
      type: "string",
    },
    { field: "details", headerName: "Details", flex: 1, type: "string" },
  ];

  const getCorLogDetails = () => {
    const log = selectedCorrespondenceLog?.emailIntegrationLog;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Col width={12} className="">
            <Typography variant="h6" component={"span"} className="mr-2">
              Correspondence Log Details
            </Typography>
          </Grid.Col>
          <Grid.Col width={12}>
            <Typography component={"span"} className="mr-2">
              <b>Date Time:</b>
            </Typography>
            <Typography component={"span"}>
              {dateUtil.convertDateTimeToLocal(
                selectedCorrespondenceLog?.correspondenceDateTimeUtc
              )}
            </Typography>
          </Grid.Col>
          <Grid.Col width={12}>
            <Typography component={"span"} className="mr-2">
              <b>Type:</b>
            </Typography>
            <Typography component={"span"}>
              {selectedCorrespondenceLog?.correspondenceTypeCode}
            </Typography>
          </Grid.Col>
          <Grid.Col width={12}>
            <Typography component={"span"} className="mr-2">
              <b>Subject:</b>
            </Typography>
            <Typography component={"span"}>
              {selectedCorrespondenceLog?.subject}
            </Typography>
          </Grid.Col>
          <Grid.Col width={12}>
            <Typography component={"span"} className="mr-2">
              <b>Details:</b>
            </Typography>
            <Typography component={"span"}>
              {selectedCorrespondenceLog?.details}
            </Typography>
          </Grid.Col>
          <Grid.Col width={12} className="mt-2">
            <Typography component={"span"} className="mr-2">
              <b>From:</b>
            </Typography>
            <Typography component={"span"}>
              {selectedCorrespondenceLog?.fromDetails}
            </Typography>
            {_.sortBy(
              selectedCorrespondenceLog?.correspondenceLogContacts?.filter(
                (x) => x.isFrom
              ),
              [
                (contact) =>
                  contact.userId
                    ? contact.userFullName?.toLowerCase()
                    : contact.contactId
                    ? contact.contactFullName?.toLowerCase()
                    : contact.contactGroupId
                    ? contact.contactGroupName?.toLowerCase()
                    : "",
              ]
            ).map((contact, i) => (
              <CorrespondenceContactChip
                onDelete={null}
                contact={contact}
                className="ml-2"
              />
            ))}
          </Grid.Col>
          <Grid.Col width={12} className="mt-2">
            <Typography component={"span"} className="mr-2">
              <b>To:</b>
            </Typography>
            <Typography component={"span"}>
              {selectedCorrespondenceLog?.toDetails}
            </Typography>
            {_.sortBy(
              selectedCorrespondenceLog?.correspondenceLogContacts?.filter(
                (x) => x.isTo
              ),
              [
                (contact) =>
                  contact.userId
                    ? contact.userFullName?.toLowerCase()
                    : contact.contactId
                    ? contact.contactFullName?.toLowerCase()
                    : contact.contactGroupId
                    ? contact.contactGroupName?.toLowerCase()
                    : "",
              ]
            ).map((contact, i) => (
              <CorrespondenceContactChip
                onDelete={null}
                contact={contact}
                className="ml-2"
              />
            ))}
          </Grid.Col>
          {log && !_.isEmpty(log) && (
            <>
              <Grid.Col width={12} className="mt-2 ">
                <Typography variant="h6" component={"span"} className="mr-2">
                  Forwarded Email Details
                </Typography>
              </Grid.Col>{" "}
              <Grid.Col width={12}>
                <Typography component={"span"} className="mr-2">
                  <b>From:</b>
                </Typography>
                <Typography component={"span"}>{log.from}</Typography>
              </Grid.Col>{" "}
              <Grid.Col width={12}>
                <Typography component={"span"} className="mr-2">
                  <b>To:</b>
                </Typography>
                <Typography component={"span"}>{log.toRecipients}</Typography>
              </Grid.Col>
              <Grid.Col width={12}>
                <Typography component={"span"} className="mr-2">
                  <b>Recieved:</b>
                </Typography>
                <Typography component={"span"}>
                  {dateUtil.convertDateTimeToLocal(log.recievedDateTime)}
                </Typography>
              </Grid.Col>
              <Grid.Col width={12}>
                <Typography component={"span"} className="mr-2">
                  <b>Subject:</b>
                </Typography>
                <Typography component={"span"}>{log.subject}</Typography>
              </Grid.Col>
              <Grid.Col width={12}>
                <Typography component={"span"} className="mr-2">
                  <b>Body:</b>
                </Typography>
                <div className="email-body-container">
                  <Typography
                    component={"span"}
                    dangerouslySetInnerHTML={{ __html: log.body as string }}
                  ></Typography>
                </div>
              </Grid.Col>
            </>
          )}
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <Card className="lynx-card" variant="outlined" sx={{ width: "100%" }}>
      {!props.noHeader && (
        <CardHeader
          className="lynx-card-header"
          title="Correspondence Log"
          action={
            userRoleCanSave && (
              <Button onClick={handleAddCorrespondenceLog}>
                Add Correspondence
              </Button>
            )
          }
        />
      )}

      <CardContent
        sx={{ padding: 0, paddingBottom: "0 !important", minHeight: "50px" }}
      >
        {props.event.id && !_.isEmpty(correspondenceLogColumns) && (
          <LynxDataGrid
            autoHeight
            parentContainerProps={{ minHeight: 200, maxHeight: 700 }}
            disableCardBorder
            localStorageName={"event-correspondence-log_" + props.event.id}
            columns={correspondenceLogColumns}
            useQuery={useGetEntityCorrespondenceLogsQuery}
            useQueryParams={{
              entityType: EntityTypes.Event,
              entityId: props.event.id,
            }}
            onlyShowTable
            entityType={EntityTypes.CorrespondenceLog}
          />
        )}
      </CardContent>

      {selectedCorrespondenceLog && (
        <LynxDialog
          closeOnBackdropClick
          isCloseInHeader
          handleClose={() => setSelectedCorrespondenceLog(null)}
          maxWidth="xl"
          open={!!selectedCorrespondenceLog}
          dialogContent={getCorLogDetails()}
        />
      )}

      {showCorrespondenceModal && (
        <CorrespondenceLogModal
          event={props.event}
          correspondenceLog={corLogForEdit ?? undefined}
          open={showCorrespondenceModal}
          eventLocked={props.eventLocked ?? false}
          handleClose={() => {
            setShowCorrespondenceModal(false);
            setCorLogForEdit(null);
            setIsLoading(false);
          }}
        />
      )}
    </Card>
  );
};

export default EventDetailsCorrespondenceLog;
