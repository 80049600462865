import { apiUrl, client } from "../modules/store";

export function getMonitoringEvents(params) { // used in offline mode
  return client.get(apiUrl + "/monitoringEvents", {
    params: params,
  });
}

export function createMonitoringEvent(form) {// used in offline mode
  return client.post(apiUrl + "/monitoringEvents", form);
}
