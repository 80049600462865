import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import SingleSelect from "components/form-controls/single-select";
import React, { useEffect, useState } from "react";
import { Form } from "components/lynx-components";
import moment from "moment";
import MultipleDatesPicker from "@ambiot/material-ui-multiple-dates-picker";

interface DayOfWeek {
  day: string;
  isSelected: boolean;
}

interface InspectionSchedulerDatesProps {
  errors: Record<string, any>;
  touched: Record<string, any>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  values: Record<string, any>;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const InspectionSchedulerDates: React.FC<InspectionSchedulerDatesProps> = ({
  errors,
  touched,
  handleChange,
  values,
  handleBlur,
  setFieldValue,
}) => {
  const initialDaysOfWeek: DayOfWeek[] = [
    { day: "Monday", isSelected: false },
    { day: "Tuesday", isSelected: false },
    { day: "Wednesday", isSelected: false },
    { day: "Thursday", isSelected: false },
    { day: "Friday", isSelected: false },
    { day: "Saturday", isSelected: false },
    { day: "Sunday", isSelected: false },
  ];

  const [customDateOpen, setCustomDateOpen] = useState(false);
  const repeatCount = Array.from({ length: 99 }, (_, index) => index + 1);
  const repeatGranularity = ["day", "week", "month", "year", "custom"];
  const [daysOfWeek, setDaysOfWeek] = useState<DayOfWeek[]>(initialDaysOfWeek);

  const isCustom = values.repeatEveryGranularity === "custom";
  const isMonthly = values.repeatEveryGranularity === "month";
  const isYearly = values.repeatEveryGranularity === "year";
  const isWeekly = values.repeatEveryGranularity === "week";

  const onClickDaysOfWeek = (day: DayOfWeek) => {
    const temp = [...daysOfWeek];
    const index = temp.indexOf(day);
    temp[index] = { ...day, isSelected: !day.isSelected };
    setDaysOfWeek(temp);
    setFieldValue(
      "daysOfWeek",
      temp.filter((f) => f.isSelected)?.map((m) => m.day) || []
    );
  };

  const getDailyDates = (startDate: Date, endDate: Date) => {
    const dates = [];
    const currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const disabledDates = [
    ...getDailyDates(
      moment().subtract(100, "year").toDate(),
      moment().subtract(1, "day").toDate()
    ),
    ...getDailyDates(
      moment().add(5, "year").toDate(),
      moment().add(100, "year").toDate()
    ),
  ];

  useEffect(() => {
    if (values.repeatEveryGranularity === "custom") {
      setCustomDateOpen(true);
    }
    return () => {
      setDaysOfWeek(initialDaysOfWeek);
      setFieldValue("daysOfWeek", []);
      setFieldValue("customDates", []);
    };
  }, [values.repeatEveryGranularity]);

  return (
    <Box minHeight={320}>
      {!isCustom && (
        <Box mb={3}>
          <Form.Group label="Start date" isRequired>
            <Form.Input
              type="date"
              value={values.startDate}
              name="startDate"
              onChange={handleChange}
              error={
                !!errors.startDate && touched.startDate && errors.startDate
              }
              onBlur={handleBlur}
              min={moment().format("YYYY-MM-DD")}
              max={moment().add(5, "years").format("YYYY-MM-DD")}
            />
          </Form.Group>
        </Box>
      )}
      <Stack direction="row" alignItems="center" spacing={1} mb={3}>
        <Typography>Repeat every</Typography>
        {!["day", "week", "custom"].includes(values.repeatEveryGranularity) && (
          <SingleSelect
            dropdownValues={repeatCount}
            value={values.repeatEveryCount}
            name="repeatEveryCount"
            onChange={handleChange}
          />
        )}

        <SingleSelect
          dropdownValues={repeatGranularity}
          value={values.repeatEveryGranularity}
          name="repeatEveryGranularity"
          onChange={handleChange}
          isSorted={false}
        />
        {isCustom && (
          <Button variant="text" onClick={() => setCustomDateOpen(true)}>
            Select Dates
          </Button>
        )}

        {isMonthly && values.startDate && (
          <Typography>
            {`on the ${moment(values.startDate).format("Do")} day of the ${
              values.repeatEveryGranularity
            }`}
          </Typography>
        )}
        {isYearly && values.startDate && (
          <Typography>
            {`on the ${moment(values.startDate).format("Do")} day of ${moment(
              values.startDate
            ).format("MMMM")}`}
          </Typography>
        )}
      </Stack>
      <Box mb={2}>
        {values.customDates.length > 0 &&
          values.customDates.map((m: { value: string }) => (
            <Chip
              key={m.value}
              label={moment(m.value).format("ll")}
              variant="outlined"
              sx={{ marginRight: 1, marginBottom: 1 }}
            />
          ))}
      </Box>

      {isWeekly && (
        <Stack direction="row" spacing={2} alignItems="center" mb={3}>
          {daysOfWeek.map((d) => (
            <Button
              key={d.day}
              color={d.isSelected ? "primary" : "inherit"}
              variant={d.isSelected ? "contained" : "outlined"}
              sx={{ borderRadius: 10, minWidth: 37, px: 0 }}
              onClick={() => onClickDaysOfWeek(d)}
            >
              {d.day[0]}
            </Button>
          ))}
        </Stack>
      )}
      {!isCustom && (
        <Box mb={3}>
          <Form.Group label="End date" isRequired>
            <Form.Input
              type="date"
              value={values.endDate}
              name="endDate"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.endDate && touched.endDate && errors.endDate}
              min={moment(values.startDate).format("YYYY-MM-DD")}
              max={moment(values.startDate)
                .add(5, "years")
                .format("YYYY-MM-DD")}
            />
          </Form.Group>
        </Box>
      )}

      <MultipleDatesPicker
        open={customDateOpen}
        selectedDates={values.customDates.map(
          (m: { value: string }) => new Date(m.value)
        )}
        onCancel={() => setCustomDateOpen(false)}
        onSubmit={(dates: any) => {
          setFieldValue(
            "customDates",
            dates.map((m: any) => ({ value: moment(m).format("YYYY-MM-DD") }))
          );
          setCustomDateOpen(false);
        }}
        disabledDates={disabledDates}
      />
    </Box>
  );
};

export default InspectionSchedulerDates;
