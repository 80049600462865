import * as React from "react";
import _ from "lodash";
import lynxColors from "../../../modules/lynxColors";
import {
  multipleSelectContainsOperator,
  multipleSelectDoesNotContainOperator,
} from "./../../data-grid/multiple-select-operator";
import { dateUtil } from "../../../services/date-util";
import { LynxGridColDef, UserDto } from "types";
import { IncidentStatus, LookupTypes } from "types/enums";
import { GridFilterOperator } from "@mui/x-data-grid-pro";
import { useGetLookupsQuery } from "services/rtkApi/endpoints/lookups";
import { useGetAssetsLookupsQuery } from "services/rtkApi/endpoints/assets";

interface PredefinedLookups {
  users: Array<UserDto>;
}

export const incidentColumns = (
  predefinedLookups: PredefinedLookups | null = null
): LynxGridColDef[] => [
  {
    field: "incidentNumber",
    headerName: "Incident #",
    width: 100,
    type: "string",
    headerClassName: "incident-number-header",
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    type: "singleSelect",
    headerClassName: "incident-status-header",
    valueOptions: [
      "Pending Review",
      "Investigation",
      "Final Review",
      "Pending Actions",
      "Closed",
    ],
    renderCell: (params) => (
      <div className="incident-status">
        <i
          className={"fe fe-circle"}
          style={{ color: statusColorSwitch(params.row.status) }}
        />
        &nbsp;
        {params.value}
      </div>
    ),
  },
  {
    field: "incidentDateTime",
    headerName: "Incident Date Time",
    width: 200,
    type: "dateTime",
    valueGetter: (value: any) => dateUtil.convertColumnDateTimeToLocal(value),
  },
  {
    field: "title",
    headerName: "Title",
    width: 200,
    type: "string",
  },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    type: "string",
  },
  {
    field: "severity",
    headerName: "Severity",
    width: 100,
    type: "singleSelect",
    query: useGetLookupsQuery,
    queryParams: { lookupType: LookupTypes.IncidentSeverity },
    getOptionValue: (value: any) => (!value ? "" : value.code),
    getOptionLabel: (value: any) => (!value ? "" : value.code),
    renderCell: (params) => (
      <div className="incident-severity">
        <i
          className={"fe fe-circle"}
          style={{ color: severityColorSwitch(params.row.severity) }}
        />
        &nbsp;
        {params.value}
      </div>
    ),
  },
  {
    field: "type",
    headerName: "Type",
    width: 150,
    type: "singleSelect",
    query: useGetLookupsQuery,
    queryParams: { lookupType: LookupTypes.IncidentType },
    getOptionValue: (value: any) => (!value ? "" : value.code),
    getOptionLabel: (value: any) => (!value ? "" : value.code),
  },
  {
    field: "subType",
    headerName: "Subtype",
    width: 200,
    type: "singleSelect",
    query: useGetLookupsQuery,
    queryParams: { lookupType: LookupTypes.IncidentSubType },
    getOptionValue: (value: any) => (!value ? "" : value.code),
    getOptionLabel: (value: any) => (!value ? "" : value.code),
  },
  {
    field: "assetName",
    headerName: "Asset Name",
    width: 150,
    type: "singleSelect",
    query: useGetAssetsLookupsQuery,
    getOptionValue: (value: any) => (!value ? "" : value.name),
    getOptionLabel: (value: any) => (!value ? "" : value.name),
  },
  {
    field: "departmentName",
    headerName: "Department",
    width: 200,
    type: "string",
  },
  {
    field: "immediateActions",
    headerName: "Immediate Actions Taken",
    width: 200,
    type: "string",
  },
  {
    field: "contributingFactors",
    headerName: "Contributing Factors",
    width: 200,
    type: "string",
  },
  {
    field: "investigationRequired",
    headerName: "Investigation Required?",
    width: 150,
    type: "boolean",
  },
  {
    field: "investigationLeadUserFullName",
    headerName: "Investigation Lead",
    width: 200,
    type: "singleSelect",
    valueOptions: predefinedLookups?.users,
    getOptionValue: (value: any) => (!value ? "" : value.fullName),
    getOptionLabel: (value: any) => (!value ? "" : value.fullName),
  },
  {
    field: "affectedPartiesContactTypes",
    headerName: "Affected Party Types",
    width: 150,
    type: "singleSelect",
    query: useGetLookupsQuery,
    queryParams: { lookupType: LookupTypes.IncidentParticipantType },
    getOptionValue: (value: any) => (!value ? "" : value.code),
    getOptionLabel: (value: any) => (!value ? "" : value.code),
    importable: true,
    renderCell: (params: any) => <div>{params.value}</div>,
    filterOperators: [
      ...multipleSelectContainsOperator,
      ...multipleSelectDoesNotContainOperator,
    ] as GridFilterOperator<any, any, any>[],
    sortable: false,
  },
];

export const statusColorSwitch = (status: string): string => {
  switch (_.toLower(status)) {
    case IncidentStatus.PendingReview:
    case IncidentStatus.FinalReview:
    case IncidentStatus.PendingActions:
      return lynxColors.harvestOrange;
    default:
      return lynxColors.primary;
  }
};

export const severityColorSwitch = (severity: string): string => {
  switch (_.toLower(severity)) {
    case "low":
      return lynxColors.success;
    case "medium":
      return lynxColors.warning;
    case "high":
      return lynxColors.error;
    default:
      return lynxColors.success;
  }
};
