export const ORG_LOAD = "ORG_LOAD";
export const ORG_LOAD_SUCCESS = "ORG_LOAD_SUCCESS";
export const ORG_LOAD_FAIL = "ORG_LOAD_FAIL";
export function loadUserOrg() {
  return {
    type: ORG_LOAD,
    payload: {
      request: {
        url: "/organizations/user",
      },
    },
  };
}
