import Chip from "@mui/material/Chip";
import React, { FC, useState } from "react";
import { DocumentSource } from "types";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { navigateTo } from "services/navigation-service";
import { useHistory } from "react-router-dom";
type AiSourcesChipProps = {
  source: DocumentSource;
  className?: string;
};
const DocumentSourceChip: FC<AiSourcesChipProps> = ({ source, className }) => {
  const history = useHistory();
  const handleSourceClick = (source: DocumentSource) => {
    if (source.type == "document") {
      navigateTo(history, `/documents/${source.lynxAttachmentId}`);
    } else if (source.eventId) {
      navigateTo(history, `/events/${source.eventId}`);
    }
  };

  return (
    <Chip
      className={className}
      icon={
        source.type == "document" || source.type == "attachment" ? (
          <InsertDriveFileIcon />
        ) : (
          <EventOutlinedIcon />
        )
      }
      label={source.entityNumber}
      variant="outlined"
      onClick={() => {
        handleSourceClick(source);
      }}
    />
  );
};

export default DocumentSourceChip;
