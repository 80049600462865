import { RtkTagTypes } from "types/enums";
import queryString from "query-string";

const { apiService } = require("../apiService");

const CUSTOMERS_ROUTE = "/customers";

const customerEndpoints = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: (params) => `${CUSTOMERS_ROUTE}?${queryString.stringify(params)}`,
      providesTags: [RtkTagTypes.GetCustomers],
      transformResponse: (response, meta) => {
        const pagination = meta.response.headers.get("Pagination");
        return { data: response, pagination };
      },
    }),

    getCustomer: builder.query({
      query: (args) => `${CUSTOMERS_ROUTE}`,
      providesTags: (result, error, id) => [
        { type: RtkTagTypes.GetCustomer, id },
      ],
    }),
    addCustomer: builder.mutation({
      query: (args) => ({
        url: CUSTOMERS_ROUTE,
        method: "POST",
        body: args,
      }),
      invalidatesTags: (result, error, { id }) => [
        RtkTagTypes.GetCustomers,
        { type: RtkTagTypes.GetCustomer, id },
      ],
    }),

    updateCustomer: builder.mutation({
      query: (args) => ({
        url: `${CUSTOMERS_ROUTE}`,
        method: "PATCH",
        body: args,
      }),
      invalidatesTags: (result, error, { id }) => [
        RtkTagTypes.GetCustomers,
        { type: RtkTagTypes.GetCustomer, id },
      ],
    }),

    deleteCustomer: builder.mutation({
      query: (id) => ({
        url: `${CUSTOMERS_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetCustomers],
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useLazyGetCustomerQuery,

  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
} = customerEndpoints;
