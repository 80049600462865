import { apiUrl, client } from "../modules/store";
import queryString from "query-string";
export function acceptPrivacyPolicy(user) {
  return client.post(apiUrl + "/account/accept", {});
}

export function checkPortalStatus(email, url) {
  var object = { email: email, url: url };
  var qs = queryString.stringify(object);
  return client.get(`${apiUrl}/auth/portalCheck?${qs}`);
}

export function deactivateUser() {
  return client.get(`${apiUrl}/auth/deactivate`);
}

export function checkTwoFactorStatus() {
  return client.get(`${apiUrl}/auth/two-factor-status`, {
    withCredentials: true,
  });
}

export function checkOrganizationSelectionStatus() {
  return client.get(`${apiUrl}/auth/organization-selection-status`, {
    withCredentials: true,
  });
}

export function authorizeTwoFactor(code) {
  return client.post(
    `${apiUrl}/auth/two-factor?code=${code}`,
    {},
    {
      withCredentials: true,
    }
  );
}
export function resendTwoFactorCode() {
  return client.get(`${apiUrl}/auth/resend-code`, {
    withCredentials: true,
  });
}
