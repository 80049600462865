import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { Card, Form, Grid } from "components/lynx-components";
import _ from "lodash";
import { useEffect, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { roleMatch } from "actions/auth";
import useAlert from "hooks/useAlert";
import {
  useCreateMonitoringLimitMutation,
  useDeleteMonitoringLimitMutation,
  useGetMonitoringLimitsQuery,
  useUpdateMonitoringLimitMutation,
} from "services/rtkApi/endpoints/monitoringLimits";

import { validationService } from "../../../services/validation";
import { LookupTypes, UserRoles } from "../../../types/enums";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import { SearchableSelect } from "../../form-controls/searchable-select";
import { LynxDialog } from "../../lynx-dialog";
import { monitoringLimitColumns } from "../settings/monitoring/monitoring-limit-columns";
import { useHistory, useParams } from "react-router-dom";
import { useLazyGetLookupsQuery } from "services/rtkApi/endpoints/lookups";
import { useGetMonitoringLocationQuery } from "services/rtkApi/endpoints/monitoringLocations";
import { useGetParametersQuery } from "services/rtkApi/endpoints/parameters";

export function MonitoringLimits(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [units, setUnits] = useState([]);
  const [columns, setColumns] = useState([]);
  const [limitTypes, setLimitTypes] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [limitFormState, setLimitFormState] = useState({});
  const [limitToDelete, setLimitToDelete] = useState({});
  const [updateTrigger] = useUpdateMonitoringLimitMutation();
  const [createTrigger] = useCreateMonitoringLimitMutation();
  const [deleteTrigger] = useDeleteMonitoringLimitMutation();
  const { showAlert } = useAlert();
  const params = useParams();
  const history = useHistory();
  const [getLookupsTrigger] = useLazyGetLookupsQuery();
  const { data: monitoringLocation } = useGetMonitoringLocationQuery(
    params.monLocId,
    { skip: !params.monLocId, refetchOnMountOrArgChange: true }
  );

  const { data: parameters } = useGetParametersQuery();

  const initialLimitFormState = {
    id: 0,
    parameterId: "",
    parameterIdError: "",
    value: "",
    unitId: "",
    unitIdError: "",
    monitoringLimitTypeId: "",
    monitoringLimitTypeIdError: "",
    monitoringLocationId: params.monLocId,
  };
  useEffect(() => {
    loadLookups();
  }, []);
  useEffect(() => {
    let newColumns = [...monitoringLimitColumns];
    let colIndex = monitoringLimitColumns.findIndex((x) => x.field == "edit");
    if (roleMatch([UserRoles.MonitoringViewer])) {
      newColumns.splice(colIndex, 1);
    } else {
      newColumns[colIndex].renderCell = (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditLimit(params.row);
                }}
                aria-label="Notifications"
                size="small"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {roleMatch([UserRoles.MonitoringAdministrator]) && (
              <Tooltip title="Remove" className="ml-1">
                <IconButton
                  onClick={() => {
                    handleDeleteLimit(params.row);
                  }}
                  aria-label="Remove"
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      };
    }

    setColumns(newColumns);
  }, [monitoringLimitColumns]);

  const handleDeleteLimit = (result) => {
    setLimitToDelete(result);
    setShowDelete(true);
  };
  const handleEditLimit = (limit) => {
    let newLimitFormState = {
      id: limit.id,
      parameterId: limit.parameterId,
      parameterIdError: "",
      value: limit.value,
      unitId: limit.unitId,
      unitIdError: "",
      monitoringLimitTypeId: limit.monitoringLimitTypeId,
      monitoringLimitTypeIdError: "",
    };
    setLimitFormState(newLimitFormState);
    setShowEdit(true);
  };
  const loadLookups = () => {
    getLookupsTrigger({ lookupType: LookupTypes.Unit }).then((res) => {
      setUnits(res.data);
    });
    getLookupsTrigger({ lookupType: LookupTypes.MonitoringLimitType }).then(
      (res) => {
        setLimitTypes(res.data);
      }
    );
  };

  const handleNavigateTo = (e, action) => {
    e.preventDefault();
    if (action == "monitoring") {
      history.push("/monitoring?view=locations");
    }
  };

  const setDefaultUnit = (paramId, newState) => {
    let param = parameters?.find((x) => x.id == paramId);
    var defaultUnit = units.find((x) => x.id == param.defaultUnitId);
    if (defaultUnit) {
      newState.unitId = defaultUnit.id;
    }
  };

  const handleLimitInputChange = (e) => {
    let newState = { ...limitFormState };
    let name = e.target.name;
    let value = e.target.value;
    _.set(newState, name, value);
    setLimitFormState(newState);
  };

  const handleSaveLimit = () => {
    if (!validateLimitDataForSave()) {
      return;
    }
    setIsLoading(true);
    let formToSave = { ...limitFormState };
    formToSave = validationService.unsetErrors(
      formToSave,
      "parameterIdError",
      "monitoringLimitTypeIdError",
      "unitIdError"
    );
    if (formToSave.id > 0) {
      updateTrigger({ id: formToSave.id, dto: formToSave }).then((res) => {
        showAlert("success", "Limit saved.");
        setIsLoading(false);
        setShowEdit(false);
        setLimitFormState(initialLimitFormState);
      });
    } else {
      createTrigger(formToSave).then((res) => {
        showAlert("success", "Limit saved.");
        setIsLoading(false);
        setShowEdit(false);
        setLimitFormState(initialLimitFormState);
      });
    }
  };

  const validateLimitDataForSave = () => {
    let newState = { ...limitFormState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "parameterId",
      "parameterIdError",
      "Parameter"
    );

    validationService.validateRequiredField(
      newState,
      "monitoringLimitTypeId",
      "monitoringLimitTypeIdError",
      "Type"
    );
    validationService.validateRequiredField(
      newState,
      "unitId",
      "unitIdError",
      "Unit"
    );

    isFormValid = !validationService.hasError(
      newState,
      "parameterIdError",
      "monitoringLimitTypeIdError",
      "unitIdError"
    );
    if (!isFormValid) {
      setLimitFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleAddClick = () => {
    setLimitFormState(initialLimitFormState);
    setShowEdit(true);
  };

  const handleParameterChange = (e) => {
    let existingState = { ...limitFormState };
    if (e.id) {
      setDefaultUnit(e.id, existingState);
    }
    setLimitFormState({
      ...existingState,
      parameterId: e.id ?? "",
    });
  };

  const limitForm = (
    <>
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              <Form.Group label="Parameter" isRequired>
                <SearchableSelect
                  name={`parameterId`}
                  options={parameters || []}
                  labelField="name"
                  idField="id"
                  value={limitFormState.parameterId}
                  error={limitFormState.parameterIdError}
                  placeholder={"Search or choose from list"}
                  onChange={handleParameterChange}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} width={12}>
              <Form.Group label="Unit" isRequired>
                <Form.Select
                  onChange={handleLimitInputChange}
                  name={`unitId`}
                  value={limitFormState.unitId}
                  error={limitFormState.unitIdError}
                >
                  <option value={""}></option>
                  {units.map((unit) => (
                    <option value={unit.id} key={unit.id}>
                      {unit.code}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={3} width={12}>
              <Form.Group label="Limit">
                <Form.Input
                  type="number"
                  onChange={handleLimitInputChange}
                  name={`value`}
                  value={limitFormState.value}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Form.Group label="Type" isRequired>
                <Form.Select
                  onChange={handleLimitInputChange}
                  name={`monitoringLimitTypeId`}
                  value={limitFormState.monitoringLimitTypeId}
                  error={limitFormState.monitoringLimitTypeIdError}
                >
                  <option value={""}></option>
                  {limitTypes.map((unit) => (
                    <option value={unit.id} key={unit.id}>
                      {unit.code}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
  const handleLimitRemove = () => {
    deleteTrigger(limitToDelete.id).then((res) => {
      showAlert("success", "Limit deleted.");
      setShowDelete(false);
      setIsLoading(false);
    });
  };
  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0">
          <Grid.Col lg={12} width={12} className="">
            <div className="d-flex">
              <Typography
                variant="h3"
                className="form-header-text"
                component="div"
              >
                Edit Monitoring Parameters and Limits - (
                {monitoringLocation?.name})
              </Typography>
            </div>
          </Grid.Col>{" "}
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "monitoring")}
              >
                Monitoring
              </Link>
              <Typography color="text.primary">
                Edit Monitoring Parameters and Limits - (
                {monitoringLocation?.name})
              </Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      <Grid.Row>
        <Container className="mt-2" maxWidth="xl">
          <Form className="card ">
            <Card.Body className="p-0">
              <Accordion defaultExpanded className="w-100">
                <AccordionSummary
                  aria-controls="details-content"
                  id="details-header"
                >
                  <Typography>Limits</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {!roleMatch([UserRoles.MonitoringViewer]) && (
                    <div className="w-100 d-flex">
                      <Button
                        className="ml-auto  mb-2 mr-1"
                        variant="contained"
                        onClick={handleAddClick}
                      >
                        Add
                      </Button>
                    </div>
                  )}

                  <div style={{ height: "100%" }} className="mt-1">
                    {!_.isEmpty(columns) && (
                      <LynxDataGrid
                        useQuery={useGetMonitoringLimitsQuery}
                        columns={columns}
                        onlyShowTable
                        autoHeight
                        useQueryParams={{
                          monitoringLocationId: params.monLocId,
                        }}
                        localStorageName={`monitoringLimits_${params.monLocId}`}
                        entityType={"MonitoringLimits"}
                      />
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </Card.Body>
          </Form>
        </Container>
      </Grid.Row>
      {showEdit && !_.isEmpty(limitFormState) && (
        <LynxDialog
          dividers
          maxWidth={"md"}
          open={showEdit}
          title={
            limitFormState.id > 0
              ? `Edit Monitoring Parameter / Limit`
              : "Add Monitoring Parameter / Limit"
          }
          fullWidth
          dialogContent={limitForm}
          handleClose={() => {
            setShowEdit(false);
            setLimitFormState({});
          }}
          handleSave={handleSaveLimit}
        />
      )}
      {showDelete && (
        <LynxDialog
          open={showDelete}
          handleClose={() => {
            setShowDelete(false);
            setLimitToDelete({});
          }}
          handleDelete={() => {
            setIsLoading(true);
            handleLimitRemove();
          }}
          title={`Remove Limit?`}
          description={
            "Are you sure you want to remove this limit from the monitoring location?"
          }
        />
      )}
    </Grid>
  );
}
