import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import {
  AudioFile as AudioFileIcon,
  Info as InfoIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Lock as LockIcon,
  VideoFile as VideoFileIcon,
} from "@mui/icons-material";
import { saveAs } from "file-saver";
import _ from "lodash";
import { Dimmer, Form } from "components/lynx-components";
import { LynxDialog } from "components/lynx-dialog";
import { validateFileSize } from "services/attachments";
import { downloadImportedAttachment } from "services/import";
import { roleMatch } from "actions/auth";
import { EntityTypes, UserRoles } from "types/enums";
import useAlert from "hooks/useAlert";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import { EntityAttachmentDto, EventDto } from "types";
import { dateUtil } from "services/date-util";
import {
  useAddEntityAttachmentsMutation,
  useDeleteEntityAttachmentMutation,
  useGetEntityAttachmentsQuery,
  useLazyDownloadAttachmentQuery,
  useLazyGetEntityAttachmentsQuery,
  useUpdateAttachmentDescriptionMutation,
} from "services/rtkApi/endpoints/attachments";
import { NewFile } from "components/attachment-viewer";
import useWindowDimensions from "hooks/useWindowDimensions";
import lynxColors from "modules/lynxColors";

interface Props {
  event: EventDto;
  eventLocked: boolean;
  importAttachments: any[];
}

const EventAttachmentDetails: React.FC<Props> = ({
  event,
  eventLocked,
  importAttachments,
}) => {
  const dimensions = useWindowDimensions();
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<NewFile>({} as NewFile);
  const [files, setFiles] = useState<NewFile[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [attachmentsUploading, setAttachmentsUploading] = useState(false);
  const [attachmentDescription, setAttachmentDescription] = useState("");
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const { showAlert } = useAlert();
  const [getEntityAttachmentsTrigger] = useLazyGetEntityAttachmentsQuery();
  const [addEntityAttachmentsTrigger] = useAddEntityAttachmentsMutation();
  const [deleteEntityAttachmentTrigger] = useDeleteEntityAttachmentMutation();
  const [downloadAttachmentTrigger] = useLazyDownloadAttachmentQuery();
  const [downloadThumbnailTrigger] = useLazyDownloadAttachmentQuery();
  const { data: entityAttachments, isFetching } = useGetEntityAttachmentsQuery(
    { entityType: EntityTypes.Event, entityId: event.id },
    { skip: !event.id }
  );
  const [updateAttachmentDescriptionTrigger] =
    useUpdateAttachmentDescriptionMutation();
  useEffect(() => {
    if (!isFetching) {
      if (!_.isEmpty(entityAttachments)) {
        prepAndSetExistingFiles(entityAttachments as EntityAttachmentDto[]);
      } else {
        setIsLoading(false);
      }
    }
  }, [isFetching]);

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };
  const prepAndSetExistingFiles = async (
    entityAttachments: EntityAttachmentDto[]
  ) => {
    setIsLoading(true);
    const newFiles = [...entityAttachments];
    const filePromises = newFiles
      .filter((x) => !files.map((a) => a.id).includes(x.id))
      .map((file) => {
        if (file.thumbnailPath) {
          return downloadAttachmentTrigger(file.id).then((res: any) => {
            if (res.data) {
              const fileUrl = URL.createObjectURL(res.data);
              setFiles((existing) => [
                {
                  name: file.fileName,
                  url: fileUrl,
                  contentType: file.contentType,
                  id: file.id,
                  createdByUserFullName: file.createdByUserFullName,
                  createdDateTimeUtc: file.createdDateTimeUtc,
                  description: file.description,
                  correspondenceDateTimeUtc: file.correspondenceDateTimeUtc,
                  correspondenceSubject: file.correspondenceSubject,
                  correspondenceTypeCode: file.correspondenceTypeCode,
                },
                ...existing,
              ]);
              return () => URL.revokeObjectURL(fileUrl);
            }
            if (res.error) {
              setFiles((existing) => [
                {
                  name: file.fileName,
                  contentType: file.contentType,
                  id: file.id,
                  createdByUserFullName: file.createdByUserFullName,
                  createdDateTimeUtc: file.createdDateTimeUtc,
                  description: file.description,
                  correspondenceDateTimeUtc: file.correspondenceDateTimeUtc,
                  correspondenceSubject: file.correspondenceSubject,
                  correspondenceTypeCode: file.correspondenceTypeCode,
                },
                ...existing,
              ]);
            }
          });
        } else {
          return Promise.resolve(
            setFiles((existing) => [
              {
                name: file.fileName,
                contentType: file.contentType,
                id: file.id,
                createdByUserFullName: file.createdByUserFullName,
                createdDateTimeUtc: file.createdDateTimeUtc,
                description: file.description,
                correspondenceDateTimeUtc: file.correspondenceDateTimeUtc,
                correspondenceSubject: file.correspondenceSubject,
                correspondenceTypeCode: file.correspondenceTypeCode,
              },
              ...existing,
            ])
          );
        }
      });

    // Wait for all file promises to complete
    await Promise.all(filePromises);

    // Set loading to false only after all files are processed
    setIsLoading(false);
  };

  const handleViewAttachment = (file: NewFile) => {
    setSelectedFile(file);
    setShowAttachmentDialog(true);

    setAttachmentDescription(file.description || "");
  };

  const handleDownloadFile = (id: number, fileName: string) => {
    downloadAttachmentTrigger(id).then((res: any) =>
      saveAs(res.data, fileName)
    );
  };

  const handleDownloadImportFile = (id: number, fileName: string) => {
    downloadImportedAttachment(id).then((res) => saveAs(res.data, fileName));
  };

  const handleUploadClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files || [];
    if (!_.isEmpty(selectedFiles)) {
      if (validateFileSize(Array.from(selectedFiles))) {
        setAttachmentsUploading(true);
        addEntityAttachmentsTrigger({
          entityType: EntityTypes.Event,
          entityId: event.id,
          attachments: selectedFiles,
        })
          .then(() => {
            getEntityAttachmentsTrigger({
              entityType: EntityTypes.Event,
              entityId: event.id,
            }).then((res: any) => {
              setAttachmentsUploading(false);
            });
          })
          .catch(() => {
            showAlert("error", "Error uploading attachments.");
            setAttachmentsUploading(false);
          });
      } else {
        setShowFileSizeError(true);
      }
    }
  };
  const handleDeleteAttachment = () => {
    deleteEntityAttachmentTrigger(selectedFile.id).then((res: any) => {
      let newFiles = files.filter((x) => x.id != selectedFile.id);
      setFiles(newFiles);
    });

    showAlert("success", "Attachment deleted.");

    setShowAttachmentDialog(false);
  };

  const handleSaveDescription = () => {
    const dto = {
      description: attachmentDescription,
    };
    updateAttachmentDescriptionTrigger({
      id: selectedFile.id,
      dto: dto,
    }).then((res) => {
      showAlert("success", "Attachment description updated.");
      const newThumbnails = [...files];
      const index = newThumbnails.findIndex((f) => f.id == selectedFile.id);
      if (index != -1) {
        newThumbnails[index].description = attachmentDescription;
        setFiles(newThumbnails);
      }
    });
  };

  const getFileIcon = (file: EntityAttachmentDto) => {
    if (file.contentType?.includes("video"))
      return <VideoFileIcon color="action" sx={{ fontSize: 150 }} />;
    if (file.contentType?.includes("audio"))
      return <AudioFileIcon color="action" sx={{ fontSize: 150 }} />;
    return <InsertDriveFileIcon color="action" sx={{ fontSize: 150 }} />;
  };

  const userRoleCanSave = roleMatch([
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
    UserRoles.EventsContributor,
  ]);
  const userRoleCanDelete = roleMatch([UserRoles.EventsAdministrator]);

  return (
    <div className="w-100 d-flex justify-content-center">
      <Card
        className="lynx-card"
        variant="outlined"
        sx={{ width: "100%", minHeight: isLoading ? "283px" : "" }}
      >
        <CardHeader
          title="Attachments"
          className="lynx-card-header"
          action={
            userRoleCanSave &&
            (eventLocked ? (
              <LockIcon color="disabled" fontSize="small" />
            ) : (
              <Button onClick={handleUploadClick}>Upload</Button>
            ))
          }
        />
        <CardContent sx={{ minHeight: 50 }}>
          <Dimmer active={isLoading} loader>
            {files.length <= 0 && !isLoading && <div>No attachments</div>}
            <ImageList
              cols={dimensions.isMobile ? 2 : 3}
              gap={8}
              rowHeight={150}
            >
              {importAttachments &&
                importAttachments.map((file, i) => (
                  <ImageListItem
                    key={"system" + _.toString(i)}
                    sx={{ border: `1px solid ${lynxColors.gray}` }}
                  >
                    <div className=" h-100 d-flex align-items-center justify-content-center">
                      <InsertDriveFileIcon
                        color="action"
                        className="mb-5"
                        sx={{ fontSize: 150 }}
                      />
                    </div>

                    <ImageListItemBar
                      title={file.fileName}
                      key={"systemItem" + _.toString(i)}
                      actionIcon={
                        <IconButton
                          key={"systemIcon" + _.toString(i)}
                          sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about ${file.fileName}`}
                          onClick={() => handleViewAttachment(file)}
                        >
                          <InfoIcon key={"systemInfo" + _.toString(i)} />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                ))}
              {files.map((file, i) => (
                <>
                  {file.contentType?.includes("image") ? (
                    <ImageListItem
                      key={"list" + _.toString(i)}
                      sx={{ border: `1px solid ${lynxColors.gray}` }}
                    >
                      <img
                        alt={file.name}
                        src={file.url}
                        loading="lazy"
                        key={"img" + _.toString(i)}
                        style={{
                          objectFit: "contain",
                          height: 100,
                          minWidth: 150,
                        }}
                      />

                      <ImageListItemBar
                        title={file.name}
                        key={"item" + _.toString(file.id || file.name)}
                        actionIcon={
                          <IconButton
                            key={"icon" + _.toString(file.id || file.name)}
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            aria-label={`info about ${file.fileName}`}
                            onClick={() => handleViewAttachment(file)}
                          >
                            <InfoIcon key={"info" + _.toString(file.id)} />
                          </IconButton>
                        }
                      />
                    </ImageListItem>
                  ) : (
                    <ImageListItem
                      key={"list" + _.toString(i)}
                      className="w-100"
                      sx={{ border: `1px solid ${lynxColors.gray}` }}
                    >
                      <div className=" h-100 w-100 d-flex align-items-center justify-content-center">
                        {getFileIcon(file)}
                      </div>

                      <ImageListItemBar
                        title={file.name}
                        key={"item" + _.toString(file.id || file.name)}
                        actionIcon={
                          <IconButton
                            key={"icon" + _.toString(file.id || file.name)}
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            aria-label={`info about ${file.fileName}`}
                            onClick={() => handleViewAttachment(file)}
                          >
                            <InfoIcon key={"info" + _.toString(file.id)} />
                          </IconButton>
                        }
                      />
                    </ImageListItem>
                  )}
                </>
              ))}
            </ImageList>
          </Dimmer>
        </CardContent>
        <input
          type="file"
          multiple
          ref={hiddenFileInput}
          onChange={handleFileChange}
          style={{ display: "none" }}
          onClick={handleInputClick}
          accept=".csv, .xlsx, .xls, .txt, image/*, .html, video/*, audio/*, .pdf, .doc, .docx, .ppt"
        />
      </Card>
      {showAttachmentDialog && !_.isEmpty(selectedFile) && (
        <LynxDialog
          open={showAttachmentDialog && !_.isEmpty(selectedFile)}
          handleClose={() => setShowAttachmentDialog(false)}
          handleDownload={() => {
            if (selectedFile.isImport) {
              handleDownloadImportFile(
                selectedFile.importHistoryId as number,
                selectedFile.name as string
              );
            } else {
              handleDownloadFile(
                selectedFile.id as number,
                selectedFile.name as string
              );
            }
          }}
          handleDelete={
            selectedFile.isImport ||
            !userRoleCanDelete ||
            selectedFile.correspondenceDateTimeUtc
              ? null
              : handleDeleteAttachment
          }
          title={selectedFile.name}
          isCloseInHeader
          description={
            <>
              <div className="p-3">
                {selectedFile.contentType &&
                selectedFile.contentType.includes("image") &&
                selectedFile.url ? (
                  <img
                    src={selectedFile.url}
                    alt={
                      "img" + _.toString(selectedFile.id || selectedFile.name)
                    }
                    loading="lazy"
                    key={
                      "img" + _.toString(selectedFile.id || selectedFile.name)
                    }
                  />
                ) : (
                  getFileIcon(selectedFile)
                )}
              </div>
              {(selectedFile.id || selectedFile.isImport) && (
                <Box>
                  <Form.Group label="Description">
                    <LynxTextArea
                      name="contactDetails"
                      onChange={(e: any) =>
                        setAttachmentDescription(e.target.value)
                      }
                      value={
                        selectedFile.isImport
                          ? `File imported on ${dateUtil.convertDateTimeToLocal(
                              selectedFile.importDateTimeUtc
                            )} by ${selectedFile.uploaderUserFullName}.`
                          : attachmentDescription
                      }
                      disabled={selectedFile.isImport}
                    ></LynxTextArea>
                  </Form.Group>{" "}
                  {selectedFile.correspondenceDateTimeUtc && (
                    <>
                      <Typography variant="subtitle2">
                        Attached to correspondence log
                      </Typography>
                      <Typography variant="subtitle2">
                        Date Time:{" "}
                        {dateUtil.convertDateTimeToLocal(
                          selectedFile.correspondenceDateTimeUtc
                        )}
                      </Typography>
                      <Typography variant="subtitle2">
                        Type: {selectedFile.correspondenceTypeCode}
                      </Typography>
                      <Typography variant="subtitle2">
                        Subject: {selectedFile.correspondenceSubject}
                      </Typography>
                    </>
                  )}
                </Box>
              )}

              {selectedFile.createdByUserFullName && (
                <>
                  <Typography variant="subtitle2">
                    Uploaded By: {selectedFile.createdByUserFullName}
                  </Typography>
                  <Typography variant="subtitle2">
                    {dateUtil.convertDateTimeToLocal(
                      selectedFile.createdDateTimeUtc
                    )}
                  </Typography>
                </>
              )}
            </>
          }
          handleSave={
            selectedFile.isImport || !userRoleCanSave
              ? null
              : handleSaveDescription
          }
        />
      )}
      <LynxDialog
        open={attachmentsUploading}
        title={`Uploading attachments. Do not close the window.`}
        description={
          <>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          </>
        }
      />
      {showFileSizeError && (
        <p className="text-danger">File size exceeds the limit</p>
      )}
    </div>
  );
};

export default EventAttachmentDetails;
